import { createSlice } from '@reduxjs/toolkit';
import { ISpecialPeriods, IPagination } from 'src/utils/types/specialPeriodsTypes';
import {
  CreateSpecialPeriods,
  DeleteSpecialPeriods,
  GetAllSpecialPeriods,
  GetSingleSpecialPeriods,
  UpdateSpecialPeriods,
} from './thunk';




interface ISpecialPeriodsState {
  data: ISpecialPeriods[];
  pagination: IPagination;
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: ISpecialPeriodsState = {
  data: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

export const specialPeriodsTestData: ISpecialPeriods[] = [
  {
    id: '60d5ec49f1b2c12d4c8e4a1a',
    propertyId: 'A12345',
    kind: 'event',
    start: '2024-01-01',
    end: '2024-01-06',
    title: "New Year's Party",
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a1b',
    propertyId: 'B23456',
    kind: 'maintenance',
    start: '2024-02-10',
    end: '2024-02-12',
    title: 'HVAC Maintenance',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a1c',
    propertyId: 'C34567',
    kind: 'event',
    start: '2024-03-15',
    end: '2024-03-20',
    title: 'Spring Festival',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a1d',
    propertyId: 'D45678',
    kind: 'maintenance',
    start: '2024-04-05',
    end: '2024-04-07',
    title: 'Plumbing Work',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a1e',
    propertyId: 'E56789',
    kind: 'event',
    start: '2024-05-01',
    end: '2024-05-03',
    title: 'May Day Celebration',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a1f',
    propertyId: 'F67890',
    kind: 'maintenance',
    start: '2024-06-10',
    end: '2024-06-12',
    title: 'Electrical Inspection',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a20',
    propertyId: 'G78901',
    kind: 'event',
    start: '2024-07-04',
    end: '2024-07-06',
    title: 'Independence Day',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a21',
    propertyId: 'H89012',
    kind: 'maintenance',
    start: '2024-08-15',
    end: '2024-08-17',
    title: 'Roof Repair',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a22',
    propertyId: 'I90123',
    kind: 'event',
    start: '2024-09-10',
    end: '2024-09-12',
    title: 'Autumn Festival',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a23',
    propertyId: 'J01234',
    kind: 'maintenance',
    start: '2024-10-05',
    end: '2024-10-07',
    title: 'Window Cleaning',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a24',
    propertyId: 'K12345',
    kind: 'event',
    start: '2024-11-01',
    end: '2024-11-03',
    title: 'Thanksgiving',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a25',
    propertyId: 'L23456',
    kind: 'maintenance',
    start: '2024-12-10',
    end: '2024-12-12',
    title: 'Fire Safety Inspection',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a26',
    propertyId: 'M34567',
    kind: 'event',
    start: '2024-01-20',
    end: '2024-01-22',
    title: 'Winter Carnival',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a27',
    propertyId: 'N45678',
    kind: 'maintenance',
    start: '2024-02-25',
    end: '2024-02-27',
    title: 'Elevator Maintenance',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a28',
    propertyId: 'O56789',
    kind: 'event',
    start: '2024-03-30',
    end: '2024-04-01',
    title: 'Easter Celebration',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a29',
    propertyId: 'P67890',
    kind: 'maintenance',
    start: '2024-04-15',
    end: '2024-04-17',
    title: 'Garden Maintenance',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a2a',
    propertyId: 'Q78901',
    kind: 'event',
    start: '2024-05-20',
    end: '2024-05-22',
    title: 'Memorial Day',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a2b',
    propertyId: 'R89012',
    kind: 'maintenance',
    start: '2024-06-25',
    end: '2024-06-27',
    title: 'Pool Cleaning',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a2c',
    propertyId: 'S90123',
    kind: 'event',
    start: '2024-07-15',
    end: '2024-07-17',
    title: 'Summer Festival',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a2d',
    propertyId: 'T01234',
    kind: 'maintenance',
    start: '2024-08-20',
    end: '2024-08-22',
    title: 'Parking Lot Resurfacing',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a2e',
    propertyId: 'U12345',
    kind: 'event',
    start: '2024-09-25',
    end: '2024-09-27',
    title: 'Harvest Festival',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a2f',
    propertyId: 'V23456',
    kind: 'maintenance',
    start: '2024-10-15',
    end: '2024-10-17',
    title: 'Gutter Cleaning',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a30',
    propertyId: 'W34567',
    kind: 'event',
    start: '2024-11-20',
    end: '2024-11-22',
    title: 'Pre-Christmas Party',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a31',
    propertyId: 'X45678',
    kind: 'maintenance',
    start: '2024-12-25',
    end: '2024-12-27',
    title: 'Boiler Inspection',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a32',
    propertyId: 'Y56789',
    kind: 'event',
    start: '2024-01-05',
    end: '2024-01-07',
    title: 'New Year Celebration',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a33',
    propertyId: 'Z67890',
    kind: 'maintenance',
    start: '2024-02-15',
    end: '2024-02-17',
    title: 'Pest Control',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a34',
    propertyId: 'A78901',
    kind: 'event',
    start: '2024-03-10',
    end: '2024-03-12',
    title: "St. Patrick's Day",
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a35',
    propertyId: 'B89012',
    kind: 'maintenance',
    start: '2024-04-20',
    end: '2024-04-22',
    title: 'Spring Cleaning',
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a36',
    propertyId: 'C90123',
    kind: 'event',
    start: '2024-05-10',
    end: '2024-05-12',
    title: "Mother's Day",
  },
  {
    id: '60d5ec49f1b2c12d4c8e4a37',
    propertyId: 'D01234',
    kind: 'maintenance',
    start: '2024-06-15',
    end: '2024-06-17',
    title: 'AC Servicing',
  },
];

const specialPeriodsSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllSpecialPeriods.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllSpecialPeriods.fulfilled, (state, action) => {
        if (action.payload) {
          const { data, pagination } = action.payload;
          state.data = data;
          state.pagination = pagination;
        } else {
          state.data = [];
          state.pagination = { currentPage: 1, pageSize: 10, totalPages: 0, totalCount: 0, hasPrevious: false, hasNext: false };
        }
        state.isLoading = false;
      })


      .addCase(GetAllSpecialPeriods.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleSpecialPeriods.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleSpecialPeriods.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleSpecialPeriods.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(CreateSpecialPeriods.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateSpecialPeriods.fulfilled, (state) => {
        state.isCreating = false;
      })
      .addCase(CreateSpecialPeriods.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateSpecialPeriods.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateSpecialPeriods.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(UpdateSpecialPeriods.rejected, (state, action) => {
        state.isUpdating = false;
      })
      .addCase(DeleteSpecialPeriods.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteSpecialPeriods.fulfilled, (state) => {
        state.isDeleting = false;
      })
      .addCase(DeleteSpecialPeriods.rejected, (state, action) => {
        state.isDeleting = false;
      });
  },
});

export default specialPeriodsSlice.reducer;
