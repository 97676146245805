import { createAsyncThunk } from '@reduxjs/toolkit';
import { PermissionService } from 'src/utils/services/service/permissionService';
import { IRoles, IPagination } from 'src/utils/types/roleTypes';



export const GetAllRoles = createAsyncThunk<
  { data: IRoles[]; pagination: IPagination },
  { page: number; pageSize: number; }
>('roles/get-all-roles', async ({ page, pageSize }) => {
  try {
    const response = await PermissionService.getAllRoles(page, pageSize);
    return response;
  } catch (error) {
    return error;
  }
});

export const GetPermissionsByRole = createAsyncThunk(
  'roles/get-permissions-by-role',
  async ({ roleId }: { roleId: string }) => {
    try {
      const response = await PermissionService.getPermissionsByRole(roleId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateRole = createAsyncThunk(
  'roles/update-permissions',
  async ({ roleId, data }: { roleId: string; data: any }) => {
    try {
      const response = await PermissionService.updateRole(roleId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteRole = createAsyncThunk('roles/delete-role', async ({ roleId }: { roleId: string }) => {
  try {
    const response = await PermissionService.deleteRole(roleId);
    return response;
  } catch (error) {
    return error;
  }
});

export const CreateRole = createAsyncThunk('roles/create-role', async ({ data }: { data: any }) => {
  try {
    const response = await PermissionService.createRole(data);
    return response;
  } catch (error) {
    return error;
  }
});
