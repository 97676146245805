import { createAsyncThunk } from '@reduxjs/toolkit';
import { PriceListService } from 'src/utils/services/service/priceList';

import { IPriceList, IPagination } from 'src/utils/types/priceListTypes';

export const CreatePriceListData = createAsyncThunk(
  'data/createPriceListData',
  async ({ data }: { data: IPriceList }) => {
    try {
      const response = await PriceListService.createPriceListData(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);


export const GetAllPriceListData = createAsyncThunk<
  { data: IPriceList[]; pagination: IPagination },
  { page: number; pageSize: number; }
>('data/getAllPriceListData', async ({ page, pageSize }) => {
  try {
    const response = await PriceListService.getAllPriceListData(page, pageSize);
    return response;
  } catch (error) {
    return error;
  }
});

export const GetSinglePriceListData = createAsyncThunk(
  'data/getSinglePriceListData',
  async ({ pricelistId }: { pricelistId: string }) => {
    try {
      const response = await PriceListService.getSinglePriceListData(pricelistId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdatePriceListData = createAsyncThunk(
  'data/updatePriceListData',
  async ({ pricelistId, data }: { pricelistId: string; data: IPriceList }) => {
    try {
      const response = await PriceListService.updatePriceListData(pricelistId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeletePriceListData = createAsyncThunk(
  'data/deletePriceListData',
  async ({ pricelistId }: { pricelistId: string }) => {
    try {
      const response = await PriceListService.deletePriceListData(pricelistId);
      return response;
    } catch (error) {
      return error;
    }
  },
);
