import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createConfig,
  deleteConfig,
  getConfigs,
  getTenantProviders,
  updateConfig,
} from 'src/utils/services/service/importProviderService';

export const getProviderConfigs = createAsyncThunk(
  "providers/getProviderConfigs",
  async () => {
    return await getConfigs();
  }
);

export const getProviders = createAsyncThunk(
  "providers/getProviders",
  async () => {
    return await getTenantProviders();
  }
);

export const createConfiguration = createAsyncThunk<any, any, { rejectValue: string }>(
  'providers/createConfiguration',
  async (data, thunkAPI) => {
    try {
      const res = await createConfig(data);
      if (res!.configurationId) {
        await thunkAPI.dispatch(getProviderConfigs());
      }
      return res;
    } catch (error) {
      console.error(error)
    }
  }
);

export const updateConfiguration = createAsyncThunk<any, any, { rejectValue: any, state: any }>(
  'providers/updateConfiguration',
  async (data: any, thunkAPI) => {
    const res = await updateConfig(data)
    if (res!.configurationId) {
      await thunkAPI.dispatch(getProviderConfigs());
    }
    return res
  }
);

export const deleteConfiguration = createAsyncThunk<any, any, { rejectValue: any, state: any }>(
  'providers/deleteConfiguration',
  async (data: any, thunkAPI) => {
    const res = await deleteConfig(data)

    if (res) {
      await thunkAPI.dispatch(getProviderConfigs());
    }

    return res
  }
);
