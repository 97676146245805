/* eslint-disable react-hooks/exhaustive-deps */
import { notification, Tooltip, Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { AppDispatch } from 'src/store';
import { ITag, IPagination } from 'src/utils/types/tagTypes';
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TagsModal from './components/TagsModal';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { TableProps } from 'antd/lib';
import { FiEdit } from 'react-icons/fi';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import TableView from 'src/components/Common/TableView/TableView';
import { DeleteTag, GetAllTags } from 'src/slices/Tags/thunk';
import { useTranslation } from 'react-i18next';
import { checkPermission } from 'src/helpers/checkActionPermission';
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';

const Tags = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<ITag | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const { isLoading, tags, isDeleting } = useSelector((state: RootState) => state.tags);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('');

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (!isLoading && tags.length === 0) {
      dispatch(GetAllTags({ page: pageNumber, pageSize })).then((action: any) => {
        if (action.payload) {
          setPagination(action.payload.pagination);
        }
      });
    }
  }, [pageNumber, pageSize]);

  const { userData } = useSelector((state: any) => state.login);

  const hasUpdatePermission = checkPermission('put', 'Tags', userData);
  const hasDeletePermission = checkPermission('delete', 'Tags', userData);
  const hasCreatePermission = checkPermission('post', 'Tags', userData);

  const columns: TableProps<ITag>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 100,
      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            {/* {hasUpdatePermission && ( */}
            <Tooltip title={t('Edit')} placement="top">
              <div
                className="tableIcon"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsEditMode(true);
                  setEditingData(record);
                }}
                aria-label={t('Edit Tags')}
              >
                <FiEdit id="updateTag" size={20} />
              </div>
            </Tooltip>
            {/* )} */}
            {/* {hasDeletePermission && ( */}
            <Tooltip title={t('Delete')} placement="top">
              <div className="tableIcon">
                <DeleteButton
                  cancelButtonId="cancelDeleteTagProcess"
                  title={t('Are you sure you want to delete?')}
                  cancelText={t('Cancel')}
                  okText={t('Proceed')}
                  okButtonId={`confirmAndDeleteTag`}
                  triggerButtonId="deleteTag"
                  onConfirm={() => {
                    deleteTag(record.id as string);
                  }}
                  aria-label={t('Delete Tags')}
                />
              </div>
            </Tooltip>
            {/* )} */}
          </div>
        );
      },
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: t('Assignments'),
      dataIndex: 'assignments',
      key: 'assignments',
      width: 300,
      render: (items: { year: number; week: number }[]) => {
        return (
          <div>
            {items.map((item, i) => (
              <div key={i}>
                Year: <span>{item.year}</span>, Week: {item.week}
                <hr />
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  const deleteTag = async (tagId: string) => {
    if (!tagId) {
      openNotificationWithIcon('error', t('Tag id is required'));
      return;
    }
    try {
      const response: any = await dispatch(
        DeleteTag({
          tagId,
        }),
      );
      if (response?.payload?.status === 204) {
        openNotificationWithIcon('success', t('Tag deleted successfully'));
        dispatch(GetAllTags({ page: pageNumber, pageSize }));
      } else {
        openNotificationWithIcon('error', t('Error deleting tag'));
      }
    } catch (error) {
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  return (
    <div>
      {contextHolder}
      <div className="page-content">
        <MetaTags>
          <title>{t('Tags')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title={t('Settings')} breadcrumbItem={t('Tags')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Tooltip title={t('Search tags by name')}>
                        <div>
                          <SearchBar
                            id="searchTags"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={t('Search tags by name')}
                          />
                        </div>
                      </Tooltip>
                    </Col>

                    {/* {hasCreatePermission && (
                    
                    )} */}

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Tooltip title={t('Add')}>
                            <Button
                              id="createTagButton"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> {t('Add')}
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                    }}
                  >
                    <TableViewPagination
                      loading={isLoading || isDeleting}
                      columns={columns}
                      dataSource={tags
                        ?.filter((tag) => {
                          if (!searchValue) return true;
                          const searchWords = searchValue?.toLowerCase().split(' ');
                          return searchWords?.some((word) => tag?.name?.toLowerCase()?.includes(word));
                        })
                        ?.reverse()}
                      showPagination={false}
                    />

                    <Pagination
                      current={pageNumber}
                      pageSize={pageSize}
                      total={pagination?.totalCount || 0}
                      onChange={(page, pageSize) => {
                        setPageNumber(page);
                        setPageSize(pageSize);
                        dispatch(GetAllTags({ page, pageSize }));
                      }}
                      style={{ float: 'right', marginTop: '20px' }}
                      showSizeChanger
                      pageSizeOptions={['10', '20', '30', '50', '100']}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/**  Modal to handle create and update  **/}
          <TagsModal
            pagination={pagination}
            isEditMode={isEditMode}
            initialValues={editingData}
            openNotificationWithIcon={openNotificationWithIcon}
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            setIsEditMode={setIsEditMode}
            setEditingData={setEditingData}
          />
        </Container>
      </div>
    </div>
  );
};

export default Tags;
