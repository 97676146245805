import { createAsyncThunk } from '@reduxjs/toolkit';
import { PreProcessedDataSetsService } from 'src/utils/services/service/mLPreProcessingFlowService';



export const fetchModelTrainingStatus = createAsyncThunk(
  'modelTrainingStatus/fetch',
  async (training_id: string, { rejectWithValue }) => {
    try {
      
      await new Promise((resolve) => setTimeout(resolve, 1000));

     
      const dummyResponse = {
        training_id: 'abc123',
        status: 'completed',
        progress: 100,
      };

      return dummyResponse;
    } catch (error) {
      return rejectWithValue('Failed to fetch model training status');
    }
  }
);