import axios, { AxiosResponse } from 'axios';
import env from 'src/env';
import { ISpecialPeriods, IPagination } from 'src/utils/types/specialPeriodsTypes';

const baseURL = env.VITE_SPECIAL_PERIODS_DEFAULT_BASE_URL();
const specialPeriodsBaseUrl = '/specialperiod/booked-events';

const getAllSpecialPeriods = async (pageNumber: number, pageSize: number) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    };
    const params = { pageNumber, pageSize };

    const response = await axios.get<{ data: ISpecialPeriods[]; pagination: IPagination }>(`${specialPeriodsBaseUrl}`, {
      headers,
      baseURL,
      params,
    });

    return response;
  } catch (error) {
    console.error('Error fetching all special periods:', error);
    throw error;
  }
};
const getSingleSpecialPeriods = async (eventId: string): Promise<ISpecialPeriods | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: ISpecialPeriods = await axios.get(`${specialPeriodsBaseUrl}/${eventId}`, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching single special period:', error);
    throw error;
  }
};

const createSpecialPeriods = async (data: ISpecialPeriods): Promise<ISpecialPeriods | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: ISpecialPeriods = await axios.post(`${specialPeriodsBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating special period:', error);
    throw error;
  }
};

const updateSpecialPeriods = async (eventId: string, data: ISpecialPeriods): Promise<ISpecialPeriods | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: ISpecialPeriods = await axios.put(`${specialPeriodsBaseUrl}/${eventId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating special period:', error);
    throw error;
  }
};

const deleteSpecialPeriods = async (eventId: string): Promise<string | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    await axios.delete(`${specialPeriodsBaseUrl}/${eventId}`, { headers, baseURL });
    return eventId;
  } catch (error) {
    console.error('Error deleting special period:', error);
    throw error;
  }
};

export const SpecialPeriodsService = {
  getAllSpecialPeriods,
  getSingleSpecialPeriods,
  createSpecialPeriods,
  updateSpecialPeriods,
  deleteSpecialPeriods,
};
