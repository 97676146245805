import { createSlice } from '@reduxjs/toolkit';
import {
  CreateImageData,
  DeleteImageData,
  GetAllImageData,
  GetSingleImageData,
  UpdateImageData,
} from './thunk';
import { IimageManagement, IPagination } from 'src/utils/types/imageManagementTypes';

interface IimageManagementState {
  data: IimageManagement[];
  pagination: IPagination;
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IimageManagementState = {
  data: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const imageManagementSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllImageData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllImageData.fulfilled, (state, action) => {
        if (action.payload) {
          const { data, pagination } = action.payload;
          state.data = data;
          state.pagination = pagination;
        } else {
          state.data = [];
          state.pagination = { currentPage: 1, pageSize: 10, totalPages: 0, totalCount: 0, hasPrevious: false, hasNext: false };
        }
        state.isLoading = false;
      })
      .addCase(GetAllImageData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleImageData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleImageData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleImageData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })

      .addCase(CreateImageData.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateImageData.fulfilled, (state, action) => {
        state.data.push(action.payload as IimageManagement);
        state.isCreating = false;
      })

      .addCase(CreateImageData.rejected, (state, action) => {
        state.error = action.payload as string || 'An error occurred while creating the image';
        state.isCreating = false;
      })

      .addCase(UpdateImageData.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateImageData.fulfilled, (state, action) => {
        const updatedImage = action.payload as IimageManagement;
        const index = state.data.findIndex((img) => img.id === updatedImage.id);
        if (index !== -1) {
          state.data[index] = updatedImage;
        }
        state.isUpdating = false;
      })
      .addCase(UpdateImageData.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to update data';
        state.isUpdating = false;
      })
      .addCase(DeleteImageData.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteImageData.fulfilled, (state, action) => {
        const deletedImageId = action.payload as string;
        state.data = state.data.filter((img) => img.id !== deletedImageId);
        state.isDeleting = false;
      })
      .addCase(DeleteImageData.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to delete data';
        state.isDeleting = false;
      });
  },
});

export default imageManagementSlice.reducer;
