import React, { useRef, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import './index.scss';

import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { message, Upload } from 'antd';

const { Dragger } = Upload;

import type { ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import { Tag } from 'antd';
import Breadcrumb from 'src/components/Common/Breadcrumb';

type EventData = {
  DateCreated: string;
  DefaultOccupancy: number;
  DiscountedRate: number;
  EventEndDate: string;
  EventLocation: string;
  EventStartDate: string;
  EventType: string;
  ExpectedAttendance: number;
  IsCancelled: boolean;
  IsRoomInternal: string;
  MandantId: number;
  MealPlan: string;
  MinAdultsForChildRates: number;
  OccupancyRate: number;
  OfferNumber: number;
  Probability: number;
  PromotionVendor: string;
  ReservationType: string;
  RoomSize: number;
  RoomType: string;
  RoomTypeCategory: string;
  RoomTypeId: number;
  SourceOfBusiness: string;
  TotalRate: number;
  WeatherConditions: string;
};

const columns1: ProColumns<EventData>[] = [
  {
    title: 'Will be cancelled?',
    dataIndex: 'IsCancelled',
    valueType: 'select',
    valueEnum: {
      true: { text: 'Yes' },
      false: { text: 'No' },
    },
  },
  {
    title: 'Probability',
    dataIndex: 'Probability',
    render: (_, record) => {
      return (
        <Tag color={record.Probability > 0.5 ? 'green' : 'red'} key={record.Probability}>
          {(record.Probability * 100).toFixed(2)}%
        </Tag>
      );
    },
  },
  {
    title: 'Date Created',
    dataIndex: 'DateCreated',
    valueType: 'date',
  },
  {
    title: 'Default Occupancy',
    dataIndex: 'DefaultOccupancy',
    valueType: 'digit',
  },
  {
    title: 'Discounted Rate',
    dataIndex: 'DiscountedRate',
    valueType: 'digit',
  },
  {
    title: 'Event End Date',
    dataIndex: 'EventEndDate',
    valueType: 'date',
  },
  {
    title: 'Event Location',
    dataIndex: 'EventLocation',
  },
  {
    title: 'Event Start Date',
    dataIndex: 'EventStartDate',
    valueType: 'date',
  },
  {
    title: 'Event Type',
    dataIndex: 'EventType',
    valueType: 'select',
    valueEnum: {
      Meeting: { text: 'Meeting' },
      Conference: { text: 'Conference' },
      Wedding: { text: 'Wedding' },
      Birthday: { text: 'Birthday' },
      Other: { text: 'Other' },
    },
  },
  {
    title: 'Expected Attendance',
    dataIndex: 'ExpectedAttendance',
    valueType: 'digit',
  },
  {
    title: 'Is Room Internal',
    dataIndex: 'IsRoomInternal',
    valueType: 'select',
    valueEnum: {
      true: { text: 'Yes' },
      false: { text: 'No' },
    },
  },
  {
    title: 'Mandant Id',
    dataIndex: 'MandantId',
  },
  {
    title: 'Meal Plan',
    dataIndex: 'MealPlan',
  },
  {
    title: 'Min Adults For Child Rates',
    dataIndex: 'MinAdultsForChildRates',
    valueType: 'digit',
  },
  {
    title: 'Occupancy Rate',
    dataIndex: 'OccupancyRate',
    valueType: 'digit',
  },
  {
    title: 'Offer Number',
    dataIndex: 'OfferNumber',
  },
  {
    title: 'Promotion Vendor',
    dataIndex: 'PromotionVendor',
  },
  {
    title: 'Reservation Type',
    dataIndex: 'ReservationType',
  },
  {
    title: 'Room Size',
    dataIndex: 'RoomSize',
  },
  {
    title: 'Room Type',
    dataIndex: 'RoomType',
  },
  {
    title: 'Room Type Category',
    dataIndex: 'RoomTypeCategory',
  },
  {
    title: 'Room Type Id',
    dataIndex: 'RoomTypeId',
  },
  {
    title: 'Source Of Business',
    dataIndex: 'SourceOfBusiness',
  },
  {
    title: 'Total Rate',
    dataIndex: 'TotalRate',
  },
  {
    title: 'Weather Conditions',
    dataIndex: 'WeatherConditions',
  },
];

const data: EventData[] = [
  {
    DateCreated: '2021-01-01',
    DefaultOccupancy: 1,
    DiscountedRate: 90,
    EventEndDate: '2021-01-01',
    EventLocation: 'Conference Room',
    EventStartDate: '2021-01-01',
    EventType: 'Meeting',
    ExpectedAttendance: 10,
    IsCancelled: false,
    IsRoomInternal: 'No',
    MandantId: 1,
    MealPlan: 'BB',
    MinAdultsForChildRates: 1,
    OccupancyRate: 0.5,
    OfferNumber: 1,
    Probability: 0.9885492230185602,
    PromotionVendor: 'None',
    ReservationType: 'Online',
    RoomSize: 20,
    RoomType: 'Single',
    RoomTypeCategory: 'Standard',
    RoomTypeId: 1,
    SourceOfBusiness: 'Direct',
    TotalRate: 100,
    WeatherConditions: 'Sunny',
  },
];

const CancellationPredictionBulk: React.FC = () => {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [eventData, setEventData] = useState<EventData[]>([]);

  const props: UploadProps = {
    accept: '.json',
    name: 'file',
    multiple: false,
    customRequest(options) {
      setLoadingData(true);
      const { onSuccess, onError, file } = options;
      const formData = new FormData();
      formData.append('file', file);
      fetch('https://api.ml.dev.data.projects.excitingdevs.com/api/v1/inferencelocal/upload-predict', {
        method: 'POST',
        body: formData,
      })
        .then(async (res) => {
          const data = await res.json();
          if (res.status === 200) {
            if (data.length === 0) {
              message.error('No data found in the file');
            }
            setEventData(data);
            setLoadingData(false);
            onSuccess(data, file);
          } else {
            setLoadingData(false);
            onError(data, file);
          }
        })
        .catch((error) => {
          setLoadingData(false);
          onError(error, file);
        });
    },
    onChange(info) {
      const { status } = info.file;

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Cancellation</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumb title="Dashboard" breadcrumbItem="Cancellation" />
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="dashboard-container">
                      <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                          Support for a single upload. Once the file is uploaded, the data will be displayed in the
                          table below. And previous data will be replaced. But you can upload the same file again to get
                          the data back.
                        </p>
                      </Dragger>
                      {eventData.length !== 0 && (
                        <ProTable<EventData>
                          loading={loadingData}
                          columns={columns1}
                          dataSource={eventData}
                          tableLayout="auto"
                          scroll={{ x: 'max-content' }}
                          rowKey="id"
                          pagination={{
                            showQuickJumper: true,
                            pageSize: 8,
                            pageSizeOptions: [5, 10, 20, 50, 100],
                          }}
                          dateFormatter="string"
                          rowClassName={(record) => {
                            return record.IsCancelled ? 'table-row-cancelled' : '';
                          }}
                          search={false}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CancellationPredictionBulk;
