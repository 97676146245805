/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, notification, Switch, Select, Upload, Image, Spin, Tooltip, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RootState } from 'src/store';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useEffect, useState } from 'react';
import { IimageManagement, IPagination } from 'src/utils/types/imageManagementTypes';
import { UploadOutlined } from '@ant-design/icons';
import {
  CreateImageData,
  GetAllImageData,
  UpdateImageData,
  GetSingleImageData,
} from 'src/slices/ImageManagement/thunk';
import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';

import { FiUpload, FiUploadCloud } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { LuTrash } from 'react-icons/lu';

interface IimageManagementProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IimageManagement | null;
  initialViewValues?: IimageManagement | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  isViewMode?: boolean;
  setIsViewMode: (isViewMode: boolean) => void;
  setEditingData: (data: IimageManagement | null) => void;
  setViewData: (data: IimageManagement | null) => void;
  pagination: IPagination;
}

const ImageManagementModal = ({
  isOpen,
  toggle,
  pagination,
  openNotificationWithIcon,
  initialValues,
  initialViewValues,
  isEditMode = false,
  setIsEditMode,
  isViewMode = false,
  setIsViewMode,

  setEditingData,
  setViewData,
}: IimageManagementProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.imageManagement);
  const dispatch: AppDispatch = useDispatch();
  const [fileList, setFileList] = useState<any[]>([]);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const { t } = useTranslation('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  // State for hashtags and keywords
  const [hashtags, setHashtags] = useState<string[]>(initialValues?.hashtags || []);
  const [keywords, setKeywords] = useState<string[]>(initialValues?.keywords || []);
  const [inputValue, setInputValue] = useState('');
  const [keywordValue, setKeywordValue] = useState('');
  const [showFileName, setShowFileName] = useState(false);

  useEffect(() => {
    setShowFileName(false);
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);

      if (initialValues?.filename) {
        setImagePreview(`${initialValues?.filename}`);
      }

      if (initialValues) {
        setHashtags(initialValues.hashtags);
        setKeywords(initialValues.keywords);
      }
    } else if (isViewMode && initialViewValues !== null) {
      form.setFieldsValue(initialViewValues);
      if (initialViewValues?.filename) {
        setImagePreview(`${initialViewValues?.filename}`);
      }
    } else {
      resetFormState();
    }
  }, [initialValues, isEditMode, isViewMode, initialViewValues]);

  const handleInputConfirm = (type: 'hashtags' | 'keywords') => {
    if (type === 'hashtags') {
      if (inputValue && !hashtags.includes(inputValue)) {
        setHashtags([...hashtags, inputValue]);
        setInputValue('');
      } else if (hashtags.includes(inputValue)) {
        openNotificationWithIcon('error', t('Hashtag already exists'));
      }
    } else if (type === 'keywords') {
      if (keywordValue && !keywords.includes(keywordValue)) {
        setKeywords([...keywords, keywordValue]);
        setKeywordValue('');
      } else if (keywords.includes(keywordValue)) {
        openNotificationWithIcon('error', t('Keyword already exists'));
      }
    }
  };

  const handleRemoveTag = (tag: string, type: 'hashtags' | 'keywords') => {
    if (type === 'hashtags') {
      setHashtags(hashtags.filter((item) => item !== tag));
    } else {
      setKeywords(keywords.filter((item) => item !== tag));
    }
  };
  useEffect(() => {
    const fetchImageData = async () => {
      if (isViewMode && initialViewValues !== null) {
        setIsLoadingImage(true);
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          console.error('No access token found');
          return;
        }

        const response = await dispatch(GetSingleImageData({ imageId: initialViewValues?.id as string }));

        if (response?.payload) {
          const imageUrl = `https://api.dev.data.projects.excitingdevs.com/api/v1/marketing/settings/imagemanagement/images/${initialViewValues?.id}/thumbnail`;

          const response = await fetch(imageUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error('Failed to fetch image');
          }

          const imageBlob = await response.blob();

          const generatedImageUrl = URL.createObjectURL(imageBlob);
          setImagePreview(generatedImageUrl);
        } else {
          console.error('No image data found in response');
        }

        form.setFieldsValue(initialViewValues);
        setIsLoadingImage(false);
      }
    };

    if (isViewMode) {
      fetchImageData();
    } else {
      resetFormState();
    }
  }, [isViewMode, initialViewValues, dispatch, form]);

  useEffect(() => {
    const fetchImageEditData = async () => {
      if (isEditMode && initialValues !== null) {
        setIsLoadingImage(true);
        const accessToken = localStorage.getItem('accessToken');
        if (!accessToken) {
          console.error('No access token found');
          return;
        }
        const response = await dispatch(GetSingleImageData({ imageId: initialValues?.id as string }));

        if (response?.payload) {
          const imageUrl = `https://api.dev.data.projects.excitingdevs.com/api/v1/marketing/settings/imagemanagement/images/${initialValues?.id}/thumbnail`;

          const response = await fetch(imageUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error('Failed to fetch image');
          }

          const imageBlob = await response.blob();

          const generatedImageUrl = URL.createObjectURL(imageBlob);
          setImagePreview(generatedImageUrl);
        } else {
          console.error('No image data found in response');
        }
        form.setFieldsValue(initialValues);
        setIsLoadingImage(false);
      }
    };
    if (isEditMode) {
      fetchImageEditData();
    } else {
      resetFormState();
    }
  }, [isEditMode, initialValues, dispatch, form]);

  const onFileChange = (info: any) => {
    setShowFileName(true);
    if (info.fileList.length > 0) {
      setFileList(info.fileList);
      const file = info.fileList[0].originFileObj;

      if (file) {
        setImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result as string);
          form.setFieldsValue({ filename: file.name });
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImagePreview(null);
      form.setFieldsValue({ filename: '' });
      setImageFile(null);
    }
  };
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    try {
      // User must at least add one hashtag and one Keyword before creating or updating an image
      if (hashtags?.length === 0) {
        openNotificationWithIcon('error', t('Please add at least one hashtag'));
        return;
      }

      if (keywords?.length === 0) {
        openNotificationWithIcon('error', t('Please add at least one keyword'));
        return;
      }

      const formData: IimageManagement = form.getFieldsValue();
      const payload = new FormData();

      const data = JSON.stringify({
        hashtags: hashtags,
        keywords: keywords,
        enabled: formData.enabled,
      });

      payload.append('data', data);

      if (imageFile) {
        payload.append('image', imageFile);
      } else {
        openNotificationWithIcon('error', t('Please choose a file'));
        return;
      }

      if (!['image/png', 'image/jpeg', 'image/gif'].includes(imageFile.type)) {
        openNotificationWithIcon('error', t('Invalid file type. Please upload a PNG, JPEG, or GIF image.'));
        return;
      }

      if (isEditMode) {
        const response: any = await dispatch(UpdateImageData({ imageId: initialValues?.id as string, data: payload }));
        if (response?.payload?.id) {
          openNotificationWithIcon('success', t('Image updated successfully'));
          resetFormState();
          dispatch(GetAllImageData({ page: pagination.currentPage, pageSize: pagination.pageSize }));
          toggle();
          setInputValue('');
          setKeywordValue('');
          setHashtags([]);
          setKeywords([]);
        } else {
          if (response.payload === 'Request failed with status code 400') {
            openNotificationWithIcon(
              'error',
              t('Invalid file type. Only image/gif, image/jpeg, image/png file types are allowed.'),
            );
          } else {
            openNotificationWithIcon('error', t('Error creating image'));
          }
          setFileList([]);
          setImagePreview(null);
          setImageFile(null);
        }
      } else {
        const response: any = await dispatch(CreateImageData({ data: payload }));

        if (response.payload.id) {
          openNotificationWithIcon('success', t('Image created successfully'));
          resetFormState();
          dispatch(GetAllImageData({ page: pagination.currentPage, pageSize: pagination.pageSize }));
          toggle();
          setInputValue('');
          setKeywordValue('');
          setHashtags([]);
          setKeywords([]);
        } else {
          if (response.payload === 'Request failed with status code 400') {
            openNotificationWithIcon(
              'error',
              t('Invalid file type. Only image/gif, image/jpeg, image/png file types are allowed.'),
            );
          } else {
            openNotificationWithIcon('error', t('Error creating image'));
          }
          setFileList([]);
          setImagePreview(null);
          setImageFile(null);
        }
      }
    } catch (error) {
      console.error('An error occurred: ', error);
      openNotificationWithIcon('error', `Error ${isEditMode ? t('updating') : t('creating')} image`);
    }
  };

  const handleCancel = () => {
    toggle();
    setInputValue('');
    setKeywordValue('');
    setHashtags([]);
    setKeywords([]);
    setShowFileName(false);
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
    }
    if (isViewMode) {
      setIsViewMode(false);
      setViewData(null);
    }
    resetFormState();
  };

  const resetFormState = () => {
    form.resetFields();
    setFileList([]);
    setImagePreview(null);
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? t('Update') : isViewMode ? t('View') : t('Upload')} {t('Image')}
        </ModalHeader>
        <ModalBody className="scrollModal">
          {isViewMode ? (
            <div>
              <p>
                <strong>{t('File Name')}:</strong> {initialViewValues?.filename}
              </p>
              {isLoadingImage ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                  <Spin size="large" />
                </div>
              ) : (
                imagePreview && (
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                    <Tooltip title={initialViewValues?.filename?.replace(/\.[^/.]+$/, '')}>
                      <img
                        width={250}
                        src={imagePreview}
                        alt={initialViewValues?.filename}
                        style={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </div>
                )
              )}

              <p>
                <strong>{t('Hashtags')}:</strong> {initialViewValues?.hashtags?.join(', ')}
              </p>
              <p>
                <strong>{t('Keywords')}:</strong> {initialViewValues?.keywords?.join(', ')}
              </p>
              <p>
                <strong>{t('Enabled')}:</strong> {initialViewValues?.enabled ? 'Yes' : 'No'}
              </p>
            </div>
          ) : (
            <Form layout="vertical" form={form}>
              <Form.Item
                label={t('File Name')}
                name="filename"
                rules={[{ required: true, message: t('Please select a file') }]}
              >
                {isLoadingImage ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
                    <Spin size="large" />
                  </div>
                ) : (
                  imagePreview && (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignContent: 'center',
                          marginBottom: '15px',
                          // backgroundColor: 'green',
                        }}
                      >
                        <img
                          title={initialViewValues?.filename?.replace(/\.[^/.]+$/, '')}
                          width={250}
                          src={imagePreview}
                          alt={initialViewValues?.filename}
                        />
                      </div>
                      {showFileName && (
                        <div
                          id="fileListDetails"
                          style={{
                            textAlign: 'center',
                            marginBottom: '15px',
                            padding: '2px',
                          }}
                        >
                          {fileList[0]?.name}
                          <span
                            onClick={() => {
                              setFileList([]);
                              setImagePreview(null);
                              form.setFieldsValue({ filename: '' });
                            }}
                            className="ml-5"
                            style={{
                              cursor: 'pointer',
                              marginLeft: '5px',
                            }}
                          >
                            <LuTrash size={15} color="gray" />
                          </span>
                        </div>
                      )}
                    </div>
                  )
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: 'yellow',
                  }}
                >
                  <Upload
                    listType="picture"
                    showUploadList={false}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    accept="image/png, image/jpeg, image/gif"
                    beforeUpload={() => false}
                    fileList={fileList}
                    onChange={onFileChange}
                    maxCount={1}
                  >
                    <Button
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // marginLeft: imagePreview ? '105px' : isEditMode ? '0px' : '0px',
                      }}
                      icon={<UploadOutlined />}
                    >
                      {t('Choose File')}
                    </Button>
                  </Upload>
                </div>
              </Form.Item>
              <Form.Item label={t('Hashtags')}>
                <Input
                  id="imageHashtags"
                  className="p-2 px-2"
                  value={inputValue}
                  placeholder={t('Enter hashtag and press enter')}
                  onChange={(e) => setInputValue(e.target.value)}
                  onPressEnter={() => handleInputConfirm('hashtags')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleInputConfirm('hashtags');
                    }
                  }}
                />
                <div style={{ marginTop: '8px' }}>
                  {hashtags.map((tag) => (
                    <Tag
                      key={tag}
                      closable
                      onClose={() => handleRemoveTag(tag, 'hashtags')}
                      style={{ margin: '5px', padding: '3px 10px' }}
                    >
                      {tag}
                    </Tag>
                  ))}
                </div>
              </Form.Item>

              <Form.Item label={t('Keywords')}>
                <Input
                  id="imageKeywords"
                  className="p-2 px-2"
                  value={keywordValue}
                  placeholder={t('Enter keyword and press enter')}
                  onChange={(e) => setKeywordValue(e.target.value)}
                  onPressEnter={() => handleInputConfirm('keywords')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleInputConfirm('keywords');
                    }
                  }}
                />
                <div style={{ marginTop: '8px' }}>
                  {keywords.map((keyword) => (
                    <Tag
                      style={{ margin: '5px', padding: '3px 10px' }}
                      key={keyword} // Use the keyword value as the key
                      closable
                      onClose={() => handleRemoveTag(keyword, 'keywords')}
                    >
                      {keyword}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
              <Form.Item label={t('Enabled')} name="enabled" valuePropName="checked">
                <Switch id="enabled" />
              </Form.Item>
            </Form>
          )}

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Tooltip title={t('Cancel')}>
              <Button
                id="imageManagementCancelButton"
                disabled={isCreating || isUpdating}
                onClick={handleCancel}
                style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
              >
                {t('Cancel')}
              </Button>
            </Tooltip>
            {!isViewMode && (
              <Tooltip title={isEditMode ? t('Update') : t('Create')}>
                <AsyncButton
                  buttonText={isEditMode ? t('Update') : t('Create')}
                  isEditMode={isEditMode}
                  style={{ marginTop: 20 }}
                  isLoading={isCreating || isUpdating}
                  onClick={handleCreateOrUpdate}
                  id="imageCreateORUpdateButton"
                />
              </Tooltip>
            )}
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ImageManagementModal;
