/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Card, Form, Input, Select, Tooltip } from 'antd';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { HotelData, IPagination } from 'src/utils/types/HotelData';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { AppDispatch } from 'src/store';
import {
  CreateCompetitorHotelEntry,
  ReadHotelCompetitors,
  UpdateCompetitorHotelEntry,
} from 'src/slices/CompetitorHotels/thunk';
import { useTranslation } from 'react-i18next';
import { IoIosRemoveCircleOutline } from 'react-icons/io';

interface ICompetitorsHotelsModalProps {
  isOpen: boolean;
  pagination: IPagination;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: any | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: HotelData | null) => void;
}

const CompetitorsHotelsModal = ({
  isOpen,
  pagination,
  openNotificationWithIcon,
  setEditingData,
  setIsEditMode,
  toggle,
  initialValues,
  isEditMode,
}: ICompetitorsHotelsModalProps) => {
  const { t } = useTranslation('');
  const { selectedProperty, Properties, realFormate } = useSelector((state: RootState) => state.porperty);
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.competitor);

  const dispatch: AppDispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEditMode && initialValues) {
      console.log(initialValues);
      form.setFieldsValue({
        competitor: {
          url: initialValues.competitors[0].url,
          offerUrl: initialValues.competitors[0].offerUrl,
          platforms: initialValues.competitors[0].platforms,
        },
      });
    }
  }, [initialValues, isEditMode]);

  const handleCreateOrUpdate = async () => {
    // await form.validateFields();
    const propertyId = realFormate.find((property) => property.mandantId === selectedProperty?.value);

    try {
      const formData: any = form.getFieldsValue();
      console.log(formData);
      const competitorsData = {
        url: formData.competitor.url,
        offerUrl: formData.competitor.offerUrl,
        platforms: formData.competitor.platforms,
      };

      const dataToSubmit: any = {
        propertyId: propertyId?.id,
        competitors: [competitorsData],
      };

      console.log(dataToSubmit);

      if (!dataToSubmit.propertyId) {
        openNotificationWithIcon('error', t('Please select a property'));
        return;
      }

      if (isEditMode) {
        const UpdatecompetitorsData = {
          url: formData.competitor.url,
          offerUrl: formData.competitor.offerUrl,
          platforms: formData.competitor.platforms,
        };

        const dataToSubmit: any = {
          propertyId: propertyId?.id,
          competitors: [UpdatecompetitorsData],
        };
        dataToSubmit.id = initialValues?.id;
        const response = await dispatch(
          UpdateCompetitorHotelEntry({
            data: dataToSubmit,
            id: initialValues?.id as string,
          }),
        );

        if ((response.payload as HotelData)['id']) {
          openNotificationWithIcon('success', t('Updated successfully'));
          handleCancel();
          form.resetFields();
          dispatch(ReadHotelCompetitors({ page: pagination.currentPage, pageSize: pagination.pageSize }));
        } else {
          openNotificationWithIcon('error', t('Failed to update hotel entry'));
        }
      } else {
        const response = await dispatch(
          CreateCompetitorHotelEntry({
            data: dataToSubmit,
          }),
        );

        if ((response.payload as HotelData)['id']) {
          openNotificationWithIcon('success', t('Created successfully'));
          handleCancel();
          form.resetFields();
          dispatch(ReadHotelCompetitors({ page: pagination.currentPage, pageSize: pagination.pageSize }));
        } else {
          openNotificationWithIcon('error', t('Failed to create hotel entry'));
        }
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
    }
    form.resetFields();
  };

  return (
    <div>
      <Modal size="lg" centered isOpen={isOpen} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>
          {isEditMode ? t('Update') : t('Create')} {t('Competitor Hotel')}
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="horizontal" form={form}>
            <Card title={t('Manage Competitors')}>
              <div className="border p-3" style={{ marginBottom: '20px' }}>
                {/* Competitor URL */}
                <Form.Item
                  label={t('Competitor URL')}
                  name={['competitor', 'url']}
                  rules={[
                    { required: true, message: t('Please enter competitor URL') },
                    { type: 'url', message: t('Please enter a valid URL') },
                  ]}
                >
                  <Input
                    id="competitorURL"
                    title={t('Competitor URL')}
                    style={{ width: '100%' }}
                    placeholder={t('Competitor URL')}
                  />
                </Form.Item>

                {/* Offer URLs */}
                <Form.List name={['competitor', 'offerUrl']}>
                  {(offerFields, { add: addOfferUrl, remove: removeOfferUrl }) => (
                    <>
                      {offerFields.map(({ key: offerKey, name: offerName, ...restOfferField }) => (
                        <div
                          key={offerKey}
                          style={{
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'center', // Aligns items vertically in the center
                          }}
                        >
                          <Form.Item
                            {...restOfferField}
                            name={[offerName, isEditMode ? 'offerUrl' : 'offerUrl']}
                            rules={[
                              { required: true, message: t('Please enter offer URL') },
                              { type: 'url', message: t('Please enter a valid URL') },
                            ]}
                            style={{ flex: 1, marginBottom: 0 }} // Sets flex for full width and removes bottom margin
                          >
                            <Input
                              id="OfferURL"
                              style={{
                                width: '95%',
                                marginRight: '10px', // Adds spacing between the input and button
                              }}
                              placeholder={t('Offer URL')}
                            />
                          </Form.Item>
                          <Button color="danger" onClick={() => removeOfferUrl(offerName)}>
                            <IoIosRemoveCircleOutline />
                          </Button>
                        </div>
                      ))}
                      <Button
                        id="addOfferURL"
                        style={{
                          marginBottom: '10px',
                          marginRight: '10px',
                        }}
                        onClick={() => addOfferUrl()}
                      >
                        {t('Add Offer URL')}
                      </Button>
                    </>
                  )}
                </Form.List>

                {/* Platforms */}
                <Form.List name={['competitor', 'platforms']}>
                  {(platformFields, { add: addPlatform, remove: removePlatform }) => (
                    <>
                      {platformFields.map(({ key: platformKey, name: platformName, ...restPlatformField }) => (
                        <div
                          key={platformKey}
                          style={{
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <div style={{ width: '38%' }}>
                            <Form.Item
                              {...restPlatformField}
                              label={t('Platform')}
                              name={[platformName, 'platform']}
                              rules={[{ required: true, message: t('Please select a platform') }]}
                            >
                              <Select
                                title={t('Platform')}
                                placeholder={t('Select platform')}
                                style={{ marginLeft: '38px' }}
                                id="Platform"
                              >
                                <Select.Option value="Booking.com" data-cy="option-booking">
                                  Booking.com
                                </Select.Option>
                                <Select.Option value="Expedia" data-cy="option-expedia">
                                  Expedia
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div>
                            <Form.Item
                              {...restPlatformField}
                              label={t('Platform URL')}
                              name={[platformName, 'platformId']}
                              rules={[
                                { required: true, message: t('Please enter platform URL') },
                                { type: 'url', message: t('Please enter a valid URL') },
                              ]}
                            >
                              <Input id="platformURL" title={t('Platform URL')} placeholder={t('Platform URL')} />
                            </Form.Item>
                          </div>

                          <Button className="btn btn-danger" onClick={() => removePlatform(platformName)}>
                            <IoIosRemoveCircleOutline />
                          </Button>
                        </div>
                      ))}
                      <Button
                        id="addPlatform"
                        style={{
                          marginBottom: '10px',
                        }}
                        onClick={() => addPlatform()}
                      >
                        {t('Add Platform')}
                      </Button>
                    </>
                  )}
                </Form.List>
              </div>
            </Card>

            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={handleCancel}
                style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
              >
                {t('Cancel')}
              </Button>
              <AsyncButton
                buttonText={isEditMode ? t('Update') : t('Create')}
                isEditMode={isEditMode}
                isLoading={isCreating || isUpdating}
                id="hotelCompetitorCreateORUpdateButton"
                onClick={handleCreateOrUpdate}
                style={{ marginTop: 20 }}
              />
            </Col>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CompetitorsHotelsModal;
