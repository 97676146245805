import axios from 'axios';
import { IMealPlans, IPagination } from 'src/utils/types/mealPlansTypes';
import env from 'src/env';
const baseURL = env.VITE_MEAL_PLANS_DATA_DEFAULT_BASE_URL();
// const baseURL = '';

const mealPlansBaseUrl = '/mealplan/meal-plans';


const getAllMealPlansData = async (
  pageNumber: number,
  pageSize: number,

): Promise<{ data: IMealPlans[]; pagination: IPagination } | null | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const params: {
      pageNumber: number;
      pageSize: number;

    } = { pageNumber, pageSize };


    const response = await axios.get(`${mealPlansBaseUrl}`, {
      headers,
      baseURL,
      params,
    });

    return response;
  } catch (error) {
    console.error('Error fetching all meal plans Data:', error);
    throw error;
  }
};
const getSingleMealPlansData = async (mealplansId: string): Promise<IMealPlans | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IMealPlans = await axios.get(`${mealPlansBaseUrl}/${mealplansId}`, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching single meal plans Data:', error);
    throw error;
  }
};

const createMealPlansData = async (data: IMealPlans): Promise<IMealPlans | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IMealPlans = await axios.post(`${mealPlansBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating meal plans  Data:', error);
    throw error;
  }
};

const updateMealPlansData = async (mealplansId: string, data: IMealPlans): Promise<IMealPlans | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IMealPlans = await axios.put(`${mealPlansBaseUrl}/${mealplansId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating meal plans  Data:', error);
    throw error;
  }
};

const deleteMealPlansData = async (mealplansId: string): Promise<string | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    await axios.delete(`${mealPlansBaseUrl}/${mealplansId}`, { headers, baseURL });
    return mealplansId;
  } catch (error) {
    console.error('Error deleting meal plans  Data:', error);
    throw error;
  }
};

export const MealPlansDataService = {
  getAllMealPlansData,
  getSingleMealPlansData,
  createMealPlansData,
  updateMealPlansData,
  deleteMealPlansData,
};
