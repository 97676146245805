import React, { useEffect, useState } from 'react';
import { Table, notification, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOffers } from 'src/slices/Offer/thunk'; // Adjust path if necessary
import { RootState } from 'src/store';
import styles from './CompetitorWebsiteAnalysis.module.scss'; // Import the SCSS module
import { useTranslation } from 'react-i18next';
import { IPagination } from 'src/utils/types/offerTypes';

const CompetitorWebsiteAnalysis = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Access offers, loading, and error from Redux state
  const { offers = [], loading = false, error = null } = useSelector((state: RootState) => state.offer || {});


  useEffect(() => {
    dispatch(fetchOffers({ page: pageNumber, pageSize })).then((action: any) => {
      if (action.payload) {
        setPagination(action.payload.pagination);
      }
    });
  }, [dispatch, pageNumber, pageSize]);
  const columns = [
    {
      title: t('Property Name'),
      dataIndex: 'Property Name',
      key: 'propertyName',
      render: (text: string) => text || '-',
    },
    {
      title: t('Location'),
      dataIndex: 'Location',
      key: 'location',
      render: (text: string) => text || '-',
    },
    {
      title: t('Price'),
      dataIndex: 'Price',
      key: 'price',
      render: (text: string) => text || '-',
    },
    {
      title: t('Reviews'),
      dataIndex: 'Reviews',
      key: 'reviews',
      render: (text: string) => text || '-',
    },
    {
      title: t('Room Type'),
      dataIndex: 'Room type',
      key: 'roomType',
      render: (text: string) => text || '-',
    },
    {
      title: t('Discounts or Offer'),
      dataIndex: 'Discounts or offer',
      key: 'discountsOrOffer',
      render: (text: string) => text || '-',
    },
    {
      title: t('Terms and Conditions'),
      dataIndex: 'Terms and conditions',
      key: 'termsAndConditions',
      render: (text: string) => text || '-',
    },
    {
      title: t('Text'),
      dataIndex: 'Text',
      key: 'text',
      render: (text: string) => text || '-',
    },
    {
      title: t('Competitor ID'),
      dataIndex: 'competitorId',
      key: 'competitorId',
      render: (text: string) => text || '-',
    },
    {
      title: t('Property ID'),
      dataIndex: 'propertyId',
      key: 'propertyId',
      render: (text: string) => text || '-',
    },
    {
      title: t('Source URL'),
      dataIndex: 'source_url',
      key: 'sourceUrl',
      render: (text: string) =>
        text ? (
          <a href={text} target="_blank" rel="noopener noreferrer">
            {t('Source')}
          </a>
        ) : (
          '-'
        ),
    },
  ];

  return (
    <div className={styles.competitorAnalysisContainer}>
      <div className={styles.competitorAnalysisHeader}>
        <h2 className={styles.competitorAnalysisTitle}>{t('Analyze Competitor Website Offers')}</h2>
      </div>

      {/* Display error message */}
      {error && <div className={styles.errorText}>{error}</div>}

      {/* Display loading spinner or Table */}
      {loading ? (
        <div className={styles.loadingSpinner}>
          <Spin size="large" tip={t('Loading offers...')} />
        </div>
      ) : offers.length > 0 ? (
        <Table
          columns={columns}
          dataSource={offers}
          rowKey={(_record, index = 0) => index.toString()}
          pagination={false}
          bordered
          className={styles.offerTable}
        />
      ) : (
        <div className={styles.noOffers}>{t('No offers found.')}</div>
      )}
    </div>
  );
};

export default CompetitorWebsiteAnalysis;
