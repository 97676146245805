import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  processCancellationPredictionSlice
} from 'src/slices/CancellationPrediction/thunk';

interface CancellationPredictionStateType {
  cancellationPrediction: Array<any>;
  event: any;
  error?: string | object | null | undefined | unknown;
  loading: boolean;
  cancellationPredictionLoading: boolean
  categories: any,
}
export const initialState: CancellationPredictionStateType = {
  cancellationPrediction: [],
  event: null,
  error: '',
  loading: false,
  categories: [],
  cancellationPredictionLoading: false
};

const CancellationPredictionSlice = createSlice({
  name: 'cancellationPrediction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processCancellationPredictionSlice.pending, (state) => {
        state.cancellationPredictionLoading = true;
        state.error = null;
      })

      .addCase(processCancellationPredictionSlice.fulfilled, (state, action) => {
        state.cancellationPrediction = action.payload;
        state.cancellationPredictionLoading = false;
      })

      .addMatcher(isError, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default CancellationPredictionSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
