import { AnyAction, createSlice } from '@reduxjs/toolkit';
import { create_Group, delete_Group, getMyGroups, getProperties_For_Tenants, update_group } from './thunk';

interface ContactsStateType {
  groups: Array<any>;
  error?: string | object | null | undefined | unknown;
  loading: boolean;
  ProPertiesForTenants: [];
  isPropertiesForTenantsLoading: boolean;
  allGroups: Array<any>[];
  isCreating: boolean;
  isGroupUpdating: boolean;
}
export const initialState: ContactsStateType = {
  groups: [],
  error: '',
  loading: false,
  ProPertiesForTenants: [],
  allGroups: [],
  isPropertiesForTenantsLoading: false,
  isCreating: false,
  isGroupUpdating: false,
};

const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMyGroups.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(getMyGroups.fulfilled, (state, action) => {
        state.groups = action.payload;
        state.loading = false;
      });
    builder.addCase(getProperties_For_Tenants.pending, (state) => {
      state.isPropertiesForTenantsLoading = true;
    });
    builder.addCase(getProperties_For_Tenants.fulfilled, (state, action) => {
      state.isPropertiesForTenantsLoading = false;
      state.ProPertiesForTenants = action.payload;
    });
    builder
      .addCase(getProperties_For_Tenants.rejected, (state) => {
        state.isPropertiesForTenantsLoading = false;
        state.ProPertiesForTenants = [];
      })
      .addCase(create_Group.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(create_Group.fulfilled, (state) => {
        state.isCreating = false;
      })
      .addCase(create_Group.rejected, (state) => {
        state.isCreating = false;
      })
      .addCase(delete_Group.pending, (state) => {
        state.loading = true;
      })
      .addCase(delete_Group.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(delete_Group.rejected, (state) => {
        state.loading = false;
      })
      .addCase(update_group.pending, (state) => {
        state.isGroupUpdating = true;
      })
      .addCase(update_group.fulfilled, (state) => {
        state.isGroupUpdating = false;
      })
      .addCase(update_group.rejected, (state) => {
        state.isGroupUpdating = false;
      });
  },
});

export default groupSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
