import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllProperties,
  getProperties as GetProperties,
  updateProperties,
} from 'src/utils/services/service/propertiesService';

export const getProperties = createAsyncThunk<any>('properties/getProperties', async () => {
  try {
    const response = await getAllProperties();
    return response.data;
  } catch (error) {
    console.error(error);
  }
});

export const getPropertiesFromServer = createAsyncThunk('properties/get-all', async (token: any) => {
  try {
    // const localStorage;
    const response = await GetProperties(token);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
});

export const UpdateProperties = createAsyncThunk('properties/updateProperties', async (data: any) => {
  try {
    const response = await updateProperties(data.token, data.id, data.data);
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
});
