import axios from 'axios';
import { IManageHotel, IPagination } from 'src/utils/types/manageHotelTypes';
import env from 'src/env';
const baseURL = env.VITE_MANAGE_HOTEL_DATA_DEFAULT_BASE_URL();
// const baseURL = '';

const manageHotelBaseUrl = '/hotels/hotels';

const getAllManageHotelData = async (
  pageNumber: number,
  pageSize: number,
): Promise<{ data: IManageHotel[]; pagination: IPagination } | null | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const params: {
      pageNumber: number;
      pageSize: number;
    } = { pageNumber, pageSize };

    const response = await axios.get(`${manageHotelBaseUrl}`, {
      headers,
      baseURL,
      params,
    });

    return response;
  } catch (error) {
    console.error('Error fetching all Hotel Data:', error);
    throw error;
  }
};
const getSingleManageHotelData = async (hoteldataId: string): Promise<IManageHotel | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IManageHotel = await axios.get(`${manageHotelBaseUrl}/${hoteldataId}`, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching single Hotel Data:', error);
    throw error;
  }
};

const createManageHotelData = async (data: IManageHotel): Promise<IManageHotel | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IManageHotel = await axios.post(`${manageHotelBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating Hotel Data:', error);
    throw error;
  }
};

const updateManageHotelData = async (hoteldataId: string, data: IManageHotel): Promise<IManageHotel | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IManageHotel = await axios.put(`${manageHotelBaseUrl}/${hoteldataId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating Hotel Data:', error);
    throw error;
  }
};

const deleteManageHotelData = async (hoteldataId: string): Promise<void> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    await axios.delete(`${manageHotelBaseUrl}/${hoteldataId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });
  } catch (error) {
    console.error('Error deleting Hotel Data:', error);
    throw error;
  }
};

export const ManageHotelDataService = {
  getAllManageHotelData,
  getSingleManageHotelData,
  createManageHotelData,
  updateManageHotelData,
  deleteManageHotelData,
};
