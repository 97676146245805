// src/slices/hotelSearch/hotelSearchThunk.ts

import { createAsyncThunk } from '@reduxjs/toolkit';
import { HotelService } from 'src/utils/services/service/hotelSearchService';
import { ISearchHotelResponse } from 'src/utils/types/searchTypes';


export const fetchHotelSearchResults = createAsyncThunk<ISearchHotelResponse, { query: string; checkin: string; checkout: string; numberOfRooms: number; offset: number }, { rejectValue: string }>(
  'hotelSearch/fetchResults',
  async ({ query, checkin, checkout, numberOfRooms, offset }, { rejectWithValue }) => {
    try {
      const response = await HotelService.searchHotels(query, checkin, checkout, numberOfRooms, offset);
      return response;
    } catch (error) {
      return rejectWithValue('Failed to fetch hotel search results');
    }
  }
);
