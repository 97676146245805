import React from 'react';
import ComparisonDataModal from './components/ComparisonDataModal';
/* eslint-disable react-hooks/exhaustive-deps */
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { notification, Tooltip, Select, Pagination, Table, Skeleton } from 'antd';
import { FiEdit, FiEye } from 'react-icons/fi';
import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { ISpecialPeriods } from 'src/utils/types/specialPeriodsTypes';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { useTranslation } from 'react-i18next';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { IComparisonData, IPagination } from 'src/utils/types/ComparisonDataTypes';
import ComparisonDataDetailsModal from './components/ComparisonDataDetailsModal';
import { RetrieveAllComparisons } from 'src/slices/ComparisonData/thunk';
import { useNumberFormatter } from '../.././utils/formatNumber'
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';
import { Bar } from 'react-chartjs-2';

const ComparisonData = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<any | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const { formatNumber, formatCurrency, formatPercentage } = useNumberFormatter();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 100,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const [api, contextHolder] = notification.useNotification();

  const { isLoading, data, nextWeekData } = useSelector((state: RootState) => state.comparisonData);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('');

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (!isLoading && data.length === 0) {
      dispatch(RetrieveAllComparisons({ page: pageNumber, pageSize })).then((action: any) => {
        if (action.payload) {
          setPagination(action.payload.pagination);
        }
      });
    }
  }, [pageNumber, pageSize]);

  const columns: TableProps<any>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 100,
      render: (_, record) => (
        <div className="tableIconBlock">
          <Tooltip title={t('View')} placement="top">
            <div
              className="tableIcon"
              onClick={() => {
                setIsDetailsModalOpen(!isModalOpen);
                setEditingData(record);
              }}
            >
              <FiEye id="viewDetails" size={20} />
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('Year'),
      dataIndex: 'year',
      key: 'year',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.year - b.year,
    },
    {
      title: t('Week'),
      dataIndex: 'week',
      key: 'week',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.week - b.week,
    },
    {
      title: t('Beds Sold'),
      dataIndex: ['current', 'bedsSold'],
      key: 'currentBedsSold',
    },
    {
      title: t('Revenue'),
      dataIndex: ['current', 'revenue'],
      key: 'currentRevenue',
      render: (text) => (
        <span>
          <strong>{formatCurrency(text)}</strong>

        </span>
      ),
    },
    {
      title: t('-1'),
      dataIndex: ['comparison', 0, 'deviation'],
      key: 'deviation1',
      render: (text) => {
        if (text === '0.00%' || text?.toString() === 'N/A' || text?.toString() === 'NaN') {
          return <span>{'---'}</span>;
        } else {
          return <span>{formatPercentage(text)}</span>;

        }
      },
    },
    {
      title: t('-2'),
      dataIndex: ['comparison', 1, 'deviation'],
      key: 'deviation2',
      render: (text) => {
        if (text === '0.00%' || text?.toString() === 'N/A' || text?.toString() === 'NaN') {
          return <span>{'---'}</span>;
        } else {
          return <span>{formatPercentage(text)}</span>;
        }
      },
    },
    {
      title: t('-3'),
      dataIndex: ['comparison', 2, 'deviation'],
      key: 'deviation3',
      render: (text) => {
        if (text === '0.00%' || text?.toString() === 'N/A' || text?.toString() === 'NaN') {
          return <span>{'---'}</span>;
        } else {
          return <span>{formatPercentage(text)}</span>;
        }
      },
    },
  ];
  const columnsForNextWeek: TableProps<any>['columns'] = [
    {
      title: t('Year'),
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: t('Week'),
      dataIndex: 'week',
      key: 'week',
      defaultSortOrder: 'descend',
    },
    {
      title: t('Beds Sold'),
      dataIndex: ['current', 'bedsSold'],
      key: 'currentBedsSold',
    },
    {
      title: t('Revenue'),
      dataIndex: ['current', 'revenue'],
      key: 'currentRevenue',
      render: (text) => (
        <span>
          <strong>{formatCurrency(text)}</strong>
        </span>
      ),
    },
    {
      title: t('-1'),
      dataIndex: ['comparison', 0, 'deviation'],
      key: 'deviation1',
      render: (text) => {
        if (text === '0.00%' || text?.toString() === 'N/A' || text?.toString() === 'NaN') {
          return <span>{'---'}</span>;
        } else {
          return <span>{formatPercentage(text)}</span>;
        }
      },
    },
    {
      title: t('-2'),
      dataIndex: ['comparison', 1, 'deviation'],
      key: 'deviation2',
      render: (text) => {
        if (text === '0.00%' || text?.toString() === 'N/A' || text?.toString() === 'NaN') {
          return <span>{'---'}</span>;
        } else {
          return <span>{formatPercentage(text)}</span>;
        }
      },
    },
    {
      title: t('-3'),
      dataIndex: ['comparison', 2, 'deviation'],
      key: 'deviation3',
      render: (text) => {
        if (text === '0.00%' || text?.toString() === 'N/A' || text?.toString() === 'NaN') {
          return <span>{'---'}</span>;
        } else {
          return <span>{formatPercentage(text)}</span>;
        }
      },
    },
  ];

  const comparisonYears = nextWeekData?.comparison?.map((item: any) => item.year) || [];
  const bookedRoomsData = nextWeekData?.comparison?.map((item: any) => item.bedsSold) || [];
  const revenueData = nextWeekData?.comparison?.map((item: any) => item.revenue) || [];
  const deviationData = nextWeekData?.comparison?.map((item: any) => parseFloat(item.deviation)) || [];

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  const generateChartData = (label: any, currentYearData: any, comparisonData: any) => ({
    labels: comparisonYears,
    datasets: [
      {
        label: `${label} (${nextWeekData?.year})`,
        data: currentYearData,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
      {
        label: `${label} (Comparison)`,
        data: comparisonData,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  });

  return (
    <div>
      {contextHolder}
      <div className="page-content">
        <MetaTags>
          <title>{t('Weekly Comparison')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title={t('Settings')} breadcrumbItem={t('Weekly Comparison')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Tooltip title={t('Search comparisons by week or year.')}>
                        <div>
                          <SearchBar
                            id="searchWeeklyComparisons"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={t('Search comparisons by week or year')}
                          />
                        </div>
                      </Tooltip>
                    </Col>

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Tooltip title={t('Add')}>
                            <Button
                              id="addWeeklyComparison"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                                setIsEditMode(false);
                                setEditingData(null);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> {t('Add')}
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div
                    style={{
                      margin: '20px 0',
                    }}
                  >
                    <div>
                      <h5>Upcoming week</h5>
                    </div>

                    <Table
                      loading={isLoading}
                      columns={columnsForNextWeek}
                      dataSource={[nextWeekData]}
                      pagination={{
                        defaultPageSize: 100,
                        pageSizeOptions: [50, 100],
                        showSizeChanger: false,
                        hideOnSinglePage: true,
                      }}
                    />

                    <div>
                      <div
                        style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '20px', marginTop: '30px' }}
                      >
                        <div>
                          {!nextWeekData ? (
                            <div>
                              <Skeleton.Avatar active shape="square" style={{ width: 600, height: 330 }} />
                            </div>
                          ) : (
                            <>
                              <h5>{t('Booked Rooms')}</h5>
                              <Bar
                                data={generateChartData(
                                  'Booked Rooms',
                                  [nextWeekData?.current?.bedsSold],
                                  bookedRoomsData,
                                )}
                                options={chartOptions}
                              />
                            </>
                          )}
                        </div>
                        <div>
                          {!nextWeekData ? (
                            <div>
                              <Skeleton.Avatar active shape="square" style={{ width: 600, height: 330 }} />
                            </div>
                          ) : (
                            <>
                              <h5>{t('Revenue')}</h5>
                              <Bar
                                data={generateChartData('Revenue', [nextWeekData?.current?.revenue], revenueData)}
                                options={chartOptions}
                              />
                            </>
                          )}
                        </div>
                        <div>
                          {!nextWeekData ? (
                            <div>
                              <Skeleton.Avatar active shape="square" style={{ width: 600, height: 330 }} />
                            </div>
                          ) : (
                            <>
                              <h5>{t('Deviation (%)')}</h5>
                              <Bar data={generateChartData('Deviation', [0], deviationData)} options={chartOptions} />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Table View */}
                  <div
                    style={
                      {
                        // height: '10vh',
                      }
                    }
                  >
                    <TableViewPagination
                      loading={isLoading}
                      columns={columns}
                      dataSource={data?.filter((e) => {
                        if (searchValue === '') return e;
                        else if (e.week.toString().includes(searchValue) || e.year.toString().includes(searchValue))
                          return e;
                      })}
                      showPagination={false}
                    />

                    <Pagination
                      current={pageNumber}
                      pageSize={pageSize}
                      total={pagination?.totalCount || 0}
                      onChange={(page, pageSize) => {
                        setPageNumber(page);
                        setPageSize(pageSize);
                        dispatch(RetrieveAllComparisons({ page, pageSize }));
                      }}
                      style={{ float: 'right', marginTop: '20px' }}
                      showSizeChanger
                      pageSizeOptions={['10', '20', '30', '50', '100']}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/**  Modal to handle create and update  **/}
          <ComparisonDataModal
            isEditMode={isEditMode}
            initialValues={editingData}
            openNotificationWithIcon={openNotificationWithIcon}
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            setIsEditMode={setIsEditMode}
            setEditingData={setEditingData}
            pagination={pagination}
          />
          <ComparisonDataDetailsModal
            pagination={pagination}
            editingData={editingData}
            setEditingData={setEditingData}
            isOpen={isDetailsModalOpen}
            toggle={() => setIsDetailsModalOpen(!isDetailsModalOpen)}
          />
        </Container>
      </div>
    </div>
  );
};

export default ComparisonData;
