/* eslint-disable react-hooks/exhaustive-deps */
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { notification, Tooltip, Pagination, Tabs, TabsProps, Form, Select, Collapse, Checkbox } from 'antd';
import { FiEdit } from 'react-icons/fi';
import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { ISpecialPeriods } from 'src/utils/types/specialPeriodsTypes';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { DeleteSpecialPeriods, GetAllSpecialPeriods, UpdateSpecialPeriods } from 'src/slices/SpecialPeriods/thunk';
import { useTranslation } from 'react-i18next';
import { ROLE_TYPE } from 'src/constants/role-types';
import { DeleteRole, GetAllRoles } from 'src/slices/Permissions/thunk';
import RoleModal from './Components/RoleModal';
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';
import { IRoles, IPagination } from 'src/utils/types/roleTypes';
import { IoEyeOutline } from 'react-icons/io5';

import { LuScan } from 'react-icons/lu';
import { menuItems } from 'src/components/VerticalLayout/Menu';
import { useForm } from 'antd/lib/form/Form';
import { AddUserToRole } from 'src/slices/AccessShield/thunk';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';

const Roles = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<ISpecialPeriods | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation('');
  const { roles, isRolesLoading, isRolesUpdating } = useSelector((state: RootState) => state.permissions);

  const [selectedRoleName, setSelectedRoleName] = useState<string>('');

  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (!isRolesLoading && roles.length === 0) {
      dispatch(GetAllRoles({ page: pageNumber, pageSize })).then((action: any) => {
        if (action.payload) {
          setPagination(action.payload.pagination);
        }
      });
    }
  }, [pageNumber, pageSize]);

  const handleDeleteRole = async (id: string) => {
    const response: any = await dispatch(
      DeleteRole({
        roleId: id,
      }),
    );

    if (response.payload.status === 204) {
      openNotificationWithIcon('success', t('Role deleted successfully'));
      dispatch(GetAllRoles({ page: pageNumber, pageSize }));
    } else {
      openNotificationWithIcon('error', t('Error deleting role'));
    }
  };

  const columns = [
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (_text: string, record: any) => (
        <div className="tableIconBlock">
          <Tooltip title={t('view')} placement="top">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
                setSelectedRoleName(record?.name);
              }}
            >
              <IoEyeOutline id="updateSpecialPeriod" size={20} />
            </div>
          </Tooltip>

          {/* <Tooltip title={t('Delete')} placement="top">
            <div className="tableIcon">
              <DeleteButton
                title={t('Are you sure you want to delete?')}
                cancelText={t('Cancel')}
                okText={t('Proceed')}
                okButtonId={`confirmAndDeleteRoomPlan`}
                triggerButtonId="deleteRoomPlan"
                onConfirm={() => {
                  handleDeleteRole(record.id as string);
                }}
                aria-label={t('Delete room plan')}
              />
            </div>
          </Tooltip> */}
        </div>
      ),
    },
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <span>{name}</span>,
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      key: 'description',
      render: (description: string) => <span>{description}</span>,
    },
  ];

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Roles',
      children: (
        <>
          <TableViewPagination
            loading={isRolesLoading || isRolesUpdating}
            columns={columns}
            dataSource={roles}
            showPagination={false}
          />

          <Pagination
            current={pageNumber}
            pageSize={pageSize}
            total={pagination?.totalCount || 0}
            onChange={(page, pageSize) => {
              setPageNumber(page);
              setPageSize(pageSize);
              dispatch(GetAllRoles({ page, pageSize }));
            }}
            style={{ float: 'right', marginTop: '20px' }}
            showSizeChanger
            pageSizeOptions={['10', '20', '30', '50', '100']}
          />
        </>
      ),
    },
    // {
    //   key: '2',
    //   label: 'Edit Access',
    //   children: 'Content of Tab Pane 2',
    // },
    {
      key: '3',
      label: 'Assign Role',
      children: (
        <>
          <AssignUserWithMenu roles={roles} menuItems={menuItems} openNotificationWithIcon={openNotificationWithIcon} />
        </>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <div className="page-content">
        <MetaTags>
          <title>{t('Role Management')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title={t('Settings')} breadcrumbItem={t('Role Management')} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        {/* <div>
                          <Tooltip title={t('Add')}>
                            <Button
                              id="createSpecialPeriodButton"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                                setIsEditMode(false);
                                setEditingData(null);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> {t('Add')}
                            </Button>
                          </Tooltip>
                        </div> */}
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '68vh',
                      overflowY: 'scroll',
                    }}
                  >
                    <Tabs
                      defaultActiveKey="1"
                      type="card"
                      // size={'large'}
                      items={items}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/**  Modal to handle create and update  **/}
          <RoleModal
            selectedRoleName={selectedRoleName}
            pagination={pagination}
            isEditMode={isEditMode}
            initialValues={editingData}
            openNotificationWithIcon={openNotificationWithIcon}
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            setIsEditMode={setIsEditMode}
            setEditingData={setEditingData}
          />
        </Container>
      </div>
    </div>
  );
};

export default Roles;

const AssignUserWithMenu = ({ roles, menuItems, openNotificationWithIcon }: any) => {
  const { Panel } = Collapse;
  const [selectedUser, setSelectedUser] = useState<string | undefined>(undefined);
  const [selectedRole, setSelectedRole] = useState<string | undefined>(undefined);
  const [form] = useForm();

  const [menuStructure, setMenuStructure] = useState<any[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const { isUserAddingToRole } = useSelector((state: RootState) => state.accessDetails);
  // Update state when menuItems is available
  useEffect(() => {
    if (menuItems) {
      setMenuStructure(
        menuItems.map((menu: any) => ({
          menuName: menu.label,
          isActive: true,
          subMenus: menu.subItems?.map((subMenu: any) => ({
            subMenuName: subMenu.label,
            isActive: true,
            permissions: [
              { permissionName: 'get', isActive: true },
              { permissionName: 'put', isActive: true },
              { permissionName: 'post', isActive: true },
              { permissionName: 'delete', isActive: true },
              { permissionName: 'getAll', isActive: true },
              { permissionName: 'Search', isActive: true },
            ],
          })),
        })),
      );
    }
  }, [menuItems]);

  const togglePermission = (menuIndex: any, subMenuIndex: any, permissionIndex: any, type: any) => {
    setMenuStructure((prev) =>
      prev.map((menu, i) =>
        i === menuIndex
          ? {
              ...menu,
              subMenus: menu.subMenus.map((subMenu: any, j: any) =>
                j === subMenuIndex
                  ? {
                      ...subMenu,
                      permissions:
                        type === 'permission'
                          ? subMenu.permissions.map((permission: any, k: any) =>
                              k === permissionIndex ? { ...permission, isActive: !permission.isActive } : permission,
                            )
                          : subMenu.permissions,
                      isActive: type === 'submenu' ? !subMenu.isActive : subMenu.isActive,
                    }
                  : subMenu,
              ),
              isActive: type === 'menu' ? !menu.isActive : menu.isActive,
            }
          : menu,
      ),
    );
  };

  function generateUUID() {
    // Generate a random UUID (version 4)
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  // Example usage
  const newUUID = generateUUID();

  // Submit handler to get the current state
  const handleSubmit = async () => {
    if (!selectedRole) {
      openNotificationWithIcon('error', 'Select a role first');
      return;
    }
    if (!selectedUser) {
      openNotificationWithIcon('error', 'Select a user first');
      return;
    }

    const payload = {
      userId: selectedUser,
      roleName: selectedRole,
      menus: menuStructure,
    };
    const response: any = await dispatch(AddUserToRole(payload));
    console.log(response);
    if (response.payload.userId) {
      openNotificationWithIcon('success', 'User assigned to the role successfully');
      setSelectedRole(undefined);
      setSelectedUser(undefined);
      form.resetFields();
    } else if (response.payload === 'Request failed with status code 400') {
      console.log('Insideeee');
      openNotificationWithIcon('error', 'Access details for User already exist.');
    } else {
      openNotificationWithIcon('error', 'Failed to assign user to role');
    }
  };

  if (!menuStructure) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div>
        {/* User and Role Select */}
        <Form form={form} layout="vertical">
          <div style={{ display: 'flex' }}>
            <Form.Item rules={[{ required: true, message: 'Select user first' }]} label={'Select Role'}>
              <Select
                allowClear
                showSearch
                style={{ width: '20vw', marginRight: '20px' }}
                placeholder="Select Role"
                onChange={(value) => setSelectedRole(value)}
                value={selectedRole}
                options={roles?.map((e: any) => ({ label: e.name, value: e.name }))}
              />
            </Form.Item>
            <Form.Item label={'Select User'}>
              <Select
                value={selectedUser}
                onChange={(value) => setSelectedUser(value)}
                options={[
                  {
                    label: 'Random User',
                    value: newUUID,
                  },
                ]}
                allowClear
                showSearch
                style={{ width: '20vw', marginRight: '20px' }}
                placeholder="Select User"
              />
            </Form.Item>
          </div>
        </Form>

        {/* Menu Structure */}
        <Collapse defaultActiveKey={['0']}>
          {menuStructure.map((menu, menuIndex) => (
            <Panel
              header={
                <Checkbox checked={menu.isActive} onChange={() => togglePermission(menuIndex, null, null, 'menu')}>
                  {menu.menuName}
                </Checkbox>
              }
              key={menuIndex}
            >
              {menu.subMenus.map((subMenu: any, subMenuIndex: any) => (
                <Collapse key={`${menuIndex}-${subMenuIndex}`} defaultActiveKey={['0']}>
                  <Panel
                    header={
                      <Checkbox
                        checked={subMenu.isActive}
                        onChange={() => togglePermission(menuIndex, subMenuIndex, null, 'submenu')}
                      >
                        {subMenu.subMenuName}
                      </Checkbox>
                    }
                    key={subMenuIndex}
                  >
                    {subMenu.permissions.map((permission: any, permissionIndex: any) => (
                      <Checkbox
                        key={permissionIndex}
                        checked={permission.isActive}
                        onChange={() => togglePermission(menuIndex, subMenuIndex, permissionIndex, 'permission')}
                      >
                        {permission.permissionName}
                      </Checkbox>
                    ))}
                  </Panel>
                </Collapse>
              ))}
            </Panel>
          ))}
        </Collapse>

        {/* Submit Button */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            marginTop: '20px',
            marginRight: '10px',
          }}
        >
          <AsyncButton
            buttonText="Assign"
            id="addUserToRole"
            disabled={isUserAddingToRole}
            isLoading={isUserAddingToRole}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};
