import { createSlice } from '@reduxjs/toolkit';
import {
  CreatePriceListData,
  DeletePriceListData,
  GetAllPriceListData,
  GetSinglePriceListData,
  UpdatePriceListData,
} from './thunk';
import { IPriceList, IPagination } from 'src/utils/types/priceListTypes';

interface IPriceListState {
  data: IPriceList[];
  pagination: IPagination;
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IPriceListState = {
  data: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const priceListSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllPriceListData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllPriceListData.fulfilled, (state, action: any) => {
        if (action.payload) {
          const { data, pagination } = action.payload;
          state.data = data;
          state.pagination = pagination;
        } else {
          state.data = [];
          state.pagination = { currentPage: 1, pageSize: 10, totalPages: 0, totalCount: 0, hasPrevious: false, hasNext: false };
        }
        state.isLoading = false;
      })
      .addCase(GetAllPriceListData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSinglePriceListData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSinglePriceListData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSinglePriceListData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreatePriceListData.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreatePriceListData.fulfilled, (state, action) => {
        state.isCreating = false;
      })
      .addCase(CreatePriceListData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdatePriceListData.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdatePriceListData.fulfilled, (state, action) => {
        const updatedContact = action.payload as IPriceList;
        const index = state.data.findIndex((contact) => contact.id === updatedContact.id);
        state.data[index] = updatedContact;
        state.isUpdating = false;
      })
      .addCase(UpdatePriceListData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isUpdating = false;
      })
      .addCase(DeletePriceListData.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeletePriceListData.fulfilled, (state, action) => {
        const deletedContactId = action.payload as string;
        state.data = state.data.filter((contact) => contact.id !== deletedContactId);
        state.isDeleting = false;
      })
      .addCase(DeletePriceListData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isDeleting = false;
      });
  },
});

export default priceListSlice.reducer;
