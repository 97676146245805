import axios from 'axios';
import env from 'src/env';
import { IRoles, IPagination } from 'src/utils/types/roleTypes';

const baseURL = env.VITE_ROLE_MANAGEMENT_BASE_URL();
const permissionsBaseUrl = '/role-management';


const getAllRoles = async (
  pageNumber: number,
  pageSize: number,

): Promise<{ data: IRoles[]; pagination: IPagination } | null | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const params: {
      pageNumber: number;
      pageSize: number;

    } = { pageNumber, pageSize };


    const response = await axios.get(`${permissionsBaseUrl}/roles`, {
      headers,
      baseURL,
      params,
    });

    return response;
  } catch (error) {
    console.error('Error fetching all Roles:', error);
    throw error;
  }
};
const getPermissionsByRole = async (roleId: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response = await axios.get(`${permissionsBaseUrl}/${roleId}`, { headers, baseURL });
    return response;
  } catch (error) {
    console.error('Error fetching Permissions by Role:', error);
    throw error;
  }
};

const updateRole = async (roleId: string, data: any) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.put(`${permissionsBaseUrl}/${roleId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating Permissions:', error);
    throw error;
  }
};

const deleteRole = async (roleId: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.delete(`${permissionsBaseUrl}/${roleId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error deleting Role:', error);
    throw error;
  }
};

const createRole = async (data: any) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.post(`${permissionsBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating Role:', error);
    throw error;
  }
};

export const PermissionService = {
  getAllRoles,
  getPermissionsByRole,
  updateRole,
  createRole,
  deleteRole,
};
