import axios from 'axios';
import env from 'src/env';
import { IComparisonData, IPagination } from 'src/utils/types/ComparisonDataTypes';

const baseURL = env.VITE_COMPARISON_DATA_BASE_URL();
const comparisonDataBaseUrl = '/comparison/weekly';

const createComparison = async (comparisonData: { hotelId: string | number; week: number; year: number }) => {
  const accessToken = localStorage.getItem('accessToken');
  return axios.post(`${comparisonDataBaseUrl}`, comparisonData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    baseURL,
  });
};
const retrieveAllComparisons = async (
  pageNumber: number,
  pageSize: number,
): Promise<{ data: IComparisonData[]; pagination: IPagination } | null | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const params: {
      pageNumber: number;
      pageSize: number;
    } = { pageNumber, pageSize };

    const response = await axios.get(`${comparisonDataBaseUrl}`, {
      headers,
      baseURL,
      params,
    });

    return response;
  } catch (error) {
    console.error('Error fetching all comparisons', error);
    throw error;
  }
};

const retrieveSpecificComparisonDetails = async (comparisonId: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.get(`${comparisonDataBaseUrl}/${comparisonId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching specific comparison details', error);
    throw error;
  }
};

export const ComparisonDataService = {
  createComparison,
  retrieveAllComparisons,
  retrieveSpecificComparisonDetails,
};
