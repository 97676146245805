import { createAsyncThunk } from '@reduxjs/toolkit';

import { addTenant } from 'src/utils/services/service/tenantService';
import { addTenantSuccessful, addingTenant } from './reducer';

export const addNewtenant = (name: string) => async (dispatch: any) => {
  dispatch(addingTenant);

  const res = await addTenant(name);
  if (res) {
    dispatch(addTenantSuccessful);
    return res;
  }
};
export const add_newTenant = createAsyncThunk(
  'tenant/add_new_tenant',
  async (tenantData: any) => {
    try {
      const response = await addTenant(tenantData);
      return response;
    } catch (error) {
      return error;
    }
  }
);

