import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { IUploadedFile } from 'src/utils/types/mLPreProcessingFlowTypes';
import DataSetsModal from './components/DataSetsModal';
import { FiEdit, FiEye } from 'react-icons/fi';

import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { GetAllDataSets, DeleteDataSet } from 'src/slices/MLPreProcessingFlow/thunk';
import AbbreviatedId from 'src/components/Common/AbbreviatedId';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import NewModal from './components/ModelTrainModal';

export const FileManagementTestData: IUploadedFile[] = [
  {
    id: '1',
    name: 'dataset1.csv',
    uploaded_at: '2024-09-05T10:00:00Z',
    size: '150KB',
  },
  {
    id: '2',
    name: 'dataset2.csv',
    uploaded_at: '2024-09-05T12:30:00Z',
    size: '200KB',
  },
  {
    id: '3',
    name: 'dataset3.csv',
    uploaded_at: '2024-09-06T09:45:00Z',
    size: '300KB',
  },
  {
    id: '4',
    name: 'dataset4.csv',
    uploaded_at: '2024-09-07T11:20:00Z',
    size: '250KB',
  },
  {
    id: '5',
    name: 'dataset5.csv',
    uploaded_at: '2024-09-08T14:10:00Z',
    size: '400KB',
  },
];

const PreProcessingDataSets = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<IUploadedFile | null>(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [viewData, setViewData] = useState<IUploadedFile | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [selectedName, setSelectedName] = useState<string | null>(null);

  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { isLoading, isDeleting, mealPlans } = useSelector((state: RootState) => state.mealPlans);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && mealPlans.length === 0) {
      dispatch(GetAllDataSets());
    }
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const columns: TableProps<IUploadedFile>['columns'] = [
    {
      title: 'Actions',
      dataIndex: 'x',
      width: 150,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsViewMode(true);
                setViewData(record);
              }}
            >
              <FiEye id="viewDetails" size={20} />
            </div>

            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
            >
              <FiEdit id="updateDataSet" size={20} />
            </div>
            <div className="tableIcon">
              <DeleteButton
                title="Are you sure you want to delete?"
                cancelText="No"
                okText="Yes"
                okButtonId={`confirmAndDeleteDataSet`}
                triggerButtonId="deleteDataSet"
                onConfirm={() => {}}
              />
            </div>
          </div>
        );
      },
    },

    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      width: 150,
      render: (text: string, record: any) => (
        <Button
          color="secondary"
          style={{ cursor: 'pointer', backgroundColor: '#E5E4E2', borderColor: '#E5E4E2', color: 'black' }}
          onClick={() => {
            setSelectedName(text);
            setSelectedId(record.id);
            setIsNewModalOpen(true);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: 'Uploaded At',
      key: 'uploaded_at',
      dataIndex: 'uploaded_at',
      width: 200,
      render: (text: string) => new Date(text).toLocaleDateString(),
    },

    {
      title: 'Size',
      key: 'size',
      dataIndex: 'size',
      width: 100,
    },
  ];

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const deleteDataSet = async (dataSetId: string) => {
    if (!dataSetId) {
      openNotificationWithIcon('error', 'Did not find the data set id');
      return;
    }
    try {
      const response = await dispatch(
        DeleteDataSet({
          dataSetId,
        }),
      );
      if (response.payload === dataSetId) {
        openNotificationWithIcon('success', 'Data Set deleted successfully');
        dispatch(GetAllDataSets());
      } else {
        openNotificationWithIcon('error', 'Error deleting Data Set');
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Something went wrong');
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>Data Sets</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title="Settings" breadcrumbItem="Data Sets" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div>
                          <SearchBar
                            id="mealPlansSearchBar"
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder="Search data by name"
                          />
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Button
                              id="createMealPlans"
                              className="btn btn-light"
                              onClick={() => {
                                setIsModalOpen(!isModalOpen);
                              }}
                            >
                              <i className="uil uil-plus me-1"></i> Create Dataset
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableView loading={false} columns={columns} dataSource={FileManagementTestData} />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <DataSetsModal
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
              initialViewValues={viewData}
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              setViewData={setViewData}
            />

            <NewModal
              isOpen={isNewModalOpen}
              toggle={() => setIsNewModalOpen(!isNewModalOpen)}
              name={selectedName}
              id={selectedId}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default PreProcessingDataSets;
