import { createSlice } from '@reduxjs/toolkit';
import { GetAllRoles, GetPermissionsByRole, DeleteRole, UpdateRole, CreateRole } from './thunk';
import { IRoles, IPagination } from 'src/utils/types/roleTypes';

interface IPermissionsState {
  roles: IRoles[];

  permissions: [];
  pagination: IPagination;
  isPermissionsLoading: boolean;
  isRolesLoading: boolean;
  isRolesUpdating: boolean;
  isRoleCreating: boolean;
  error: string | null;
}

const initialState: IPermissionsState = {
  roles: [],
  permissions: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },

  isPermissionsLoading: false,
  isRolesLoading: false,
  isRolesUpdating: false,
  isRoleCreating: false,
  error: null,
};

const permissionsSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRoles.pending, (state) => {
        state.isRolesLoading = true;
      })
      .addCase(GetAllRoles.fulfilled, (state, action: any) => {
        if (action.payload) {
          const { roles, pagination } = action.payload;
          state.roles = roles;
          state.pagination = pagination;
        } else {
          state.roles = [];
          state.pagination = { currentPage: 1, pageSize: 10, totalPages: 0, totalCount: 0, hasPrevious: false, hasNext: false };
        }
        state.isRolesLoading = false;
      })


      .addCase(GetAllRoles.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isRolesLoading = false;
      })
      .addCase(GetPermissionsByRole.pending, (state) => {
        state.isPermissionsLoading = true;
      })
      .addCase(GetPermissionsByRole.fulfilled, (state, action) => {
        state.permissions = action.payload as [];
        state.isPermissionsLoading = false;
      })
      .addCase(GetPermissionsByRole.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isPermissionsLoading = false;
      })
      .addCase(UpdateRole.pending, (state) => {
        state.isRolesUpdating = true;
      })
      .addCase(UpdateRole.fulfilled, (state) => {
        state.isRolesUpdating = false;
      })
      .addCase(UpdateRole.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isRolesUpdating = false;
      })
      .addCase(DeleteRole.pending, (state) => {
        state.isRolesUpdating = true;
      })
      .addCase(DeleteRole.fulfilled, (state) => {
        state.isRolesUpdating = false;
      })
      .addCase(DeleteRole.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isRolesUpdating = false;
      })
      .addCase(CreateRole.pending, (state) => {
        state.isRoleCreating = true;
      })
      .addCase(CreateRole.fulfilled, (state) => {
        state.isRoleCreating = false;
      })
      .addCase(CreateRole.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isRoleCreating = false;
      });
  },
});

export default permissionsSlice.reducer;
