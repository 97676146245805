import api from '../axiosConfig';
import env from '../../../env';
import axios from 'axios';
import { IRoomInformation, IPagination } from 'src/utils/types/roomInformationTypes';

const baseURL = env.VITE_ROOM_INFORMATION_DEFAULT_BASE_URL();
const roomConfigurationBaseUrl = '/roominformation/competitors/rooms';


const getAllRoomConfigurations = async (
  pageNumber: number,
  pageSize: number,
): Promise<{ data: IRoomInformation[]; pagination: IPagination } | null | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const params: {
      pageNumber: number;
      pageSize: number;
    } = { pageNumber, pageSize };

    const response = await axios.get(`${roomConfigurationBaseUrl}`, {
      headers,
      baseURL,
      params,
    });

    return response;
  } catch (error) {
    console.error('Error fetching all room information:', error);
    throw error;
  }
};
export const RoomInformationService = {
  getAllRoomConfigurations,
};
