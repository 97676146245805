import { createAsyncThunk } from '@reduxjs/toolkit';
import { processOccupancyAssistance } from 'src/utils/services/service/dashboardService';

export const processOccupancyAssistanceSlice = createAsyncThunk<any, any>(
  'dashboard/processOccupancyAssistance',
  async (data) => {
    try {
      return await processOccupancyAssistance(data);
    } catch (error) {
      throw error;
    }
  },
);

