import { createAsyncThunk } from '@reduxjs/toolkit';
import { ExternalApiKeysService } from 'src/utils/services/service/externalApiKeysService';
import { IExternalApiKeys, IPagination } from 'src/utils/types/externalApiKeysTypes';

export const GetAllExternalApiKeys = createAsyncThunk<
  { data: IExternalApiKeys[]; pagination: IPagination },
  { page: number; pageSize: number; }
>('data/getAll', async ({ page, pageSize }) => {
  try {
    const response = await ExternalApiKeysService.getExternalApiKeys(page, pageSize);
    return response;
  } catch (error) {
    return error;
  }
});


export const GetSingleExternalApiKey = createAsyncThunk(
  'data/getSingle',
  async ({ externalApiKeyId }: { externalApiKeyId: string }) => {
    try {
      const response = await ExternalApiKeysService.getSingleExternalApiKey(externalApiKeyId);
      return response;
    } catch (error) {
      console.error('Error fetching single external api key:', error);
      throw error;
    }
  },
);

export const CreateExternalApiKey = createAsyncThunk(
  'data/create',
  async ({ data }: { data: IExternalApiKeys }) => {
    try {
      const response = await ExternalApiKeysService.createExternalApiKey(data);
      return response;
    } catch (error) {
      console.error('Error creating external api key:', error);
      throw error;
    }
  },
);

export const UpdateExternalApiKey = createAsyncThunk(
  'data/update',
  async ({ externalApiKeyId, data }: { externalApiKeyId: string; data: IExternalApiKeys }) => {
    try {
      const response = await ExternalApiKeysService.updateExternalApiKey(externalApiKeyId, data);
      return response;
    } catch (error) {
      console.error('Error updating external api key:', error);
      throw error;
    }
  },
);

export const DeleteExternalApiKey = createAsyncThunk(
  'data/delete',
  async ({ externalApiKeyId }: { externalApiKeyId: string }) => {
    try {
      const response = await ExternalApiKeysService.deleteExternalApiKey(externalApiKeyId);
      return response;
    } catch (error) {
      console.error('Error deleting external api key:', error);
      throw error;
    }
  },
);

export const ValidateApiKey = createAsyncThunk('data/validate', async ({ apiKey }: { apiKey: string }) => {
  try {
    const response = await ExternalApiKeysService.validateApiKey(apiKey);
    return response;
  } catch (error) {
    console.error('Error validating external api key:', error);
    throw error;
  }
});
