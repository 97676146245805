import { createSlice } from '@reduxjs/toolkit';
import { IComparisonData, IPagination } from 'src/utils/types/ComparisonDataTypes';
import { CreateComparisonData, RetrieveAllComparisons, RetrieveSpecificComparisonDetails } from './thunk';

interface IComparisonDataState {
  data: IComparisonData[];
  pagination: IPagination;
  singleComparisonData: IComparisonData | null;
  isLoading: boolean;
  isCreating: boolean;
  error: string | null;
  nextWeekData?: IComparisonData | null;
}

const initialState: IComparisonDataState = {
  data: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  singleComparisonData: null,
  isLoading: false,
  isCreating: false,
  error: null,
  nextWeekData: null,
};

const comparisonDataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(RetrieveAllComparisons.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(RetrieveAllComparisons.fulfilled, (state, action) => {
      // Determine the upcoming week
      const getCurrentWeekNumber = () => {
        const date = new Date();
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);

        // Get the day of the year
        const dayOfYear = Math.floor((date.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000)) + 1;

        // Get the week number (ISO-8601)
        const weekNumber = Math.ceil(dayOfYear / 7);

        return weekNumber;
      };

      const getNextWeekNumber = () => {
        const currentWeek = getCurrentWeekNumber();
        const currentYear = new Date().getFullYear();

        const nextWeek = currentWeek === 52 ? 1 : currentWeek + 1;

        return nextWeek;
      };

      const currentWeek = getCurrentWeekNumber();
      const nextWeek = getNextWeekNumber();
      const currentYear = new Date().getFullYear();

      if (action.payload) {
        const { data, pagination } = action.payload;
        state.data = data;
        state.pagination = pagination;

        // ** Filtering the next week data **
        const nextWeekData = data?.find((item) => item?.year === currentYear && item?.week === nextWeek);
        state.nextWeekData = nextWeekData;
      } else {
        state.data = [];
        state.pagination = {
          currentPage: 1,
          pageSize: 10,
          totalPages: 0,
          totalCount: 0,
          hasPrevious: false,
          hasNext: false,
        };
      }

      state.isLoading = false;
    });
    builder.addCase(RetrieveAllComparisons.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });
    builder.addCase(RetrieveSpecificComparisonDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(RetrieveSpecificComparisonDetails.fulfilled, (state, action) => {
      state.singleComparisonData = action.payload as IComparisonData;
      state.isLoading = false;
    });
    builder.addCase(RetrieveSpecificComparisonDetails.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isLoading = false;
    });
    builder.addCase(CreateComparisonData.pending, (state) => {
      state.isCreating = true;
    });
    builder.addCase(CreateComparisonData.fulfilled, (state, action) => {
      state.isCreating = false;
    });
    builder.addCase(CreateComparisonData.rejected, (state, action) => {
      state.error = action.payload as string;
      state.isCreating = false;
    });
  },
});

export default comparisonDataSlice.reducer;
