import { createSlice } from '@reduxjs/toolkit';
import { GetAllRoomConfigurations } from './thunk';
import { IRoomInformation, IPagination } from 'src/utils/types/roomInformationTypes';

interface IRoomInformationState {
  roomInformation: IRoomInformation[];
  pagination: IPagination;
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IRoomInformationState = {
  roomInformation: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const roomInformationSlice = createSlice({
  name: 'roomInformation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRoomConfigurations.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(GetAllRoomConfigurations.fulfilled, (state, action) => {
        if (action.payload) {
          const { roomInformation, pagination } = action.payload;
          state.roomInformation = roomInformation;
          state.pagination = pagination;
        } else {
          state.roomInformation = [];
          state.pagination = { currentPage: 1, pageSize: 10, totalPages: 0, totalCount: 0, hasPrevious: false, hasNext: false };
        }
        state.isLoading = false;
      })
      .addCase(GetAllRoomConfigurations.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      });
  },
});

export default roomInformationSlice.reducer;
