import React, { useImperativeHandle, useState } from 'react';

import { Form, Select, DatePicker, Slider, InputNumber, Checkbox, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

// ** Room Type ;
const roomTypesArray = ['Apartment', 'Resting Place', 'Room', 'Holiday Home'];
// ** Meal Plans
const mealPlansArray = ['Overnight Stay', 'Bed and Breakfast', 'Half Board', 'Full Board', 'All Inclusive'];

interface IPricingForm {
  totalRate: number;
  variationPercentage: number;
  dateRange: [Date, Date];
}

interface RoomTypes {
  IsApartment: boolean;
  IsRestingPlace: boolean;
  IsRoom: boolean;
  IsHolidayHome: boolean;
}

interface MealPlans {
  IsOvernightStay: boolean;
  IsBedAndBreakfast: boolean;
  IsHalfBoard: boolean;
  IsFullBoard: boolean;
  IsAllInclusive: boolean;
}

interface IPricingFormHandle {
  fetchValues: () => Promise<IPricingForm>;
  mealPlans: MealPlans;
  roomTypes: RoomTypes;
}
interface IPricingFormProps {
  callFunction: () => void;
}

const PricingForm = React.forwardRef<IPricingFormHandle, IPricingFormProps>(({ callFunction }, ref) => {
  const [form] = Form.useForm<IPricingForm>();
  const { t } = useTranslation('');

  const [selectedRoomType, setSelectedRoomType] = useState(null);
  const [selectedMealPlan, setSelectedMealPlan] = useState(null);
  const [roomTypes, setRoomTypes] = useState({
    IsApartment: false,
    IsRestingPlace: false,
    IsRoom: false,
    IsHolidayHome: false,
  });

  const [mealPlans, setMealPlans] = useState({
    IsOvernightStay: false,
    IsBedAndBreakfast: false,
    IsHalfBoard: false,
    IsFullBoard: false,
    IsAllInclusive: false,
  });

  // **  for room type change
  const handleRoomTypeChange = (selectedOption: any) => {
    // Reset all values to false
    const updatedRoomTypes = {
      IsApartment: false,
      IsRestingPlace: false,
      IsRoom: false,
      IsHolidayHome: false,
    };

    // Set the corresponding room type to true based on the selected option
    if (selectedOption === 'Apartment') {
      updatedRoomTypes.IsApartment = true;
    } else if (selectedOption === 'Resting Place') {
      updatedRoomTypes.IsRestingPlace = true;
    } else if (selectedOption === 'Room') {
      updatedRoomTypes.IsRoom = true;
    } else if (selectedOption === 'Holiday Home') {
      updatedRoomTypes.IsHolidayHome = true;
    }

    // Update state
    setRoomTypes(updatedRoomTypes);
    setSelectedRoomType(selectedOption);
  };

  // **  for meal plan change
  const handleMealPlanChange = (selectedOption: any) => {
    // Reset all values to false
    const updatedMealPlans = {
      IsOvernightStay: false,
      IsBedAndBreakfast: false,
      IsHalfBoard: false,
      IsFullBoard: false,
      IsAllInclusive: false,
    };

    // Set the corresponding meal plan to true based on the selected option
    if (selectedOption === 'Overnight Stay') {
      updatedMealPlans.IsOvernightStay = true;
    } else if (selectedOption === 'Bed and Breakfast') {
      updatedMealPlans.IsBedAndBreakfast = true;
    } else if (selectedOption === 'Half Board') {
      updatedMealPlans.IsHalfBoard = true;
    } else if (selectedOption === 'Full Board') {
      updatedMealPlans.IsFullBoard = true;
    } else if (selectedOption === 'All Inclusive') {
      updatedMealPlans.IsAllInclusive = true;
    }

    // Update state
    setMealPlans(updatedMealPlans);
    setSelectedMealPlan(selectedOption);
  };

  const fetchValues = async () => {
    await form.validateFields();
    return await form.getFieldsValue();
  };

  useImperativeHandle(ref, () => ({
    fetchValues: async () => await fetchValues(),
    mealPlans: mealPlans,
    roomTypes: roomTypes,
  }));

  const stringArrayToOptions = (array: string[]) => {
    return array.map((item) => ({ label: t(item), value: item }));
  };

  const mainForm = (
    <>
      <Form layout="vertical" form={form}>
        <div style={{ display: 'flex' }}>
          <Form.Item
            className="menu-item"
            required
            label={<span style={{ color: '#7b8190' }}>{t('Room Type')}</span>}
            name="roomType"
            style={{ width: '30%' }}
            rules={[{ required: true, message: t('Please select a room type') }]}
          >
            <Select
              placeholder={t('Select room type')}
              showSearch
              options={stringArrayToOptions(roomTypesArray)}
              value={selectedRoomType}
              onChange={handleRoomTypeChange}
            />
          </Form.Item>
          <Form.Item
            required
            style={{ width: '25%', marginLeft: '0.5rem' }}
            name="minOccupancy"
            label={<span style={{ color: '#7b8190' }}>{t('Meal Plan')}</span>}
            rules={[{ required: true, message: t('Please select meal plan') }]}
          >
            <Select
              placeholder={t('Select meal plan')}
              showSearch
              options={stringArrayToOptions(mealPlansArray)}
              value={selectedMealPlan}
              onChange={handleMealPlanChange}
            />
          </Form.Item>

          <Form.Item
            label={<span style={{ color: '#7b8190' }}>{t('Date Range')}</span>}
            required
            name="dateRange"
            rules={[{ required: true, message: t('Please select date range') }]}
            style={{ width: '30%', marginLeft: '0.5rem' }}
          >
            <RangePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: t('Please add total rate') }]}
            style={{ width: '30%', marginLeft: '0.5rem' }}
            required
            label={<span style={{ color: '#7b8190' }}>{t('Base Rate')}</span>}
            name="totalRate"
          >
            <InputNumber placeholder={t('Base Rate')} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: t('Please add variation percentage') }]}
            style={{ width: '30%', marginLeft: '0.5rem' }}
            required
            label={<span style={{ color: '#7b8190' }}>{t('Variation Percentage')}</span>}
            name="variationPercentage"
          >
            <InputNumber placeholder={t('Variation Percentage')} style={{ width: '100%' }} />
          </Form.Item>

          <div style={{ alignContent: 'center', width: '25%', marginLeft: '0.5rem' }}>
            <button className="btn btn-primary" onClick={callFunction}>
              {t('Process')}
            </button>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>{/* <di */}</div>
      </Form>
    </>
  );

  const AdvancedForm = (
    <>
      <Form layout="vertical" form={form}>
        <div style={{ display: 'flex' }}>
          <div style={{ alignContent: 'center', width: '25%', marginLeft: '0.5rem' }}>
            <button className="btn btn-primary" onClick={callFunction}>
              {t('Process')}
            </button>
          </div>
        </div>
      </Form>
    </>
  );

  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        type="card"
        size={'small'}
        items={[
          {
            label: t(`Main`),
            key: '1',
            children: mainForm,
          },
        ]}
      />
    </div>
  );
});

export { PricingForm };
export type { IPricingFormHandle, IPricingForm };
