import { Col, DatePicker, DatePickerProps, Form, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { RootState } from 'src/slices';
import { CreateComparisonData, RetrieveAllComparisons } from 'src/slices/ComparisonData/thunk';
import { AppDispatch } from 'src/store';
import { IComparisonData, IPagination } from 'src/utils/types/ComparisonDataTypes';
import { NotificationType } from 'src/utils/types/NotificationTypes';

interface IComparisonDataModalModalProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: { id: string; hotelId: string; week: number; year: number } | null;
  isEditMode?: boolean;
  setIsEditMode?: (isEditMode: boolean) => void;
  setEditingData?: (data: IComparisonData | null) => void;
  pagination: IPagination;
}

const ComparisonDataModal = ({
  isOpen,
  openNotificationWithIcon,
  pagination,
  toggle,
}: IComparisonDataModalModalProps) => {
  const [selectedYear, setSelectedYear] = React.useState<string | null>(null);
  const [selectedWeek, setSelectedWeek] = React.useState<string | null>(null);
  const { selectedProperty } = useSelector((state: RootState) => state.porperty);
  const { isCreating, data } = useSelector((state: RootState) => state.comparisonData);
  const { t } = useTranslation('');

  const dispatch: AppDispatch = useDispatch();

  const [form] = useForm();

  const onWeekChange: DatePickerProps['onChange'] = (_date, dateString) => {
    const week = (dateString as string).split('-')[1];
    const realFormat = week?.replace(/(st|nd|rd|th)/, '');

    setSelectedWeek(realFormat as string);
  };

  const onYearChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setSelectedYear(dateString as string);
  };

  const handleAddWeeklyComparison = async () => {
    await form.validateFields();

    const payload = {
      propertyId: selectedProperty?.value?.toString(),
      week: selectedWeek,
      year: selectedYear,
    };

    if (!payload.propertyId) {
      openNotificationWithIcon('error', t('Property Id is required'));
      return;
    }

    const comparisonExists = data?.some(
      (item) => item?.week?.toString() === payload.week && item?.year?.toString() === payload.year,
    );

    if (comparisonExists) {
      openNotificationWithIcon('error', t('Comparison data already exists for the selected week and year'));
      return;
    }

    try {
      const response: any = await dispatch(CreateComparisonData(payload));
      if (response?.payload?.id) {
        openNotificationWithIcon('success', t('Comparison data added successfully'));
        toggle();
        handleResetWeekYearState();
        dispatch(RetrieveAllComparisons({ page: pagination.currentPage, pageSize: pagination.pageSize }));
      } else {
        openNotificationWithIcon('error', t('Error adding comparison data'));
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  const handleResetWeekYearState = () => {
    setSelectedWeek(null);
    setSelectedYear(null);
    form.resetFields();
  };

  return (
    <div>
      <Modal
        style={{
          width: 300,
        }}
        centered
        isOpen={isOpen}
        toggle={() => {
          toggle();
          handleResetWeekYearState();
        }}
      >
        <ModalHeader
          toggle={() => {
            toggle();
            handleResetWeekYearState();
          }}
          tag="h5"
        >
          {t('Add Comparison')}
        </ModalHeader>
        <ModalBody>
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label={t('Year')}
              name="year"
              rules={[
                {
                  required: true,
                  message: t('Please select year'),
                },
              ]}
            >
              <DatePicker
                placeholder={t('Please select year')}
                id="selectYear"
                style={{
                  width: '100%',
                }}
                className="p-2"
                onChange={onYearChange}
                picker="year"
              />
            </Form.Item>
            <Form.Item
              required={true}
              label={t('Week')}
              name="week"
              rules={[
                {
                  required: true,
                  message: t('Please select week'),
                },
              ]}
            >
              <DatePicker
                placeholder={t('Please select week')}
                id="selectWeek"
                style={{
                  width: '100%',
                }}
                className="p-2"
                onChange={onWeekChange}
                picker="week"
              />
            </Form.Item>
          </Form>
          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Tooltip title={t('Cancel')}>
              <Button
                id=""
                // disabled={isCreating || isUpdating}
                onClick={() => {
                  toggle();
                  handleResetWeekYearState();
                }}
                style={{ marginTop: 10, marginRight: 10, background: 'white', color: 'black' }}
              >
                {t('Cancel')}
              </Button>
            </Tooltip>
            <Tooltip title={t('Add')}>
              <AsyncButton
                style={{ marginTop: 10, marginRight: 10 }}
                buttonText={t('Add')}
                id="addComparisonDataButton"
                isLoading={isCreating}
                disabled={isCreating}
                onClick={handleAddWeeklyComparison}
              />
            </Tooltip>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ComparisonDataModal;
