import { createSlice } from '@reduxjs/toolkit';
import { IExternalApiKeys, IPagination } from 'src/utils/types/externalApiKeysTypes';
import {
  CreateExternalApiKey,
  DeleteExternalApiKey,
  GetAllExternalApiKeys,
  GetSingleExternalApiKey,
  UpdateExternalApiKey,
  ValidateApiKey,
} from './thunk';

interface IExternalApiKeysState {
  data: IExternalApiKeys[];
  pagination: IPagination;
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
  isApiKeyValidating: boolean;
  isApiKeyValid: boolean;
}

const initialState: IExternalApiKeysState = {
  data: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
  isApiKeyValidating: false,
  isApiKeyValid: false,
};

const externalApiKeysSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllExternalApiKeys.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(GetAllExternalApiKeys.fulfilled, (state, action) => {
        if (action.payload) {
          const { data, pagination } = action.payload;
          state.data = data;
          state.pagination = pagination;
        } else {
          state.data = [];
          state.pagination = { currentPage: 1, pageSize: 10, totalPages: 0, totalCount: 0, hasPrevious: false, hasNext: false };
        }
        state.isLoading = false;
      })
      .addCase(GetAllExternalApiKeys.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleExternalApiKey.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleExternalApiKey.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleExternalApiKey.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateExternalApiKey.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateExternalApiKey.fulfilled, (state, action) => {
        state.data.push(action.payload as IExternalApiKeys);
        state.isCreating = false;
      })
      .addCase(CreateExternalApiKey.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateExternalApiKey.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateExternalApiKey.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(UpdateExternalApiKey.rejected, (state) => {
        state.isUpdating = false;
      })
      .addCase(DeleteExternalApiKey.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteExternalApiKey.fulfilled, (state) => {
        state.isDeleting = false;
      })
      .addCase(DeleteExternalApiKey.rejected, (state) => {
        state.isDeleting = false;
      })
      .addCase(ValidateApiKey.pending, (state) => {
        state.isApiKeyValidating = true;
      })
      .addCase(ValidateApiKey.fulfilled, (state) => {
        state.isApiKeyValid = true;
        state.isApiKeyValidating = false;
      })
      .addCase(ValidateApiKey.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isApiKeyValidating = false;
        state.isApiKeyValid = false;
      });
  },
});

export default externalApiKeysSlice.reducer;
