import { createAsyncThunk } from '@reduxjs/toolkit';
import { ManageHotelDataService } from 'src/utils/services/service/manageHotel';

import { IManageHotel, IPagination } from 'src/utils/types/manageHotelTypes';

export const CreateManageHotelData = createAsyncThunk(
  'data/createManageHotelData',
  async ({ data }: { data: IManageHotel }) => {
    try {
      const response = await ManageHotelDataService.createManageHotelData(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);
export const GetAllManageHotelData = createAsyncThunk<
  { data: IManageHotel[]; pagination: IPagination },
  { page: number; pageSize: number; }
>('data/getAllManageHotelData', async ({ page, pageSize }) => {
  try {
    const response = await ManageHotelDataService.getAllManageHotelData(page, pageSize);
    return response;
  } catch (error) {
    return error;
  }
});



export const GetSingleManageHotelData = createAsyncThunk(
  'data/getSingleManageHotelData',
  async ({ hoteldataId }: { hoteldataId: string }) => {
    try {
      const response = await ManageHotelDataService.getSingleManageHotelData(hoteldataId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateManageHotelData = createAsyncThunk(
  'data/updateManageHotelData',
  async ({ hoteldataId, data }: { hoteldataId: string; data: IManageHotel }) => {
    try {
      const response = await ManageHotelDataService.updateManageHotelData(hoteldataId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteManageHotelData = createAsyncThunk(
  'data/deleteManageHotelData',
  async ({ hoteldataId }: { hoteldataId: string }) => {
    try {
      const response = await ManageHotelDataService.deleteManageHotelData(hoteldataId);
      return response;
    } catch (error) {
      return error;
    }
  },
);