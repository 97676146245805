/* eslint-disable react-hooks/exhaustive-deps */
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification, Tooltip, Pagination } from 'antd';
import { IPriceList, IPagination } from 'src/utils/types/priceListTypes';
import PriceListModal from './components/PriceListModal';
import { FiEdit } from 'react-icons/fi';

import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { useNumberFormatter } from '../.././utils/formatNumber'
import { IRoomInformation } from 'src/utils/types/roomInformationTypes';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { DeletePriceListData, GetAllPriceListData } from 'src/slices/PriceList/thunk';
import AbbreviatedId from 'src/components/Common/AbbreviatedId';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useTranslation } from 'react-i18next';
import { GetAllRoomConfigurations } from 'src/slices/RoomInformation/thunk';
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';

const PriceList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<IPriceList | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const [api, contextHolder] = notification.useNotification();

  const { t } = useTranslation('');
  const { formatCurrency } = useNumberFormatter();

  const { isLoading, data, isDeleting } = useSelector((state: RootState) => state.priceList);
  const { roomInformation } = useSelector((state: RootState) => state.roomInformation);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && data.length === 0) {
      dispatch(GetAllPriceListData({ page: pageNumber, pageSize })).then((action: any) => {
        if (action.payload) {
          setPagination(action.payload.pagination);
        }
      });
    }
    dispatch(GetAllRoomConfigurations({ page: pageNumber, pageSize }));

  }, [pageNumber, pageSize]);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const getRoomTypeByRoomId = (roomId: number | string) => {
    const getAllRooms = (data: IRoomInformation[]) => {
      let allRooms: {
        roomType: string;
        platformId: string;
        roomId: string;
      }[] = [];

      data.forEach((item) => {
        allRooms = allRooms.concat(item.rooms);
      });

      return allRooms;
    };

    const roomType = getAllRooms(roomInformation).find((room) => room.roomId === roomId);
    return roomType?.roomType;
  };


  const columns: TableProps<IPriceList>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 150,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <Tooltip title={t('Edit')}>
              <div
                className="tableIcon"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsEditMode(true);
                  setEditingData(record);
                }}
                aria-label={t('Edit')}
              >
                <FiEdit id="updatePriceList" size={20} />
              </div>
            </Tooltip>

            <Tooltip title={t('Delete')}>
              <div className="tableIcon">
                <DeleteButton
                  title={t('Are you sure you want to delete?')}
                  cancelText={t('Cancel')}
                  okText={t('Proceed')}
                  okButtonId={`confirmAndDeletePriceList`}
                  triggerButtonId="deletePriceList"
                  onConfirm={() => {
                    deletePriceList(record.id!);
                  }}
                  aria-label={t('Delete')}
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      title: t('Name'),
      key: 'name',
      dataIndex: 'name',
      width: 250,
    },

    {
      title: t('Start Date'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: '15%',
      render: (text: string) => new Date(text).toLocaleDateString(),
    },
    {
      title: t('End Date'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: '15%',
      render: (text: string) => new Date(text).toLocaleDateString(),
    },

    {
      title: t('Items'),
      dataIndex: 'items',
      key: 'items',
      render: (items: { roomTypeId: number; price: number }[]) => {
        return (
          <div>
            {items.map((item) => (
              <div key={item.roomTypeId}>
                Room Type <strong>{getRoomTypeByRoomId(item.roomTypeId)}</strong>:
                <span id='items-price'>
                  {formatCurrency(item.price)}
                </span>


              </div>
            ))}
          </div>
        );
      },
    }


  ];

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const deletePriceList = async (pricelistId: string) => {
    if (!pricelistId) {
      openNotificationWithIcon('error', t('Price id not found'));
      return;
    }
    try {
      const response = await dispatch(
        DeletePriceListData({
          pricelistId,
        }),
      );
      if (response.payload === pricelistId) {
        openNotificationWithIcon('success', t('Price List deleted successfully'));
        dispatch(GetAllPriceListData({ page: pageNumber, pageSize }));
      } else {
        openNotificationWithIcon('error', t('Error deleting Price List'));
      }
    } catch (error) {
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>{t('Price List')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title={t('Settings')} breadcrumbItem={t('Price List')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <Tooltip title={t('Search price list by name')}>
                          <div>
                            <SearchBar
                              id="priceListSearchBar"
                              value={searchValue}
                              onChange={handleSearchChange}
                              placeholder={t('Search price list by name')}
                            />
                          </div>
                        </Tooltip>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Tooltip title={t('Add')} placement="top">
                              <Button
                                id="addPriceList"
                                className="btn btn-light"
                                onClick={() => {
                                  setIsModalOpen(!isModalOpen);
                                }}
                              >
                                <i className="uil uil-plus me-1"></i> {t('Add')}
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableViewPagination
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={
                          Array.isArray(data)
                            ? data
                              ?.filter((data: IPriceList) => {
                                if (searchValue === '') return data;
                                else if (data.name?.toLowerCase().includes(searchValue.toLowerCase())) {
                                  return data;
                                }
                              })
                              ?.reverse()
                            : []
                        }
                        showPagination={false}
                      />

                      <Pagination
                        current={pageNumber}
                        pageSize={pageSize}
                        total={pagination?.totalCount || 0}
                        onChange={(page, pageSize) => {
                          setPageNumber(page);
                          setPageSize(pageSize);
                          dispatch(GetAllPriceListData({ page, pageSize }));
                        }}
                        style={{ float: 'right', marginTop: '20px' }}
                        showSizeChanger
                        pageSizeOptions={['10', '20', '30', '50', '100']}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <PriceListModal
              pagination={pagination}
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default PriceList;
