import { createAsyncThunk } from '@reduxjs/toolkit';
import { AccessDetailsService } from 'src/utils/services/service/accessShieldService';

export const GetAccessDetailsBYUserId = createAsyncThunk('accessdetails/get-by-id', async (userId: string) => {
  if (!userId || userId === undefined || userId === null) {
    console.error('User Id Not found, cannot make request for access details');
    return;
  }

  try {
    const response = await AccessDetailsService.getAccessDetailsByUserID(userId);
    return response;
  } catch (error) {
    return error;
  }
});

export const GetAllAccessDetails = createAsyncThunk('accessdetails/get-All', async (roleName: string) => {
  try {
    const response = await AccessDetailsService.getAllAccessDetails(roleName);
    return response;
  } catch (error) {
    return error;
  }
});

export const UpdateAccessDetailsForAUser = createAsyncThunk(
  'accessdetails/update',
  async ({ accessData, userId }: { accessData: any; userId: string }) => {
    try {
      const response = await AccessDetailsService.updateAccessDetailsForAUser(accessData, userId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const AddUserToRole = createAsyncThunk('accessdetails/add-user', async (data: any, { rejectWithValue }) => {
  try {
    const response = await AccessDetailsService.addUserToRole(data);
    return response;
  } catch (error) {
    return error;
  }
});
