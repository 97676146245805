/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, notification, Select, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RootState } from 'src/store';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useEffect, useState } from 'react';
import { IMealPlans, IPagination } from 'src/utils/types/mealPlansTypes';

import { CreateMealPlansData, GetAllMealPlansData, UpdateMealPlansData } from 'src/slices/MealPlans/thunk';

import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { useTranslation } from 'react-i18next';

interface IMealPlansProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IMealPlans | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IMealPlans | null) => void;
  pagination: IPagination;
}

const MealPlansModal = ({
  isOpen,
  toggle,
  pagination,
  openNotificationWithIcon,
  initialValues,
  isEditMode = false,
  setIsEditMode,
  setEditingData,
}: IMealPlansProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating, data } = useSelector((state: RootState) => state.mealPlans);
  const { selectedProperty, realFormate } = useSelector((state: RootState) => state.porperty);

  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('');

  const [propertyIdOptions, setPropertyIdOptions] = useState<string[]>([]);

  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);

  useEffect(() => {
    const fetchMealPlans = async () => {
      const response = await dispatch(
        GetAllMealPlansData({ page: pagination.currentPage, pageSize: pagination.pageSize }),
      );

      if (response?.payload) {
        const uniquePropertyIds = Array.from(
          new Set((response.payload as IMealPlans[])?.map((mealPlan) => mealPlan.propertyId)),
        );
        setPropertyIdOptions(uniquePropertyIds);
      }
    };

    fetchMealPlans();
  }, [dispatch]);

  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IMealPlans = form.getFieldsValue();
    if (!selectedProperty.value || selectedProperty.value === '') {
      openNotificationWithIcon('error', t('Property id not found'));
      return;
    }
    // ** Get the current selected property id
    const propertyId = realFormate.find((property) => property.mandantId === selectedProperty?.value);
    formData.propertyId = propertyId?.id;
    try {
      if (isEditMode) {
        // ** Update Operation
        const payload = formData;
        // payload.tenant = initialValues?.tenant;
        payload.id = initialValues?.id;
        const response = await dispatch(
          UpdateMealPlansData({
            data: payload,
            mealplansId: initialValues?.id as string,
          }),
        );
        // ** Handled Response
        if ((response.payload as IMealPlans)['id']) {
          openNotificationWithIcon('success', t('Meal plans updated successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllMealPlansData({ page: 1, pageSize: 50 }));
        } else {
          openNotificationWithIcon('error', t(`Error updating Meal plans data`));
        }
      } else {
        // ** Create Operation
        const payload = formData;
        const response = await dispatch(
          CreateMealPlansData({
            data: payload,
          }),
        );

        // ** Handled Response
        if ((response.payload as IMealPlans)['id']) {
          openNotificationWithIcon('success', t('Meal plans created successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllMealPlansData({ page: 1, pageSize: 50 }));
        } else {
          openNotificationWithIcon('error', t(`Error creating Meal plans data`));
        }
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', t(`Something went wrong`));
    }
  };

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? t('Update') : t('Create')} {t('Meal Plans')}
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label={t('Name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('Please enter name'),
                },
              ]}
            >
              <Input id="mealPlansName" placeholder={t('Name')} className="p-2 px-2" />
            </Form.Item>

            <Form.Item
              required={true}
              label={t('Accommodation Kinds')}
              name="accommodationKinds"
              rules={[
                {
                  required: true,
                  message: t('Please select accommodation kinds'),
                },
              ]}
            >
              <Select mode="multiple" id="mealPlansAccommodationKinds" placeholder={t('Select Accommodation Kinds')}>
                <Select.Option value="full">{t('Full')}</Select.Option>
                <Select.Option value="half">{t('Half')}</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label={t('Room Types')} name="roomTypes">
              <Select mode="multiple" id="mealPlansRoomTypes" placeholder={t('Select Room Types')}>
                <Select.Option value="single">{t('Single')}</Select.Option>
                <Select.Option value="double">{t('Double')}</Select.Option>
                <Select.Option value="suite">{t('Suite')}</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              required={true}
              label={t('Description')}
              name="description"
              rules={[
                {
                  required: true,
                  message: t('Please enter a description'),
                },
              ]}
            >
              <Input.TextArea id="mealPlansDescription" placeholder={t('Description')} className="p-2 px-2" />
            </Form.Item>
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Tooltip title={t('Cancel')}>
              <Button
                id="manage-hotel-cancel-button"
                disabled={isCreating || isUpdating}
                onClick={handleCancel}
                style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
              >
                {t('Cancel')}
              </Button>
            </Tooltip>
            <Tooltip title={isEditMode ? t('Update') : t('Create')}>
              <AsyncButton
                buttonText={isEditMode ? t('Update') : t('Create')}
                isEditMode={isEditMode}
                style={{ marginTop: 20 }}
                isLoading={isCreating || isUpdating}
                onClick={handleCreateOrUpdate}
                id="mealPlansCreateORUpdateButton"
              />
            </Tooltip>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MealPlansModal;
