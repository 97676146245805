
import { createSlice } from '@reduxjs/toolkit';
import { deployModelThunk } from './thunk';

interface ModelDeploymentState {
  deployment: any | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: ModelDeploymentState = {
  deployment: null,
  isLoading: false,
  error: null,
};

const modelDeploymentSlice = createSlice({
  name: 'modelDeployment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deployModelThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deployModelThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deployment = action.payload;
      })
      .addCase(deployModelThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to deploy the model';
      });
  },
});

export default modelDeploymentSlice.reducer;
