import MetaTags from 'react-meta-tags';
import { useEffect, useRef, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  Spinner,
} from 'reactstrap';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import DeleteModal from 'src/components/Modals/DeleteModal';
import Select from 'react-select';
import MultipleSelect from 'src/components/elements/MultipleSelect';
import { RootState } from 'src/slices';
import {
  createExternalEvents,
  updateExternalEvents,
  deleteExternalEvents,
  getExternalEvents,
  getEventCategories,
  getExternalEvent,
} from 'src/slices/ExternalEvents/thunk';
import DatePicker, { DatePickerRef } from 'react-multi-date-picker';
import type { Value } from 'react-multi-date-picker';
import GoogleMap from 'src/components/elements/GoogleMap';
import LocationIcon from 'src/components/elements/Icons/LocationIcon';
import MultipleInput from 'src/components/elements/MultipleInput';
import EditIcon from 'src/components/elements/Icons/EditIcon';
import { getAllCities, getAllContinents, getAllCountries, getAllPois, getAllRegions } from 'src/slices/thunks';
import { getTenants } from 'src/utils/services/service/tenantService';
import LoaderOverlay from 'src/components/elements/LoaderOverlay';
import DeleteIcon from 'src/components/elements/Icons/DeleteIcon';
import { getDateNow } from 'src/utils/helpers';
import { ITableDataEvents, IPagination } from 'src/utils/types/externalEvents';
import './index.scss';
import { city, continents, countries, regions } from '../GeographicalLocations/DropDownStates';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TableView from 'src/components/Common/TableView/TableView';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import { FiEdit } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { Tooltip, Pagination } from 'antd';
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';

const EventsList = () => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [shoDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showChangeModal, setShowChangeModal] = useState<boolean>(false);
  const [continentOption, setContinentOption] = useState<any>([]);
  const [countryOption, setCountryOption] = useState<any>([]);
  const [regionOption, setRegionOption] = useState<any>([]);
  const [cityOption, setCityOption] = useState<any>([]);
  const [poisOption, setPoisOption] = useState<any>([]);
  const [propertiesOption, setPropertiesOption] = useState<any>([]);
  const [tenantsOption, setTenantsOption] = useState<any>([]);
  const [tagsOption, setTagsOption] = useState<any>([]);
  const [artistsOption, setArtistsOption] = useState<any>([]);
  const [categoriesOption, setCategoriesOption] = useState<any>();
  const [showCreateErrorMessage, setShowCreateErrorMessage] = useState(false);
  const [isReceivedEdditData, setIsReceivedEditData] = useState(false);
  const [eventName, setEventName] = useState<any>('');
  const [eventDescription, setEventDescription] = useState<any>('');
  const [eventLocation, setEventLocation] = useState({ lat: 0, lon: 0 });
  const [eventExpectedAttendance, setEventExpectedAttendance] = useState(0);
  const [currentEventId, setCurrentEventId] = useState('');
  const datePickerRef = useRef<DatePickerRef>();
  const [dateValue, setDateValue] = useState<Value | any>(new Date());
  const [selectedDate, setSelectedDate] = useState({ start: getDateNow(), end: getDateNow() });
  const [tenantsList, setTenantsList] = useState<any>([]);
  const [loadingTenants, setLoadingTenants] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [avaFieldValues, setAvaFieldValues] = useState({
    name: '',
    description: '',
    lat: '',
    lon: '',
    expectedAttendance: '',
  });
  const [disableBtn, setDisableBtn] = useState(true);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [optionsError, setOptionsError] = useState({
    continentError: false,
    countryError: false,
    regionError: false,
    cityError: false,
    poisError: false,
    propertiesError: false,
    // tenantsError: false,
    tagsError: false,
    artistsError: false,
    categoriesError: false,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const dispatch = useDispatch();

  const { cities, loadingFields, pois } = useSelector((state: RootState) => state.geographicLocations);

  const { events, categories, eventsLoading } = useSelector((state: RootState) => state.externalEvents);

  const { selectedProperty, properties } = useSelector((state: any) => state.porperty);

  const { t } = useTranslation('');

  useEffect(() => {
    setDisableBtn(valuesIsNotEmpty());
  }, [
    continentOption,
    countryOption,
    regionOption,
    cityOption,
    poisOption,
    propertiesOption,
    tenantsOption,
    tagsOption,
    artistsOption,
    categoriesOption,
    eventDescription,
    eventName,
    eventLocation,
    eventExpectedAttendance,
  ]);

  const getTenantsList = async () => {
    setLoadingTenants(true);
    const response = await getTenants();
    setTenantsList(
      response?.data?.tenants?.map((item: any) => ({
        name: item.name,
        id: item.id,
      })),
    );
    setLoadingTenants(false);
  };

  const getLocationOptions = async () => {
    if (!showChangeModal && !showCreateModal) {
      dispatch(getAllCities());
      dispatch(getAllRegions());
      dispatch(getAllCountries());
      dispatch(getAllContinents());
      dispatch(getAllPois());
      dispatch(getEventCategories());
      getTenantsList();
    } else {
      setIsReceivedEditData(false);
    }
  };

  const toggleMapModal = () => setMapModalOpen(!mapModalOpen);

  const handleConfirmLocation = (_: any) => {
    // TODO: Add key for google map
    // setSelectedLocation(location);
  };

  const handleDatePickerClose = () => {
    setSelectedDate({ start: dateValue?.[0]?.format(), end: dateValue?.[1]?.format() });
    datePickerRef.current?.closeCalendar();
  };


  useEffect(() => {
    if (properties.length) {
      dispatch(getExternalEvents({ page: pageNumber, pageSize, propertyId: properties[0].value })).then((action: any) => {
        if (action.payload) {
          setPagination(action.payload.pagination);
        }
      });
    }
  }, [pageNumber, pageSize, properties.value]);

  useEffect(() => {
    if (selectedProperty) {
      dispatch(getExternalEvents({ page: pageNumber, pageSize, propertyId: selectedProperty.value })).then((action: any) => {
        if (action.payload) {
          setPagination(action.payload.pagination);
        }
      });
    }
  }, [selectedProperty.value, pageNumber, pageSize]);

  const pageOptions: any = {
    sizePerPage: 10,
    totalSize: events?.length,
    custom: true,
  };

  const defaultSorted: any = [
    {
      dataIndex: 'e',
      order: 'asc',
    },
  ];

  const contactListColumns = [
    {
      title: t('Actions'),
      dataIndex: 'x',

      render: (_cellContent: string, data: any) => {
        return (
          <div className="tableIconBlock">
            <Tooltip title={t('Edit')}>
              <div className="tableIcon" onClick={() => toggleEditModal(data)} aria-label={t('Edit')}>
                <FiEdit size={20} />
              </div>
            </Tooltip>
            <Tooltip title={t('Delete')}>
              <div className="tableIcon">
                <DeleteButton
                  title={t('Are you sure you want to delete?')}
                  cancelText={t('Cancel')}
                  okText={t('Proceed')}
                  okButtonId={`confirmAndDeleteNotificationContact`}
                  triggerButtonId="deleteNotificationContact"
                  onConfirm={() => {
                    handleDelete(data);
                  }}
                  aria-label={t('Delete')}
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: t('Name'),
      dataIndex: 'name',
    },
    {
      title: t('Category'),
      dataIndex: 'category',
    },
    {
      title: t('Start'),
      dataIndex: 'start',
    },
    {
      title: t('End'),
      dataIndex: 'end',
    },
    {
      title: t('Lat'),
      dataIndex: 'lat',
    },
    {
      title: t('Lon'),
      dataIndex: 'lon',
    },
    {
      title: t('Continents'),
      dataIndex: 'continents',

      render: (_: any, data: ITableDataEvents) => {
        return (
          <div className="d-flex gap-2">
            {data?.continents?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {item} {index < data?.continents?.length - 1 && <span>,</span>}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: t('Countries'),
      dataIndex: 'countries',

      render: (_: any, data: ITableDataEvents) => {
        return (
          <div className="d-flex gap-2">
            {data?.countries?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {item} {index < data?.countries?.length - 1 && <span>,</span>}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: t('Regions'),
      dataIndex: 'regions',
      render: (_: any, data: ITableDataEvents) => {
        return (
          <div className="d-flex gap-2">
            {data?.regions?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {item} {index < data?.regions?.length - 1 && <span>,</span>}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: t('Cities'),
      dataIndex: 'cities',

      render: (_: any, data: ITableDataEvents) => {
        return (
          <div className="d-flex gap-2">
            {data?.cities?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {item} {index < data?.cities?.length - 1 && <span>,</span>}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: t('POIs'),
      dataIndex: 'pois',

      render: (_: any, data: ITableDataEvents) => {
        return (
          <div className="d-flex gap-2">
            {data?.pois?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {item} {index < data?.pois?.length - 1 && <span>,</span>}
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      title: t('Tags'),
      dataIndex: 'tags',

      render: (_: any, data: ITableDataEvents) => {
        return (
          <div className="d-flex gap-2">
            {data?.tags?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  {item} {index < data?.tags?.length - 1 && ','}
                </div>
              );
            })}
          </div>
        );
      },
    },
  ];

  const closeCreateModal = () => {
    setPoisOption(poisOption);
    setContinentOption(continentOption);
    setCountryOption([]);
    setCityOption([]);
    setRegionOption([]);
    setPropertiesOption([]);
    setTenantsOption([]);
    setEventName('');
    setEventDescription('');
    setCategoriesOption({});
    setEventLocation({ lat: 0, lon: 0 });
    setTagsOption([]);
    setArtistsOption([]);
    setEventExpectedAttendance(0);
    setShowCreateModal(false);
    setCurrentEventId('');
    setDateValue(new Date());
    setOptionsError({
      cityError: false,
      continentError: false,
      countryError: false,
      poisError: false,
      propertiesError: false,
      regionError: false,
      // tenantsError: false,
      tagsError: false,
      artistsError: false,
      categoriesError: false,
    });
    setShowCreateErrorMessage(false);
  };

  const closeEditModal = () => {
    setCurrentEventId('');
    setEventName('');
    setEventDescription('');
    setCategoriesOption({});
    setEventLocation({ lat: 0, lon: 0 });
    setShowChangeModal(false);
  };

  const crateModal = async () => {
    await getLocationOptions();
    setShowCreateModal(!showCreateModal);
  };

  const lengthIsZero = (arr: any[]) => {
    return arr.length === 0;
  };

  const { userData } = useSelector((state: any) => state.login);

  const extractRealmFromIssuer = (iss: string) => {
    const parts = iss?.split('/');
    const realmIndex = parts?.indexOf('realms');
    if (realmIndex !== -1 && realmIndex + 1 < parts?.length) {
      return parts[realmIndex + 1];
    }
    return '';
  };

  const realm = userData?.tenant_name;

  const valuesIsNotEmpty = () => {
    return (
      !lengthIsZero(continentOption) &&
      // !lengthIsZero(tenantsOption) &&
      !lengthIsZero(propertiesOption) &&
      !lengthIsZero(countryOption) &&
      !lengthIsZero(regionOption) &&
      !lengthIsZero(cityOption) &&
      // !lengthIsZero(poisOption) &&
      // !lengthIsZero(tagsOption) &&
      !lengthIsZero(artistsOption) &&
      !!categoriesOption &&
      !!eventDescription &&
      !!eventName &&
      !!eventExpectedAttendance &&
      !!eventLocation.lat &&
      !!eventLocation.lon
    );
  };

  const handleSubmitCreateModal = (data: any) => {
    if (
      lengthIsZero(continentOption) ||
      // lengthIsZero(tenantsOption) ||
      lengthIsZero(propertiesOption) ||
      lengthIsZero(countryOption) ||
      lengthIsZero(regionOption) ||
      lengthIsZero(cityOption) ||
      lengthIsZero(poisOption) ||
      // lengthIsZero(tagsOption) ||
      lengthIsZero(artistsOption) ||
      !categoriesOption ||
      !(Boolean(selectedDate.start) || Boolean(selectedDate.end))
    ) {
      setShowCreateErrorMessage(true);
      setOptionsError({
        cityError: lengthIsZero(cityOption),
        continentError: lengthIsZero(continentOption),
        countryError: lengthIsZero(countryOption),
        poisError: lengthIsZero(poisOption),
        propertiesError: lengthIsZero(propertiesOption),
        regionError: lengthIsZero(regionOption),
        // tenantsError: lengthIsZero(tenantsOption),
        tagsError: lengthIsZero(tagsOption),
        artistsError: lengthIsZero(artistsOption),
        categoriesError: !categoriesOption,
      });
      return;
    }

    setOptionsError({
      cityError: false,
      continentError: false,
      countryError: false,
      poisError: false,
      propertiesError: false,
      regionError: false,
      // tenantsError: false,
      tagsError: false,
      artistsError: false,
      categoriesError: false,
    });
    setShowCreateErrorMessage(false);
    // const tenant = realm === 'master' ? tenantsOption : realm;
    const tenant = realm;
    const tenantString = Array.isArray(tenant) ? tenant.join(',') : tenant;

    const sendData = {
      name: data.name,
      description: data.description,
      category: categoriesOption,
      lat: data.lat,
      lon: data.lon,
      start: selectedDate.start,
      end: selectedDate.end || selectedDate.start,
      properties: propertiesOption.map((number: any) => number.toString()),
      tenants: [tenant],
      Continents: continentOption,
      Countries: countryOption,
      Regions: regionOption,
      Cities: cityOption,
      pois: poisOption,
      Tags: tagsOption,
      data: {
        expectedAttendance: data.expectedAttendance,
        artists: artistsOption,
        location: '',
      },
      propertyId: selectedProperty,
    };

    dispatch(createExternalEvents(sendData));
    setShowCreateErrorMessage(false);
    closeCreateModal();
  };

  const getOneEventOnId = async (id: string) => {
    if (!showChangeModal && !showCreateModal) {
      const res = (await dispatch(getExternalEvent(id))) as any;
      return res?.payload;
    }
  };

  const toggleEditModal = async (data: any) => {
    try {
      setIsReceivedEditData(true);
      await getLocationOptions();
      const oneEvent = await getOneEventOnId(data?.id);
      if (!oneEvent) {
        return;
      }

      const poisOptions = oneEvent.pois.map((item: any) => ({ name: item, id: item }));
      const continentOptions = oneEvent.continents.map((item: any) => ({ name: item, id: item }));
      const countryOptions = oneEvent.countries.map((item: any) => ({ name: item, id: item }));
      const cityOptions = oneEvent.cities.map((item: any) => ({ name: item, id: item }));
      const regionOptions = oneEvent.regions.map((item: any) => ({ name: item, id: item }));
      const propertiesOptions = oneEvent.properties.map((item: any) => ({ name: item, id: item }));
      // const tenantsOptions = [oneEvent.tenants.map((item: any) => ({ name: item, id: item }))];

      setPoisOption(poisOptions);
      setContinentOption(continentOptions);
      setCountryOption(countryOptions);
      setCityOption(cityOptions);
      setRegionOption(regionOptions);
      setPropertiesOption(propertiesOptions);
      // setTenantsOption(tenantsOptions);
      setEventName(oneEvent.name);
      setEventDescription(oneEvent.description);
      setCategoriesOption({ label: oneEvent.category, value: oneEvent.category });
      setEventLocation({ lat: oneEvent.lat, lon: oneEvent.lon });
      setTagsOption(oneEvent?.tags);
      setArtistsOption(oneEvent?.data?.artists);
      setEventExpectedAttendance(oneEvent?.data?.expectedAttendance);
      setCurrentEventId(data.id);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsReceivedEditData(false);
      setShowChangeModal(!showChangeModal);
    }
  };

  const isStringArray = (array: any[]): boolean => {
    return array.every((item) => typeof item === 'string');
  };

  const processArray = (array: any[]): any[] => {
    if (isStringArray(array)) {
      return array;
    } else {
      return array.map((item) => (typeof item === 'object' && 'name' in item ? item.name : item));
    }
  };

  const handleSubmitEditeModal = (data: any) => {
    const processedPoisOption = processArray(poisOption);
    const processedContinentOption = processArray(continentOption);
    const processedCountryOption = processArray(countryOption);
    const processedRegionOption = processArray(regionOption);
    const processedCityOption = processArray(cityOption);
    const processedPropertiesOption = processArray(propertiesOption);
    // const processedTenantsOption = processArray(tenantsOption);

    const editData = {
      Name: data.name,
      description: data.description,
      Category: categoriesOption.value,
      lat: data.lat,
      lon: data.lon,
      Start: selectedDate.start,
      End: selectedDate.end,
      properties: processedPropertiesOption,
      Tenants: [realm],
      Continents: processedContinentOption,
      Countries: processedCountryOption,
      Regions: processedRegionOption,
      Cities: processedCityOption,
      Pois: processedPoisOption,
      Tags: tagsOption,
      data: {
        expectedAttendance: data.expectedAttendance,
        artists: artistsOption,
        location: '',
      },
      id: currentEventId,
      propertyId: selectedProperty,
    };

    dispatch(updateExternalEvents(editData));
    closeEditModal();
  };

  const handleDelete = (data: any): void => {
    setCurrentEventId(data.id);
    setShowDeleteModal(!shoDeleteModal);
  };

  const confirmDelete = () => {
    const sendDeleteData = { eventId: currentEventId, propertyId: selectedProperty };

    dispatch(deleteExternalEvents(sendDeleteData));
    setShowDeleteModal(false);
  };

  const [cityData, setCityData] = useState([]);

  useEffect(() => {
    const data: any = getCitiesByCountries(city, countryOption);
    setCityData(data);
  }, [countryOption]);

  function getCitiesByCountries(cities: any, countryNames: any) {
    const allCities: any = [];
    countryNames.forEach((countryName: string) => {
      const country = cities[countryName];
      if (country) {
        for (const regionObj of country) {
          allCities.push({ name: regionObj.name });
        }
      }
    });
    return allCities;
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };


  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{t('External Events')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title={t('Settings')} breadcrumbItem={t('External Events')} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Tooltip title={t('Search external  events')}>
                        <div>
                          <SearchBar
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={t('Search external  events')}
                          />
                        </div>
                      </Tooltip>
                    </Col>

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Tooltip title={t('Add')} placement="top">
                            <Link to="#" className="btn btn-light" onClick={crateModal}>
                              <i className="uil uil-plus me-1"></i> {t('Add')}
                            </Link>
                          </Tooltip>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                    }}
                  >
                    <TableViewPagination
                      loading={eventsLoading || isReceivedEdditData}
                      columns={contactListColumns}
                      dataSource={events}
                      showPagination={false}
                    />
                    <Pagination
                      current={pageNumber}
                      pageSize={pageSize}
                      total={pagination?.totalCount || 0}
                      onChange={(page, newPageSize) => {
                        setPageNumber(page);
                        setPageSize(newPageSize);


                        dispatch(getExternalEvents({ page, pageSize: newPageSize, propertyId: selectedProperty }));
                      }}
                      style={{ float: 'right', marginTop: '20px' }}
                      showSizeChanger
                      pageSizeOptions={['10', '20', '30', '50', '100']}
                    />

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal isOpen={showCreateModal} toggle={crateModal}>
            <ModalHeader toggle={crateModal} tag="h4">
              {t('Create')} {t('Event')}
            </ModalHeader>
            <ModalBody className="scrollModal">
              <AvForm
                onValidSubmit={(_e: any, values: any) => {
                  handleSubmitCreateModal(values);
                }}
              >
                <Row form>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Row form>
                        <Col xs={12}>
                          <div className="mb-3">
                            <AvField
                              name="name"
                              label={t('Name')}
                              type="text"
                              placeholder={t('Enter Name')}
                              errorMessage={t('Enter Name')}
                              validate={{
                                required: { value: true },
                              }}
                              value={eventName}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEventName(e.target.value)}
                            />
                          </div>

                          <div className="mb-3">
                            <AvField
                              name="description"
                              label={t('Description')}
                              type="textarea"
                              placeholder={t('Enter Description')}
                              errorMessage={t('Enter Description')}
                              validate={{
                                required: { value: true },
                              }}
                              style={{ resize: 'none' }}
                              value={eventDescription}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEventDescription(e.target.value)}
                            />
                          </div>

                          <div className="mb-3">
                            <label>{t('Select Categories')}</label>
                            <Select
                              onChange={(value: any) => {
                                setCategoriesOption(value.value);
                                setOptionsError((prev) => ({ ...prev, categoriesError: false }));
                              }}
                              label="Categories"
                              name="categories"
                              isLoading={loadingFields}
                              value={categoriesOption?.value}
                              placeholder={t('Select Categories')}
                              options={categories?.map((item: any) => {
                                return { label: item.name, value: item.name };
                              })}
                              required
                              styles={{
                                control: (baseStyles: any, _: any) => ({
                                  ...baseStyles,
                                  borderColor: optionsError.categoriesError ? 'red' : '#e2e5e8',
                                }),
                              }}
                            />
                            {optionsError.categoriesError && (
                              <span style={{ color: 'red' }}>{t('Enter Category')}</span>
                            )}
                          </div>

                          <div className="locationBlock">
                            <span>
                              <AvField
                                name="lat"
                                label={t('Enter Lat')}
                                type="input"
                                placeholder={t('Enter Lat')}
                                errorMessage={t('Enter Lat')}
                                validate={{
                                  required: { value: true, errorMessage: t('Enter Lat') },
                                  pattern: {
                                    value: '^[0-9]+(.[0-9]+)?$',
                                    errorMessage: t('Invalid Lat, only numbers allowed'),
                                  },
                                }}
                                value={eventLocation.lat}
                                onChange={(e: React.ChangeEvent<any>) =>
                                  setEventLocation((prev) => ({ ...prev, lat: e.target.value }))
                                }
                              />
                            </span>
                            <span>
                              <AvField
                                name="lon"
                                label={t('Enter Lon')}
                                type="input"
                                placeholder={t('Enter Lon')}
                                errorMessage={t('Enter Lon')}
                                validate={{
                                  required: { value: true, errorMessage: t('Enter Lon') },
                                  pattern: {
                                    value: '^[0-9]+(.[0-9]+)?$',
                                    errorMessage: t('Invalid Lat, only numbers allowed'),
                                  },
                                }}
                                value={avaFieldValues.lon}
                                onChange={(e: React.ChangeEvent<any>) =>
                                  setEventLocation((prev) => ({ ...prev, lon: e.target.value }))
                                }
                              />
                            </span>
                            <span style={{ alignSelf: 'center' }} onClick={toggleMapModal}>
                              <LocationIcon />
                              <GoogleMap
                                isOpen={mapModalOpen}
                                toggle={toggleMapModal}
                                onConfirm={handleConfirmLocation}
                              />
                            </span>
                          </div>

                          <div className="mb-3">
                            <label>{t('Select Date')}</label>
                            <DatePicker
                              ref={datePickerRef}
                              value={dateValue}
                              onChange={(date) => {
                                setDateValue(date);
                              }}
                              range
                              dateSeparator=" to "
                              inputClass="custom-date-picker"
                            >
                              <Button color="primary" style={{ margin: '5px' }} onClick={handleDatePickerClose}>
                                {t('Close')}
                              </Button>
                            </DatePicker>
                          </div>

                          <div>
                            <MultipleInput onWordsChange={setTagsOption} label={t('Enter Tags')} />
                          </div>

                          <div className="mb-3">
                            <label>{t('Continent')}</label>
                            <MultipleSelect
                              onChange={setContinentOption}
                              isLoading={loadingFields}
                              label={t('Continent')}
                              name="continents"
                              placeholder={t('Select continent')}
                              options={continents as any}
                              required
                              isEmpty={optionsError.continentError}
                            />
                          </div>

                          <div className="mb-3">
                            <label>{t('Country')}</label>
                            <MultipleSelect
                              onChange={setCountryOption}
                              label={t('Country')}
                              name="country"
                              isLoading={loadingFields}
                              placeholder={t('Select country')}
                              options={countries as any}
                              required={true}
                              isEmpty={optionsError.countryError}
                            />
                          </div>

                          <div className="mb-3">
                            <label>{t('Region')}</label>
                            <MultipleSelect
                              onChange={setRegionOption}
                              isLoading={loadingFields}
                              label="Region"
                              name="region"
                              placeholder={t('Select region')}
                              options={regions as any}
                              required={true}
                              isEmpty={optionsError.regionError}
                            />
                          </div>

                          {/* ! have to work here */}
                          <div className="mb-3">
                            <label>{t('City')}</label>
                            <MultipleSelect
                              onChange={setCityOption}
                              label={t('City')}
                              name="cities"
                              isLoading={loadingFields}
                              placeholder={t('Select city')}
                              options={cityData as any}
                              required={true}
                              isEmpty={optionsError.cityError}
                            />
                          </div>

                          <div className="mb-3">
                            <label>{t('POIs')}</label>
                            <MultipleSelect
                              onChange={setPoisOption}
                              label={t('POIs')}
                              name="poi"
                              placeholder={t('Select POIs')}
                              options={pois as any}
                              isLoading={loadingFields}
                              required={true}
                              isEmpty={optionsError.poisError}
                            />
                          </div>

                          <div className="mb-3">
                            <label>{t('Properties')}</label>
                            <MultipleSelect
                              onChange={setPropertiesOption}
                              label={t('Properties')}
                              name="properties"
                              placeholder={t('Select Properties')}
                              options={properties?.map((item: any) => ({ name: item.value })) as any}
                              isLoading={loadingFields}
                              required={true}
                              isEmpty={optionsError.propertiesError}
                            />
                          </div>

                          {/* <div className="mb-3">
                                  {realm === 'master' ? (
                                    <>
                                      <label>Tenants</label>
                                      <MultipleSelect
                                        onChange={setTenantsOption}
                                        label="Tenants"
                                        name="tenants"
                                        placeholder={'Select Tenants'}
                                        options={tenantsList as any}
                                        isLoading={loadingTenants}
                                        required={true}
                                        isEmpty={optionsError.tenantsError}
                                      />
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div> */}

                          <div className="mb-3">
                            <AvField
                              name="expectedAttendance"
                              label={t('Expected Attendance')}
                              type="input"
                              placeholder={t('Enter Expected Attendance')}
                              errorMessage={t('Enter Expected Attendance')}
                              validate={{
                                required: { value: true, errorMessage: t('Enter Expected Attendance') },
                                pattern: {
                                  value: '^[0-9]+$',
                                  errorMessage: 'Invalid value, only numbers allowed',
                                },
                              }}
                              value={eventExpectedAttendance}
                              onChange={(e: React.ChangeEvent<any>) => setEventExpectedAttendance(e.target.value)}
                            />
                          </div>

                          <div>
                            <MultipleInput
                              onWordsChange={setArtistsOption}
                              label={t('Enter Artists')}
                              isEmpty={optionsError.artistsError}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      {showCreateErrorMessage && <span style={{ color: 'red' }}>{t('All fields is required')}</span>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button disabled={!disableBtn} type="submit" className="btn btn-success w-sm save-user">
                        {t('Create')}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          {/*  create modal end */}

          {/*  edit modal  */}

          <Modal isOpen={showChangeModal} toggle={toggleEditModal}>
            <ModalHeader toggle={toggleEditModal} tag="h4">
              {t('Update')} {t('Event')}
            </ModalHeader>
            <ModalBody className="scrollModal">
              <AvForm
                onValidSubmit={(_e: any, values: any) => {
                  handleSubmitEditeModal(values);
                }}
              >
                <Row form>
                  <Col xs={12}>
                    <div className="mb-3">
                      <div className="pb-2">
                        <Row form>
                          <Col xs={12}>
                            <div className="mb-3">
                              <AvField
                                name="name"
                                label={t('Name')}
                                type="text"
                                placeholder={t('Enter Name')}
                                errorMessage={t('Enter Name')}
                                validate={{
                                  required: { value: true },
                                }}
                                value={eventName || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEventName(e.target.value)}
                              />
                            </div>

                            <div className="mb-3">
                              <AvField
                                name={t('Description')}
                                label={t('Description')}
                                type="textarea"
                                placeholder={t('Enter Description')}
                                errorMessage={t('Enter Description')}
                                validate={{
                                  required: { value: true },
                                }}
                                style={{ resize: 'none' }}
                                value={eventDescription || ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  setEventDescription(e.target.value)
                                }
                              />
                            </div>

                            <div className="mb-3">
                              <label>{t('Select Categories')}</label>
                              <Select
                                onChange={(value: any) => {
                                  setCategoriesOption(value);
                                }}
                                label={t('Categories')}
                                name="categories"
                                isLoading={loadingFields}
                                value={categoriesOption}
                                placeholder={t('Select Categories')}
                                options={categories?.map((item: any) => {
                                  return { label: item.name, value: item.name };
                                })}
                                required
                              />
                            </div>

                            <div className="locationBlock">
                              <span>
                                <AvField
                                  name="lat"
                                  label={t('Enter Lat')}
                                  type="input"
                                  placeholder={t('Enter Lat')}
                                  errorMessage={t('Enter Lat')}
                                  validate={{
                                    required: { value: true, errorMessage: t('Enter Lat') },
                                    pattern: {
                                      value: '^[0-9]+(.[0-9]+)?$',
                                      errorMessage: t('Invalid Lat, only numbers allowed'),
                                    },
                                  }}
                                  value={eventLocation.lat}
                                  onChange={(e: React.ChangeEvent<any>) =>
                                    setEventLocation((prev) => ({ ...prev, lat: e.target.value }))
                                  }
                                />
                              </span>
                              <span>
                                <AvField
                                  name="lon"
                                  label={t('Enter Lon')}
                                  type="input"
                                  placeholder={t('Enter Lon')}
                                  errorMessage={t('Enter Lon')}
                                  validate={{
                                    required: { value: true, errorMessage: t('Enter Lon') },
                                    pattern: {
                                      value: '^[0-9]+(.[0-9]+)?$',
                                      errorMessage: t('Invalid Lat, only numbers allowed'),
                                    },
                                  }}
                                  value={eventLocation.lon}
                                  onChange={(e: React.ChangeEvent<any>) =>
                                    setEventLocation((prev) => ({ ...prev, lon: e.target.value }))
                                  }
                                />
                              </span>
                              <span style={{ alignSelf: 'center' }} onClick={toggleMapModal}>
                                <LocationIcon />
                                <GoogleMap
                                  isOpen={mapModalOpen}
                                  toggle={toggleMapModal}
                                  onConfirm={handleConfirmLocation}
                                />
                              </span>
                            </div>

                            <div className="mb-3">
                              <label>{t('Select Date')}</label>
                              <DatePicker
                                ref={datePickerRef}
                                value={dateValue}
                                onChange={(date) => {
                                  setDateValue(date);
                                }}
                                range
                                dateSeparator=" to "
                                inputClass="custom-date-picker"
                              >
                                <Button color="primary" style={{ margin: '5px' }} onClick={handleDatePickerClose}>
                                  {t('Close')}
                                </Button>
                              </DatePicker>
                            </div>

                            <div>
                              <MultipleInput
                                onWordsChange={setTagsOption}
                                label={t('Enter Tags')}
                                defaultValue={tagsOption}
                              />
                            </div>

                            <div className="mb-3">
                              <label>{t('Continent')}</label>
                              <MultipleSelect
                                onChange={(value) => setContinentOption(value)}
                                isLoading={loadingFields}
                                label={t('Continent')}
                                name="continents"
                                placeholder={t('Select continent')}
                                defaultValues={continentOption}
                                options={continents as any}
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <label>{t('Country')}</label>
                              <MultipleSelect
                                onChange={setCountryOption}
                                name="country"
                                label={t('Country')}
                                isLoading={loadingFields}
                                placeholder={t('Select country')}
                                options={countries as any}
                                required={true}
                              />
                            </div>

                            <div className="mb-3">
                              <label>{t('Region')}</label>
                              <MultipleSelect
                                onChange={setRegionOption}
                                isLoading={loadingFields}
                                label="Region"
                                name="region"
                                defaultValues={regionOption}
                                placeholder={t('Select region')}
                                options={regions as any}
                                required={true}
                              />
                            </div>

                            <div className="mb-3">
                              <label>{t('City')}</label>
                              <MultipleSelect
                                onChange={setCityOption}
                                label={t('City')}
                                name="cities"
                                isLoading={loadingFields}
                                options={cityData}
                                placeholder={t('Select city')}
                                required={true}
                              />
                            </div>

                            <div className="mb-3">
                              <label>{t('POIs')}</label>
                              <MultipleSelect
                                onChange={(value: any) => setPoisOption(value)}
                                label={t('POIs')}
                                name="poi"
                                placeholder={t('Select POIs')}
                                options={pois as any}
                                isLoading={loadingFields}
                                defaultValues={poisOption}
                                required
                              />
                            </div>

                            <div className="mb-3">
                              <label>{t('Properties')}</label>
                              <MultipleSelect
                                onChange={(value: any) => setPropertiesOption(value)}
                                label={t('Properties')}
                                name="properties"
                                placeholder={t('Select Properties')}
                                defaultValues={propertiesOption}
                                options={properties?.map((item: any) => ({ name: item.value })) as any}
                                isLoading={loadingFields}
                                required
                              />
                            </div>

                            {/* <div className="mb-3">
                                    <label>Tenants</label>
                                    <MultipleSelect
                                      onChange={setTenantsOption}
                                      label="Tenants"
                                      name="tenants"
                                      placeholder={'Select Tenants'}
                                      options={tenantsList as any}
                                      defaultValues={tenantsOption}
                                      isLoading={loadingTenants}
                                      required={true}
                                    />
                                  </div> */}

                            <div className="mb-3">
                              <AvField
                                name="expectedAttendance"
                                label={t('Expected Attendance')}
                                type="input"
                                placeholder={t('Enter Expected Attendance')}
                                errorMessage={t('Enter Expected Attendance')}
                                validate={{
                                  required: { value: true, errorMessage: t('Enter Expected Attendance') },
                                  pattern: {
                                    value: '^[0-9]+$',
                                    errorMessage: 'Invalid value, only numbers allowed',
                                  },
                                }}
                                value={eventExpectedAttendance || ''}
                                onChange={(e: React.ChangeEvent<any>) => setEventExpectedAttendance(e.target.value)}
                              />
                            </div>
                            <div>
                              <MultipleInput
                                onWordsChange={setArtistsOption}
                                label={t('Enter Artists')}
                                defaultValue={artistsOption}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end pt-3">
                              <button disabled={!disableBtn} type="submit" className="btn btn-success w-sm save-user">
                                {t('Update')}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>

          {/*  delete modal  */}
          <DeleteModal
            isOpen={shoDeleteModal}
            toggleDeleteModal={handleDelete}
            handleShowDeleteModal={() => setShowDeleteModal(false)}
            onDelete={confirmDelete}
            title="Delete Location"
          />
        </Container>
      </div>
    </>
  );
};

export default withRouter(EventsList);
