import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotificationContactService } from 'src/utils/services/service/notificationContacts';

import { INotificationContacts, IPagination } from 'src/utils/types/notificatioinContactsTypes';

export const CreateNotificationContact = createAsyncThunk(
  'data/createNotificationContact',
  async ({ data }: { data: INotificationContacts }) => {
    try {
      const response = await NotificationContactService.createNotificationContact(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);
export const GetAllNotificationContacts = createAsyncThunk<
  { data: INotificationContacts[]; pagination: IPagination },
  { page: number; pageSize: number; }
>('data/getAllNotificationContacts', async ({ page, pageSize }) => {
  try {
    const response = await NotificationContactService.getAllNotificationContacts(page, pageSize);
    return response;
  } catch (error) {
    return error;
  }
});


export const GetSingleNotificationContacts = createAsyncThunk(
  'data/getSingleNotificationContacts',
  async ({ notificationId }: { notificationId: string }) => {
    try {
      const response = await NotificationContactService.getSingleNotificationContact(notificationId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateNotificationContact = createAsyncThunk(
  'data/updateNotificationContact',
  async ({ notificationId, data }: { notificationId: string; data: INotificationContacts }) => {
    try {
      const response = await NotificationContactService.updateNotificationContact(notificationId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteNotificationContact = createAsyncThunk(
  'data/deleteNotificationContact',
  async ({ notificationId }: { notificationId: string }) => {
    try {
      const response = await NotificationContactService.deleteNotificationContact(notificationId);
      return response;
    } catch (error) {
      return error;
    }
  },
);
