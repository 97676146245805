//Include Both Helper File with needed methods
import { getFirebaseBackend } from 'src/helpers/firebase_helper';

// action
import { apiError, loginSuccess, setUserData, updateLoadingState } from './reducer';
import { postLogin } from 'src/utils/services/service/authService';
import { postFakeLogin, postJwtLogin, postSocialLogin } from 'src/helpers/fakebackend_helper';
import { SignInProps } from 'src/utils/types/authTypes';
import env from 'src/env';
import { jwtDecode } from 'jwt-decode';

export const loginUser = (user: SignInProps, history: any) => async (dispatch: any) => {
  try {
    let res;
    dispatch(updateLoadingState(true));
    res = await postLogin(user);
    if (res.success == 'true') {
      dispatch(updateLoadingState(false));
      const decodedData = jwtDecode(res?.accessToken);
      dispatch(setUserData(decodedData));

      localStorage.setItem('accessToken', res?.accessToken);
      dispatch(loginSuccess(res));
      history.push('/dashboard');
    } else {
      dispatch(updateLoadingState(false));
      dispatch(apiError(res?.error));
    }
    dispatch(updateLoadingState(false));
  } catch (error) {
    dispatch(apiError('Login failed'));
    dispatch(updateLoadingState(false));
  }
};

export const socialLogin = (data: any, history: any, type: any) => async (dispatch: any) => {
  try {
    let response;

    if (env.VITE_REACT_APP_DEFAULTAUTH() === 'firebase') {
      const fireBaseBackend = getFirebaseBackend();

      response = fireBaseBackend.socialLoginUser(data, type);
    } else {
      response = postSocialLogin(data);
    }

    const socialdata = await response;

    if (socialdata) {
      localStorage.setItem('authUser', JSON.stringify(socialdata));
      dispatch(loginSuccess(socialdata));
      history.push('/dashboard');
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = (history: any) => async (dispatch: any) => {
  try {
    localStorage.removeItem('accessToken');
    history.push('/login');
  } catch (error) {
    dispatch(apiError(error));
  }
};
