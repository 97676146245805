import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createWeatherConf, deleteWeatherConf,
  getWeatherConf,
  getWeathersConf,
  updateWeatherConf,
} from 'src/utils/services/service/weatherConfigsService';

export const getWeathersConfigs = createAsyncThunk(
  "weather/getWeathersConfigs",
  async () => {
    try {
      return await getWeathersConf();
    } catch (error) {
      console.error(error);
    }
  }
);

export const getWeatherConfig = createAsyncThunk<any, any>(
  "weather/getWeatherConfig",
  async (id: string) => {
    try {
      return await getWeatherConf(id)
    } catch (error) {
      console.error(error);
    }
  }
);

export const createWeatherConfigs = createAsyncThunk<any, any, { rejectValue: string }>(
  'weather/createWeatherConfigs',
  async (data, thunkAPI) => {
    try {
      const res = await createWeatherConf(data);
      if (res!.id) {
        await thunkAPI.dispatch(getWeathersConfigs());
      }
      return res;
    } catch (error) {
      console.error(error);
    }
  }
);

export const updateWeatherConfigs = createAsyncThunk<any, any, { rejectValue: any, state: any }>(
  'weather/updateWeatherConfigs',
  async (data: any, thunkAPI) => {
    const res = await updateWeatherConf(data)
    if (res!.message) {
      await thunkAPI.dispatch(getWeathersConfigs());
    }
    return res
  }
);

export const deleteWeatherConfigs = createAsyncThunk<any, any, { rejectValue: any, state: any }>(
  'weather/deleteWeatherConfigs',
  async (data: any, thunkAPI) => {
    const res = await deleteWeatherConf(data)
    if (res) {
      await thunkAPI.dispatch(getWeathersConfigs());
    }
    return res
  }
);
