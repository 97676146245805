import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { ConfirmationModal } from '../ConfirmationModal';
import { IoTrashOutline } from 'react-icons/io5';
import { GoTrash } from 'react-icons/go';
import { useTranslation } from 'react-i18next';

interface DeleteButtonProps {
  title: string;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  triggerButtonId?: string;
  okButtonId?: string;
  cancelButtonId?: string;
}

const DeleteButton = ({
  onConfirm,
  title,
  cancelText,
  okText,
  triggerButtonId,
  okButtonId,
  cancelButtonId,
}: DeleteButtonProps) => {
  const { t } = useTranslation('');

  return (
    <div>
      <ConfirmationModal
        confirmationType="delete-confirmation"
        title={t(title)}
        onConfirm={onConfirm}
        style={{ backgroundColor: 'none', border: 'none' }}
        okText={t(okText)}
        cancelText={t(cancelText)}
        cancelButtonId={cancelButtonId}
        okButtonId={okButtonId}
        triggerButtonContent={
          <>
            <GoTrash id={triggerButtonId} className="tableIcon" size={20} />
          </>
        }
      />
    </div>
  );
};

export default DeleteButton;
