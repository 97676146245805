import { createAsyncThunk } from '@reduxjs/toolkit';
import { roomPlanService } from 'src/utils/services/service/roomPlanService';
import { IRoomConfiguration, IPagination } from 'src/utils/types/roomConfigurationsTypes';

const CreateRoomPlan = createAsyncThunk(
  'room-configurations/create-room-plan',
  async ({ data }: { data: IRoomConfiguration }) => {
    try {
      const response = await roomPlanService.createRoomConfiguration(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

const GetAllRoomPlans = createAsyncThunk<
  { roomConfigurations: IRoomConfiguration[]; pagination: IPagination },
  { page: number; pageSize: number; searchField?: string; searchTerm?: string }
>(
  'room-configurations/get-all-room-plans',
  async ({ page, pageSize, searchField, searchTerm }, { rejectWithValue }) => {
    try {
      const response = await roomPlanService.getAllRoomConfigurations(page, pageSize, searchField, searchTerm);

      if (!response || !response.roomConfigurations || !response.pagination) {
        throw new Error('Invalid response structure');
      }

      return response;
    } catch (error: any) {
      console.error('Error:', error);
      return rejectWithValue(error.message);
    }
  },
);
const GetSingleRoomPlan = createAsyncThunk(
  'room-configurations/get-single-room-plan',
  async ({ roomConfigurationId }: { roomConfigurationId: string }) => {
    try {
      const response = await roomPlanService.getRoomConfigurationById(roomConfigurationId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

const UpdateRoomPlan = createAsyncThunk(
  'room-configurations/update-room-plan',
  async ({ data }: { data: IRoomConfiguration }) => {
    try {
      const response = await roomPlanService.updateRoomConfiguration(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

const DeleteRoomPlan = createAsyncThunk(
  'room-configurations/delete-room-plan',
  async ({ roomConfigurationId }: { roomConfigurationId: string }) => {
    try {
      const response = await roomPlanService.deleteRoomConfiguration(roomConfigurationId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export { CreateRoomPlan, GetAllRoomPlans, GetSingleRoomPlan, UpdateRoomPlan, DeleteRoomPlan };
