import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification, Spin, Switch, Pagination } from 'antd';
import { IimageManagement, IPagination } from 'src/utils/types/imageManagementTypes';
import ImageManagementModal from './components/ImageManagementModal';
import { FiEdit, FiEye } from 'react-icons/fi';
import { Tooltip } from 'antd';

import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { GetAllImageData, DeleteImageData, GetSingleImageData } from 'src/slices/ImageManagement/thunk';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useTranslation } from 'react-i18next';
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';

const ImageManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isViewMode, setIsViewMode] = useState(false);
  const [editingData, setEditingData] = useState<IimageManagement | null>(null);
  const [viewData, setViewData] = useState<IimageManagement | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [tableDataWithImages, setTableDataWithImages] = useState<IimageManagement[]>([]);
  const [isImageView, setIsImageView] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const { isLoading, isDeleting, data } = useSelector((state: RootState) => state.imageManagement);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('');

  useEffect(() => {
    if (!isLoading && data.length === 0) {
      dispatch(GetAllImageData({ page: pageNumber, pageSize })).then((action: any) => {
        if (action.payload) {
          setPagination(action.payload.pagination);
        }
      });
    }
  }, [pageNumber, pageSize]);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleToggleEnabled = (id: string, enabled: boolean) => {};
  const fetchImagesForTableData = async (tableData: IimageManagement[]) => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    tableData.forEach(async (row) => {
      try {
        const response = await dispatch(GetSingleImageData({ imageId: row.id as string }));

        if (response?.payload) {
          const imageUrl = `https://api.dev.data.projects.excitingdevs.com/api/v1/marketing/settings/imagemanagement/images/${row.id}/thumbnail`;

          const response = await fetch(imageUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error('Failed to fetch image');
          }

          const imageBlob = await response.blob();

          const generatedImageUrl = URL.createObjectURL(imageBlob);

          setTableDataWithImages((prevData) =>
            prevData.map((dataRow) => (dataRow.id === row.id ? { ...dataRow, imageUrl: generatedImageUrl } : dataRow)),
          );
        }
      } catch (error) {
        console.error(`Error fetching image for id ${row.id}:`, error);
      }
    });
  };

  useEffect(() => {
    if (data.length > 0) {
      setTableDataWithImages(data);
      fetchImagesForTableData(data);
    }
  }, [data]);

  const columns: TableProps<IimageManagement>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 100,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <Tooltip title={t('View')}>
              <div
                className="tableIcon"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsViewMode(true);
                  setViewData(record);
                }}
                aria-label={t('View')}
              >
                <FiEye id="viewImage" size={20} />
              </div>
            </Tooltip>

            <div
              className="tableIcon"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setIsEditMode(true);
                setEditingData(record);
              }}
              aria-label={t('Edit')}
            >
              {' '}
              <Tooltip title={t('Edit')}>
                <FiEdit id="updateImage" size={20} />
              </Tooltip>
            </div>

            <Tooltip title={t('Delete')}>
              <div className="tableIcon">
                <DeleteButton
                  title={t('Are you sure you want to delete?')}
                  cancelText={t('Cancel')}
                  okText={t('Proceed')}
                  okButtonId="confirmAndDeleteImage"
                  triggerButtonId="deleteImage"
                  onConfirm={() => {
                    deleteImage(record.id!);
                  }}
                  aria-label={t('Delete')}
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: t('Image'),
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: 120,
      render: (imageUrl) =>
        imageUrl ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={imageUrl} alt="Thumbnail" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin size="default" />
          </div>
        ),
    },
    {
      title: t('Hashtags'),
      dataIndex: 'hashtags',
      key: 'hashtags',
      width: 200,
      render: (hashtags) => hashtags?.join(', '),
    },
    {
      title: t('Keywords'),
      dataIndex: 'keywords',
      key: 'keywords',
      width: 200,
      render: (keywords) => keywords?.join(', '),
    },

    {
      title: t('File name'),
      dataIndex: 'filename',
      key: 'filename',
      width: 200,
    },
    {
      title: t('Enabled'),
      dataIndex: 'enabled',
      key: 'enabled',
      width: 100,
      render: (enabled, record) => (
        <Switch
          checked={enabled}
          onChange={(checked) => {
            handleToggleEnabled(record.id as string, checked);
          }}
        />
      ),
    },
  ];

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const deleteImage = async (imageId: string) => {
    if (!imageId) {
      openNotificationWithIcon('error', t('Image id is missing'));
      return;
    }
    try {
      const response = await dispatch(
        DeleteImageData({
          imageId,
        }),
      );
      if (response.payload === imageId) {
        openNotificationWithIcon('success', t('Image deleted successfully'));

        dispatch(GetAllImageData({ page: pageNumber, pageSize }));
      } else {
        openNotificationWithIcon('error', t('Error deleting image'));
      }
    } catch (error) {
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>{t('Image Management')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title={t('Settings')} breadcrumbItem={t('Image Management')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <Tooltip title={t('Search image by hashtags or keywords')}>
                          <div>
                            <SearchBar
                              id="imageManagementSearchBar"
                              value={searchValue}
                              onChange={handleSearchChange}
                              placeholder={t('Search image by hashtags or keywords')}
                            />
                          </div>
                        </Tooltip>
                      </Col>

                      <Col md={6} className="d-flex justify-content-end">
                        <div className="d-flex justify-content-end ">
                          <div style={{ marginRight: '10px' }}>
                            <Tooltip title={t('Add')} placement="top">
                              <Button
                                id="createImageManagement"
                                className="btn btn-light"
                                onClick={() => {
                                  setIsModalOpen(!isModalOpen);
                                }}
                              >
                                <i className="uil uil-plus me-1"></i> {t('Add')}
                              </Button>
                            </Tooltip>
                          </div>
                          <div>
                            <Tooltip title={isImageView ? t('Table View') : t('Image View')}>
                              <Button className="btn btn-light" onClick={() => setIsImageView(!isImageView)}>
                                {isImageView ? t('Table View') : t('Image View')}
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      {isImageView ? (
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                            gap: '10px',
                            marginTop: '30px',
                          }}
                        >
                          {tableDataWithImages
                            ?.filter((data: IimageManagement) => {
                              if (searchValue === '') return true;
                              const searchLower = searchValue.toLowerCase();
                              return (
                                data.hashtags?.some((tag) => tag.toLowerCase().includes(searchLower)) ||
                                data.keywords?.some((keyword) => keyword.toLowerCase().includes(searchLower)) ||
                                data.filename?.toLowerCase().includes(searchLower)
                              );
                            })
                            ?.reverse()
                            .map((image) => (
                              <div
                                key={image.id}
                                onClick={() => {
                                  setIsModalOpen(true);
                                  setIsViewMode(true);
                                  setViewData(image);
                                }}
                              >
                                {image.imageUrl ? (
                                  <img
                                    src={image.imageUrl}
                                    alt={image.filename}
                                    style={{ width: '150px', height: '150px', objectFit: 'cover', cursor: 'pointer' }}
                                  />
                                ) : (
                                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Spin size="default" />
                                  </div>
                                )}
                              </div>
                            ))}
                        </div>
                      ) : (
                        <div
                          style={{
                            height: '80vh',
                          }}
                        >
                          <TableViewPagination
                            loading={isLoading || isDeleting}
                            columns={columns}
                            dataSource={tableDataWithImages
                              ?.filter((data: IimageManagement) => {
                                if (searchValue === '') return data;
                                const searchLower = searchValue.toLowerCase();
                                return (
                                  data.hashtags?.some((tag) => tag.toLowerCase().includes(searchLower)) ||
                                  data.keywords?.some((keyword) => keyword.toLowerCase().includes(searchLower)) ||
                                  data.filename?.toLowerCase().includes(searchLower)
                                );
                              })
                              ?.reverse()}
                            showPagination={false}
                          />

                          <Pagination
                            current={pageNumber}
                            pageSize={pageSize}
                            total={pagination?.totalCount || 0}
                            onChange={(page, pageSize) => {
                              setPageNumber(page);
                              setPageSize(pageSize);
                              dispatch(GetAllImageData({ page, pageSize }));
                            }}
                            style={{ float: 'right', marginTop: '20px' }}
                            showSizeChanger
                            pageSizeOptions={['10', '20', '30', '50', '100']}
                          />
                        </div>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <ImageManagementModal
              pagination={pagination}
              isEditMode={isEditMode}
              initialValues={editingData}
              initialViewValues={viewData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              setEditingData={setEditingData}
              setViewData={setViewData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ImageManagement;
