/* eslint-disable prefer-const */
import { ChangeEvent, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap';

import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';

import { AvField, AvForm } from 'availity-reactstrap-validation';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { IdCell } from 'src/components/elements/ShowTableId';
import { hideMiddleUUID } from 'src/helpers/formaters/handleMiddleUuid';
import { ICreateUser, IEditModalData } from 'src/utils/types/userTypes';

//Import Breadcrumb
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { isEmpty } from 'lodash';

//redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'src/hook';
import { changeUserFields, createNewUser, getUsers } from 'src/slices/Users/thunk';

import SearchComponent from 'src/components/elements/SearchComponent';
import { getMyGroups } from 'src/slices/Groups/thunk';
import MultipleSelect from 'src/components/elements/MultipleSelect';

import './index.scss';
import { unwrapResult } from '@reduxjs/toolkit';
import { getTenants } from 'src/utils/services/service/tenantService';
import { FiEdit } from 'react-icons/fi';
import TableView from 'src/components/Common/TableView/TableView';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

const UsersList = () => {
  const dispatch = useDispatch();

  const data = useSelector((state: any) => ({
    users: state.users.users?.length ? state.users.users : [],
    totalPages: state.users.totalPages ? state.users.totalPages : 0,
    loading: state.users.loading,
  }));

  const { groups } = useSelector((state: any) => state.groups);
  const { userData } = useSelector((state: any) => state.login);

  const extractRealmFromIssuer = (iss: string) => {
    const parts = iss?.split('/');
    const realmIndex = parts?.indexOf('realms');
    if (realmIndex !== -1 && realmIndex + 1 < parts?.length) {
      return parts[realmIndex + 1];
    }
    return '';
  };

  const realm = userData?.tenant_name;

  const [modal, setModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [defaultEditModalValue, setDefaultEditModalValue] = useState<Partial<IEditModalData>>({});
  const [editFirstName, setEditFirstName] = useState('');
  const [editLastName, setEditLastName] = useState('');
  const [disabledEditModalBtn, setDisabledEditModalBtn] = useState(true);
  const [skipPages, setSkipPages] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [allGroups, setAllGroups] = useState([]);
  const [tenantsOption, setTenantsOption] = useState<any>([]);
  const [tenantsList, setTenantsList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState('');

  const { t } = useTranslation('');

  const handleShow = () => setModal(false);
  const closeChangeModal = () => setShowChangeModal(false);

  const handlePageChange = (page: number) => {
    const skip = (page - 1) * 10;
    setSkipPages(skip);
  };

  useEffect(() => {
    dispatch(getMyGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUsers({ skip: skipPages }));
  }, [dispatch, skipPages, search]);

  const pageOptions = {
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: false,
    onPageChange: handlePageChange,
    totalSize: data?.totalPages,
  };

  const defaultSorted: any = [
    {
      dataField: 'e',
      order: 'asc',
    },
  ];

  const handleEdit = (data: ICreateUser) => {
    const name = data.firstName;
    const surname = data.lastName;

    setDefaultEditModalValue({
      name,
      surname,
      id: data.id,
    });
    setShowChangeModal(true);
  };

  const contactListColumns = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 150,
      render: (_cellContent: string, data: ICreateUser) => {
        return (
          <>
            <div className="tableIconBlock">
              <Tooltip title={t('Edit')} placement="top">
                <div className="tableIcon" onClick={() => handleEdit(data)} aria-label={t('Edit')}>
                  <FiEdit id="updateTexts" size={20} />
                </div>
              </Tooltip>
            </div>
          </>
        );
      },
    },

    {
      title: t('Name'),
      dataIndex: 'name',
      width: 300,
      render: (_cellContent: any, data: any) => {
        return `${data.firstName || data.lastName ? `${data.firstName || ''} ${data.lastName || ''}` : '-'}`;
      },
    },
    {
      title: t('Username'),
      width: 300,
      dataIndex: 'userName',
    },

    {
      title: t('Email'),
      dataIndex: 'email',
      render: (cellContent: string, data: any) => {
        return `${data.alternateEmail || ''}`;
      },
    },
    // {
    //   text: 'Groups',
    //   dataField: 'groups',
    //   sort: true,
    //   editable: false,
    //   formatter: (cellContent: any) => {
    //     return cellContent?.length ? (
    //       <div className="groupItemBlock">
    //         {cellContent?.map((item: any, index: number) => {
    //           return (
    //             <span className="groupItem" key={index}>
    //               {item.name}
    //             </span>
    //           );
    //         })}
    //       </div>
    //     ) : (
    //       '-'
    //     );
    //   },
    // },
  ];

  const toggle = () => {
    setModal(!modal);
    if (!modal && !isEmpty(data.users) && !!isEdit) {
      setTimeout(() => {
        setIsEdit(false);
      }, 500);
    }
  };

  const toggleEditModal = () => {
    setShowChangeModal(!showChangeModal);
    if (!modal && !isEmpty(data.users) && !!isEdit) {
      setTimeout(() => {
        setIsEdit(false);
      }, 500);
    }
  };

  // State to manage form submission feedback (e.g., errors)
  const [formFeedback, setFormFeedback] = useState({ error: '', success: '' });

  const handleValidUserSubmit = async (values: any) => {
    // Reset form feedback state
    setFormFeedback({ error: '', success: '' });

    if (!isEdit) {
      // Access isAdmin value
      const isAdmin = values.isAdmin;

      // Conditionally add "admin" to allGroups
      let updatedGroups: string[] = [...allGroups];
      if (isAdmin) {
        updatedGroups.push('admin');
      }
      const newValues = {
        ...values,
        groups: updatedGroups,
      };

      try {
        //  * Pass Tenant as a params

        // const tenant = realm === 'master' ? tenantsOption : realm;
        // const tenantString = Array.isArray(tenant) ? tenant.join(',') : tenant;

        const actionResult = await dispatch(createNewUser({ data: newValues, tenant: realm }));
        unwrapResult(actionResult); // This will throw an error if the thunk was rejected
        // Handle success
        setFormFeedback({ ...formFeedback, success: t('User successfully created!') });
        setModal(true);
        setAllGroups([]);
      } catch (err) {
        // Handle error
        setFormFeedback({ ...formFeedback, error: t('Failed to create user. Please try again.') });
        // Optionally log the error or display a more specific message based on the error
        console.error('Create user error:', err);
      }
    }
    toggle();
  };

  const handleUserClicks = () => {
    getTenantsList();
    setIsEdit(false);
    toggle();
  };

  const handleValidEditUserSubmit = async (values: any) => {
    if (!isEdit) {
      const editedUser: any = {
        firstName: values.firstName,
        lastName: values.lastName,
        userId: defaultEditModalValue.id,
        newPassword: values.newPassword,
      };
      dispatch(changeUserFields(editedUser));
      setShowChangeModal(false);
    }
    toggleEditModal();
  };

  const editFieldsChange = () => {
    if (
      (editFirstName?.length === 0 && defaultEditModalValue.name?.length) ||
      (editLastName?.length === 0 && defaultEditModalValue.surname?.length)
    ) {
      setDisabledEditModalBtn(false);
      return;
    }

    const trimmedFirstName = editFirstName.trim();
    const trimmedLastName = editLastName.trim();

    const isChanged =
      (editFirstName !== defaultEditModalValue.name?.trim() && trimmedFirstName !== '') ||
      (trimmedLastName !== defaultEditModalValue.surname?.trim() && trimmedLastName !== '');

    setDisabledEditModalBtn(!isChanged);
  };

  const handleSearch = (search: string) => {
    setSearch(search);
  };

  useEffect(() => {
    editFieldsChange();
  }, [editLastName, editFirstName]);
  const [loadingTenants, setLoadingTenants] = useState(false);

  const getTenantsList = async () => {
    setLoadingTenants(true);
    const response = await getTenants();
    setTenantsList(
      response?.data?.tenants?.map((item: any) => ({
        name: item.name,
        id: item.id,
      })),
    );
    setLoadingTenants(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{t('Users')}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={t('System Settings')} breadcrumbItem={t('Users')} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Tooltip title={t('Search user by name, email, username')}>
                        <div>
                          <SearchBar
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={t('Search user by name, email, username')}
                          />
                        </div>
                      </Tooltip>
                    </Col>

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Tooltip title={t('Add')}>
                            <Button to="#" className="btn btn-light" onClick={handleUserClicks}>
                              <i className="uil uil-plus me-1"></i> {t('Add')}
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                    }}
                  >
                    <TableView
                      loading={data.loading}
                      columns={contactListColumns}
                      dataSource={data?.users?.filter((value: any) => {
                        if (searchValue === '') {
                          return value;
                        } else if (
                          value?.firstName?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
                          value?.lastName?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
                          value?.userName?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
                          value?.alternateEmail?.toLowerCase()?.includes(searchValue.toLowerCase())
                        ) {
                          return value;
                        }
                      })}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal centered isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {t('Add User')}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onValidSubmit={(_e: any, values: any) => {
                  handleValidUserSubmit(values);
                }}
              >
                <Row form>
                  <Col xs={12}>
                    <div className="mb-3">
                      <AvField
                        name="username"
                        label={t('Username')}
                        type="username"
                        placeholder={t('Enter username')}
                        errorMessage={t('Enter username')}
                        validate={{
                          required: { value: true },
                        }}
                        value={''}
                      />
                      <div className="py-2">
                        <AvField
                          name="password"
                          label={t('Password')}
                          type="password"
                          placeholder={t('Enter password')}
                          errorMessage={t('Enter password')}
                          validate={{
                            required: { value: true },
                          }}
                          value={''}
                        />
                      </div>
                      <AvField
                        name="firstName"
                        label={t('First Name')}
                        type="firstName"
                        placeholder={t('Enter first name')}
                        errorMessage={t('Enter first name')}
                        validate={{
                          required: { value: false },
                        }}
                        value={''}
                      />
                      <div className="py-2">
                        <AvField
                          name="lastName"
                          label={t('Last Name')}
                          type="lastName"
                          placeholder={t('Enter last name')}
                          errorMessage={t('Enter last name')}
                          validate={{
                            required: { value: false },
                          }}
                          value={''}
                        />
                      </div>
                      <AvField
                        name="alternateEmail"
                        label={t('Alternate Email')}
                        placeholder={t('Enter alternate email')}
                        type="email"
                        errorMessage={t('Enter alternate email')}
                        validate={{
                          required: { value: false },
                        }}
                        value={''}
                      />
                      <div className="py-2">
                        <AvField
                          name="isAdmin"
                          label="Admin"
                          type="checkbox"
                          errorMessage="Check if user is admin"
                          validate={{
                            required: { value: false },
                          }}
                        />
                      </div>

                      {/* <div className="mb-3">
                                                    {realm === 'master' ? (
                                                      <>
                                                        <label>Tenants</label>
                                                        <MultipleSelect
                                                          onChange={setTenantsOption}
                                                          label="Tenants"
                                                          name="tenants"
                                                          placeholder={'Select Tenants'}
                                                          options={tenantsList as any}
                                                          isLoading={loadingTenants}
                                                          required={true}
                                                        />
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div> */}

                      <div className="py-2">
                        <label>{t('Groups')}</label>
                        <MultipleSelect options={groups} onChange={setAllGroups} placeholder={t('Enter groups')} />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button type="button" title={t('Close')} className="btn btn-light w-sm mx-2" onClick={handleShow}>
                        {t('Close')}
                      </button>
                      <button type="submit" title={t('Create')} className="btn btn-success w-sm save-user">
                        {t('Create')}
                      </button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {formFeedback.error && <div className="error">{formFeedback.error}</div>}
                    {formFeedback.success && <div className="success">{formFeedback.success}</div>}
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
          <Modal isOpen={showChangeModal} centered toggle={toggleEditModal}>
            <ModalHeader toggle={toggleEditModal} tag="h4">
              {t('Edit user')}
            </ModalHeader>
            <ModalBody>
              <AvForm
                onValidSubmit={(_e: any, values: any) => {
                  handleValidEditUserSubmit(values);
                }}
              >
                <Row form>
                  <Col xs={12}>
                    <div className="mb-3">
                      <AvField
                        name="firstName"
                        label={t('First Name')}
                        type="firstName"
                        placeholder={t('Enter first Name')}
                        errorMessage={t('Enter first Name')}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setEditFirstName(e.target.value)}
                        validate={{
                          required: { value: false },
                        }}
                        value={defaultEditModalValue.name || ''}
                      />
                      <div className="py-2">
                        <AvField
                          name="lastName"
                          label={t('Last Name')}
                          type="lastName"
                          placeholder={t('Enter last Name')}
                          errorMessage={t('Enter last Name')}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => setEditLastName(e.target.value)}
                          validate={{
                            required: { value: false },
                          }}
                          value={defaultEditModalValue.surname || ''}
                        />
                      </div>

                      <AvField
                        name="newPassword"
                        label={t('New password')}
                        type="password"
                        placeholder={t('Enter new password')}
                        errorMessage={t('Enter new password')}
                        validate={{
                          required: { value: true },
                        }}
                        value={''}
                      />
                      <div className="py-2">
                        <AvField
                          name="confirmPassword"
                          label={t('Confirm password')}
                          type="password"
                          placeholder={t('Enter password')}
                          errorMessage={t('Password mismatch')}
                          validate={{
                            required: { value: true },
                            match: {
                              value: 'newPassword',
                              errorMessage: t('Passwords do not match'),
                            },
                          }}
                          value={''}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="button"
                        title={t('Close')}
                        className="btn btn-light w-sm mx-2"
                        onClick={closeChangeModal}
                      >
                        {t('Close')}
                      </button>
                      <button
                        title={t('Update')}
                        type="submit"
                        className="btn btn-success w-sm save-user"
                        // disabled={disabledEditModalBtn}
                      >
                        {t('Update')}
                      </button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default withRouter(UsersList);
