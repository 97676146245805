/* eslint-disable no-useless-catch */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllExternalEvents,
  createExternalEvent,
  updateExternalEvent,
  deleteExternalEvent,
  getAllCategories,
  getAllExternalEvent,
} from 'src/utils/services/service/externalEventsService';
import { ITableDataEvents, IPagination } from 'src/utils/types/externalEvents';


export const getExternalEvents = createAsyncThunk<
  { events: ITableDataEvents[]; pagination: IPagination },
  { page: number; pageSize: number; propertyId: number }
>('events/getExternalEvents', async ({ page, pageSize, propertyId }, { rejectWithValue }) => {
  try {
    const response = await getAllExternalEvents(page, pageSize, propertyId);
    if (response) {
      return { events: response.data, pagination: response.pagination };
    } else {
      return rejectWithValue('No response data');
    }
  } catch (error: any) {
    return rejectWithValue(error.message || 'Error fetching events');
  }
});
export const getExternalEvent = createAsyncThunk<any, any>('events/getExternalEvent', async (eventId) => {
  try {
    return await getAllExternalEvent(eventId);
  } catch (error) {
    throw error;
  }
});

export const getEventCategories = createAsyncThunk('events/getEventCategories', async () => {
  try {
    return await getAllCategories();
  } catch (error) {
    throw error;
  }
});

export const createExternalEvents = createAsyncThunk<any, any>(
  'events/createExternalEvents',
  async (data: any, thunkAPI) => {
    const { propertyId, tenants, ...cont } = data;
    try {
      cont.tenants = tenants;
      const res = await createExternalEvent(cont, tenants);
      if (res?.id) {
        await thunkAPI.dispatch(getExternalEvents(propertyId));
      }
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const updateExternalEvents = createAsyncThunk('events/updateExternalEvents', async (data: any, thunkAPI) => {
  try {
    const { propertyId, id, ...cont } = data;
    const res = await updateExternalEvent(cont, id);
    if (res && res.message) {
      await thunkAPI.dispatch(getExternalEvents(propertyId));
    }
    return res;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const deleteExternalEvents = createAsyncThunk('events/deleteExternalEvents', async (data: any, thunkAPI) => {
  try {
    const res = await deleteExternalEvent(data.eventId);
    if (res) {
      await thunkAPI.dispatch(getExternalEvents(data.propertyId));
    }
    return res;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
