// src/utils/services/offerService.ts
import axios from 'axios';
import { IOfferResponse, IPagination } from 'src/utils/types/offerTypes';




export const OfferService = {
  async extractOffers(
    pageNumber: number,
    pageSize: number
  ): Promise<{ data: IOfferResponse; pagination: IPagination } | null | any> {
    try {
      const accessToken = localStorage.getItem('accessToken');
      const headers = {
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
      };

      const response = await axios.get('https://api.dev.data.projects.excitingdevs.com/api/v1/offerpackages/offer-package/OfferPackages', {
        headers,
        params: { pageNumber, pageSize },
      });

      return response;
    } catch (error) {
      console.error('Error fetching offers:', error);
      throw new Error('Failed to fetch offers');
    }
  },
};
