import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import env from '../../env';

export const getAccessToken = () => `Bearer ${localStorage.getItem('accessToken')}`;

const defaultAxiosConfig: AxiosRequestConfig = {
  baseURL: env.VITE_REACT_APP_DEFAULTAUTH(),
  timeout: 60000,
  headers: {
    Authorization: undefined, // Set Authorization to undefined or remove the header
    // Other headers if needed
  },
};

const api = axios.create({
  ...defaultAxiosConfig,
});

const handleError = async (error: any) => {
  const status = error.response?.status || error.status;

  switch (status) {
    case 401:
      // ! Uncommenting bottom 2 Line will log out the user if the API request returns a 401 error.
      localStorage.removeItem('accessToken');
      window.location.reload();
      return Promise.reject(error);
    case 403: {
      return Promise.reject(error);
    }
    default:
      return Promise.reject(error);
  }
};

api.interceptors.request.use((config) => {
  const authorization = getAccessToken();
  if (config.data instanceof FormData) {
    return config;
  }
  config.headers['Content-Type'] = 'application/json';

  return config;
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    return handleError(error);
  },
);

export default api;
