import { Form, Input, Modal, notification, Tooltip } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap';

import Breadcrumb from 'src/components/Common/Breadcrumb';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TableView from 'src/components/Common/TableView/TableView';
import SearchComponent from 'src/components/elements/SearchComponent';
import { IdCell } from 'src/components/elements/ShowTableId';
import { hideMiddleUUID } from 'src/helpers/formaters/handleMiddleUuid';
import { UpdateProperties, getPropertiesFromServer } from 'src/slices/thunks';
//Import Brea
const Properties = () => {
  const [skipPages, setSkipPages] = useState<number>(0);
  const [api, contextHolder] = notification.useNotification();
  const [editingData, setEditingData] = useState<null | any>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation('');
  const { properties, selectedProperty, loading, Properties, isError, realFormate, isUpdating } = useSelector(
    (state: any) => state.porperty,
  );
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const token = localStorage.getItem('accessToken');
  const handlePageChange = (page: number) => {
    const skip = (page - 1) * 10;
    setSkipPages(skip);
  };
  const pageOptions = {
    sizePerPage: 10,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: false,
    onPageChange: handlePageChange,
    totalSize: 10,
  };
  const defaultSorted: any = [
    {
      dataIndex: 'e',
      order: 'asc',
    },
  ];

  const handleEdit = (data: any) => {
    setEditingData(data);
    setIsEditModalOpen(true);
  };

  const propertiesColumns: ColumnsType<any> = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      render: (_cellContent: any, data: any) => (
        <>
          <Tooltip title={t('Edit')} placement="top">
            <div
              className="tableIcon"
              onClick={() => {
                handleEdit(data);
                setIsEditModalOpen(true);
              }}
              aria-label={t('Edit')}
            >
              <FiEdit size={20} />
            </div>
          </Tooltip>
        </>
      ),
    },
    {
      title: t('Mandant ID'),
      dataIndex: 'mandantId',
      sorter: (a: any, b: any) => {
        const mandantA = a.mandantId ? String(a.mandantId) : '';
        const mandantB = b.mandantId ? String(b.mandantId) : '';
        return mandantA.localeCompare(mandantB);
      },
      onFilter: (value: any, record: any) => {
        return record.mandantId ? String(record.mandantId).indexOf(value) === 0 : false;
      },
      render: (_cellContent: any, data: any) => <>{data.mandantId}</>,
    },
    {
      title: t('Name'),
      dataIndex: 'name',
    },
    {
      title: t('Address'),
      dataIndex: 'address',
      render: (_cellContent: any, data: any) => <>{data.address ? data.address : 'N/A'}</>,
    },
    {
      title: t('Postal Code'),
      dataIndex: 'postalCode',
      sorter: (a: any, b: any) => {
        if (!a.postalCode) return 1;
        if (!b.postalCode) return -1;
        return a.postalCode.localeCompare(b.postalCode);
      },
      onFilter: (value: any, record: any) => {
        return record.postalCode ? record.postalCode.indexOf(value) === 0 : record.postalCode;
      },
    },
    {
      title: t('Place'),
      dataIndex: 'place',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
      render: (_cellContent: any, data: any) => <>{data.email ? data.email : 'N/A'}</>,
    },
    {
      title: t('Phone'),
      dataIndex: 'phone',
      render: (_cellContent: any, data: any) => <>{data.phone ? data.phone : 'N/A'}</>,
    },
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   sort: true,
    //   editable: false, // Mark ID as non-editable
    //   formatter: (cellContent: string) => {
    //     const { hiddenId, id } = hideMiddleUUID(cellContent);
    //     return <IdCell hiddenId={hiddenId ? hiddenId : ''} id={id ? id : ''} />;
    //   },
    // },
  ];
  const [form] = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    if (editingData) {
      form.setFieldsValue(editingData);
    }
  }, [editingData]);

  const handleUpdate = async (values: any) => {
    await form.validateFields();

    const data = {
      id: editingData.id,
      token: token,
      data: form.getFieldsValue(),
    };
    try {
      const response: any = await dispatch(UpdateProperties(data));
      if (response?.payload) {
        api.success({
          message: t('Properties updated successfully'),
          description: '',
        });
        setIsEditModalOpen(false);
        dispatch(getPropertiesFromServer(token));
      } else {
        api.error({
          message: t('Failed to update properties'),
        });
      }
    } catch (error: any) {
      api.error(error.message || t('Failed to update'));
      console.error(error);
    }
  };

  return (
    <div>
      {contextHolder}
      <div className="page-content">
        <MetaTags>
          <title>{t('Properties')}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title={t('Settings')} breadcrumbItem={t('Properties')} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Tooltip title={t('Search properties by name, email, phone, place, address, postal code')}>
                        <div>
                          <SearchBar
                            value={searchValue}
                            onChange={handleSearchChange}
                            placeholder={t('Search properties by name, email, phone, place, address, postal code')}
                          />
                        </div>
                      </Tooltip>
                    </Col>

                    {/* <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Link to="#" className="btn btn-light" onClick={handleKeyClicks}>
                            <i className="uil uil-plus me-1"></i> Add
                          </Link>
                        </div>
                      </div>
                    </Col> */}
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                      marginTop: '10px',
                    }}
                  >
                    <TableView
                      loading={loading}
                      columns={propertiesColumns}
                      dataSource={realFormate?.filter((e: any) => {
                        if (searchValue === '') {
                          return e;
                        } else if (
                          e.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                          e.email.toLowerCase().includes(searchValue.toLowerCase()) ||
                          e.phone.toLowerCase().includes(searchValue.toLowerCase()) ||
                          e.place.toLowerCase().includes(searchValue.toLowerCase()) ||
                          e.address.toLowerCase().includes(searchValue.toLowerCase()) ||
                          e.postalCode.toLowerCase().includes(searchValue.toLowerCase())
                        ) {
                          return e;
                        }
                      })}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            // width={700}
            okText="Update"
            title={<p>{t('Update Property')}</p>}
            open={isEditModalOpen}
            onOk={() => {
              form.submit();
            }}
            onCancel={() => {
              setIsEditModalOpen(false);
              setEditingData(null);
            }}
            confirmLoading={isUpdating}
            destroyOnClose={true}
          >
            <Form layout="vertical" onFinish={handleUpdate} form={form}>
              <Row xl="12">
                <Col xl="6">
                  <Form.Item label={t('Id')} name={'id'}>
                    <Input readOnly disabled placeholder="Id" />
                  </Form.Item>
                  {/* mandantId */}
                </Col>
                <Col xl="6">
                  <Form.Item label={t('Mandant Id')} name={'mandantId'}>
                    <Input readOnly disabled placeholder={t('Mandant Id')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row xl="12">
                <Col xl="6">
                  <Form.Item label={t('Name')} name={'name'}>
                    <Input placeholder={t('Name')} />
                  </Form.Item>
                </Col>
                <Col xl="6">
                  <Form.Item label={t('Phone')} name={'phone'}>
                    <Input placeholder={t('Phone')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row xl="12">
                <Col xl="6">
                  <Form.Item label={t('Email')} name={'email'}>
                    <Input placeholder={t('Email')} />
                  </Form.Item>
                </Col>
                <Col xl="6">
                  <Form.Item label={t('Place')} name={'place'}>
                    <Input placeholder={t('Place')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row xl="12">
                <Col xl="6">
                  <Form.Item label={t('Address')} name={'address'}>
                    <Input placeholder={t('Address')} />
                  </Form.Item>
                </Col>
                <Col xl="6">
                  <Form.Item label={t('Postal Code')} name={'postalCode'}>
                    <Input placeholder={t('Postal Code')} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default Properties;
