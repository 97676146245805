import { createAsyncThunk } from '@reduxjs/toolkit';
import { TextManagementService } from 'src/utils/services/service/textManagementService';
import { IText, IPagination } from 'src/utils/types/textTypes';

export const CreateNewTextEntry = createAsyncThunk(
  'texts/createNewTextEntry',
  async ({ data }: { data: { content: string; hashtags: string[]; keywords: string[]; enabled: boolean } }) => {
    try {
      const response = await TextManagementService.createNewTextEntry(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);



export const GetAllTexts = createAsyncThunk<
  { data: IText[]; pagination: IPagination },
  { page: number; pageSize: number; }
>('texts/getAllTexts', async ({ page, pageSize }) => {
  try {
    const response = await TextManagementService.getListOfTexts(page, pageSize);

    return response;

  } catch (error) {
    return error;
  }
});

export const GetSingleText = createAsyncThunk('texts/getSingleText', async ({ textId }: { textId: string }) => {
  try {
    const response = await TextManagementService.getSingleText(textId);
    return response;
  } catch (error) {
    return error;
  }
});

export const UpdateTextEntry = createAsyncThunk(
  'texts/updateTextEntry',
  async ({
    textId,
    data,
  }: {
    textId: string;
    data: { content: string; hashtags: string[]; keywords: string[]; enabled: boolean };
  }) => {
    try {
      const response = await TextManagementService.updateTextEntry(textId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteTextEntry = createAsyncThunk('texts/deleteTextEntry', async ({ textId }: { textId: string }) => {
  try {
    const response = await TextManagementService.deleteTextEntry(textId);
    return response;
  } catch (error) {
    return error;
  }
});
