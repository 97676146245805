import axios from 'axios';
import {
  IUploadedFile,
  IFileUpload,
  InitiateTrainingState,
  IModelDeploymentRequest,
} from 'src/utils/types/mLPreProcessingFlowTypes';

const baseURL = '';

const dataSetsBaseUrl = '/datasets';
const modelTrainingBaseUrl = '/model/train';
const modelWeightsBaseUrl = '/model/weights';
const modelDeployBaseUrl = '/model/deploy';

const getAllDataSets = async (): Promise<IUploadedFile[] | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IUploadedFile[] = await axios.get(`${dataSetsBaseUrl}`, { headers, baseURL });
    return response;
  } catch (error) {
    console.error('Error fetching all Data Sets:', error);
    throw error;
  }
};

const getSingleDataSet = async (dataSetId: string): Promise<IUploadedFile | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IUploadedFile = await axios.get(`${dataSetsBaseUrl}/${dataSetId}`, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching single Data Sets:', error);
    throw error;
  }
};

const createDataSet = async (data: IFileUpload): Promise<IFileUpload | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IFileUpload = await axios.post(`${dataSetsBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating data sets:', error);
    throw error;
  }
};

const updateDataSet = async (dataSetId: string, data: IFileUpload): Promise<IFileUpload | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IFileUpload = await axios.put(`${dataSetsBaseUrl}/${dataSetId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating data sets:', error);
    throw error;
  }
};

const deleteDataSet = async (dataSetId: string): Promise<string | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    await axios.delete(`${dataSetsBaseUrl}/${dataSetId}`, { headers, baseURL });
    return dataSetId;
  } catch (error) {
    console.error('Error deleting data set:', error);
    throw error;
  }
};

const initiateModelTraining = async (data: InitiateTrainingState): Promise<InitiateTrainingState | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: InitiateTrainingState = await axios.post(`${modelTrainingBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error Model Training:', error);
    throw error;
  }
};

export const getModelTrainingStatus = async (training_id: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.get(`${modelTrainingBaseUrl}/${training_id}/status`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error retrieving model training status:', error);
    throw error;
  }
};
export const getModelWeights = async (id: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.get(`${modelWeightsBaseUrl}/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error retrieving model weights:', error);
    throw error;
  }
};

export const deployModel = async (data: IModelDeploymentRequest) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.post(`${modelDeployBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deploying model:', error);
    throw error;
  }
};

export const getDeploymentStatus = async (deploymentId: string) => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const dummyResponse = {
      deployment_id: deploymentId,
      status: 'completed',
      progress: 100,
    };

    return dummyResponse;
  } catch (error) {
    console.error('Error fetching deployment status:', error);
    throw error;
  }
};

export const submitInference = async (inferenceData: any) => {
  try {
    return {
      status: 'success',
      prediction: [0.5, 1.2, 2.3],
    };
  } catch (error) {
    console.error('Error submitting inference data:', error);
    throw error;
  }
};

export const PreProcessedDataSetsService = {
  getAllDataSets,
  getSingleDataSet,
  createDataSet,
  updateDataSet,
  deleteDataSet,
  initiateModelTraining,
  getModelTrainingStatus,
  getModelWeights,
  deployModel,
  getDeploymentStatus,
  submitInference,
};
