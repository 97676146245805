import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, FormGroup, Label } from 'reactstrap';
import { FlowView } from '@ant-design/pro-flow';
import styles from '../index.module.css';
import { edges, nodes } from '../data';
import { notification } from 'antd';
import { AppDispatch } from 'src/store';
import { useDispatch } from 'react-redux';
import { InitiateModelTraining } from 'src/slices/ModelTraining/thunk';
import TrainingStatusModal from './TrainingStatusModal';

import { NotificationType } from 'src/utils/types/NotificationTypes';

interface NewModalProps {
  isOpen: boolean;
  toggle: () => void;
  name: string | null;
  id: number | null;
}

interface FormData {
  dataset_id: string;
  algorithm: string;
  save_location: string;
}

const NewModal: React.FC<NewModalProps> = ({ isOpen, toggle, name, id }) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    dataset_id: id ? id.toString() : '',
    algorithm: '',
    save_location: '',
  });
  const dispatch: AppDispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [generatedId, setGeneratedId] = useState<string | null>(null);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const handleFormInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const handleNodeClick = (node: any) => {
    setIsFormModalOpen(true);
  };

  const handleFormSubmit = async () => {
    try {
      const response = await dispatch(InitiateModelTraining({ data: formData }));
      if (response) {
        const newGeneratedId = Math.floor(Math.random() * 10000).toString();
        setGeneratedId(newGeneratedId);

        openNotificationWithIcon('success', 'Model training started successfully.');
        setIsStatusModalOpen(true);
      } else {
        openNotificationWithIcon('error', 'Failed to initiate model training');
      }
    } catch (error) {
      console.error('Error during model training initiation:', error);
    }

    setIsFormModalOpen(false);
  };

  const toggleFormModal = () => setIsFormModalOpen(!isFormModalOpen);

  return (
    <>
      {contextHolder}
      <Modal isOpen={isOpen} toggle={toggle} modalClassName="custom-modal" style={{ maxWidth: '1200px' }}>
        <ModalHeader toggle={toggle}>ML Model Training Flow</ModalHeader>
        <ModalBody>
          <p>{name}</p>
          <p>ID: {id}</p> {/* Display the passed ID */}
          <div className={styles.container}>
            <FlowView nodes={nodes} edges={edges} onNodeClick={handleNodeClick} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/* Form Modal */}
      <Modal isOpen={isFormModalOpen} toggle={toggleFormModal}>
        <ModalHeader toggle={toggleFormModal}>Initiate Model Training</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="algorithm">Algorithm</Label>
            <Input
              type="select"
              name="algorithm"
              id="algorithm"
              value={formData.algorithm}
              onChange={handleFormInputChange}
            >
              <option value="">Select an algorithm</option>
              <option value="linear_regression">Linear Regression</option>
              <option value="decision_trees">Decision Trees</option>
              <option value="neural_networks">Neural Networks</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="save_location">Save Location</Label>
            <Input
              type="text"
              name="save_location"
              id="save_location"
              value={formData.save_location}
              onChange={handleFormInputChange}
              placeholder="Enter save location"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleFormSubmit}>
            Train Model
          </Button>
          <Button color="secondary" onClick={toggleFormModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <TrainingStatusModal
        isOpen={isStatusModalOpen}
        toggle={() => setIsStatusModalOpen(!isStatusModalOpen)}
        id={generatedId!}
      />
    </>
  );
};

export default NewModal;
