const continents = [
  //   {
  //     name: 'Africa',
  //   },
  //   {
  //     name: 'Antarctica',
  //   },
  //   {
  //     name: 'Asia',
  //   },
  {
    name: 'Europe',
  },
  //   {
  //     name: 'North America',
  //   },
  //   {
  //     name: 'Australia',
  //   },
  //   {
  //     name: 'South America',
  //   },
];

const countries = [
  { name: 'Albania' },
  { name: 'Andorra' },
  { name: 'Armenia' },
  { name: 'Austria' },
  { name: 'Azerbaijan' },
  { name: 'Belarus' },
  { name: 'Belgium' },
  { name: 'Bosnia and Herzegovina' },
  { name: 'Bulgaria' },
  { name: 'Croatia' },
  { name: 'Cyprus' },
  { name: 'Czech Republic' },
  { name: 'Denmark' },
  { name: 'Estonia' },
  { name: 'Finland' },
  { name: 'France' },
  { name: 'Georgia' },
  { name: 'Germany' },
  { name: 'Greece' },
  { name: 'Hungary' },
  { name: 'Iceland' },
  { name: 'Ireland' },
  { name: 'Italy' },
  { name: 'Kazakhstan' },
  { name: 'Kosovo' },
  { name: 'Latvia' },
  { name: 'Liechtenstein' },
  { name: 'Lithuania' },
  { name: 'Luxembourg' },
  { name: 'Malta' },
  { name: 'Moldova' },
  { name: 'Monaco' },
  { name: 'Montenegro' },
  { name: 'Netherlands' },
  { name: 'North Macedonia' },
  { name: 'Norway' },
  { name: 'Poland' },
  { name: 'Portugal' },
  { name: 'Romania' },
  { name: 'Russia' },
  { name: 'San Marino' },
  { name: 'Serbia' },
  { name: 'Slovakia' },
  { name: 'Slovenia' },
  { name: 'Spain' },
  { name: 'Sweden' },
  { name: 'Switzerland' },
  { name: 'Turkey' },
  { name: 'Ukraine' },
  { name: 'United Kingdom' },
  { name: 'Vatican City' },
];

const regions = [
  { name: 'Europe' },
  { name: 'Eastern Europe' },
  { name: 'Northern Europe' },
  { name: 'Southern Europe' },
  { name: 'Western Europe' },
];

const city = {
  Albania: [
    { name: 'Berat' },
    { name: 'Dibër' },
    { name: 'Durrës' },
    { name: 'Elbasan' },
    { name: 'Fier' },
    { name: 'Gjirokastër' },
    { name: 'Korçë' },
    { name: 'Kukës' },
    { name: 'Lezhë' },
    { name: 'Shkodër' },
    { name: 'Tiranë' },
    { name: 'Vlorë' },
  ],
  Andorra: [
    { name: 'Andorra la Vella' },
    { name: 'Canillo' },
    { name: 'Encamp' },
    { name: 'Escaldes-Engordany' },
    { name: 'La Massana' },
    { name: 'Ordino' },
    { name: 'Sant Julià de Lòria' },
  ],
  Armenia: [
    { name: 'Aragatsotn' },
    { name: 'Ararat' },
    { name: 'Armavir' },
    { name: 'Gegharkunik' },
    { name: 'Kotayk' },
    { name: 'Lori' },
    { name: 'Shirak' },
    { name: 'Syunik' },
    { name: 'Tavush' },
    { name: 'Vayots Dzor' },
    { name: 'Yerevan' },
  ],
  Austria: [
    { name: 'Burgenland' },
    { name: 'Carinthia' },
    { name: 'Lower Austria' },
    { name: 'Salzburg' },
    { name: 'Styria' },
    { name: 'Tyrol' },
    { name: 'Upper Austria' },
    { name: 'Vienna' },
    { name: 'Vorarlberg' },
  ],
  Azerbaijan: [
    { name: 'Absheron' },
    { name: 'Ganja-Gazakh' },
    { name: 'Lankaran' },
    { name: 'Mingachevir' },
    { name: 'Nagorno-Karabakh' },
    { name: 'Nakhchivan' },
    { name: 'Shaki-Zaqatala' },
    { name: 'Shirvan' },
    { name: 'Sumqayit' },
    { name: 'Yukhari Karabakh' },
  ],
  Belarus: [
    { name: 'Brest' },
    { name: 'Gomel' },
    { name: 'Grodno' },
    { name: 'Minsk' },
    { name: 'Mogilev' },
    { name: 'Vitebsk' },
  ],
  Belgium: [
    { name: 'Antwerp' },
    { name: 'East Flanders' },
    { name: 'Flemish Brabant' },
    { name: 'Hainaut' },
    { name: 'Liège' },
    { name: 'Limburg' },
    { name: 'Luxembourg' },
    { name: 'Namur' },
    { name: 'Walloon Brabant' },
    { name: 'West Flanders' },
  ],
  'Bosnia and Herzegovina': [
    { name: 'Federation of Bosnia and Herzegovina' },
    { name: 'Republika Srpska' },
    { name: 'Brčko District' },
  ],
  Bulgaria: [
    { name: 'Blagoevgrad' },
    { name: 'Burgas' },
    { name: 'Dobrich' },
    { name: 'Gabrovo' },
    { name: 'Haskovo' },
    { name: 'Kardzhali' },
    { name: 'Kyustendil' },
    { name: 'Lovech' },
    { name: 'Montana' },
    { name: 'Pazardzhik' },
    { name: 'Pernik' },
    { name: 'Pleven' },
    { name: 'Plovdiv' },
    { name: 'Razgrad' },
    { name: 'Ruse' },
    { name: 'Shumen' },
    { name: 'Silistra' },
    { name: 'Sliven' },
    { name: 'Smolyan' },
    { name: 'Sofia' },
    { name: 'Sofia Province' },
    { name: 'Stara Zagora' },
    { name: 'Targovishte' },
    { name: 'Varna' },
    { name: 'Veliko Tarnovo' },
    { name: 'Vidin' },
    { name: 'Vratsa' },
    { name: 'Yambol' },
  ],
  Croatia: [
    { name: 'Bjelovar-Bilogora' },
    { name: 'Brod-Posavina' },
    { name: 'Dubrovnik-Neretva' },
    { name: 'Istria' },
    { name: 'Karlovac' },
    { name: 'Koprivnica-Križevci' },
    { name: 'Krapina-Zagorje' },
    { name: 'Lika-Senj' },
    { name: 'Međimurje' },
    { name: 'Osijek-Baranja' },
    { name: 'Požega-Slavonia' },
    { name: 'Primorje-Gorski Kotar' },
    { name: 'Šibenik-Knin' },
    { name: 'Sisak-Moslavina' },
    { name: 'Split-Dalmatia' },
    { name: 'Varaždin' },
    { name: 'Virovitica-Podravina' },
    { name: 'Vukovar-Syrmia' },
    { name: 'Zadar' },
    { name: 'Zagreb' },
    { name: 'Zagreb County' },
  ],
  Cyprus: [
    { name: 'Famagusta' },
    { name: 'Kyrenia' },
    { name: 'Larnaca' },
    { name: 'Limassol' },
    { name: 'Nicosia' },
    { name: 'Paphos' },
  ],
  'Czech Republic': [
    { name: 'Central Bohemian Region' },
    { name: 'Hradec Králové Region' },
    { name: 'Karlovy Vary Region' },
    { name: 'Liberec Region' },
    { name: 'Moravian-Silesian Region' },
    { name: 'Olomouc Region' },
    { name: 'Pardubice Region' },
    { name: 'Plzeň Region' },
    { name: 'Prague' },
    { name: 'South Bohemian Region' },
    { name: 'South Moravian Region' },
    { name: 'Ústí nad Labem Region' },
    { name: 'Vysočina Region' },
    { name: 'Zlín Region' },
  ],
  Denmark: [
    { name: 'Capital Region' },
    { name: 'Central Denmark Region' },
    { name: 'North Denmark Region' },
    { name: 'Region of Southern Denmark' },
    { name: 'Region Zealand' },
  ],
  Estonia: [
    { name: 'Harju' },
    { name: 'Hiiu' },
    { name: 'Ida-Viru' },
    { name: 'Jõgeva' },
    { name: 'Järva' },
    { name: 'Lääne' },
    { name: 'Lääne-Viru' },
    { name: 'Põlva' },
    { name: 'Pärnu' },
    { name: 'Rapla' },
    { name: 'Saare' },
    { name: 'Tartu' },
    { name: 'Valga' },
    { name: 'Viljandi' },
    { name: 'Võru' },
  ],
  Finland: [
    { name: 'Åland Islands' },
    { name: 'Central Finland' },
    { name: 'Central Ostrobothnia' },
    { name: 'Kainuu' },
    { name: 'Kymenlaakso' },
    { name: 'Lapland' },
    { name: 'North Karelia' },
    { name: 'Northern Ostrobothnia' },
    { name: 'Northern Savonia' },
    { name: 'Ostrobothnia' },
    { name: 'Pirkanmaa' },
    { name: 'Satakunta' },
    { name: 'South Karelia' },
    { name: 'Southern Ostrobothnia' },
    { name: 'Southern Savonia' },
    { name: 'Uusimaa' },
    { name: 'Varsinais-Suomi' },
  ],
  France: [
    { name: 'Auvergne-Rhône-Alpes' },
    { name: 'Bourgogne-Franche-Comté' },
    { name: 'Brittany' },
    { name: 'Centre-Val de Loire' },
    { name: 'Corsica' },
    { name: 'Grand Est' },
    { name: 'Hauts-de-France' },
    { name: 'Île-de-France' },
    { name: 'Normandy' },
    { name: 'Nouvelle-Aquitaine' },
    { name: 'Occitanie' },
    { name: 'Pays de la Loire' },
    { name: "Provence-Alpes-Côte d'Azur" },
  ],
  Georgia: [
    { name: 'Abkhazia' },
    { name: 'Adjara' },
    { name: 'Guria' },
    { name: 'Imereti' },
    { name: 'Kakheti' },
    { name: 'Kvemo Kartli' },
    { name: 'Mtskheta-Mtianeti' },
    { name: 'Racha-Lechkhumi and Kvemo Svaneti' },
    { name: 'Samegrelo-Zemo Svaneti' },
    { name: 'Samtskhe-Javakheti' },
    { name: 'Shida Kartli' },
    { name: 'Tbilisi' },
  ],
  Germany: [
    { name: 'Baden-Württemberg' },
    { name: 'Bavaria' },
    { name: 'Berlin' },
    { name: 'Brandenburg' },
    { name: 'Bremen' },
    { name: 'Hamburg' },
    { name: 'Hesse' },
    { name: 'Lower Saxony' },
    { name: 'Mecklenburg-Vorpommern' },
    { name: 'North Rhine-Westphalia' },
    { name: 'Rhineland-Palatinate' },
    { name: 'Saarland' },
    { name: 'Saxony' },
    { name: 'Saxony-Anhalt' },
    { name: 'Schleswig-Holstein' },
    { name: 'Thuringia' },
  ],
  Greece: [
    { name: 'Aegean Islands' },
    { name: 'Central Greece' },
    { name: 'Crete' },
    { name: 'Epirus' },
    { name: 'Ionian Islands' },
    { name: 'Macedonia' },
    { name: 'Peloponnese' },
    { name: 'Thessaly' },
    { name: 'Thrace' },
    { name: 'Western Greece' },
  ],
  Hungary: [
    { name: 'Bács-Kiskun' },
    { name: 'Baranya' },
    { name: 'Békés' },
    { name: 'Borsod-Abaúj-Zemplén' },
    { name: 'Budapest' },
    { name: 'Csongrád-Csanád' },
    { name: 'Fejér' },
    { name: 'Győr-Moson-Sopron' },
    { name: 'Hajdú-Bihar' },
    { name: 'Heves' },
    { name: 'Jász-Nagykun-Szolnok' },
    { name: 'Komárom-Esztergom' },
    { name: 'Nógrád' },
    { name: 'Pest' },
    { name: 'Somogy' },
    { name: 'Szabolcs-Szatmár-Bereg' },
    { name: 'Tolna' },
    { name: 'Vas' },
    { name: 'Veszprém' },
    { name: 'Zala' },
  ],
  Iceland: [
    { name: 'Capital Region' },
    { name: 'Southern Peninsula' },
    { name: 'Western Region' },
    { name: 'Westfjords' },
    { name: 'Northwestern Region' },
    { name: 'Northeastern Region' },
    { name: 'Eastern Region' },
    { name: 'Southern Region' },
  ],
  Ireland: [
    { name: 'Carlow' },
    { name: 'Cavan' },
    { name: 'Clare' },
    { name: 'Cork' },
    { name: 'Donegal' },
    { name: 'Dublin' },
    { name: 'Galway' },
    { name: 'Kerry' },
    { name: 'Kildare' },
    { name: 'Kilkenny' },
    { name: 'Laois' },
    { name: 'Leitrim' },
    { name: 'Limerick' },
    { name: 'Longford' },
    { name: 'Louth' },
    { name: 'Mayo' },
    { name: 'Meath' },
    { name: 'Monaghan' },
    { name: 'Offaly' },
    { name: 'Roscommon' },
    { name: 'Sligo' },
    { name: 'Tipperary' },
    { name: 'Waterford' },
    { name: 'Westmeath' },
    { name: 'Wexford' },
    { name: 'Wicklow' },
  ],
  Italy: [
    { name: 'Abruzzo' },
    { name: 'Aosta Valley' },
    { name: 'Apulia' },
    { name: 'Basilicata' },
    { name: 'Calabria' },
    { name: 'Campania' },
    { name: 'Emilia-Romagna' },
    { name: 'Friuli Venezia Giulia' },
    { name: 'Lazio' },
    { name: 'Liguria' },
    { name: 'Lombardy' },
    { name: 'Marche' },
    { name: 'Molise' },
    { name: 'Piedmont' },
    { name: 'Sardinia' },
    { name: 'Sicily' },
    { name: 'Trentino-South Tyrol' },
    { name: 'Tuscany' },
    { name: 'Umbria' },
    { name: 'Veneto' },
  ],
  Kazakhstan: [
    { name: 'Akmola' },
    { name: 'Aktobe' },
    { name: 'Almaty' },
    { name: 'Atyrau' },
    { name: 'East Kazakhstan' },
    { name: 'Karaganda' },
    { name: 'Kostanay' },
    { name: 'Kyzylorda' },
    { name: 'Mangystau' },
    { name: 'North Kazakhstan' },
    { name: 'Pavlodar' },
    { name: 'Turkestan' },
    { name: 'West Kazakhstan' },
    { name: 'Zhambyl' },
  ],
  Kosovo: [
    { name: 'Ferizaj' },
    { name: 'Gjakova' },
    { name: 'Gjilan' },
    { name: 'Mitrovica' },
    { name: 'Peja' },
    { name: 'Pristina' },
    { name: 'Prizren' },
  ],
  Latvia: [{ name: 'Kurzeme' }, { name: 'Latgale' }, { name: 'Riga' }, { name: 'Vidzeme' }, { name: 'Zemgale' }],
  Liechtenstein: [
    { name: 'Balzers' },
    { name: 'Eschen' },
    { name: 'Gamprin' },
    { name: 'Mauren' },
    { name: 'Planken' },
    { name: 'Ruggell' },
    { name: 'Schaan' },
    { name: 'Schellenberg' },
    { name: 'Triesen' },
    { name: 'Triesenberg' },
    { name: 'Vaduz' },
  ],
  Lithuania: [
    { name: 'Alytus' },
    { name: 'Kaunas' },
    { name: 'Klaipėda' },
    { name: 'Marijampolė' },
    { name: 'Panevėžys' },
    { name: 'Šiauliai' },
    { name: 'Tauragė' },
    { name: 'Telšiai' },
    { name: 'Utena' },
    { name: 'Vilnius' },
  ],
  Luxembourg: [
    { name: 'Canton of Capellen' },
    { name: 'Canton of Clervaux' },
    { name: 'Canton of Diekirch' },
    { name: 'Canton of Echternach' },
    { name: 'Canton of Esch-sur-Alzette' },
    { name: 'Canton of Grevenmacher' },
    { name: 'Canton of Luxembourg' },
    { name: 'Canton of Mersch' },
    { name: 'Canton of Redange' },
    { name: 'Canton of Remich' },
    { name: 'Canton of Vianden' },
    { name: 'Canton of Wiltz' },
  ],
  Malta: [{ name: 'Gozo' }, { name: 'Malta' }],
  Moldova: [
    { name: 'Anenii Noi' },
    { name: 'Bălți' },
    { name: 'Basarabeasca' },
    { name: 'Bender' },
    { name: 'Briceni' },
    { name: 'Cahul' },
    { name: 'Călărași' },
    { name: 'Cantemir' },
    { name: 'Căușeni' },
    { name: 'Cimișlia' },
    { name: 'Criuleni' },
    { name: 'Dondușeni' },
    { name: 'Drochia' },
    { name: 'Dubăsari' },
    { name: 'Edineț' },
    { name: 'Fălești' },
    { name: 'Florești' },
    { name: 'Glodeni' },
    { name: 'Hîncești' },
    { name: 'Ialoveni' },
    { name: 'Leova' },
    { name: 'Nisporeni' },
    { name: 'Ocnița' },
    { name: 'Orhei' },
    { name: 'Rezina' },
    { name: 'Rîbnița' },
    { name: 'Rîșcani' },
    { name: 'Sîngerei' },
    { name: 'Soroca' },
    { name: 'Șoldănești' },
    { name: 'Ștefan Vodă' },
    { name: 'Strășeni' },
    { name: 'Taraclia' },
    { name: 'Telenești' },
    { name: 'Ungheni' },
    { name: 'Vulcănești' },
  ],
  Monaco: [{ name: 'Fontvieille' }, { name: 'La Condamine' }, { name: 'Monaco-Ville' }, { name: 'Monte Carlo' }],
  Montenegro: [
    { name: 'Andrijevica' },
    { name: 'Bar' },
    { name: 'Berane' },
    { name: 'Bijelo Polje' },
    { name: 'Budva' },
    { name: 'Cetinje' },
    { name: 'Danilovgrad' },
    { name: 'Gusinje' },
    { name: 'Herceg Novi' },
    { name: 'Kolašin' },
    { name: 'Kotor' },
    { name: 'Mojkovac' },
    { name: 'Nikšić' },
    { name: 'Petnjica' },
    { name: 'Plav' },
    { name: 'Pljevlja' },
    { name: 'Plužine' },
    { name: 'Podgorica' },
    { name: 'Rožaje' },
    { name: 'Šavnik' },
    { name: 'Tivat' },
    { name: 'Ulcinj' },
    { name: 'Žabljak' },
  ],
  Netherlands: [
    { name: 'Drenthe' },
    { name: 'Flevoland' },
    { name: 'Friesland' },
    { name: 'Gelderland' },
    { name: 'Groningen' },
    { name: 'Limburg' },
    { name: 'North Brabant' },
    { name: 'North Holland' },
    { name: 'Overijssel' },
    { name: 'South Holland' },
    { name: 'Utrecht' },
    { name: 'Zeeland' },
  ],
  'North Macedonia': [
    { name: 'Eastern' },
    { name: 'Northeastern' },
    { name: 'Pelagonia' },
    { name: 'Polog' },
    { name: 'Skopje' },
    { name: 'Southeastern' },
    { name: 'Southwestern' },
    { name: 'Vardar' },
  ],
  Norway: [
    { name: 'Agder' },
    { name: 'Innlandet' },
    { name: 'Møre og Romsdal' },
    { name: 'Nordland' },
    { name: 'Oslo' },
    { name: 'Rogaland' },
    { name: 'Troms og Finnmark' },
    { name: 'Trøndelag' },
    { name: 'Vestfold og Telemark' },
    { name: 'Vestland' },
    { name: 'Viken' },
  ],
  Poland: [
    { name: 'Greater Poland' },
    { name: 'Kuyavian-Pomeranian' },
    { name: 'Lesser Poland' },
    { name: 'Łódź' },
    { name: 'Lower Silesian' },
    { name: 'Lublin' },
    { name: 'Lubusz' },
    { name: 'Masovian' },
    { name: 'Opole' },
    { name: 'Podlaskie' },
    { name: 'Pomeranian' },
    { name: 'Silesian' },
    { name: 'Subcarpathian' },
    { name: 'Świętokrzyskie' },
    { name: 'Warmian-Masurian' },
    { name: 'West Pomeranian' },
  ],
  Portugal: [
    { name: 'Aveiro' },
    { name: 'Beja' },
    { name: 'Braga' },
    { name: 'Bragança' },
    { name: 'Castelo Branco' },
    { name: 'Coimbra' },
    { name: 'Évora' },
    { name: 'Faro' },
    { name: 'Guarda' },
    { name: 'Leiria' },
    { name: 'Lisbon' },
    { name: 'Portalegre' },
    { name: 'Porto' },
    { name: 'Santarém' },
    { name: 'Setúbal' },
    { name: 'Viana do Castelo' },
    { name: 'Vila Real' },
    { name: 'Viseu' },
  ],
  Romania: [
    { name: 'Alba' },
    { name: 'Arad' },
    { name: 'Argeș' },
    { name: 'Bacău' },
    { name: 'Bihor' },
    { name: 'Bistrița-Năsăud' },
    { name: 'Botoșani' },
    { name: 'Brăila' },
    { name: 'Brașov' },
    { name: 'Bucharest' },
    { name: 'Buzău' },
    { name: 'Călărași' },
    { name: 'Caraș-Severin' },
    { name: 'Cluj' },
    { name: 'Constanța' },
    { name: 'Covasna' },
    { name: 'Dâmbovița' },
    { name: 'Dolj' },
    { name: 'Galați' },
    { name: 'Giurgiu' },
    { name: 'Gorj' },
    { name: 'Harghita' },
    { name: 'Hunedoara' },
    { name: 'Ialomița' },
    { name: 'Iași' },
    { name: 'Ilfov' },
    { name: 'Maramureș' },
    { name: 'Mehedinți' },
    { name: 'Mureș' },
    { name: 'Neamț' },
    { name: 'Olt' },
    { name: 'Prahova' },
    { name: 'Sălaj' },
    { name: 'Satu Mare' },
    { name: 'Sibiu' },
    { name: 'Suceava' },
    { name: 'Teleorman' },
    { name: 'Timiș' },
    { name: 'Tulcea' },
    { name: 'Vâlcea' },
    { name: 'Vaslui' },
    { name: 'Vrancea' },
  ],
  'San Marino': [
    { name: 'Acquaviva' },
    { name: 'Borgo Maggiore' },
    { name: 'Chiesanuova' },
    { name: 'Domagnano' },
    { name: 'Faetano' },
    { name: 'Fiorentino' },
    { name: 'Montegiardino' },
    { name: 'San Marino' },
    { name: 'Serravalle' },
  ],
  Serbia: [
    { name: 'Belgrade' },
    { name: 'Bor' },
    { name: 'Braničevo' },
    { name: 'Jablanica' },
    { name: 'Kolubara' },
    { name: 'Mačva' },
    { name: 'Moravica' },
    { name: 'Nišava' },
    { name: 'North Bačka' },
    { name: 'North Banat' },
    { name: 'Pirot' },
    { name: 'Podunavlje' },
    { name: 'Pomoravlje' },
    { name: 'Rasina' },
    { name: 'Raška' },
    { name: 'South Bačka' },
    { name: 'South Banat' },
    { name: 'Srem' },
    { name: 'Šumadija' },
    { name: 'Toplica' },
    { name: 'Vojvodina' },
    { name: 'West Bačka' },
    { name: 'Zaječar' },
    { name: 'Zlatibor' },
  ],
  Slovakia: [
    { name: 'Banská Bystrica' },
    { name: 'Bratislava' },
    { name: 'Košice' },
    { name: 'Nitra' },
    { name: 'Prešov' },
    { name: 'Trenčín' },
    { name: 'Trnava' },
    { name: 'Žilina' },
  ],
  Slovenia: [
    { name: 'Ajdovščina' },
    { name: 'Beltinci' },
    { name: 'Benedikt' },
    { name: 'Bistrica ob Sotli' },
    { name: 'Bled' },
    { name: 'Bloke' },
    { name: 'Bohinj' },
    { name: 'Borovnica' },
    { name: 'Bovec' },
    { name: 'Braslovče' },
    { name: 'Brda' },
    { name: 'Brezovica' },
    { name: 'Brežice' },
    { name: 'Cankova' },
    { name: 'Cerklje na Gorenjskem' },
    { name: 'Cerknica' },
    { name: 'Cerkno' },
    { name: 'Cerkvenjak' },
    { name: 'Cirkulane' },
    { name: 'Destrnik' },
    { name: 'Divača' },
    { name: 'Dobje' },
    { name: 'Dobrepolje' },
    { name: 'Dobrna' },
    { name: 'Dobrova-Polhov Gradec' },
    { name: 'Dobrovnik' },
    { name: 'Dol pri Ljubljani' },
    { name: 'Dolenjske Toplice' },
    { name: 'Domžale' },
    { name: 'Dornava' },
    { name: 'Dravograd' },
    { name: 'Duplek' },
    { name: 'Gorenja Vas-Poljane' },
    { name: 'Gorišnica' },
    { name: 'Gorje' },
    { name: 'Gornja Radgona' },
    { name: 'Gornji Grad' },
    { name: 'Gornji Petrovci' },
    { name: 'Grad' },
    { name: 'Grosuplje' },
    { name: 'Hajdina' },
    { name: 'Hoče-Slivnica' },
    { name: 'Hodoš' },
    { name: 'Horjul' },
    { name: 'Hrastnik' },
    { name: 'Hrpelje-Kozina' },
    { name: 'Idrija' },
    { name: 'Ig' },
    { name: 'Ilirska Bistrica' },
    { name: 'Ivancna Gorica' },
    { name: 'Izola' },
    { name: 'Jesenice' },
    { name: 'Jezersko' },
    { name: 'Juršinci' },
    { name: 'Kamnik' },
    { name: 'Kanal ob Soči' },
    { name: 'Kidričevo' },
    { name: 'Kobarid' },
    { name: 'Kobilje' },
    { name: 'Kočevje' },
    { name: 'Komen' },
    { name: 'Komenda' },
    { name: 'Koper' },
    { name: 'Kostel' },
    { name: 'Kozje' },
    { name: 'Kranj' },
    { name: 'Kranjska Gora' },
    { name: 'Krško' },
    { name: 'Kungota' },
    { name: 'Kuzma' },
    { name: 'Laško' },
    { name: 'Lenart' },
    { name: 'Lendava' },
    { name: 'Litija' },
    { name: 'Ljubljana' },
    { name: 'Ljubno' },
    { name: 'Ljutomer' },
    { name: 'Log-Dragomer' },
    { name: 'Logatec' },
    { name: 'Loška Dolina' },
    { name: 'Loški Potok' },
    { name: 'Lovrenc na Pohorju' },
    { name: 'Luče' },
    { name: 'Lukovica' },
    { name: 'Majšperk' },
    { name: 'Makole' },
    { name: 'Maribor' },
    { name: 'Markovci' },
    { name: 'Medvode' },
    { name: 'Mengeš' },
    { name: 'Metlika' },
    { name: 'Mežica' },
    { name: 'Miklavž na Dravskem Polju' },
    { name: 'Miren-Kostanjevica' },
    { name: 'Mirna' },
    { name: 'Mirna Peč' },
    { name: 'Mislinja' },
    { name: 'Mokronog-Trebelno' },
    { name: 'Moravče' },
    { name: 'Moravske Toplice' },
    { name: 'Mozirje' },
    { name: 'Murska Sobota' },
    { name: 'Muta' },
    { name: 'Naklo' },
    { name: 'Nazarje' },
    { name: 'Nova Gorica' },
    { name: 'Novo Mesto' },
    { name: 'Odranci' },
    { name: 'Oplotnica' },
    { name: 'Ormož' },
    { name: 'Osilnica' },
    { name: 'Pesnica' },
    { name: 'Piran' },
    { name: 'Pivka' },
    { name: 'Podčetrtek' },
    { name: 'Podlehnik' },
    { name: 'Podvelka' },
    { name: 'Poljčane' },
    { name: 'Polzela' },
    { name: 'Postojna' },
    { name: 'Prebold' },
    { name: 'Preddvor' },
    { name: 'Prevalje' },
    { name: 'Ptuj' },
    { name: 'Puconci' },
    { name: 'Rače-Fram' },
    { name: 'Radeče' },
    { name: 'Radenci' },
    { name: 'Radlje ob Dravi' },
    { name: 'Radovljica' },
    { name: 'Ravne na Koroškem' },
    { name: 'Razkrižje' },
    { name: 'Rečica ob Savinji' },
    { name: 'Renče-Vogrsko' },
    { name: 'Ribnica' },
    { name: 'Ribnica na Pohorju' },
    { name: 'Rogaška Slatina' },
    { name: 'Rogašovci' },
    { name: 'Rogatec' },
    { name: 'Ruše' },
    { name: 'Šalovci' },
    { name: 'Šempeter-Vrtojba' },
    { name: 'Šenčur' },
    { name: 'Šentilj' },
    { name: 'Šentjernej' },
    { name: 'Šentjur' },
    { name: 'Šentrupert' },
    { name: 'Sevnica' },
    { name: 'Sežana' },
    { name: 'Škocjan' },
    { name: 'Škofja Loka' },
    { name: 'Škofljica' },
    { name: 'Slovenj Gradec' },
    { name: 'Slovenska Bistrica' },
    { name: 'Slovenske Konjice' },
    { name: 'Šmarje pri Jelšah' },
    { name: 'Šmartno ob Paki' },
    { name: 'Šmartno pri Litiji' },
    { name: 'Sodražica' },
    { name: 'Solčava' },
    { name: 'Šoštanj' },
    { name: 'Štore' },
    { name: 'Straža' },
    { name: 'Sveta Ana' },
    { name: 'Sveta Trojica v Slovenskih Goricah' },
    { name: 'Sveti Andraž v Slovenskih Goricah' },
    { name: 'Sveti Jurij ob Ščavnici' },
    { name: 'Sveti Jurij v Slovenskih Goricah' },
    { name: 'Sveti Tomaž' },
    { name: 'Tabor' },
    { name: 'Tišina' },
    { name: 'Tolmin' },
    { name: 'Trbovlje' },
    { name: 'Trebnje' },
    { name: 'Trnovska Vas' },
    { name: 'Trzin' },
    { name: 'Tržič' },
    { name: 'Turnišče' },
    { name: 'Velenje' },
    { name: 'Velika Polana' },
    { name: 'Velike Lašče' },
    { name: 'Veržej' },
    { name: 'Videm' },
    { name: 'Vipava' },
    { name: 'Vitanje' },
    { name: 'Vodice' },
    { name: 'Vojnik' },
    { name: 'Vransko' },
    { name: 'Vrhnika' },
    { name: 'Vuzenica' },
    { name: 'Zagorje ob Savi' },
    { name: 'Žalec' },
    { name: 'Zavrč' },
    { name: 'Zreče' },
    { name: 'Železniki' },
    { name: 'Žetale' },
    { name: 'Žiri' },
    { name: 'Žirovnica' },
    { name: 'Žužemberk' },
  ],
  Spain: [
    { name: 'A Coruña' },
    { name: 'Albacete' },
    { name: 'Alicante' },
    { name: 'Almería' },
    { name: 'Álava' },
    { name: 'Asturias' },
    { name: 'Ávila' },
    { name: 'Badajoz' },
    { name: 'Balearic Islands' },
    { name: 'Barcelona' },
    { name: 'Biscay' },
    { name: 'Burgos' },
    { name: 'Cáceres' },
    { name: 'Cádiz' },
    { name: 'Cantabria' },
    { name: 'Castellón' },
    { name: 'Ciudad Real' },
    { name: 'Cuenca' },
    { name: 'Córdoba' },
    { name: 'Gipuzkoa' },
    { name: 'Girona' },
    { name: 'Granada' },
    { name: 'Guadalajara' },
    { name: 'Huelva' },
    { name: 'Huesca' },
    { name: 'Jaén' },
    { name: 'La Rioja' },
    { name: 'Las Palmas' },
    { name: 'León' },
    { name: 'Lleida' },
    { name: 'Lugo' },
    { name: 'Madrid' },
    { name: 'Málaga' },
    { name: 'Murcia' },
    { name: 'Navarre' },
    { name: 'Ourense' },
    { name: 'Palencia' },
    { name: 'Pontevedra' },
    { name: 'Salamanca' },
    { name: 'Santa Cruz de Tenerife' },
    { name: 'Segovia' },
    { name: 'Seville' },
    { name: 'Soria' },
    { name: 'Tarragona' },
    { name: 'Teruel' },
    { name: 'Toledo' },
    { name: 'Valencia' },
    { name: 'Valladolid' },
    { name: 'Zamora' },
    { name: 'Zaragoza' },
  ],
  Sweden: [
    { name: 'Blekinge' },
    { name: 'Dalarna' },
    { name: 'Gävleborg' },
    { name: 'Gotland' },
    { name: 'Halland' },
    { name: 'Jämtland' },
    { name: 'Jönköping' },
    { name: 'Kalmar' },
    { name: 'Kronoberg' },
    { name: 'Norrbotten' },
    { name: 'Örebro' },
    { name: 'Östergötland' },
    { name: 'Skåne' },
    { name: 'Södermanland' },
    { name: 'Stockholm' },
    { name: 'Uppsala' },
    { name: 'Värmland' },
    { name: 'Västerbotten' },
    { name: 'Västernorrland' },
    { name: 'Västmanland' },
    { name: 'Västra Götaland' },
  ],
  Switzerland: [
    { name: 'Aargau' },
    { name: 'Appenzell Ausserrhoden' },
    { name: 'Appenzell Innerrhoden' },
    { name: 'Basel-Landschaft' },
    { name: 'Basel-Stadt' },
    { name: 'Bern' },
    { name: 'Fribourg' },
    { name: 'Geneva' },
    { name: 'Glarus' },
    { name: 'Grisons' },
    { name: 'Jura' },
    { name: 'Lucerne' },
    { name: 'Neuchâtel' },
    { name: 'Nidwalden' },
    { name: 'Obwalden' },
    { name: 'Schaffhausen' },
    { name: 'Schwyz' },
    { name: 'Solothurn' },
    { name: 'St. Gallen' },
    { name: 'Thurgau' },
    { name: 'Ticino' },
    { name: 'Uri' },
    { name: 'Valais' },
    { name: 'Vaud' },
    { name: 'Zug' },
    { name: 'Zürich' },
  ],
};

export { continents, countries, regions, city };
