/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, notification, Select, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RootState } from 'src/store';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useEffect, useState } from 'react';
import { IManageHotel, IPagination } from 'src/utils/types/manageHotelTypes';
import { CreateManageHotelData, GetAllManageHotelData, UpdateManageHotelData } from 'src/slices/ManageHotel/thunk';
import { AppDispatch } from 'src/store';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

interface IManageHotelProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: IManageHotel | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: IManageHotel | null) => void;
  initialViewValues?: IManageHotel | null;
  isViewMode?: boolean;
  setIsViewMode: (isViewMode: boolean) => void;
  setViewData: (data: IManageHotel | null) => void;
  pagination: IPagination;
}
// const { Option } = Select;

const ManageHotelModal = ({
  isOpen,
  toggle,
  pagination,
  openNotificationWithIcon,
  initialValues,
  isEditMode = false,
  setIsEditMode,
  setEditingData,
  initialViewValues,
  isViewMode = false,
  setIsViewMode,
  setViewData,
}: IManageHotelProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('');
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.manageHotel);
  const dispatch: AppDispatch = useDispatch();

  // State to store unique dropdown options

  const [kinds, setKinds] = useState([]);
  const [segments, setSegments] = useState([]);

  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);

  useEffect(() => {
    const fetchManageHotelData = async () => {
      const response = await dispatch(GetAllManageHotelData({ page: pagination.currentPage, pageSize: pagination.pageSize }));

    };

    fetchManageHotelData();
  }, [dispatch]);

  useEffect(() => {
    const fetchKinds = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response: any = await axios.get(
          'https://api.dev.data.projects.excitingdevs.com/api/v1/hotels/hotels/kinds',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        setKinds(response);
      } catch (error) {
        console.error('Error fetching kinds:', error);
      }
    };

    const fetchSegments = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const response: any = await axios.get(
          'https://api.dev.data.projects.excitingdevs.com/api/v1/hotels/hotels/segments',
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        setSegments(response);
      } catch (error) {
        console.error('Error fetching segments:', error);
      }
    };

    fetchKinds();
    fetchSegments();
  }, []);

  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: IManageHotel = form.getFieldsValue();

    try {
      if (isEditMode) {
        // ** Update Operation
        const payload = formData;
        // payload.tenant = initialValues?.tenant;
        payload.id = initialValues?.id;
        const response = await dispatch(
          UpdateManageHotelData({
            data: payload,
            hoteldataId: initialValues?.id as string,
          }),
        );
        // ** Handled Response
        if ((response.payload as IManageHotel)['id']) {
          openNotificationWithIcon('success', t('Hotel data updated successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllManageHotelData({ page: pagination.currentPage, pageSize: pagination.pageSize }));

        } else {
          openNotificationWithIcon('error', t(`Error updating Hotel data`));
        }
      } else {
        // ** Create Operation
        const payload = formData;
        const response = await dispatch(
          CreateManageHotelData({
            data: payload,
          }),
        );

        // ** Handled Response
        if ((response.payload as IManageHotel)['id']) {
          openNotificationWithIcon('success', t('Hotel data created successfully'));
          handleCancel();
          form.resetFields();
          dispatch(GetAllManageHotelData({ page: pagination.currentPage, pageSize: pagination.pageSize }));
        } else {
          openNotificationWithIcon('error', t(`Error creating Hotel data`));
        }
      }
    } catch (error) {
      openNotificationWithIcon('error', t(`Something went wrong`));
    }
  };

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
    }
    if (isViewMode) {
      setIsViewMode(false);
      setViewData(null);
    }
    form.resetFields();
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? t('Update') : isViewMode ? t('View') : t('Create')} {t('Manage Hotel')}
        </ModalHeader>
        <ModalBody className="scrollModal">
          {isViewMode ? (
            <div>
              <p>
                <strong>{t('Category')}:</strong> {initialViewValues?.category}
              </p>
              <p>
                <strong>{t('Name')}:</strong> {initialViewValues?.name}
              </p>
              <p>
                <strong>{t('Kind')}:</strong> {initialViewValues?.kind}
              </p>
              <p>
                <strong>{t('Segment')}:</strong> {initialViewValues?.segment}
              </p>
            </div>
          ) : (
            <Form layout="vertical" form={form}>
              <Form.Item
                required
                label={t('Category')}
                name="category"
                rules={[
                  {
                    required: true,
                    message: t('Please select a category'),
                  },
                ]}
              >
                <Input id="manageHotelCategory" placeholder={t('Category')} className="p-2 px-2" />
              </Form.Item>

              <Form.Item
                required={true}
                label={t('Name')}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('Please enter name'),
                  },
                ]}
              >
                <Input id="manageHotelName" placeholder={t('Name')} className="p-2 px-2" />
              </Form.Item>
              <Form.Item
                required
                label="Kind"
                name="kind"
                rules={[
                  {
                    required: true,
                    message: 'Please select kind',
                  },
                ]}
              >
                <Select placeholder="Select Kind" id="manageHotelKind">
                  {kinds.map((kind) => (
                    <Select.Option key={kind} value={kind}>
                      {kind}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                required
                label="Segment"
                name="segment"
                rules={[
                  {
                    required: true,
                    message: 'Please select segment',
                  },
                ]}
              >
                <Select placeholder="Select Segment" id="manageHotelSegment">
                  {segments.map((segment) => (
                    <Select.Option key={segment} value={segment}>
                      {segment}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          )}
          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Tooltip title={t('Cancel')}>
              <Button
                id="manageHotelCancelButton"
                disabled={isCreating || isUpdating}
                onClick={handleCancel}
                style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
              >
                {t('Cancel')}
              </Button>
            </Tooltip>
            {!isViewMode && (
              <Tooltip title={isEditMode ? t('Update') : t('Create')}>
                <AsyncButton
                  buttonText={isEditMode ? t('Update') : t('Create')}
                  isEditMode={isEditMode}
                  style={{ marginTop: 20 }}
                  isLoading={isCreating || isUpdating}
                  onClick={handleCreateOrUpdate}
                  id="hotelCreateORUpdateButton"
                />
              </Tooltip>
            )}
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ManageHotelModal;
