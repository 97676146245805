/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, notification, Select, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AppDispatch, RootState } from 'src/store';
import {
  CreateNotificationContact,
  GetAllNotificationContacts,
  UpdateNotificationContact,
} from 'src/slices/NotificationContacts/thunk';
import { useEffect } from 'react';
import { INotificationContacts, IPagination } from 'src/utils/types/notificatioinContactsTypes';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { useTranslation } from 'react-i18next';

interface ICreateNotificationContactsProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: INotificationContacts | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: INotificationContacts | null) => void;
  pagination: IPagination;
}
type NotificationType = 'success' | 'info' | 'warning' | 'error';

const NotificationContactModal = ({
  isOpen,
  toggle,
  openNotificationWithIcon,
  initialValues,
  pagination,

  isEditMode = false,
  setIsEditMode,
  setEditingData,
}: ICreateNotificationContactsProps) => {
  const [form] = Form.useForm();
  const { isCreating, isUpdating } = useSelector((state: RootState) => state.notificationContacts);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation('');

  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);

  // ** Function to handle create or update
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: INotificationContacts = form.getFieldsValue();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const validateEmail = (email: string) => emailRegex.test(email);

    if (!validateEmail(formData.email)) {
      notification.error({
        message: 'Invalid Email',
        description: 'Please enter valid email address',
      });
      return;
    }

    try {
      if (isEditMode) {
        const payload = formData;
        payload.id = initialValues?.id;

        const response = await dispatch(
          UpdateNotificationContact({
            data: payload,
            notificationId: initialValues?.id as string,
          }),
        );

        // ** Handled Response
        if ((response.payload as INotificationContacts)['id']) {
          openNotificationWithIcon('success', `${t('Notification contact updated  successfully')}`);
          handleCancel();
          form.resetFields();
          dispatch(GetAllNotificationContacts({ page: 1, pageSize: 50 }));
        } else {
          openNotificationWithIcon('error', `${t('Error updating notification contact')}`);
        }
      } else {
        const payload = formData;
        const response = await dispatch(
          CreateNotificationContact({
            data: payload,
          }),
        );
        // ** Handled Response
        if ((response.payload as INotificationContacts)['id']) {
          openNotificationWithIcon('success', `${t('Notification contact created successfully')}`);
          handleCancel();
          form.resetFields();
          dispatch(GetAllNotificationContacts({ page: 1, pageSize: 50 }));
        } else {
          openNotificationWithIcon('error', `${t('Error creating notification contact')}`);
        }
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon(
        'error',
        `${t('Error')} ${isEditMode ? t('updating') : t('creating')} ${t('Notification Contact')}`,
      );
    }
  };

  const handleCancel = () => {
    toggle();
    form.resetFields();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
    }
  };

  return (
    <div>
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5" title={t(isEditMode ? t('Update') : t('Create'))}>
          {isEditMode ? t('Update') : t('Create')} {t('Notification Contacts')}
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label={t('Name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('Please enter name'),
                },
              ]}
            >
              <Input id="notificationContactName" placeholder="Name" className="p-2 px-2" />
            </Form.Item>
            <Form.Item
              style={{ marginTop: '-10px' }}
              required={true}
              label={t('Email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('Please enter email address'),
                },
                // {
                //   type: 'email',
                //   message: t('Please enter valid email address'),
                // },
              ]}
            >
              <Input id="notificationContactEmail" placeholder={t('Email address')} className="p-2 px-2" />
            </Form.Item>
            <Form.Item
              style={{ marginTop: '-10px' }}
              required={true}
              label={t('Kind')}
              name="kind"
              rules={[
                {
                  required: true,
                  message: t('Please select kind'),
                },
              ]}
            >
              <Select
                id="notificationContactKind"
                allowClear
                placeholder={t('Select kind')}
                style={{
                  height: '40px',
                }}
              >
                {[
                  { value: 'tax_advisor', label: t('Tax Advisor') },
                  { value: 'financial_advisor', label: t('Financial Advisor') },
                  { value: 'investment_broker', label: t('Investment Broker') },
                  { value: 'legal_advisor', label: t('Legal Advisor') },
                  { value: 'real_estate_agent', label: t('Real Estate Agent') },
                ].map((item) => (
                  <Select.Option key={item.label} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Tooltip title={t('Cancel')}>
              <Button
                id="notificationCancelButton"
                disabled={isCreating || isUpdating}
                onClick={handleCancel}
                style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
              >
                {t('Cancel')}
              </Button>
            </Tooltip>
            <Tooltip title={isEditMode ? t('Update') : t('Create')}>
              <AsyncButton
                buttonText={isEditMode ? t('Update') : t('Create')}
                isEditMode={isEditMode}
                style={{ marginTop: 20 }}
                isLoading={isCreating || isUpdating}
                onClick={handleCreateOrUpdate}
                id="notificationCreateORUpdateButton"
              />
            </Tooltip>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NotificationContactModal;
