import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  processPricingPredictionSlice
} from 'src/slices/PricingPrediction/thunk';

interface PricingPredictionStateType {
  pricingPrediction: Array<any>;
  event: any;
  error?: string | object | null | undefined | unknown;
  loading: boolean;
  pricingPredictionLoading: boolean
  categories: any,
}
export const initialState: PricingPredictionStateType = {
  pricingPrediction: [],
  event: null,
  error: '',
  loading: false,
  categories: [],
  pricingPredictionLoading: false
};

const PricingPredictionSlice = createSlice({
  name: 'pricingPrediction',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processPricingPredictionSlice.pending, (state) => {
        state.pricingPredictionLoading = true;
        state.error = null;
      })

      .addCase(processPricingPredictionSlice.fulfilled, (state, action) => {
        state.pricingPrediction = action.payload;
        state.pricingPredictionLoading = false;
      })

      .addMatcher(isError, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default PricingPredictionSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
