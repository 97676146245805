import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createExternalEvents,
  deleteExternalEvents,
  getExternalEvents,
  updateExternalEvents,
  getExternalEvent,
  getEventCategories,
} from 'src/slices/ExternalEvents/thunk';
import { ITableDataEvents, IPagination } from 'src/utils/types/externalEvents';

interface EventsStateType {
  events: ITableDataEvents[];
  pagination: IPagination;
  event: ITableDataEvents | null;
  error?: string | object | null;
  loading: boolean;
  eventsLoading: boolean;
  categories: Array<string>;
}

export const initialState: EventsStateType = {
  events: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  event: null,
  error: null,
  loading: false,
  categories: [],
  eventsLoading: false,
};

const externalEventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExternalEvents.pending, (state) => {
        state.eventsLoading = true;
        state.error = null;
      })
      .addCase(getExternalEvents.fulfilled, (state, action) => {
        const { events, pagination } = action.payload || { events: [], pagination: initialState.pagination };
        state.events = events;
        state.pagination = pagination;
        state.eventsLoading = false;
      })
      .addCase(getExternalEvents.rejected, (state, action) => {
        state.error = action.error.message;
        state.eventsLoading = false;
      })


      .addCase(getExternalEvent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getExternalEvent.fulfilled, (state, action) => {
        state.event = action.payload;
        state.loading = false;
      })
      .addCase(getExternalEvent.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })


      .addCase(createExternalEvents.pending, (state) => {
        state.eventsLoading = true;
        state.error = null;
      })
      .addCase(createExternalEvents.fulfilled, (state) => {
        state.eventsLoading = false;
      })
      .addCase(createExternalEvents.rejected, (state, action) => {
        state.error = action.error.message;
        state.eventsLoading = false;
      })


      .addCase(updateExternalEvents.pending, (state) => {
        state.eventsLoading = true;
        state.error = null;
      })
      .addCase(updateExternalEvents.fulfilled, (state) => {
        state.eventsLoading = false;
      })
      .addCase(updateExternalEvents.rejected, (state, action) => {
        state.error = action.error.message;
        state.eventsLoading = false;
      })


      .addCase(deleteExternalEvents.pending, (state) => {
        state.eventsLoading = true;
        state.error = null;
      })
      .addCase(deleteExternalEvents.fulfilled, (state) => {
        state.eventsLoading = false;
      })
      .addCase(deleteExternalEvents.rejected, (state, action) => {
        state.error = action.error.message;
        state.eventsLoading = false;
      })


      .addCase(getEventCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getEventCategories.fulfilled, (state, action) => {
        state.categories = action.payload.categories || [];
        state.loading = false;
      })
      .addCase(getEventCategories.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })


      .addMatcher(isError, (state, action) => {
        state.error = action.payload || action.error.message || 'An error occurred';
        state.loading = false;
        state.eventsLoading = false;
      });
  },
});

export default externalEventsSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith('rejected');
}
