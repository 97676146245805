export const checkPermission = (
  permission: 'getAll' | 'put' | 'delete' | 'post',
  moduleName: string,
  userAccessPermissions: any,
): boolean => {
  if (!userAccessPermissions) {
    console.error('No userAccessPermissions provided.');
    return false; // Return false if userAccessPermissions doesn't exist
  }

  // Validate that userAccessPermissions has a valid menus array
  if (!Array.isArray(userAccessPermissions.menus)) {
    console.error('Invalid or missing "menus" array in userAccessPermissions:', userAccessPermissions);
    return false;
  }

  // Iterate through each menu to check permissions
  for (const menu of userAccessPermissions.menus) {
    if (!menu.isActive) {
      console.log(`Skipping inactive menu: ${menu.menuName}`);
      continue; // Skip inactive menus
    }

    // Iterate through submenus of the menu
    for (const subMenu of menu.subMenus) {
      if (!subMenu.isActive) {
        console.log(`Skipping inactive subMenu: ${subMenu.subMenuName}`);
        continue; // Skip inactive submenus
      }

      console.log(`Processing subMenu: ${subMenu.subMenuName}`);

      // Check if the module name matches the subMenuName (case-insensitive)
      if (subMenu.subMenuName?.toLowerCase() === moduleName?.toLowerCase()) {
        console.log(`Found matching subMenu: ${subMenu.subMenuName}`);

        // Find the permission in the permissions array
        const foundPermission = subMenu.permissions.find(
          (perm: any) => perm.permissionName === permission && perm.isActive,
        );

        if (foundPermission) {
          console.log(`Permission "${permission}" found and active in subMenu: ${subMenu.subMenuName}`);
          return true; // Permission found and is active
        } else {
          console.warn(`Permission "${permission}" not found or inactive in subMenu: ${subMenu.subMenuName}`);
        }
      }
    }
  }

  console.error(`Permission "${permission}" not found for module "${moduleName}".`);
  return false; // Return false if no matching permission is found
};
