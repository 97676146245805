import { createAsyncThunk } from '@reduxjs/toolkit';
import { createGroup, deleteGroup, getAllGroups, updateGroup } from 'src/utils/services/service/groupService';
import { getPropertiesForTenants } from 'src/utils/services/service/tenantService';

export const getMyGroups = createAsyncThunk<any>('groups/getMyGroups', async () => {
  try {
    const response = await getAllGroups();
    return response.data;
  } catch (error) {
    return error;
  }
});

export const getProperties_For_Tenants = createAsyncThunk('getPropertiesForTenants', async () => {
  try {
    const response = await getPropertiesForTenants();
    return response;
  } catch (error) {
    return error;
  }
});

export const create_Group = createAsyncThunk('groups/createGroup', async ({ data }: any) => {
  try {
    const response = await createGroup(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const delete_Group = createAsyncThunk('groups/deleteGroup', async ({ id }: { id: string }) => {
  try {
    const response = await deleteGroup(id);
    return response;
  } catch (error) {
    return error;
  }
});

export const update_group = createAsyncThunk('groups/updateGroup', async ({ data, id }: { data: any; id: string }) => {
  try {
    const response = await updateGroup(data, id);
    return response;
  } catch (error) {
    return error;
  }
});

// export const CreateGroup =
