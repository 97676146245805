import { Input } from 'antd';
import React from 'react';
import { IoSearchSharp } from 'react-icons/io5';

interface SearchBarProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  placeholder?: string;
  id?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ value, onChange, style, placeholder = 'Search...', id }) => {
  return (
    <div>
      <div className="">
        <Input
          id={id}
          allowClear
          value={value}
          onChange={onChange}
          prefix={<IoSearchSharp color="#ced4da" size={20} />}
          style={{
            backgroundColor: '#f8f9fa',
            borderRadius: 3,
            borderColor: '#ced4da',
            width: 500,
            padding: '10px',
            ...style,
          }}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default SearchBar;
