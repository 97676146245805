import React from 'react';
import { Spinner } from 'reactstrap';
import './index.scss'

interface LoaderOverlayProps {
  loading: boolean;
}

const LoaderOverlay: React.FC<LoaderOverlayProps> = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="loaderOverlayBlock">
          <Spinner color="light" />
        </div>
      )}
    </>
  );
};

export default LoaderOverlay;
