// Import axios for making HTTP requests and API configurations.
import axios from 'axios';
import api from '../axiosConfig';
import env from '../../../env';

// Retrieving API URLs from environment variables.
const apiKeyUrl = env.VITE_REACT_APP_API_KEYS_DEFAULT();
const usersUrl = env.VITE_REACT_APP_DEFAULTAUTH_USERS();

/**
 * Fetches API keys.
 * @returns A Promise resolving to the response containing API keys.
 */
export const getKeys = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      // Use the accessToken for Authorization header if available.
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };

    const response = await axios.get(`${apiKeyUrl}/api-keys`, { headers });
    return response;
  } catch (error) {
    console.error('Error fetching API keys:', error);
    // Handle or throw the error based on your error handling policy
    throw error;
  }
};

/**
 * Fetches a list of users.
 * @returns A Promise resolving to the data containing users list.
 */
export const getUsers = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await api.get(`/User?skip=0&take=100&searchKeyword=`, { headers, baseURL: usersUrl });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    // Handle or throw the error based on your error handling policy
    throw error;
  }
};

/**
 * Creates a new API key.
 * @param data The data for the new API key.
 * @returns A Promise resolving to the response of the creation request.
 */
export const createKey = async (data: any, tenant: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.post(`${apiKeyUrl}/api-keys`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        tenant: tenant,
      },
    });

    return response;
  } catch (error) {
    console.error('Error creating API key:', error);
    // Handle or throw the error based on your error handling policy
    throw error;
  }
};

/**
 * Edits an existing API key.
 * @param data The data to edit the API key with.
 * @returns A Promise resolving to the data of the edited API key.
 */
export const editKey = async (data: any) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.put(
      `${apiKeyUrl}/api-keys/${data.apiKey}`,
      { userId: data.userId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error editing API key:', error);
    // Handle or throw the error based on your error handling policy
    throw error;
  }
};

/**
 * Deletes an API key.
 * @param data The identifier for the API key to delete.
 * @returns A Promise resolving to the data of the deletion request.
 */
export const deleteKey = async (data: any) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.delete(`${apiKeyUrl}/api-keys/${data}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error deleting API key:', error);
    // Handle or throw the error based on your error handling policy
    throw error;
  }
};
