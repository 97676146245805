import axios from 'axios';
import { IimageManagement, IPagination } from 'src/utils/types/imageManagementTypes';
import env from 'src/env';
const baseURL = env.VITE_IMAGE_MANAGEMENT_DATA_DEFAULT_BASE_URL();

const imageManagementBaseUrl = '/marketing/settings/imagemanagement/images';

const getAllimageData = async (pageNumber: number,
  pageSize: number,): Promise<{ data: IimageManagement[]; pagination: IPagination } | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const params: {
      pageNumber: number;
      pageSize: number;

    } = { pageNumber, pageSize };

    const response = await axios.get(`${imageManagementBaseUrl}`, {
      headers,
      baseURL,
      params,
    });

    return response;
  } catch (error) {
    console.error('Error fetching all image Data:', error);
    throw error;
  }
};


const getSingleimageData = async (imageId: string): Promise<IimageManagement | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IimageManagement = await axios.get(`${imageManagementBaseUrl}/${imageId}/thumbnail`, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching single image Data:', error);
    throw error;
  }
};

const createimageData = async (data: IimageManagement): Promise<IimageManagement | null | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IimageManagement = await axios.post(`${imageManagementBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating image Data:', error);
    return error;
  }
};

const updateimageData = async (imageId: string, data: IimageManagement): Promise<IimageManagement | null | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response: IimageManagement = await axios.put(`${imageManagementBaseUrl}/${imageId}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating image  Data:', error);
    return error;
  }
};

const deleteimageData = async (imageId: string): Promise<string | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    await axios.delete(`${imageManagementBaseUrl}/${imageId}`, { headers, baseURL });
    return imageId;
  } catch (error) {
    console.error('Error deleting image  Data:', error);
    throw error;
  }
};

export const ImageManagementService = {
  getAllimageData,
  getSingleimageData,
  createimageData,
  updateimageData,
  deleteimageData,
};
