import { createSlice } from '@reduxjs/toolkit';
import {
CreateDataSet,
DeleteDataSet,
  GetAllDataSets,
  GetSingleDataSet,
UpdateDataSet,
} from './thunk';
import { IUploadedFile,IFileUpload } from 'src/utils/types/mLPreProcessingFlowTypes';

interface IUploadedFileState {
  dataSets: IUploadedFile[];
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IUploadedFileState = {
  dataSets: [],
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const preProcessingDataSetsSlice = createSlice({
  name: 'dataSets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllDataSets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllDataSets.fulfilled, (state, action) => {
        state.dataSets = action.payload as [];
        state.isLoading = false;
      })
      .addCase(GetAllDataSets.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleDataSet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleDataSet.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleDataSet.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateDataSet.pending, (state) => {
        state.isCreating = true;
      })
 
      .addCase(CreateDataSet.fulfilled, (state, action) => {
            const fileUpload = action.payload as IFileUpload;
            const newUploadedFile: IUploadedFile = {
              id: undefined, 
              name: fileUpload.file.name,
              uploaded_at: new Date().toISOString(),
              size: fileUpload.file.size.toString() 
            };
            state.dataSets.push(newUploadedFile);
            state.isCreating = false;
          })
          
      .addCase(CreateDataSet.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateDataSet.pending, (state) => {
        state.isUpdating = true;
      })

      .addCase(UpdateDataSet.fulfilled, (state, action) => {
            if (!Array.isArray(state.dataSets)) {
              console.error("dataSets is not an array, resetting to empty array");
              state.dataSets = [];
            }
          
            const fileUpload = action.payload as IFileUpload;
          
           
            if (!fileUpload || !fileUpload.file) {
              console.error("fileUpload is undefined or missing file property");
              return;
            }
          
            const index = state.dataSets.findIndex((file) => file.id === fileUpload.id);
            
            if (index !== -1) {
            
              state.dataSets[index] = {
                ...state.dataSets[index],
                name: fileUpload.file.name,
                uploaded_at: new Date().toISOString(),
                size: fileUpload.file.size.toString(),
              };
            } else {
            
              state.dataSets.push({
                id: fileUpload.id,
                name: fileUpload.file.name,
                uploaded_at: new Date().toISOString(),
                size: fileUpload.file.size.toString(),
              });
            }
          
            state.isUpdating = false;
          })
          
          
          
          
      .addCase(UpdateDataSet.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isUpdating = false;
      })
      .addCase(DeleteDataSet.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteDataSet.fulfilled, (state, action) => {
        const deletedContactId = action.payload as string;
        state.dataSets = state.dataSets.filter((contact) => contact.id !== deletedContactId);
        state.isDeleting = false;
      })
      .addCase(DeleteDataSet.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isDeleting = false;
      });
  },
});

export default preProcessingDataSetsSlice.reducer;
