import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { notification, Tooltip, Pagination } from 'antd';
import { IManageHotel, IPagination } from 'src/utils/types/manageHotelTypes';
import ManageHotelModal from './components/ManageHotelModal';
import { FiEdit, FiEye } from 'react-icons/fi';

import TableView from 'src/components/Common/TableView/TableView';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { GetAllManageHotelData, DeleteManageHotelData } from 'src/slices/ManageHotel/thunk';
import AbbreviatedId from 'src/components/Common/AbbreviatedId';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { useTranslation } from 'react-i18next';
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';

const ManageHotel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<IManageHotel | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isViewMode, setIsViewMode] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const [viewData, setViewData] = useState<IManageHotel | null>(null);
  const [api, contextHolder] = notification.useNotification();

  const { t } = useTranslation('');

  const { isLoading, data } = useSelector((state: RootState) => state.manageHotel);
  const dispatch: AppDispatch = useDispatch();


  useEffect(() => {
    if (!isLoading && data.length === 0) {
      dispatch(GetAllManageHotelData({ page: pageNumber, pageSize })).then((action: any) => {
        if (action.payload) {
          setPagination(action.payload.pagination);
        }
      });

    }
  }, [pageNumber, pageSize]);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const columns: TableProps<IManageHotel>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 100,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <Tooltip title={t('View')}>
              <div
                className="tableIcon"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsViewMode(true);
                  setViewData(record);
                }}
                aria-label={t('View')}
              >
                <FiEye id="viewManageHotel" size={20} />
              </div>
            </Tooltip>

            <Tooltip title={t('Edit')}>
              <div
                className="tableIcon"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsEditMode(true);
                  setEditingData(record);
                }}
                aria-label={t('Edit')}
              >
                <FiEdit id="updateManageHotel" size={20} />
              </div>
            </Tooltip>
            <Tooltip title={t('Delete')}>
              <div className="tableIcon">
                <DeleteButton
                  title={t('Are you sure you want to delete?')}
                  cancelText={t('Cancel')}
                  okText={t('Proceed')}
                  okButtonId={`confirmAndDeleteManageHotelData`}
                  triggerButtonId="deleteManageHotelData"
                  onConfirm={() => {
                    deleteManageHotelData(record.id!);
                  }}
                  aria-label={t('Delete')}
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      title: t('Category'),
      dataIndex: 'category',
      key: 'category',
      width: 100,
    },
    {
      title: t('Name'),
      key: 'name',
      dataIndex: 'name',
      width: 100,
    },

    {
      title: t('Kind'),
      dataIndex: 'kind',
      key: 'kind',
      width: 100,
    },
    {
      title: t('Segment'),
      dataIndex: 'segment',
      key: 'segment',
      width: 100,
    },
  ];
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  const deleteManageHotelData = async (hoteldataId: string) => {
    if (!hoteldataId) {
      openNotificationWithIcon('error', t('Price id not found'));
      return;
    }
    try {
      await dispatch(
        DeleteManageHotelData({
          hoteldataId,
        }),
      );
      openNotificationWithIcon('success', t('Hotel data deleted successfully'));
      dispatch(GetAllManageHotelData({ page: pageNumber, pageSize }));


    } catch (error: any) {
      console.error('Error deleting Hotel Data:', error);
      if (error?.response) {
        console.error('Response Data:', error?.response?.data);
      }
      openNotificationWithIcon('error', t('Error deleting Hotel data'));
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>{t('Manage Hotel')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title={t('Settings')} breadcrumbItem={t('Manage Hotel')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <Tooltip title={t('Search hotel by name, category, kind')}>
                          <div>
                            <SearchBar
                              id="manageHotelSearchBar"
                              value={searchValue}
                              onChange={handleSearchChange}
                              placeholder={t('Search hotel by name, category, kind')}
                            />
                          </div>
                        </Tooltip>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Tooltip title={t('Add')} placement="top">
                              <Button
                                id="addManageHotel"
                                className="btn btn-light"
                                onClick={() => {
                                  setIsModalOpen(!isModalOpen);
                                }}
                              >
                                <i className="uil uil-plus me-1"></i> {t('Add')}
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableViewPagination
                        loading={isLoading}
                        columns={columns}
                        dataSource={
                          Array.isArray(data)
                            ? data
                              ?.filter((data: IManageHotel) => {
                                if (searchValue === '') return data;
                                else if (
                                  data.category?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                  data.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                  data.kind?.toLowerCase().includes(searchValue.toLowerCase())
                                ) {
                                  return data;
                                }
                              })
                              ?.reverse()
                            : []
                        }
                        showPagination={false}
                      />

                      <Pagination
                        current={pageNumber}
                        pageSize={pageSize}
                        total={pagination?.totalCount || 0}
                        onChange={(page, pageSize) => {
                          setPageNumber(page);
                          setPageSize(pageSize);
                          dispatch(GetAllManageHotelData({ page, pageSize }));
                        }}
                        style={{ float: 'right', marginTop: '20px' }}
                        showSizeChanger
                        pageSizeOptions={['10', '20', '30', '50', '100']}
                      />


                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <ManageHotelModal
              pagination={pagination}

              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
              isViewMode={isViewMode}
              setIsViewMode={setIsViewMode}
              initialViewValues={viewData}
              setViewData={setViewData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ManageHotel;
