/* eslint-disable react-hooks/exhaustive-deps */
import { notification, Tag, Tooltip, Pagination } from 'antd';
import { TableProps } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import TableView from 'src/components/Common/TableView/TableView';
import { RootState } from 'src/slices';
import { AppDispatch } from 'src/store';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { IText, IPagination } from 'src/utils/types/textTypes';
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TextsModal from './components/TextsModal';
import { useTranslation } from 'react-i18next';
import { DeleteTextEntry, GetAllTexts } from 'src/slices/Texts/thunk';
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';

const Texts = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [editingData, setEditingData] = React.useState<IText | null>(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [api, contextHolder] = notification.useNotification();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const { isLoading, data, isDeleting } = useSelector((state: RootState) => state.texts);
  const dispatch: AppDispatch = useDispatch();

  const { t } = useTranslation('');

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
      duration: 2,
      closable: true,
    });
  };

  useEffect(() => {
    if (!isLoading && data.length === 0) {
      dispatch(GetAllTexts({ page: pageNumber, pageSize })).then((action: any) => {
        if (action.payload) {
          setPagination(action.payload.pagination);
        }
      });
    }
  }, [pageNumber, pageSize]);
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const columns: TableProps<IText>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 50,
      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <Tooltip title={t('Edit')} placement="top">
              <div
                className="tableIcon"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsEditMode(true);
                  setEditingData(record);
                }}
                aria-label={t('Edit Texts')}
              >
                <FiEdit id="updateTexts" size={20} />
              </div>
            </Tooltip>
            <Tooltip title={t('Delete')} placement="top">
              <div className="tableIcon">
                <DeleteButton
                  title={t('Are you sure you want to delete?')}
                  cancelText={t('Cancel')}
                  okText={t('Proceed')}
                  okButtonId={`confirmAndDeleteTexts`}
                  triggerButtonId="deleteText"
                  onConfirm={() => {
                    deleteText(record.id as string);
                  }}
                  aria-label={t('Delete Texts')}
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: t('Content'),
      dataIndex: 'content',
      key: 'content',
      width: 100,
    },
    {
      title: t('Hashtags'),
      dataIndex: 'hashtags',
      key: 'hashtags',
      width: 100,
      render: (hashtags: string[]) => (
        <div>
          {hashtags.map((hashtags, i) => (
            <Tag
              style={{
                margin: '5px 5px',
              }}
              key={i}
            >
              #{hashtags}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: t('Keywords'),
      dataIndex: 'keywords',
      key: 'keywords',
      width: 100,
      render: (keywords: string[]) => (
        <div>
          {keywords.map((keyword, i) => (
            <Tag
              style={{
                margin: '5px 5px',
              }}
              key={i}
            >
              {keyword}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: t('Enabled'),
      dataIndex: 'enabled',
      key: 'enabled',
      width: 40,
      render: (enabled: boolean) => (
        <div>
          <span>{enabled ? 'Yes' : 'No'}</span>
        </div>
      ),
    },
  ];

  const deleteText = async (textId: string) => {
    if (!textId) {
      openNotificationWithIcon('error', t('Text id is required'));
      return;
    }
    try {
      const response: any = await dispatch(
        DeleteTextEntry({
          textId,
        }),
      );
      if (response?.payload?.status === 204) {
        openNotificationWithIcon('success', t('Text deleted successfully'));
        dispatch(GetAllTexts({ page: pageNumber, pageSize }));
      } else {
        openNotificationWithIcon('error', t('Error deleting Text'));
      }
    } catch (error) {
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>{t('Texts')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title={t('Settings')} breadcrumbItem={t('Texts')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <Tooltip title={t('Search texts by contents , keywords , hashtags')}>
                          <div>
                            <SearchBar
                              id="textsSearchBar"
                              value={searchValue}
                              onChange={handleSearchChange}
                              placeholder={t('Search texts by contents , keywords , hashtags')}
                            />
                          </div>
                        </Tooltip>
                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Tooltip title={t('Add')}>
                              <Button
                                id="createTexts"
                                className="btn btn-light"
                                onClick={() => {
                                  setIsModalOpen(!isModalOpen);
                                }}
                              >
                                <i className="uil uil-plus me-1"></i> {t('Add')}
                              </Button>
                            </Tooltip>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >
                      <TableViewPagination
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={
                          Array.isArray(data)
                            ? data.filter((text) => {
                                if (searchValue === '') {
                                  return true; // Return true to include the text if the search value is empty
                                } else {
                                  const searchLower = searchValue.toLowerCase();
                                  return (
                                    text?.content?.toLowerCase()?.includes(searchLower) ||
                                    text?.keywords?.join()?.toLowerCase()?.includes(searchLower) ||
                                    text?.hashtags?.join()?.toLowerCase()?.includes(searchLower)
                                  );
                                }
                              })
                            : []
                        }
                        showPagination={false}
                      />

                      <Pagination
                        current={pageNumber}
                        pageSize={pageSize}
                        total={pagination?.totalCount || 0}
                        onChange={(page, pageSize) => {
                          setPageNumber(page);
                          setPageSize(pageSize);
                          dispatch(GetAllTexts({ page, pageSize }));
                        }}
                        style={{ float: 'right', marginTop: '20px' }}
                        showSizeChanger
                        pageSizeOptions={['10', '20', '30', '50', '100']}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <TextsModal
              pagination={pagination}
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Texts;
