import axios from 'axios';
import { ISearchHotelResponse } from 'src/utils/types/searchTypes';

export const HotelService = {
  async searchHotels(
    query: string,
    checkin: string,
    checkout: string,
    numberOfRooms: number,
    offset: number,
  ): Promise<ISearchHotelResponse> {
    try {
      // Build the query parameters using URLSearchParams
      const params = new URLSearchParams({
        url: query,
        checkin,
        checkout,
        group_adults: numberOfRooms.toString(),
      });

      // Construct the full URL with query parameters
      const fullUrl = `https://api.dev.competitoranalysis.data.projects.excitingdevs.com/get_hotel_rooms?${params.toString()}`;

      // Make the API request using the constructed URL
      const response = await axios.get<ISearchHotelResponse>(fullUrl);

      return response.data;
    } catch (error) {
      console.error('Error fetching hotel search results:', error);
      throw new Error('Failed to fetch hotel search results');
    }
  },
};
