


import { createAsyncThunk } from '@reduxjs/toolkit';
import { PreProcessedDataSetsService } from 'src/utils/services/service/mLPreProcessingFlowService';

import { InitiateTrainingState } from 'src/utils/types/mLPreProcessingFlowTypes';

export const InitiateModelTraining = createAsyncThunk(
      'dataSets/initiateModelTraining',
      async ({ data }: { data: InitiateTrainingState }) => {
            try {
                  const response = await PreProcessedDataSetsService.initiateModelTraining(data);
                  return response;
                } catch (error) {
                  throw new Error('Failed to initiateModelTraining: ' + error.message);
                }
                
      }
    );