import { createSlice } from '@reduxjs/toolkit';
import {
  CreateNotificationContact,
  DeleteNotificationContact,
  GetAllNotificationContacts,
  GetSingleNotificationContacts,
  UpdateNotificationContact,
} from './thunk';
import { INotificationContacts, IPagination } from 'src/utils/types/notificatioinContactsTypes';

interface INotificationContactsState {
  data: INotificationContacts[];
  pagination: IPagination;
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: INotificationContactsState = {
  data: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const notificationContactsSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllNotificationContacts.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(GetAllNotificationContacts.fulfilled, (state, action: any) => {
        if (action.payload) {
          const { data, pagination } = action.payload;
          state.data = data;
          state.pagination = pagination;
        } else {
          state.data = [];
          state.pagination = { currentPage: 1, pageSize: 10, totalPages: 0, totalCount: 0, hasPrevious: false, hasNext: false };
        }
        state.isLoading = false;
      })
      .addCase(GetAllNotificationContacts.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleNotificationContacts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleNotificationContacts.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleNotificationContacts.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateNotificationContact.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateNotificationContact.fulfilled, (state, action) => {
        state.data.push(action.payload as INotificationContacts);
        state.isCreating = false;
      })
      .addCase(CreateNotificationContact.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateNotificationContact.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateNotificationContact.fulfilled, (state, action) => {
        const updatedContact = action.payload as INotificationContacts;
        const index = state.data.findIndex((contact) => contact.id === updatedContact.id);
        state.data[index] = updatedContact;
        state.isUpdating = false;
      })
      .addCase(UpdateNotificationContact.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isUpdating = false;
      })
      .addCase(DeleteNotificationContact.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteNotificationContact.fulfilled, (state, action) => {
        const deletedContactId = action.payload as string;
        state.data = state.data.filter((contact) => contact.id !== deletedContactId);
        state.isDeleting = false;
      })
      .addCase(DeleteNotificationContact.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isDeleting = false;
      });
  },
});

export default notificationContactsSlice.reducer;
