import api from '../axiosConfig';
import env from '../../../env';

const userApi = env.VITE_REACT_APP_DEFAULTAUTH_USERS() || '';

export const getAllGroups = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await api.get(`/Group`, { headers, baseURL: userApi });
  return res;
};

export const createGroup = async (creationData: any) => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    throw new Error('No access token found');
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json', // Ensure the content type is set
  };

  try {
    const res = await api.post('/Group', creationData, { headers, baseURL: userApi });
    return res;
  } catch (error) {
    console.error('Error creating group:', error); // Debugging line
    throw error;
  }
};

export const updateGroup = async (updatingData: any, groupId: string) => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    throw new Error('No access token found');
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json', // Ensure the content type is set
  };

  try {
    const res = await api.put(`/Group/${groupId}`, updatingData, { headers, baseURL: userApi });
    return res;
  } catch (error) {
    console.error('Error updating group:', error); // Debugging line
    throw error;
  }
};

export const deleteGroup = async (id: string) => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    throw new Error('No access token found');
  }

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  };

  try {
    const res = await api.delete(`/Group/${id}`, { headers, baseURL: userApi });
    return res;
  } catch (error) {
    console.error('Error creating group:', error); // Debugging line
    throw error;
  }
};
