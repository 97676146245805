import axios from 'axios';
import env from 'src/env';
import { IRoomConfiguration, IPagination } from 'src/utils/types/roomConfigurationsTypes';

const baseURL = env.VITE_ROOM_CONFIGURATIONS_BASE_URL();
const roomPlaneGETBaseUrl = '/room-config/room-configurations/rooms';
const roomPlaneBaseUrl = '/room-config/room-configurations';

const getAllRoomConfigurations = async (
  pageNumber: number,
  pageSize: number,
  searchField?: string,
  searchTerm?: string,
): Promise<{ roomConfigurations: IRoomConfiguration[]; pagination: IPagination } | null | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const params: {
      pageNumber: number;
      pageSize: number;
      searchField?: string;
      searchTerm?: string;
    } = { pageNumber, pageSize };

    if (searchField && searchField.trim()) {
      params.searchField = searchField;
    }
    if (searchTerm && searchTerm.trim()) {
      params.searchTerm = searchTerm;
    }

    const response = await axios.get(`${roomPlaneGETBaseUrl}`, {
      headers,
      baseURL,
      params,
    });

    return response;
  } catch (error) {
    console.error('Error fetching all room configurations:', error);
    throw error;
  }
};

const getRoomConfigurationById = async (roomConfigurationId: string): Promise<IRoomConfiguration | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IRoomConfiguration = await axios.get(`${roomPlaneBaseUrl}/${roomConfigurationId}`, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error fetching room configuration by ID:', error);
    throw error;
  }
};

const createRoomConfiguration = async (roomConfiguration: IRoomConfiguration): Promise<IRoomConfiguration | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IRoomConfiguration = await axios.post(`${roomPlaneBaseUrl}`, roomConfiguration, {
      headers,
      baseURL,
    });
    return response;
  } catch (error) {
    console.error('Error creating room configuration:', error);
    throw error;
  }
};

const updateRoomConfiguration = async (roomConfiguration: IRoomConfiguration): Promise<IRoomConfiguration | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const response: IRoomConfiguration = await axios.put(
      `${roomPlaneBaseUrl}/${roomConfiguration.id}`,
      roomConfiguration,
      {
        headers,
        baseURL,
      },
    );
    return response;
  } catch (error) {
    console.error('Error updating room configuration:', error);
    throw error;
  }
};

const deleteRoomConfiguration = async (roomConfigurationId: string): Promise<string | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    await axios.delete(`${roomPlaneBaseUrl}/${roomConfigurationId}`, { headers, baseURL });
    return roomConfigurationId;
  } catch (error) {
    console.error('Error deleting room configuration:', error);
    throw error;
  }
};

export const roomPlanService = {
  getAllRoomConfigurations,
  getRoomConfigurationById,
  createRoomConfiguration,
  updateRoomConfiguration,
  deleteRoomConfiguration,
};
