export function getTenantId (tokenData?: { iss?: string }): string | undefined {
  const iss = tokenData?.iss;
  const parts = iss?.split('/');
  return parts && parts[parts.length - 1];
}

export const getDateNow = () => {
  const originalDate = new Date();
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0');
  const day = String(originalDate.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`
}

