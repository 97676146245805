import { createAsyncThunk } from '@reduxjs/toolkit';
import { RoomInformationService } from 'src/utils/services/service/roomInformationService';
import { IRoomInformation, IPagination } from 'src/utils/types/roomInformationTypes';



export const GetAllRoomConfigurations = createAsyncThunk<
  { roomInformation: IRoomInformation[]; pagination: IPagination },
  { page: number; pageSize: number; }
>(
  'roomInformation/getAllRoomConfigurations',
  async ({ page, pageSize }, { rejectWithValue }) => {
    try {
      const response = await RoomInformationService.getAllRoomConfigurations(page, pageSize);
      console.log(response);
      if (!response || !response.roomInformation || !response.pagination) {
        throw new Error('Invalid response structure');
      }

      return response;
    } catch (error: any) {
      console.error('Error fetching all room information:', error);
      return rejectWithValue(error.message);
    }
  },
);