import { createSlice } from '@reduxjs/toolkit';
import { fetchModelTrainingStatus } from './thunk'; 

interface TrainingStatusState {
  training_id: string | null;
  status: string | null;
  progress: number | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: TrainingStatusState = {
  training_id: null,
  status: null,
  progress: null,
  isLoading: false,
  error: null,
};

const modelTrainingStatusSlice = createSlice({
  name: 'modelTrainingStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModelTrainingStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchModelTrainingStatus.fulfilled, (state, action) => {
        state.training_id = action.payload.training_id;
        state.status = action.payload.status;
        state.progress = action.payload.progress;
        state.isLoading = false;
      })
      .addCase(fetchModelTrainingStatus.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      });
  },
});

export default modelTrainingStatusSlice.reducer;
