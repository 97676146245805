import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { changeApiKey, createNewKey, deleteApiKey, getApiKeys, getKeycloakUsers } from "./thunk";
import { IApiKeysTypesResponse } from 'src/utils/types/apiKeysTypes';
import { IUserResponse } from 'src/utils/types/userTypes';

interface ContactsStateType {
    apiKeys: Array<IApiKeysTypesResponse>,
    keycloakUsers: { users: IUserResponse[] },
    error?: string | object | null | undefined | unknown,
    loading: boolean
}
export const initialState: ContactsStateType = {
    apiKeys: [],
    keycloakUsers: { users: [] },
    error: '',
    loading: false
};

const keysSlice = createSlice({
    name: "keys",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getApiKeys.pending, (state) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(getApiKeys.fulfilled, (state, action: PayloadAction<any>) => {
                state.apiKeys = action.payload.apiKeys;
                state.loading = false;
            })

            .addCase(getKeycloakUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })

            .addCase(getKeycloakUsers.fulfilled, (state, action: PayloadAction<any>) => {
                state.keycloakUsers = action.payload;
                state.loading = false;
            })

            .addCase(createNewKey.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(createNewKey.fulfilled, (state, action) => {
                state.apiKeys.push(action.payload);
                state.loading = false;
            })

            .addCase(changeApiKey.fulfilled, (state, action) => {
                 const changed = state.apiKeys.find(key => key.id === action.payload.id);
            })

            .addCase(deleteApiKey.fulfilled, (state, action) => {
                // const user = state.users.find(user => user.id === action.payload.id);
                state.apiKeys = state.apiKeys.filter(item => item.id! === action.payload)
            })


            .addMatcher(isError, (state, action: PayloadAction<any>) => {
                state.error = action.payload;
                state.loading = false;
            });
    }

});

export default keysSlice.reducer;

function isError(action: AnyAction) {
    return action.type.endsWith('rejected');
}
