import { createSlice } from '@reduxjs/toolkit';
import { GetAccessDetailsBYUserId, GetAllAccessDetails, UpdateAccessDetailsForAUser, AddUserToRole } from './thunk';

interface IAccessDetailsState {
  AccessInFormation: any | null;
  AllAccessDetails: any | null;
  isLoading: boolean;
  isAllAccessDetailsLoading: boolean;
  isFailedToLoadAccessInformation: boolean;
  isUpdatingAccessDetails: boolean;
  isUserAddingToRole: boolean;
}

const initialState: IAccessDetailsState = {
  AccessInFormation: null,
  AllAccessDetails: null,
  isFailedToLoadAccessInformation: false,
  isLoading: true,
  isAllAccessDetailsLoading: false,
  isUpdatingAccessDetails: false,
  isUserAddingToRole: false,
};

const AccessDetailsSlice = createSlice({
  name: 'AccessDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAccessDetailsBYUserId.pending, (state) => {
        state.AccessInFormation = null;
        state.isFailedToLoadAccessInformation = false;
        state.isLoading = true;
      })
      .addCase(GetAccessDetailsBYUserId.fulfilled, (state, action) => {
        state.AccessInFormation = action.payload;
        state.isLoading = false;
        state.isFailedToLoadAccessInformation = false;
      })
      .addCase(GetAccessDetailsBYUserId.rejected, (state) => {
        state.AccessInFormation = null;
        state.isFailedToLoadAccessInformation = true;
        state.isLoading = false;
      })
      .addCase(GetAllAccessDetails.pending, (state) => {
        state.AllAccessDetails = null;
        state.isAllAccessDetailsLoading = true;
      })
      .addCase(GetAllAccessDetails.fulfilled, (state, action) => {
        state.AllAccessDetails = action.payload;
        state.isAllAccessDetailsLoading = false;
      })
      .addCase(GetAllAccessDetails.rejected, (state) => {
        state.AllAccessDetails = null;
        state.isAllAccessDetailsLoading = false;
      })
      .addCase(UpdateAccessDetailsForAUser.pending, (state) => {
        state.isUpdatingAccessDetails = true;
      })
      .addCase(UpdateAccessDetailsForAUser.fulfilled, (state) => {
        state.isUpdatingAccessDetails = false;
      })
      .addCase(UpdateAccessDetailsForAUser.rejected, (state) => {
        state.isUpdatingAccessDetails = false;
      })
      .addCase(AddUserToRole.pending, (state) => {
        state.isUserAddingToRole = true;
      })
      .addCase(AddUserToRole.fulfilled, (state) => {
        state.isUserAddingToRole = false;
      })
      .addCase(AddUserToRole.rejected, (state) => {
        state.isUserAddingToRole = false;
      });
  },
});

export default AccessDetailsSlice.reducer;
