import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, DatePicker, Button, Tabs, Select, notification, Tooltip } from 'antd';
import { Column, DualAxes, Line } from '@ant-design/plots';
import './index.scss';

import { useDispatch, useSelector } from 'react-redux';
import { getProperties } from 'src/slices/Properties/thunk';
import { atom, useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import { RootState } from 'src/slices';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

interface INewOccupancyRate {
  Date: string;
  RoomType: string;
  Count: number;
  AvgOccupancyRate: number;
  BookingsCancelled: number;
  AvgTotalRate: number;
  SunnyDayBookings: number;
  RainyDayBookings: number;
}

interface IOccupancyRate {
  occupancyRates: INewOccupancyRate[];
  loading: boolean;
}

const OccupancyRateLineChart = ({ occupancyRates, loading }: IOccupancyRate) => {
  const config = {
    data: occupancyRates,
    xField: 'Date',
    yField: 'AvgOccupancyRate',
    smooth: true,
    loading,
    slider: {
      x: {
        labelFormatter: (d: any) => new Date(d).toLocaleDateString(),
      },
    },
  };
  return <Line {...config} />;
};

const BookingGroupChart = ({ occupancyRates, loading }: IOccupancyRate) => {
  const data: { Date: string; Value: number; Type: string }[] = [];

  occupancyRates.forEach((element) => {
    data.push({
      Date: element.Date,
      Value: element.BookingsCancelled,
      Type: 'Bookings Cancelled - ' + element.RoomType,
    });
    data.push({
      Date: element.Date,
      Value: element.Count,
      Type: 'Total Bookings - ' + element.RoomType,
    });
  });

  const config = {
    xField: 'Date',
    yField: 'Value',
    colorField: 'Type',
    group: true,
    style: {
      inset: 5,
    },
    loading,
    data,
    slider: {
      x: {
        labelFormatter: (d: any) => new Date(d).toLocaleDateString(),
      },
    },
  };
  return <Column {...config} />;
};

const RateWithWeather = ({ occupancyRates, loading }: IOccupancyRate) => {
  const uvBillData: { time: string; count: number; name: string }[] = [];
  occupancyRates.forEach((element) => {
    uvBillData.push({
      time: element.Date,
      count: element.SunnyDayBookings,
      name: 'SunnyDayBookings',
      // name: "SunnyDayBookings - " + element.RoomType,
    });
    uvBillData.push({
      time: element.Date,
      count: element.RainyDayBookings,
      name: 'RainyDayBookings',
      // name: "RainyDayBookings - " + element.RoomType,
    });
  });

  const transformData: { time: string; count: number; name: string }[] = [];
  occupancyRates.forEach((element) => {
    transformData.push({
      time: element.Date,
      count: element.AvgOccupancyRate,
      name: 'AvgOccupancyRate',
      // name: "AvgOccupancyRate - "  + element.RoomType,
    });
  });

  const config = {
    xField: 'time',
    children: [
      {
        data: uvBillData,
        yField: 'count',
        colorField: 'name',
        stack: true,
        style: { maxWidth: 80 },
        scale: { y: { domainMax: 1200 } },
        interaction: { elementHighlightByColor: { background: true } },
      },
      {
        data: transformData,
        type: 'line',
        yField: 'count',
        colorField: 'name',
        style: {
          lineWidth: 2,
        },
        axis: { y: { position: 'right' } },
        interaction: {
          tooltip: {
            crosshairs: false,
            marker: false,
          },
        },
      },
    ],
    loading,
    slider: {
      x: {
        labelFormatter: (d: any) => new Date(d).toLocaleDateString(),
      },
    },
  };
  return <DualAxes {...config} />;
};

interface IAnomanyEntry {
  DateCreated: string;
  AvgTotalRate?: number;
  AvgOccupancyRate?: number;
  Anomalies?: number;
  Score: number;
  Baseline: number;
}

interface IAnomalyChart {
  anomalies: IAnomanyEntry[];
  targetYField: keyof IAnomanyEntry;
  loading: boolean;
}

const AnomalyChart = ({ anomalies, targetYField, loading }: IAnomalyChart) => {
  // we remove the Anomalies field if the value of the field is 0
  const anomaliesProcessed = anomalies.map((anomaly) => {
    if (anomaly.Anomalies === 0) {
      delete anomaly.Anomalies;
    } else if (anomaly.Anomalies === 1) {
      anomaly.Anomalies = anomaly.AvgTotalRate;
    }
    return anomaly;
  });

  const config = {
    loading,
    data: anomaliesProcessed,
    xField: 'DateCreated',
    legend: true,
    children: [
      {
        type: 'line',
        yField: targetYField,
        style: {
          stroke: '#5B8FF9',
          lineWidth: 2,
        },
        axis: {
          y: {
            title: targetYField,
            style: { titleFill: '#5B8FF9' },
          },
        },
      },
      {
        type: 'line',
        yField: 'Baseline',
        style: {
          stroke: '#5AD8A6',
          lineWidth: 2,
        },
        axis: {
          y: {
            position: 'right',
            title: 'Baseline',
            style: { titleFill: '#5AD8A6' },
          },
        },
      },
    ],
    annotations: [
      {
        type: 'point',
        xField: 'DateCreated',
        yField: 'Anomalies',
        shapeField: 'point',
        sizeField: 4,
        style: { stroke: 'red' },
        tooltip: false,
      },
    ],
    slider: {
      x: {
        labelFormatter: (d: any) => new Date(d).toLocaleDateString(),
      },
    },
  };
  return <DualAxes {...config} />;
};

interface IRequest {
  url: string;
  values: IAnomalyForm;
}

interface IAnomalyForm {
  dateRange: [any, any];
  mendantId: string;
}

const Anomaly = () => {
  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm<IAnomalyForm>();
  const [anomalies, setAnomalies] = useState<IAnomanyEntry[]>([]);
  const [anomalies1, setAnomalies1] = useState<IAnomanyEntry[]>([]);
  const [anomaliesNew, setAnomaliesNew] = useState<INewOccupancyRate[]>([]);

  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const { properties, selectedProperty, loading: loadingMandant } = useSelector((state: any) => state.porperty);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    dispatch(getProperties());
  };

  const formStateAtom = atom({
    key: 'formState',
    default: {
      dateRange: [new Date(), new Date()],
      mendantId: '',
    },
  });
  const [formState, setFormState] = useRecoilState(formStateAtom);

  const fetchData = async ({ url, values }: IRequest) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        StartDate: values.dateRange[0].format('YYYY-MM-DD'),
        EndDate: values.dateRange[1].format('YYYY-MM-DD'),
        MandantId: Number.parseInt(selectedProperty?.value),
      }),
    };

    const response = await fetch(url, requestOptions);
    const data = await response.json();

    // if (!response.ok) {
    //   api.error({
    //     message: data?.error?.message || 'Failed to fetch',
    //   });
    //   throw new Error(data?.error?.message || 'Failed to fetch');
    // }

    // api.success({
    //   message: 'Data fetched successfully',
    //   // description: 'Fetched url' + url,
    // });
    return data;
  };

  const mutation1 = useMutation(fetchData);
  const mutation2 = useMutation(fetchData);
  const mutation3 = useMutation(fetchData);

  const url1 =
    'https://prod-03.germanywestcentral.logic.azure.com:443/workflows/49f86f9d276a4cb89741c3efc51ee94c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=j10cy1YLsLg8f5qsamOldyqYCAMQuY5x5MbcDlcoYCU';
  const url2 =
    'https://prod-06.germanywestcentral.logic.azure.com:443/workflows/5de7c2ccf8134aacbe663e02b3a45a57/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=w9dpei3TedN3QDZA0g0l2pGCN54HjvRg8NpNz1-hOYw';
  const url3 =
    'https://prod-23.germanywestcentral.logic.azure.com:443/workflows/eaec96824c2e4c35abe17d26099f1eac/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Cp0zh9SO5f5x8d6d9cmyPh0hHqKr47284LCzcQxl1YI';

  const onFinish = (_: any) => {
    form.validateFields();
    const values = form.getFieldsValue();

    mutation1.mutate({ url: url1, values: values });
    mutation2.mutate({ url: url2, values: values });
    mutation3.mutate({ url: url3, values: values });
  };

  useEffect(() => {
    if (mutation1.data) {
      setAnomalies(mutation1.data.value);
    }
  }, [mutation1.data]);

  useEffect(() => {
    if (mutation2.data) {
      setAnomalies1(mutation2.data.value);
    }
  }, [mutation2.data]);

  useEffect(() => {
    if (mutation3.data) {
      setAnomaliesNew(mutation3.data.value);
    }
  }, [mutation3.data]);

  return (
    <>
      {contextHolder}
      <div className="page-content">
        <MetaTags>
          <title>{t('Anomaly Detection')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title={t('Dashboard')} breadcrumbItem={t('Anomaly Detection')} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Form layout="inline" form={form} onFinish={onFinish}>
                      <Form.Item
                        rules={[{ required: true, message: t('Please select a date range') }]}
                        name="dateRange"
                        label={t('Date Range')}
                      >
                        <RangePicker />
                      </Form.Item>
                      {/* <Form.Item
                        rules={[{ required: true, message: 'Please type in a mendant id' }]}
                        name="mendantId"
                        label="Mendant ID"
                      >
                        <Select style={{ width: '100px' }} options={properties} showSearch />
                      </Form.Item> */}
                      <Form.Item>
                        <Button
                          loading={mutation1.isLoading || mutation2.isLoading || mutation3.isLoading}
                          disabled={loadingMandant}
                          type="primary"
                          htmlType="submit"
                          aria-label={t('Submit')}
                          title={t('Submit')}
                        >
                          {t('Submit')}
                        </Button>
                      </Form.Item>
                    </Form>
                  </Row>
                  <Tabs type="card">
                    <Tabs.TabPane
                      disabled={mutation1.isLoading}
                      key="1"
                      tab={
                        <Tooltip title={t('Anomaly Detection (Avg. Total Rate)')}>
                          {t('Anomaly Detection (Avg. Total Rate)')}
                        </Tooltip>
                      }
                    >
                      <AnomalyChart loading={mutation1.isLoading} anomalies={anomalies} targetYField="AvgTotalRate" />
                    </Tabs.TabPane>
                    {/* <Tabs.TabPane disabled={mutation2.isLoading} key="11" tab="Anomaly Detection (Ocupancy Rate)">
                      <AnomalyChart
                        loading={mutation2.isLoading}
                        anomalies={anomalies1}
                        targetYField="AvgOccupancyRate"
                      />
                    </Tabs.TabPane> */}
                    <Tabs.TabPane
                      disabled={mutation3.isLoading}
                      key="2"
                      tab={<Tooltip title={t('Occupancy Rate')}>{t('Occupancy Rate')}</Tooltip>}
                    >
                      <OccupancyRateLineChart loading={mutation3.isLoading} occupancyRates={anomaliesNew} />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                      disabled={mutation3.isLoading}
                      key="3"
                      tab={<Tooltip title={t('Booking by Groups')}>{t('Booking by Groups')}</Tooltip>}
                    >
                      <BookingGroupChart loading={mutation3.isLoading} occupancyRates={anomaliesNew} />
                    </Tabs.TabPane>
                    {/* <Tabs.TabPane key="4" tab="Rate with Weather">
                                            <RateWithWeather loading={mutation3.isLoading} occupancyRates={anomaliesNew} />
                                        </Tabs.TabPane> */}
                  </Tabs>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Anomaly;
