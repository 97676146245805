import { createAsyncThunk } from '@reduxjs/toolkit';
import { processPricingPrediction } from 'src/utils/services/service/dashboardService';

export const processPricingPredictionSlice = createAsyncThunk<any, any>(
  'dashboard/processPricingPrediction',
  async (data) => {
    try {
      return await processPricingPrediction(data);
    } catch (error) {
      throw error;
    }
  },
);

