import { createAsyncThunk } from '@reduxjs/toolkit';
import { OfferService } from 'src/utils/services/service/offerService';
import { IOffer, IPagination } from 'src/utils/types/offerTypes';


export const fetchOffers = createAsyncThunk<
  { data: IOffer[]; pagination: IPagination },
  { page: number; pageSize: number; }
>('offers/fetchOffers', async ({ page, pageSize }) => {
  try {
    const response = await OfferService.extractOffers(page, pageSize);
    return response;
  } catch (error) {
    return error;
  }
});