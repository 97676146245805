import MetaTags from 'react-meta-tags';
import { useState, useEffect } from 'react';
import { Row, Col, Alert, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import api from 'src/utils/services/axiosConfig';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Spin } from 'antd';

// actions
import { loginUser, socialLogin } from '../../slices/thunks';
import Select from 'react-select';

//import images
import { SignInProps } from 'src/utils/types/authTypes';
import LanguageDropdown from 'src/components/CommonForBoth/TopbarDropdown/LanguageDropdown';

interface LoginProps {
  history: any;
}

const Login = ({ history }: LoginProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('');
  const [options, setOptions] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const [isEmailProvided, setIsEmailProvided] = useState<string | null>(null);
  const [isPasswordProvided, setIsPasswordProvided] = useState<string | null>(null);
  const [isTenantProvided, setIsTenantProvided] = useState<string | null>(null);

  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: 'Select tenant',
  });

  const { error, isLoading } = useSelector((state: any) => ({
    error: state.login.error,
    isLoading: state.login.isLoading,
  }));

  const handleGetTenant = async () => {
    setIsLoading(true);
    try {
      const res = await api.get('/Tenant', {});
      if (res.status !== 200) return;
      const optionArray = res.data.tenants?.map((item: any) => ({
        value: item.id,
        label: item.displayName,
      }));
      setIsLoading(false);
      setOptions(optionArray);
    } catch (error) {
      /* empty */
      setIsLoading(false);
    } finally {
      /* empty */
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetTenant();
  }, []);

  const handleValidSubmit = (_event: any, values: SignInProps) => {
    values.tenant = selectedOption;
    dispatch(loginUser(values, history));
  };

  const signIn = (res: any, type: any) => {
    if (type === 'google' && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, history, type));
    } else if (type === 'facebook' && res) {
      const postData = {
        name: res.name,
        token: res.accessToken,
      };
      dispatch(socialLogin(postData, history, type));
    }
  };

  const schema = yup.object().shape({
    username: yup
      .string()
      .required(String(t('authentication:usernameRequired')))
      .trim(),
    password: yup
      .string()
      .required(String(t('authentication:passwordRequired')))
      .trim(),
    tenant: yup.object().required(String(t('authentication:tenantLabel'))),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignInProps>({
    resolver: yupResolver(schema),
  });

  return (
    <>
      <MetaTags>
        <title>Login | Data!</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div
            style={{
              display: 'flex',
              marginBottom: '-80px',
              justifyContent: 'end',
              alignItems: 'center',
              alignContent: 'center',
            }}
          >
            <div style={{ color: '#000000!' }}>{t('Language :')}</div>
            <LanguageDropdown />
          </div>

          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>
                <div className="py-5">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/dashboard" className="d-block auth-logo">
                      Data!
                    </Link>
                  </div>
                  <div className="text-center mb-4">
                    <h5>{t('Welcome Back!')}</h5>
                    <p>{t('Sign in to continue to Data!.')}</p>
                  </div>
                  <AvForm
                    onValidSubmit={(e: any, v: any) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    <div className="form-floating form-floating-custom mb-3">
                      <AvField
                        name="username"
                        label="Email"
                        id="username"
                        onChange={(e: any) => {
                          if (e.target.value) {
                            setIsEmailProvided(e.target.value);
                          } else {
                            setIsEmailProvided(null);
                          }
                        }}
                        className="form-control"
                        placeholder={t('Enter username')}
                        type="username"
                        required
                      />
                    </div>
                    <div className="form-floating form-floating-custom mb-3">
                      <AvField
                        id="password"
                        name="password"
                        label={t('Password')}
                        // value="SaasAdm_3232"
                        onChange={(e: any) => {
                          if (e.target.value) {
                            setIsPasswordProvided(e.target.value);
                          } else {
                            setIsPasswordProvided(null);
                          }
                        }}
                        type="password"
                        className="form-control"
                        required
                        placeholder={t('Enter Password')}
                      />
                    </div>
                    <label htmlFor="choices-multiple-skill-input" className="form-label">
                      {t('Tenants')}
                    </label>
                    <Select
                      inputId={selectedOption?.value}
                      control={control}
                      onChange={(value: any) => {
                        if (value) {
                          setIsTenantProvided(value.value);
                        } else {
                          setIsTenantProvided(null);
                        }
                        setSelectedOption(value.value);
                      }}
                      // onChange={()=>{} }
                      label="tenant"
                      name="tenant"
                      value={selectedOption?.value}
                      placeholder={t('Select tenant')}
                      options={options}
                      isLoading={loading}
                      required
                    />

                    <div className="form-check form-check-info font-size-16 mt-2">
                      <input className="form-check-input" type="checkbox" id="remember-check" />
                      <label className="form-check-label font-size-14" htmlFor="remember-check">
                        {t('Remember me')}
                      </label>
                    </div>

                    <div className="mt-3">
                      {isLoading ? (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                            justifyItems: 'center',
                          }}
                        >
                          <Spin size="large" />
                        </div>
                      ) : (
                        <button
                          disabled={
                            isEmailProvided === null || isPasswordProvided === null || isTenantProvided === null
                          }
                          className="btn btn-info w-100 "
                          type="submit"
                          id="loginButton"
                        >
                          {t('Log in')}
                        </button>
                      )}
                    </div>

                    <div className="mt-4 text-center">
                      {/* <h5 className="font-size-14 mb-3">Sign in with</h5> */}

                      <ul className="list-inline"></ul>
                    </div>

                    {/* <div className="mt-4 text-center">
                      <Link className="text-muted" to="/forgot-password">
                        <i className="mdi mdi-lock me-1"></i> Forgot your password?
                      </Link>
                    </div> */}
                  </AvForm>

                  {/* <div className="mt-5 text-center text-muted">
                    <p>
                      Don&apos;t have an account ?{' '}
                      <Link to="/register" className="fw-medium text-decoration-underline">
                        {' '}
                        Signup{' '}
                      </Link>
                    </p>
                  </div> */}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <div className="text-center text-muted p-4">
                  <p className="mb-0">
                    {/* &copy;{new Date().getFullYear()} {t('Crafted with by Data!')} */}
                    Made with ❤️ in southern tyrolia
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default withRouter(Login);

{
  /* <i className="mdi mdi-heart text-danger"></i>{' '} */
}
