import React from 'react';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { ITag, IPagination } from 'src/utils/types/tagTypes';
/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, DatePickerProps, Form, Input, notification, Popconfirm, Select, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AppDispatch, RootState } from 'src/store';
import { Card, Space } from 'antd';
import { useEffect } from 'react';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { TableProps } from 'antd/lib';
import { AddAssignmentToTag, CreateTag, GetAllTags, UpdateTag } from 'src/slices/Tags/thunk';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

interface ISpecialPeriodsProps {
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: ITag | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: ITag | null) => void;
  pagination: IPagination;
}
const TagsModal = ({
  isOpen,
  pagination,
  openNotificationWithIcon,
  setEditingData,
  setIsEditMode,
  toggle,
  initialValues,
  isEditMode,
}: ISpecialPeriodsProps) => {
  const [form] = Form.useForm();
  const [assignmentForm] = Form.useForm();
  const { isCreating, isUpdating, isAddingAssignment, tags } = useSelector((state: RootState) => state.tags);
  const [assignments, setAssignments] = React.useState<{ year: string; week: string }[]>([]);
  const [isAssignmentsModalOpen, setIsAssignmentsModalOpen] = React.useState(false);
  const [selectedYear, setSelectedYear] = React.useState<string | null>(null);
  const [selectedWeek, setSelectedWeek] = React.useState<string | null>(null);
  const { selectedProperty } = useSelector((state: RootState) => state.porperty);
  const { t } = useTranslation('');

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);
      if (initialValues) {
        setAssignments(
          initialValues?.assignments?.map((assignment) => ({
            year: assignment?.year?.toString(),
            week: assignment?.week?.toString(),
          })),
        );
      }
    }
  }, [initialValues, isEditMode]);

  const assignmentColumns: TableProps['columns'] = [
    {
      title: t('Action'),
      dataIndex: 'x',
      key: 'x',
      width: 100,
      render: (_, _record, index) => (
        <>
          <Popconfirm
            title={t('Remove assignment')}
            description={t('Are you sure you want to remove this ?')}
            okText={t('Yes')}
            cancelText={t('No')}
            onConfirm={() => {
              setAssignments((prevAssignments) => prevAssignments.filter((_, i) => i !== index));
            }}
          >
            <IoIosRemoveCircleOutline
              size={20}
              color="red"
              style={{
                cursor: 'pointer',
              }}
            />
          </Popconfirm>
        </>
      ),
    },
    {
      title: t('Year'),
      dataIndex: 'year',
      key: 'year',
      width: 100,
    },
    {
      title: t('Week'),
      dataIndex: 'week',
      key: 'week',
      width: 100,
    },
  ];

  // ** Function to handle create or update
  const handleCreateOrUpdate = async () => {
    await form.validateFields();
    const formData: ITag | any = form.getFieldsValue();

    try {
      formData.assignments = assignments;
      // Check if there are no assignments
      if (assignments.length === 0) {
        form.setFields([
          {
            name: 'assignments',
            errors: [t('Please add at least one assignment to the tag.')],
          },
        ]);
        return;
      }

      if (isEditMode) {
        const payload = formData;
        payload.id = initialValues?.id;
        payload.propertyId = selectedProperty?.value?.toString();

        if (!payload.propertyId) {
          openNotificationWithIcon('error', t('Property Id is required'));
          return;
        }

        const response = await dispatch(UpdateTag({ data: payload, tagId: initialValues?.id as string }));
        if ((response.payload as ITag)['id']) {
          openNotificationWithIcon('success', t('Tags Updated successfully'));
          handleCancel();
          form.resetFields();
          setAssignments([]);
          dispatch(GetAllTags({ page: pagination.currentPage, pageSize: pagination.pageSize }));
        } else {
          openNotificationWithIcon('error', t(`Error Updating Tags`));
        }
      } else {
        const payload = formData;
        payload.propertyId = selectedProperty?.value?.toString();

        if (!payload.propertyId) {
          openNotificationWithIcon('error', t('Property Id is required'));
          return;
        }

        if (tags.some((tag) => tag.name === formData.name)) {
          form.setFields([
            {
              name: 'name',
              errors: [t('A tag with the name already exists.')],
            },
          ]);
          return;
        }
        const response = await dispatch(CreateTag({ data: payload }));
        if ((response.payload as ITag)['id']) {
          openNotificationWithIcon('success', t('Tags created successfully'));
          handleCancel();
          form.resetFields();
          setAssignments([]);
          dispatch(GetAllTags({ page: pagination.currentPage, pageSize: pagination.pageSize }));
        } else {
          openNotificationWithIcon('error', t('`Error creating Tags`'));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddAssignmentsToTag = async () => {
    try {
      await assignmentForm.validateFields(['year', 'week']);

      // Check for duplicate year/week combination
      const isDuplicate = assignments.some(
        (assignment) => assignment.year === selectedYear && assignment.week === selectedWeek,
      );

      if (isDuplicate) {
        assignmentForm.setFields([
          {
            name: 'year',
            errors: [t('The selected year already exists.')],
          },
          {
            name: 'week',
            errors: [t('The selected week already exists.')],
          },
        ]);
        return;
      }

      setAssignments((prevAssignments) => [
        ...prevAssignments,
        { year: selectedYear as string, week: selectedWeek as string },
      ]);

      form.setFields([
        {
          name: 'assignments',
          errors: [],
        },
      ]);

      assignmentForm.setFields([
        {
          name: 'year',
          errors: [],
        },
        {
          name: 'week',
          errors: [],
        },
      ]);
      setIsAssignmentsModalOpen(false);
      handleCancelForAssignments();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    toggle();
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
      form.resetFields();
    }
  };

  const onWeekChange: DatePickerProps['onChange'] = (_date, dateString) => {
    const week = (dateString as string).split('-')[1];
    const realFormat = week?.replace(/(st|nd|rd|th)/, '');

    setSelectedWeek(realFormat as string);
  };

  const onYearChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setSelectedYear(dateString as string);
  };

  const handleCancelForAssignments = () => {
    setIsAssignmentsModalOpen(false);
    assignmentForm.resetFields();
    setSelectedWeek(null);
    setSelectedYear(null);
  };

  return (
    <div>
      {' '}
      <Modal centered isOpen={isOpen} toggle={() => handleCancel()}>
        <ModalHeader toggle={() => handleCancel()} tag="h5">
          {isEditMode ? t('Update') : t('Create')} Tag
        </ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label={t('Name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('Please enter name a tag name'),
                },
              ]}
            >
              <Input id="tagName" placeholder={t('Please enter name a tag name')} className="p-2 px-2" />
            </Form.Item>
            <Form.Item
              name="assignments"
              label={t('Assignments')}
              rules={[
                {
                  validator: async () => {
                    if (assignments.length === 0) {
                      throw new Error(t('Please add at least one assignment to the tag.'));
                    }
                  },
                },
              ]}
            >
              <Card
                title={t('Manage Assignments')}
                extra={
                  <Button id="openAssignmentModal" onClick={() => setIsAssignmentsModalOpen(true)}>
                    {
                      <>
                        <i className="uil uil-plus me-1"></i>
                      </>
                    }
                    {t('Add Assignment')}
                  </Button>
                }
                style={{ width: '100%' }}
              >
                <Table
                  pagination={{
                    defaultPageSize: 100,
                    pageSizeOptions: [50, 100],
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                  scroll={{ y: 200 }}
                  columns={assignmentColumns}
                  dataSource={assignments}
                />
              </Card>
            </Form.Item>
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Tooltip title={t('Cancel')}>
              <Button
                id="tagsCancelButton"
                disabled={isCreating || isUpdating}
                onClick={handleCancel}
                style={{ marginTop: 20, marginRight: 10, background: 'white', color: 'black' }}
              >
                {t('Cancel')}
              </Button>
            </Tooltip>
            <Tooltip title={isEditMode ? t('Update') : t('Create')}>
              <AsyncButton
                buttonText={isEditMode ? t('Update') : t('Create')}
                isEditMode={isEditMode}
                style={{ marginTop: 20 }}
                isLoading={isCreating || isUpdating}
                onClick={handleCreateOrUpdate}
                id="tagsCreateORUpdateButton"
              />
            </Tooltip>
          </Col>
        </ModalBody>
      </Modal>
      <Modal
        style={{
          width: 300,
        }}
        centered
        isOpen={isAssignmentsModalOpen}
        toggle={() => setIsAssignmentsModalOpen(false)}
      >
        <ModalHeader toggle={() => setIsAssignmentsModalOpen(false)} tag="h5">
          {t('Add Assignment')}
        </ModalHeader>
        <ModalBody>
          <Form layout="vertical" form={assignmentForm}>
            <Form.Item
              required={true}
              label={t('Year')}
              name="year"
              rules={[
                {
                  required: true,
                  message: t('Please select year'),
                },
              ]}
            >
              <DatePicker
                placeholder={t('Please select year')}
                id="selectYear"
                style={{
                  width: '100%',
                }}
                className="p-2"
                onChange={onYearChange}
                picker="year"
              />
            </Form.Item>
            <Form.Item
              required={true}
              label={t('Week')}
              name="week"
              rules={[
                {
                  required: true,
                  message: t('Please select week'),
                },
              ]}
            >
              <DatePicker
                placeholder={t('Please select week')}
                id="selectWeek"
                style={{
                  width: '100%',
                }}
                className="p-2"
                onChange={onWeekChange}
                picker="week"
              />
            </Form.Item>
          </Form>
          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Tooltip title={t('Cancel')}>
              <Button
                id="assignmentsCancelButton"
                disabled={isCreating || isUpdating}
                onClick={handleCancelForAssignments}
                style={{ marginTop: 10, marginRight: 10, background: 'white', color: 'black' }}
              >
                {t('Cancel')}
              </Button>
            </Tooltip>
            <Tooltip title={t('Add')}>
              <Button
                id="assignmentsAddButton"
                isLoading={isAddingAssignment}
                onClick={handleAddAssignmentsToTag}
                style={{ marginTop: 10, marginRight: 10 }}
              >
                {t('Add')}
              </Button>
            </Tooltip>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default TagsModal;
