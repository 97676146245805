import { createAsyncThunk } from '@reduxjs/toolkit';
import { ImageManagementService } from 'src/utils/services/service/imageManagementService';
import { IimageManagement, IPagination } from 'src/utils/types/imageManagementTypes';

export const CreateImageData = createAsyncThunk(
  'data/createImageData',
  async ({ data }: { data: any }, { rejectWithValue }) => {
    try {
      const response: any = await ImageManagementService.createimageData(data);
      return response;
    } catch (error: any) {
      console.error('Error in CreateImageData Thunk: ', error);

      return rejectWithValue(error.response?.data?.message || error.message || 'An unknown error occurred');
    }
  },
);
export const GetAllImageData = createAsyncThunk<
  { data: IimageManagement[]; pagination: IPagination },
  { page: number; pageSize: number }
>('data/getAllImageData', async ({ page, pageSize }, { rejectWithValue }) => {
  try {
    const response = await ImageManagementService.getAllimageData(page, pageSize);
    return response;
  } catch (error: any) {
    return rejectWithValue(error?.message || 'An error occurred');
  }
});

export const GetSingleImageData = createAsyncThunk(
  'data/getSingleImageData',
  async ({ imageId }: { imageId: string }, { rejectWithValue }) => {
    try {
      const response = await ImageManagementService.getSingleimageData(imageId);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const UpdateImageData = createAsyncThunk(
  'data/updateImageData',
  async ({ imageId, data }: { imageId: string; data: any }, { rejectWithValue }) => {
    try {
      const response = await ImageManagementService.updateimageData(imageId, data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const DeleteImageData = createAsyncThunk(
  'data/deleteImageData',
  async ({ imageId }: { imageId: string }, { rejectWithValue }) => {
    try {
      const response = await ImageManagementService.deleteimageData(imageId);
      return imageId;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);
