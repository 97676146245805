/* eslint-disable react-hooks/exhaustive-deps */
import { notification, Space, Table, Tag, Tooltip, Pagination } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { AppDispatch } from 'src/store';
import { Competitor, HotelData, IPagination } from 'src/utils/types/HotelData';
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { IoIosSearch } from 'react-icons/io';
import SearchModal from './components/SearchModal';
import { FiEdit } from 'react-icons/fi';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import CompetitorsHotelsModal from './components/CompetitorsHotelsModal';
import { DeleteCompetitorHotelEntry, ReadHotelCompetitors } from 'src/slices/CompetitorHotels/thunk';
import TableView from 'src/components/Common/TableView/TableView';
import { useTranslation } from 'react-i18next';
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';

const CompetitorHotels = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<HotelData | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const { t } = useTranslation('');

  console.log(isModalOpen);

  const dispatch: AppDispatch = useDispatch();
  const { isLoading, data, isDeleting } = useSelector((state: RootState) => state.competitor);
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  useEffect(() => {
    dispatch(ReadHotelCompetitors({ page: pageNumber, pageSize })).then((action: any) => {
      if (action.payload) {
        setPagination(action.payload.pagination);
      }
    });
  }, [pageNumber, pageSize, dispatch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const deleteCompetitorHotel = async (Id: string) => {
    if (!Id) {
      openNotificationWithIcon('error', t('Did not find the hotel id'));
      return;
    }
    try {
      const response = await dispatch(
        DeleteCompetitorHotelEntry({
          id: Id as string,
        }),
      );

      if (response.payload.status === 204) {
        openNotificationWithIcon('success', t('Competitor hotel deleted successfully'));

        dispatch(ReadHotelCompetitors({ page: pageNumber, pageSize }));
      } else {
        openNotificationWithIcon('error', t('Error deleting Competitor hotel'));
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  return (
    <div>
      <div>
        <div>
          {contextHolder}
          <div className="page-content">
            <MetaTags>
              <title>{t('Competitor Hotel')}</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumb title={t('Settings')} breadcrumbItem={t('Competitor Hotel')} />

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <Tooltip title={t('Search hotels by URL or Platform ID')}>
                            <div>
                              <SearchBar
                                id="competitorHotelSearchBar"
                                value={searchValue}
                                onChange={handleSearchChange}
                                placeholder={t('Search hotels by URL or Platform ID')}
                              />
                            </div>
                          </Tooltip>
                        </Col>

                        <Col md={6}>
                          <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                            {/* <Button
                              id="addNotificationContact"
                              className="btn btn-info"
                              onClick={() => {
                                setIsSearchModalOpen(!isSearchModalOpen);
                              }}
                              title={t('Search Hotels')}
                            >
                              <IoIosSearch />

                              <span
                                style={{
                                  marginLeft: '5px',
                                }}
                              >
                                {t('Search Hotels')}
                              </span>
                            </Button> */}
                            <Col md={6}>
                              <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                                <div>
                                  <Tooltip title={t('Add')} placement="top">
                                    <Button
                                      id="addCompetitorHotels"
                                      className="btn btn-light"
                                      onClick={() => {
                                        setIsModalOpen(!isModalOpen);
                                      }}
                                    >
                                      <i className="uil uil-plus me-1"></i> {t('Add')}
                                    </Button>
                                  </Tooltip>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </Col>
                      </Row>

                      {/* Table View */}
                      <div
                        style={{
                          height: '64vh',
                        }}
                      >
                        <Table
                          loading={isLoading || isDeleting}
                          bordered
                          scroll={{ y: 400 }}
                          sticky={true}
                          dataSource={
                            Array.isArray(data)
                              ? data?.filter((hotel) => {
                                  if (searchValue === '') {
                                    return hotel;
                                  } else if (
                                    hotel?.competitors?.some((competitor) => {
                                      return competitor?.url?.toLowerCase().includes(searchValue.toLowerCase());
                                    })
                                  ) {
                                    return hotel;
                                  } else if (
                                    hotel?.competitors?.some((competitor) => {
                                      return competitor?.platforms?.some((platform) => {
                                        return platform.platformId?.toLowerCase().includes(searchValue.toLowerCase());
                                      });
                                    })
                                  ) {
                                    return hotel;
                                  } else if (
                                    hotel?.competitors?.some((competitor) => {
                                      return competitor?.platforms?.some((platform) => {
                                        return platform.platform?.toLowerCase().includes(searchValue.toLowerCase());
                                      });
                                    })
                                  ) {
                                    return hotel;
                                  }
                                })
                              : []
                          }
                          // rowKey="propertyId"
                          pagination={false}
                        >
                          <Table.Column<HotelData>
                            title={t('Actions')}
                            width={90}
                            key="action"
                            render={(_, record: HotelData) => (
                              <div style={{ display: 'flex' }}>
                                <Tooltip title={t('Edit')} placement="top">
                                  <div
                                    className="tableIcon EditCompetitor"
                                    onClick={() => {
                                      setIsModalOpen(true);
                                      setIsEditMode(true);
                                      setEditingData(record);
                                    }}
                                  >
                                    <FiEdit id="updateCompetitorHotel" size={20} />
                                  </div>
                                </Tooltip>
                                <Tooltip title={t('Delete')}>
                                  <div className="tableIcon DeleteCompetitor">
                                    <DeleteButton
                                      title={t('Are you sure you want to delete?')}
                                      cancelText={t('Cancel')}
                                      okText={t('Proceed')}
                                      triggerButtonId="deleteCompetitorHotel"
                                      okButtonId={`confirmAndDeleteCompetitorHotel`}
                                      onConfirm={() => {
                                        deleteCompetitorHotel(record.id as string);
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            )}
                          />

                          <Table.Column<HotelData>
                            width={170}
                            title={t('Competitor URL')}
                            dataIndex={['competitors']} // Access all competitors
                            key="url"
                            render={(competitors: Competitor[]) => (
                              <>
                                {competitors?.map((competitor, index) => (
                                  <Tooltip title={competitor?.url} placement="top" key={index}>
                                    <a href={competitor?.url} target="_blank" rel="noopener noreferrer">
                                      {competitor?.url}
                                    </a>

                                    <hr />
                                  </Tooltip>
                                ))}
                              </>
                            )}
                          />
                          <Table.Column<HotelData>
                            width={170}
                            title={t('Offer URL')}
                            dataIndex="competitors"
                            key="offerUrl"
                            render={(competitors: Competitor[]) => (
                              <>
                                {competitors?.map((competitor, competitorIndex) =>
                                  competitor?.offerUrls?.map((offerUrl, offerIndex) => (
                                    <Tooltip
                                      title={offerUrl?.offerUrl_}
                                      placement="top"
                                      key={`${competitorIndex}-${offerIndex}`}
                                    >
                                      <a href={offerUrl?.offerUrl_} target="_blank" rel="noopener noreferrer">
                                        {offerUrl?.offerUrl_}
                                      </a>
                                      <hr />
                                    </Tooltip>
                                  )),
                                )}
                              </>
                            )}
                          />

                          <Table.ColumnGroup title={t('Competitors')}>
                            <Table.Column<HotelData>
                              title={t('Platform')}
                              key="platform"
                              render={(_, record: HotelData) => (
                                <>
                                  {record?.competitors?.map((competitor, competitorIndex) => (
                                    <div key={competitorIndex}>
                                      {competitor?.platforms?.map((platform, platformIndex) => (
                                        <div key={platformIndex}>
                                          {platform.platform}
                                          <hr />
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </>
                              )}
                            />
                            <Table.Column<HotelData>
                              title={t('Platform ID')}
                              key="platformId"
                              render={(_, record: HotelData) => (
                                <>
                                  {record?.competitors?.map((competitor, competitorIndex) => (
                                    <div key={competitorIndex}>
                                      {competitor?.platforms.map((platform, platformIndex) => (
                                        <div key={platformIndex}>
                                          <Tag color="blue">{platform.platformId}</Tag>
                                          <br />
                                          <hr />
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </>
                              )}
                            />
                          </Table.ColumnGroup>
                        </Table>

                        <Pagination
                          current={pageNumber}
                          pageSize={pageSize}
                          total={pagination.totalCount}
                          onChange={(page, pageSize) => {
                            setPageNumber(page);
                            setPageSize(pageSize);
                            dispatch(ReadHotelCompetitors({ page, pageSize }));
                          }}
                          style={{ float: 'right', marginTop: '20px' }}
                          showSizeChanger
                          pageSizeOptions={['10', '20', '30', '50', '100']}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/**  Modal to handle create and update  **/}
              <CompetitorsHotelsModal
                pagination={pagination}
                isEditMode={isEditMode}
                initialValues={editingData}
                openNotificationWithIcon={openNotificationWithIcon}
                isOpen={isModalOpen}
                toggle={() => setIsModalOpen(!isModalOpen)}
                setIsEditMode={setIsEditMode}
                setEditingData={setEditingData}
              />

              <SearchModal isOpen={isSearchModalOpen} toggle={() => setIsSearchModalOpen(!isSearchModalOpen)} />
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitorHotels;
