import env from 'src/env';
import api from '../axiosConfig';

const userApi = env.VITE_REACT_APP_DEFAULTAUTH_USERS() || '';

export const getProfilePicture = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await api.get(`/User/profile-picture`, {
    headers,
    baseURL: userApi,
    responseType: 'arraybuffer',
  });
  return res?.data;
};

export const upLoadProfilePicture = async (formData: any) => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const res = await api.post(`/User/profile-picture`, formData, {
    headers,
    baseURL: userApi,
    responseType: 'arraybuffer',
  });
  return res?.data;
};

export const deleteProfilePicture = async () => {
  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const res = await api.delete(`/User/profile-picture`, {
    headers,
    baseURL: userApi,
  });
  return res?.data;
};
