import { Col, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Bar, Line } from 'react-chartjs-2';
import { IComparisonData, IPagination } from 'src/utils/types/ComparisonDataTypes';
import { TableProps } from 'antd/lib';
import TableView from 'src/components/Common/TableView/TableView';
import { useNumberFormatter } from '../../../utils/formatNumber'

interface IComparisonDataDetailsModalProps {
  isOpen: boolean;
  toggle: () => void;
  editingData: any;
  setEditingData: (record: IComparisonData | null) => any;
  pagination: IPagination;
}

const ComparisonDataDetailsModal = ({
  editingData,
  isOpen,
  toggle,
  setEditingData,
  pagination,
}: IComparisonDataDetailsModalProps) => {
  const { t } = useTranslation('');
  const { formatCurrency, formatPercentage } = useNumberFormatter();
  if (!editingData) return null;

  const comparisonYears = editingData.comparison.map((item: any) => item.year);
  const bookedRoomsData = editingData.comparison.map((item: any) => item.bedsSold);
  const revenueData = editingData.comparison.map((item: any) => item.revenue);
  const deviationData = editingData.comparison.map((item: any) => parseFloat(item.deviation));

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  const generateChartData = (label: any, currentYearData: any, comparisonData: any) => ({
    labels: comparisonYears,
    datasets: [
      {
        label: `${label} (${editingData.year})`,
        data: currentYearData,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
      {
        label: `${label} (Comparison)`,
        data: comparisonData,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  });

  const deviationYears = Array.from({ length: 3 }); // Generates [2023, 2022, 2021]
  const columns: TableProps<any>['columns'] = [
    {
      title: t('Booked Rooms'),
      dataIndex: ['current', 'bedsSold'],
      key: 'currentBedsSold',
    },
    {
      title: t('Revenue'),
      dataIndex: ['current', 'revenue'],
      key: 'currentRevenue',
      render: (text) => (
        <span>
          <strong>{formatCurrency(text)}</strong>
        </span>
      ),
    },
    // Dynamically generated deviation columns
    ...deviationYears.map((year, index) => ({
      title: `${editingData?.year} - ${editingData?.comparison[index]?.year}`,
      dataIndex: ['comparison', index, 'deviation'],
      key: `deviation${index + 1}`,
      render: (text: any) => {
        if (text === '0.00%' || text?.toString() === 'N/A' || text?.toString() === 'NaN') {
          return <span>{'---'}</span>;
        } else {
          return <span>{formatPercentage(text)}</span>;
        }
      },
    })),
  ];

  return (
    <div>
      <Modal
        size="xl"
        centered
        isOpen={isOpen}
        toggle={() => {
          toggle();
          setEditingData(null);
        }}
      >
        <ModalHeader
          toggle={() => {
            toggle();
            setEditingData(null);
          }}
          tag="h5"
        >
          {t('Weekly Comparison')}
        </ModalHeader>
        <ModalBody>
          <h5 style={{ textAlign: 'start' }}>{t('Current')}</h5>
          <div
            style={{
              justifyContent: 'space-between',
              marginTop: '20px',
              textAlign: 'start',
            }}
          >
            <p>
              {t('Week')} : <strong>{editingData?.week}</strong>
            </p>
            <p>
              {t('Year')} : <strong>{editingData?.year}</strong>
            </p>
          </div>
          <p>
            {t('Booked Rooms')} : <strong>{editingData?.current?.bedsSold}</strong>
          </p>
          <p>
            {t('Revenue')} : <strong> {formatCurrency(editingData?.current?.revenue)}</strong>
          </p>
          {/* Bar Charts Grid */}
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '20px', marginTop: '30px' }}>
            <div>
              <h5>{t('Booked Rooms')}</h5>
              <Bar
                data={generateChartData('Booked Rooms', [editingData.current.bedsSold], bookedRoomsData)}
                options={chartOptions}
              />
            </div>
            <div>
              <h5>{t('Revenue')}</h5>
              <Bar
                data={generateChartData('Revenue', [editingData.current.revenue], revenueData)}
                options={chartOptions}
              />
            </div>
            <div>
              <h5>{t('Deviation (%)')}</h5>
              <Bar data={generateChartData('Deviation', [0], deviationData)} options={chartOptions} />
            </div>
          </div>

          {/* Report Section */}
          <h5 style={{ textAlign: 'start', marginTop: '30px' }}>{t('Previous Year Comparison')}</h5>

          <TableView columns={columns} dataSource={[editingData]} />

          <Col style={{ display: 'flex', justifyContent: 'end', marginTop: '50px' }}>
            <Tooltip title={t('Cancel')}>
              <Button
                id="comparisonDetailsModalCancelButton"
                onClick={() => {
                  toggle();
                  setEditingData(null);
                }}
                style={{ marginTop: 10, marginRight: 10, background: 'white', color: 'black' }}
              >
                {t('Cancel')}
              </Button>
            </Tooltip>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ComparisonDataDetailsModal;
