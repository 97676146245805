import { notification, Tooltip, Pagination } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { IExternalApiKeys, IPagination } from 'src/utils/types/externalApiKeysTypes';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import { Link } from 'react-router-dom';
import { TableProps } from 'antd/lib';
import { FiEdit } from 'react-icons/fi';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import TableView from 'src/components/Common/TableView/TableView';
import ExternalApiKeysModal from './components/ExternalApiKeysModal';
import { AppDispatch } from 'src/store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { DeleteExternalApiKey, GetAllExternalApiKeys } from 'src/slices/ExternalApiKeys/thunk';
import { useTranslation } from 'react-i18next';
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';

const ExternalApiKeys = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<IExternalApiKeys | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const { t } = useTranslation('');

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  const dispatch: AppDispatch = useDispatch();
  const { isLoading, data, isDeleting } = useSelector((state: RootState) => state.externalApiKeys);


  useEffect(() => {
    if (!isLoading && data.length === 0) {
      dispatch(GetAllExternalApiKeys({ page: pageNumber, pageSize })).then((action: any) => {
        if (action.payload) {
          setPagination(action.payload.pagination);
        }
      });

    }
  }, [pageNumber, pageSize]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const columns: TableProps<IExternalApiKeys>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 150,

      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <Tooltip title={t('Edit')}>
              <div
                className="tableIcon"
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsEditMode(true);
                  setEditingData(record);
                }}
                aria-label={t('Edit')}
              >
                <FiEdit id="updateExternalApiKey" size={20} />
              </div>
            </Tooltip>
            <Tooltip title={t('Delete')}>
              <div className="tableIcon">
                <DeleteButton
                  title={t('Are you sure you want to delete?')}
                  cancelText={t('Cancel')}
                  okText={t('Proceed')}
                  okButtonId={`confirmAndDeleteExternalApiKey`}
                  triggerButtonId="deleteExternalApiKey"
                  onConfirm={() => {
                    deleteExternalAPiKey(record.id as string);
                  }}
                  aria-label={t('Delete')}
                />
              </div>
            </Tooltip>

          </div>
        );
      },
    },

    {
      title: t('Name'),
      key: 'name',
      dataIndex: 'name',
      width: 200,
    },

    {
      title: t('External System'),
      dataIndex: 'externalSystem',
      key: 'externalSystem',
      width: 200,
    },
    {
      title: t('API Key'),
      key: 'apiKey',
      dataIndex: 'apiKey',
      width: 300,
    },
  ];

  const deleteExternalAPiKey = async (externalApiKeyId: string) => {
    if (!externalApiKeyId) {
      openNotificationWithIcon('error', t('Id not found'));
      return;
    }
    try {
      const response = await dispatch(
        DeleteExternalApiKey({
          externalApiKeyId,
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        openNotificationWithIcon('success', t('External api key deleted successfully'));

        dispatch(GetAllExternalApiKeys({ page: pageNumber, pageSize }));
      } else {
        openNotificationWithIcon('error', t('Error deleting external api key'));
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  return (
    <div>
      <div>
        {contextHolder}
        <div className="page-content">
          <MetaTags>
            <title>{t('External Api Keys')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title={t('System Settings')} breadcrumbItem={t('External Api Keys')} />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <Tooltip title={t('Search keys by name, property id, external system')}>
                          <div>
                            <SearchBar
                              id="externalApiKeySearchBar"
                              value={searchValue}
                              onChange={handleSearchChange}
                              placeholder={t('Search keys by name, property id, external system')}
                            />
                          </div>
                        </Tooltip>

                      </Col>

                      <Col md={6}>
                        <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                          <div>
                            <Tooltip title={t('Add')}>
                              <Button
                                id="addExternalApiKey"
                                className="btn btn-light"
                                onClick={() => {
                                  setIsModalOpen(!isModalOpen);
                                }}
                              >
                                <i className="uil uil-plus me-1"></i> {t('Add')}
                              </Button>
                            </Tooltip>

                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* Table View */}
                    <div
                      style={{
                        height: '64vh',
                      }}
                    >

                      <TableViewPagination
                        loading={isLoading || isDeleting}
                        columns={columns}
                        dataSource={data
                          ?.filter((e: IExternalApiKeys) => {
                            if (searchValue === '') return e;
                            else if (
                              e?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                              e?.propertyId?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                              e?.externalSystem?.toLowerCase().includes(searchValue.toLowerCase())
                            )
                              return e;
                          })
                          ?.reverse()}
                        showPagination={false}
                      />

                      <Pagination
                        current={pageNumber}
                        pageSize={pageSize}
                        total={pagination?.totalCount || 0}
                        onChange={(page, pageSize) => {
                          setPageNumber(page);
                          setPageSize(pageSize);
                          dispatch(GetAllExternalApiKeys({ page, pageSize }));
                        }}
                        style={{ float: 'right', marginTop: '20px' }}
                        showSizeChanger
                        pageSizeOptions={['10', '20', '30', '50', '100']}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/**  Modal to handle create and update  **/}
            <ExternalApiKeysModal
              pagination={pagination}
              isEditMode={isEditMode}
              initialValues={editingData}
              openNotificationWithIcon={openNotificationWithIcon}
              isOpen={isModalOpen}
              toggle={() => setIsModalOpen(!isModalOpen)}
              setIsEditMode={setIsEditMode}
              setEditingData={setEditingData}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ExternalApiKeys;
