import axios, { AxiosResponse } from 'axios';
import env from 'src/env';

const baseURL = env.VITE_ACCESS_SHIELD_BASE_URL();
const accessShieldBaseURL = '/accessshield/AccessShield';
const accessShieldBaseURLv1 = '/AccessShield/access-shield';

const getAccessDetailsByUserID = async (userId: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };

    const response = await axios.get(`${accessShieldBaseURL}/${userId}`, { headers, baseURL });
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getAllAccessDetails = async (roleName: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };

    const response = await axios.get(`${accessShieldBaseURL}/access-shield`, {
      headers,
      baseURL,
      params: {
        searchField: 'RoleName',
        pageSize: 25,
        page: 1,
        searchTerm: roleName,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateAccessDetailsForAUser = async (accessData: any, userId: string) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };

    const response = await axios.put(`${accessShieldBaseURL}/${userId}`, accessData, { headers, baseURL });
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const addUserToRole = async (data: any) => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };

    const response = await axios.post(`${accessShieldBaseURL}`, data, { headers, baseURL });
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const AccessDetailsService = {
  getAccessDetailsByUserID,
  getAllAccessDetails,
  updateAccessDetailsForAUser,
  addUserToRole,
};
