import React, { useState } from 'react';

import { Modal, Checkbox } from 'antd';
import { Button, Col, ModalBody, Row } from 'reactstrap';

interface ModalConfirmProps {
  title: string;
  onConfirm: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
  triggerButtonContent?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  style?: any;
  cancelButtonId?: string;
  okButtonId?: string;
  triggerButtonId?: string;
  confirmationType?: 'delete-confirmation' | `update-confirmation`;
}

const ConfirmationModal = ({
  title,
  onConfirm,
  onCancel,
  okText = 'Yes',
  cancelText = 'No',
  triggerButtonContent = 'Show Modal',
  disabled = false,
  className = '',
  style,
  loading,
  triggerButtonId,
  cancelButtonId,
  okButtonId,
  confirmationType = 'delete-confirmation',
}: ModalConfirmProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleOk = () => {
    setIsVisible(false);
    onConfirm();
  };

  const handleCancel = () => {
    setIsVisible(false);
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      <div id={triggerButtonId} className={className} onClick={() => setIsVisible(true)} style={style}>
        {triggerButtonContent}
      </div>
      {confirmationType === 'delete-confirmation' && (
        <>
          <Modal footer={null} centered open={isVisible} onCancel={handleCancel}>
            <ModalBody>
              <Row form>
                <Col xs={12}>
                  <div className="mb-3 text-center fs-5">{title}</div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="w-100 d-flex justify-content-center">
                    <button
                      id={cancelButtonId}
                      type="button"
                      className="btn btn-light w-50  mx-2"
                      onClick={handleCancel}
                    >
                      {cancelText}
                    </button>
                    <button
                      id={okButtonId}
                      type="submit"
                      className="btn btn-danger w-50 save-user"
                      onClick={() => handleOk()}
                    >
                      {okText}
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </>
      )}
      {confirmationType === 'update-confirmation' && (
        <Modal
          centered
          title={title}
          open={isVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText={okText}
          cancelText={cancelText}
          footer={
            <>
              <div className="flex items-center justify-between">
                <div>
                  <Button style={{ marginRight: 5 }} id={cancelButtonId} onClick={handleCancel}>
                    {cancelText}
                  </Button>
                  <Button id={okButtonId} onClick={handleOk}>
                    {' '}
                    {okText}
                  </Button>
                </div>
              </div>
            </>
          }
        ></Modal>
      )}

      {/* <Modal
        centered
        title={title}
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={okText}
        cancelText={cancelText}
        footer={
          <>
            <div className="flex items-center justify-between">
              <div>
                <Button style={{ marginRight: 5 }} id={cancelButtonId} onClick={handleCancel}>
                  {cancelText}
                </Button>
                <Button id={okButtonId} onClick={handleOk}>
                  {' '}
                  {okText}
                </Button>
              </div>
            </div>
          </>
        }
      ></Modal> */}
    </>
  );
};

export { ConfirmationModal };
