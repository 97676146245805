import api from 'src/utils/services/axiosConfig';
import env from 'src/env';
import { ITableDataEvents, IPagination } from 'src/utils/types/externalEvents';
import axios from 'axios';
const eventsUrl = env.VITE_EXTERNAL_EVENT();

const getAccessToken = () => localStorage.getItem('accessToken');

const createHeaders = (token: string | null) => ({
  Authorization: token ? `Bearer ${token}` : null,
});

const handleError = (error: any) => {
  console.error(error);
};


export const getAllExternalEvent = async (eventId: string) => {
  try {
    const res = await api.get(`/events-api/event?eventId=${eventId}`, {
      headers: createHeaders(getAccessToken()),
      baseURL: eventsUrl,
    });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};




export const getAllExternalEvents = async (
  pageNumber: number,
  pageSize: number,
  propertyId: number
): Promise<{ data: ITableDataEvents[]; pagination: IPagination } | null> => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    const headers = accessToken
      ? { Authorization: `Bearer ${accessToken}` }
      : {};

    const params = {
      pageNumber,
      pageSize,
      propertyId,
    };

    const response = await axios.get('/events-api/events/events', {
      headers,
      baseURL: eventsUrl,
      params,
    });

    const { data } = response;

    return {
      data: data.data as ITableDataEvents[],
      pagination: data.pagination as IPagination,
    };
  } catch (error) {
    console.error('Error fetching all events:', error);
    return null;
  }
};

export const getAllCategories = async () => {
  try {
    const res = await api.get(`/events-api/eventCategories`, { headers: createHeaders(null), baseURL: eventsUrl });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const getExternalEvent = async () => {
  try {
    const accessToken = getAccessToken();
    const res = await api.get('/events-api/event', { headers: createHeaders(accessToken), baseURL: eventsUrl });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const createExternalEvent = async (data: any, tenant: string) => {
  try {
    const accessToken = getAccessToken();
    const res = await api.post('/events-api/event', data, {
      headers: createHeaders(accessToken),
      baseURL: eventsUrl,
      params: {
        tenant: tenant,
      },
    });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const updateExternalEvent = async (data: any, eventId: string) => {
  try {
    const accessToken = getAccessToken();
    const res = await api.put(`/events-api/event?eventId=${eventId}`, data, {
      headers: createHeaders(accessToken),
      baseURL: eventsUrl,
    });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteExternalEvent = async (eventId: string) => {
  try {
    const accessToken = getAccessToken();
    const res = await api.delete(`/events-api/event?eventId=${eventId}`, {
      headers: createHeaders(accessToken),
      baseURL: eventsUrl,
    });
    return res.data;
  } catch (error) {
    handleError(error);
  }
};
