import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { FlowView } from '@ant-design/pro-flow';
import { useDispatch, useSelector } from 'react-redux';
import { fetchModelTrainingStatus } from 'src/slices/ModelTrainingStatus/thunk';
import { RootState } from 'src/store';
import WeightsModal from '../components/WeightsModal';
interface TrainingStatusModalProps {
  isOpen: boolean;
  toggle: () => void;
  id: string;
}

const TrainingStatusModal: React.FC<TrainingStatusModalProps> = ({ isOpen, toggle, id }) => {
  const dispatch = useDispatch();
  const { status, progress, isLoading, error } = useSelector((state: RootState) => state.trainingStatus);

  const [nodes, setNodes] = useState<any[]>([]);
  const [edges, setEdges] = useState<any[]>([]);
  const [isWeightsModalOpen, setIsWeightsModalOpen] = useState(false);
  const [weightsId, setWeightsId] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen && id) {
      dispatch(fetchModelTrainingStatus(id));
    }
  }, [isOpen, id, dispatch]);

  useEffect(() => {
    const baseNodes = [
      {
        id: 'node1',
        data: {
          title: 'Training in Progress',
          description: `Training ${id}`,
          style: { backgroundColor: 'yellow', borderRadius: '50%' },
        },
      },
    ];

    const baseEdges = [
      {
        id: 'edge1',
        source: 'node1',
        target: 'node2',
        type: 'smooth',
      },
    ];

    if (status === 'completed') {
      baseNodes.push({
        id: 'node2',
        data: {
          title: 'Training Completed',
          description: `Training ${id} complete`,
          style: { backgroundColor: 'green', borderRadius: '50%' },
        },
      });

      baseEdges.push({
        id: 'edge2',
        source: 'node1',
        target: 'node2',
        type: 'smooth',
      });
    }

    setNodes(baseNodes);
    setEdges(baseEdges);
  }, [status, id]);

  const handleNodeClick = (event: React.MouseEvent, node: any) => {
    const nodeId = node.id;
    const generatedWeightsId = `${Math.floor(Math.random() * 10000)}`;

    setWeightsId(generatedWeightsId);
    setIsWeightsModalOpen(true);
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} modalClassName="custom-modal" style={{ maxWidth: '1200px' }}>
        <ModalHeader toggle={toggle}>Training Status</ModalHeader>
        <ModalBody>
          {isLoading && <p>Loading training status...</p>}
          {error && <p>Error: {error}</p>}
          {status && <p>Status: {status}</p>}
          {progress !== null && <p>Progress: {progress}%</p>}

          <div style={{ height: '400px', width: '100%' }}>
            {/* Display Flow View with dynamic nodes and edges */}
            <FlowView nodes={nodes} edges={edges} onNodeClick={handleNodeClick} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {/* Weights modal */}
      <WeightsModal
        isOpen={isWeightsModalOpen}
        toggle={() => setIsWeightsModalOpen(!isWeightsModalOpen)}
        id={weightsId!}
      />
    </>
  );
};

export default TrainingStatusModal;
