import { createAsyncThunk } from '@reduxjs/toolkit';
import { HotelCompetitorService } from 'src/utils/services/service/hotelsCompetitorService';
import { HotelData, IPagination } from 'src/utils/types/HotelData';

export const CreateCompetitorHotelEntry = createAsyncThunk(
  'data/createCompetitorHotelEntry',
  async ({ data }: { data: HotelData }) => {
    try {
      const response = await HotelCompetitorService.createCompetitorHotelEntry(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);



export const ReadHotelCompetitors = createAsyncThunk<
  { data: HotelData[]; pagination: IPagination },
  { page: number; pageSize: number; }
>('data/readHotelCompetitors', async ({ page, pageSize }) => {
  try {
    const response = await HotelCompetitorService.readHotelCompetitors(page, pageSize);
    return response;
  } catch (error) {
    return error;
  }
});

export const ReadSpecificHotelCompetitor = createAsyncThunk(
  'data/readSpecificHotelCompetitor',
  async ({ id }: { id: number }) => {
    try {
      const response = await HotelCompetitorService.readSpecificHotelCompetitor(id);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateCompetitorHotelEntry = createAsyncThunk(
  'data/updateCompetitorHotelEntry',
  async ({ data, id }: { data: HotelData; id: string }) => {
    try {
      const response = await HotelCompetitorService.updateCompetitorHotelEntry(data, id);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteCompetitorHotelEntry = createAsyncThunk(
  'data/deleteCompetitorHotelEntry',
  async ({ id }: { id: number | string }) => {
    try {
      const response = await HotelCompetitorService.deleteCompetitorHotelEntry(id);
      return response;
    } catch (error) {
      return error;
    }
  },
);
