import axios from 'axios';
import { getDecodedToken } from 'src/utils/jwtDecode';
import env from 'src/env';

const weatherApiUrl = env.VITE_WEATHER_CONFIG_DEFAULT();

export const getWeathersConf = async () => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = {
    Authorization: null// `Bearer ${accessToken}`,
  };

  return await axios.get(`${weatherApiUrl}/providers/weather/configurations`, { headers })
}

export const getWeatherConf = async (id: string) => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = {
    Authorization: null// `Bearer ${accessToken}`,
  };

  return await axios.get(`${weatherApiUrl}/providers/weather/configurations/${id}`, { headers })
}

export const createWeatherConf = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");

  const res = await axios.post(
    `${weatherApiUrl}/providers/weather/configurations`,
    data,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return res;
}

export const updateWeatherConf = async (config: any) => {
  const accessToken = localStorage.getItem("accessToken");

  const { id, ...data } = config;

  const res = await axios.put(
    `${weatherApiUrl}/providers/weather/configurations/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return res;
}

export const deleteWeatherConf = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");

  const res = await axios.delete(
    `${weatherApiUrl}/providers/weather/configurations/${data.id}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,

      },
    }
  );

  return res;
}
