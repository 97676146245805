import { createAsyncThunk } from '@reduxjs/toolkit';
import { ComparisonDataService } from 'src/utils/services/service/comparisonDataService';
import { IComparisonData, IPagination } from 'src/utils/types/ComparisonDataTypes';


export const CreateComparisonData = createAsyncThunk('data/createComparison', async (data: any) => {
  const response = await ComparisonDataService.createComparison(data);
  return response;
});

export const RetrieveAllComparisons = createAsyncThunk<
  { data: IComparisonData[]; pagination: IPagination },
  { page: number; pageSize: number; }
>('data/retrieve-all-comparisons', async ({ page, pageSize }) => {
  try {
    const response = await ComparisonDataService.retrieveAllComparisons(page, pageSize);
    return response;
  } catch (error) {
    return error;
  }
});



export const RetrieveSpecificComparisonDetails = createAsyncThunk(
  'data/retrieve-single-comparisons',
  async (comparisonId: string) => {
    try {
      const response = await ComparisonDataService.retrieveSpecificComparisonDetails(comparisonId);
      return response;
    } catch (error) {
      return error;
    }
  },
);
