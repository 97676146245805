import React, { useRef, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Container, Row, Spinner, Button, Modal } from 'reactstrap';
import { processTotalRatePredictionSlice } from 'src/slices/TotalRatePrediction/thunk';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import Chart, { ChartProps, defaultChartValue } from 'src/components/elements/Chart';
import './index.scss';
import { RootState } from 'src/slices';
import { IUserInputForm, IUserInputFormHandle, UserInputForm } from '../form/UserInputForm';
import { NotificationArgsProps, Tabs, TabsProps, notification } from 'antd';
import { IUserInputFormHandleV1, UserInputFormV1 } from '../form/UserInputFormV1';

const TotalRatePrediction: React.FC = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState<File | null>(null);
  const [chartData, setChartData] = useState<ChartProps>(defaultChartValue);
  const { totalRatePrediction, totalRatePredictionLoading } = useSelector(
    (state: RootState) => state.totalRatePrediction,
  );
  const { properties, selectedProperty } = useSelector((state: RootState) => state.porperty);

  const userInputFormHandle = useRef<IUserInputFormHandleV1>(null);

  const handleButtonClick = async () => {
    const formValues: any = await userInputFormHandle.current?.fetchValues();

    const mealPlans = userInputFormHandle.current?.mealPlans;
    const roomTypes = userInputFormHandle.current?.roomTypes;
    const weathers = userInputFormHandle.current?.weathers;
    const regions = userInputFormHandle.current?.regions;
    const reservationTypes = userInputFormHandle.current?.reservationTypes;
    const isRoomInternal = userInputFormHandle.current?.isRoomInternal;

    const data = convertData(formValues as IUserInputForm, selectedProperty?.value);
    const newModalData = {
      ...mealPlans,
      ...roomTypes,
      ...regions,
      ...reservationTypes,
      ...weathers,
      RoomSize: formValues?.RoomSize,
      TotalRate: formValues?.totalRate,
      DiscountedRate: formValues?.discountRate,
      IsRoomInternal: isRoomInternal,
      MandantId: 0,
      MandantName: selectedProperty?.value,
      WeatherConditions: formValues?.weatherCondition,
      ArrivalDate: formValues?.dateRange[0],
      DepartureDate: formValues?.dateRange[1],
    };

    const keysToBeFiltered = [
      'RoomTypeName',
      'SourceOfBusiness',
      'BookingPromotion',
      'MandantName',
      'WeatherConditions',
      'PromotionVendor',
    ];

    Object.keys(newModalData).forEach((key) => {
      if (keysToBeFiltered.includes(key)) {
        // @ts-ignore
        delete newModalData[key];
      }
    });

    const blob = new Blob([JSON.stringify(newModalData)], { type: 'application/json' });
    const file = new File([blob], 'Occupancy.json', { type: 'application/json' });

    const requestData = {
      modelName: 'TotalRate',
      file: file,
    };

    try {
      const response: any = await dispatch(processTotalRatePredictionSlice(requestData));
      setChartData(response?.payload);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  type NotificationType = 'success' | 'info' | 'warning' | 'error';
  type NotificationPlacement = NotificationArgsProps['placement'];

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string, placement: NotificationPlacement) => {
    api[type]({
      message: message,
      placement,
    });
  };

  const handleSubmit = async () => {
    if (!file) {
      openNotificationWithIcon('warning', 'Please select a file', 'bottomRight');
      return;
    }

    const requestData = {
      modelName: 'TotalRate',
      file: file,
    };

    try {
      const response: any = await dispatch(processTotalRatePredictionSlice(requestData));
      setChartData(response?.payload); // Assuming data is in the format expected by your chart library
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'File Upload',
      children: (
        <Card>
          <CardBody>
            <Row>
              <Col md={12}>
                <div className="file-upload-container">
                  <div id="file-upload-div">
                    <input type="file" onChange={handleFileChange} />
                    <button className="btn btn-primary" disabled={totalRatePredictionLoading} onClick={handleSubmit}>
                      Upload File
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ),
    },
    {
      key: '2',
      label: 'Data Entry',
      children: (
        <Card>
          <CardBody>
            <UserInputFormV1 callFunction={handleButtonClick} ref={userInputFormHandle} />
            {/* <button className="btn btn-primary" onClick={handleButtonClick}>
              Process
            </button> */}
          </CardBody>
        </Card>
      ),
    },
  ];

  return (
    <div className="page-content">
      <MetaTags>
        <title>TotalRate</title>
      </MetaTags>
      <Container fluid>
        {contextHolder}
        {/* <Breadcrumb title="Dashboard" breadcrumbItem="TotalRate" /> */}
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="dashboard-container">
                      {totalRatePredictionLoading && (
                        <div className="spinnerBlock">
                          <Spinner />
                        </div>
                      )}
                      {chartData && <Chart {...chartData} />}
                    </div>
                    <FileUploadModal
                      showModal={showModal}
                      handleFileChange={handleFileChange}
                      handleSubmit={handleSubmit}
                      totalRatePredictionLoading={totalRatePredictionLoading}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Tabs style={{ marginTop: '-10px' }} defaultActiveKey="1" items={items} />
      </Container>
    </div>
  );
};

const convertData = (data: IUserInputForm, MandantName: string) => {
  return {
    Inputs: {
      data: [
        {
          BookingCode: 'example_value',
          DateCreated: '2000-01-01T00:00:00.000Z',
          IsCancelled: 'false',
          ReservationType: 'example_value',
          SourceOfBusiness: 'example_value',
          PromotionVendor: 'example_value',
          MandantId: 0,
          MandantName: MandantName,
          MandantAddress: 'example_value',
          MandantPostalCode: 0,
          MandantPlace: 'example_value',
          MandantEmail: 'example_value',
          MandantPhone: 'example_value',
          RoomTypeId: 0,
          RoomType: data?.roomType,
          DefaultOccupancy: 0,
          MaximumOccupancy: data?.maxOccupancy,
          MinimumOccupancy: data?.minOccupancy,
          IsRoomInternal: false,
          RoomSize: 10,
          RoomTypeCategory: 'example_value',
          RoomTypeCode: 0.0,
          MinAdultsForChildRates: 0,
          TotalRate: 1000,
          DiscountedRate: 100,
          ArrivalDate: data?.dateRange[0],
          DepartureDate: data?.dateRange[1],
          MealPlan: 'example_value',
          EventType: 'example_value',
          EventStartDate: data?.dateRange[0],
          EventEndDate: data?.dateRange[1],
          EventLocation: '2000-01-01T00:00:00.000Z',
          ExpectedAttendance: 0,
          WeatherConditions: data?.weatherCondition,
          OfferNumber: data?.offer?.length,
        },
      ],
    },
    GlobalParameters: 0.0,
  };
};

const FileUploadModal = ({
  showModal,
  closeModal,
  handleFileChange,
  handleSubmit,
  totalRatePredictionLoading,
  chartData,
}: any) => {
  return (
    <Modal isOpen={showModal} onRequestClose={closeModal}>
      <div id="file-upload-div">
        <input type="file" onChange={handleFileChange} />
        <button disabled={totalRatePredictionLoading} onClick={handleSubmit}>
          Upload File
        </button>
      </div>
      {totalRatePredictionLoading && (
        <div className="spinnerBlock">
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

export default TotalRatePrediction;
