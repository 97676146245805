import { useTranslation } from "react-i18next";

const localeMap: Record<string, string> = {
      gr: "el-GR", // Greek
      it: "it-IT", // Italian
      rs: "sr-RS", // Serbian
      sp: "es-ES", // Spanish
      eng: "en-US", // English
};

export const useNumberFormatter = () => {
      const { i18n } = useTranslation();

      const currentLocale = i18n.language;
      const locale = localeMap[currentLocale] || "en-US";

      const currency = currentLocale === 'en' ? 'USD' : 'EUR';

      return {
            formatNumber: (
                  value: number,
                  options: Intl.NumberFormatOptions = { style: "decimal" }
            ) => {

                  return new Intl.NumberFormat(locale, options).format(value);
            },
            formatCurrency: (
                  value: number,

                  options: Intl.NumberFormatOptions = { style: "currency", currency }
            ) => {
                  if (value === 0) {

                        return new Intl.NumberFormat(locale, { style: "decimal" }).format(value);
                  }
                  return new Intl.NumberFormat(locale, options).format(value);
            },

            formatPercentage: (
                  value: string | number,
                  options: Intl.NumberFormatOptions = {
                        style: "percent",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                  }
            ) => {

                  if (typeof value === "string") {
                        value = parseFloat(value.replace("%", "")) / 100;
                  } else {
                        value = value / 100;
                  }

                  return new Intl.NumberFormat(locale, options).format(value);
            }

      };
};
