import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Spin, notification, Select, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHotelSearchResults } from 'src/slices/HotelSearch/thunk';
import { RootState } from 'src/store';
import styles from './HotelSearch.module.scss';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const HotelSearch = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');
  const [checkin, setCheckin] = useState('');
  const [checkout, setCheckout] = useState('');
  const [numberOfRooms, setNumberOfRooms] = useState(1);
  const dispatch = useDispatch();

  const { hotels, loading, error } = useSelector((state: RootState) => state.hotelSearch);

  const handleSearchHotels = () => {
    if (!query || !checkin || !checkout) {
      notification.error({
        message: t('Error'),
        description: t('Please fill all fields.'),
      });
      return;
    }

    dispatch(fetchHotelSearchResults({ query, checkin, checkout, numberOfRooms, offset: 0 }));
  };

  const columns = [
    {
      title: t('Room Type'),
      dataIndex: 'room_type',
      key: 'room_type',
    },
    {
      title: t('Original Price'),
      dataIndex: 'pre_discount_price',
      key: 'pre_discount_price',
    },
    {
      title: t('Discounted Price'),
      dataIndex: 'post_discount_price',
      key: 'post_discount_price',
    },
    {
      title: t('Cancellation Policy'),
      dataIndex: 'cancellation_policy',
      key: 'cancellation_policy',
    },
    {
      title: t('No of beds'),
      dataIndex: 'beds',
      key: 'beds',
    },
    {
      title: t('Amenities'),
      dataIndex: 'amenities',
      key: 'amenities',
    },
  ];

  const hotelData = hotels.length > 0 ? hotels[0].hotels : [];

  return (
    <div className={styles.hotelSearchContainer}>
      <div className={styles.hotelSearchHeader}>
        <h2 className={styles.hotelSearchTitle}>{t('Get Bookings.com Data')}</h2>
      </div>

      <Form layout="vertical" className={styles.searchControls}>
        <Form.Item label={t('Select hotel')} required>
          <Select
            placeholder={t('Select hotel')}
            value={query}
            onChange={(value) => setQuery(value)}
            className={styles.searchInput}
          >
            <Option value="https://www.booking.com/hotel/lk/yakaduru-safari-camp-yala.en-gb.html">
              Yakaduru Safari Camp Yala (LK)
            </Option>
            <Option value="https://www.booking.com/hotel/de/amper-germering.en-gb.html">
              Hotel Amper Germering (DE)
            </Option>
            <Option value="https://www.booking.com/hotel/de/paintner.en-gb.html">Hotel Paintner (DE)</Option>
            <Option value="https://www.booking.com/hotel/de/huber-living-basic-205.en-gb.html">
              Huber Living Basic 205 (DE)
            </Option>
            <Option value="https://www.booking.com/hotel/de/my-home-munchen-freiham.en-gb.html">
              My Home München Freiham (DE)
            </Option>
          </Select>
        </Form.Item>

        <Form.Item label={t('Check-in Date (YYYY-MM-DD)')} required>
          <Input
            placeholder={t('Check-in Date (YYYY-MM-DD)')}
            value={checkin}
            onChange={(e) => setCheckin(e.target.value)}
            className={styles.searchInput}
          />
        </Form.Item>

        <Form.Item label={t('Check-out Date (YYYY-MM-DD)')} required>
          <Input
            placeholder={t('Check-out Date (YYYY-MM-DD)')}
            value={checkout}
            onChange={(e) => setCheckout(e.target.value)}
            className={styles.searchInput}
          />
        </Form.Item>

        <Form.Item label={t('Number of adults')} required>
          <Input
            type="number"
            placeholder={t('Number of Rooms')}
            value={numberOfRooms}
            onChange={(e) => setNumberOfRooms(parseInt(e.target.value))}
            className={styles.searchInput}
          />
        </Form.Item>

        <Button type="primary" onClick={handleSearchHotels} className={styles.searchButton} disabled={loading}>
          {loading ? <Spin /> : t('Search Hotels')}
        </Button>
      </Form>

      {error && <div className={styles.errorText}>{error}</div>}

      {loading ? (
        <Spin size="large" className={styles.loadingSpinner} />
      ) : (
        <Table
          columns={columns}
          dataSource={hotelData}
          rowKey={(record, index = 0) => index.toString()}
          pagination={{ pageSize: 10 }}
          className={styles.hotelTable}
        />
      )}
    </div>
  );
};

export default HotelSearch;
