/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Tabs, TabsProps, Tooltip, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Transfer, TransferProps } from 'antd/lib';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { getMyGroups, getProperties_For_Tenants, getUsers, update_group } from 'src/slices/thunks';

const GroupUpdateModal = ({ isOpen, toggle, updatingData }: any) => {
  const [form] = useForm();
  const dispatch = useDispatch();

  const data = useSelector((state: any) => ({
    users: state.users.users?.length ? state.users.users : [],
    totalPages: state.users.totalPages ? state.users.totalPages : 0,
    loading: state.users.loading,
  }));

  const { ProPertiesForTenants, groups, isCreating, loading, isGroupUpdating } = useSelector(
    (state: any) => state.groups,
  );

  const { properties, selectedProperty, Properties, isError } = useSelector((state: any) => state.porperty);
  const [initialUsers, setInitialUsers] = useState([]);
  const [initialProperties, setInitialProperties] = useState<string[]>([]);

  useEffect(() => {
    if (updatingData) {
      form.setFieldsValue(updatingData);
      setInitialUsers(updatingData?.users || []);
      setInitialProperties(updatingData?.properties || []);
      setTargetKeys(updatingData?.users || []);
      setTargetKeysForProperties(updatingData?.properties || []);
    }
  }, [updatingData]);

  type NotificationType = 'success' | 'info' | 'warning' | 'error';

  const [targetKeys, setTargetKeys] = useState<any[]>(initialUsers);
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);

  const [targetKeysForProperties, setTargetKeysForProperties] = useState<any[]>(initialProperties);
  const [selectedKeysForProperties, setSelectedKeysForProperties] = useState<any[]>([]);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  const onChangeV1: TransferProps['onChange'] = (nextTargetKeys) => {
    setTargetKeysForProperties(nextTargetKeys);
  };

  const onSelectChangeV1: TransferProps['onSelectChange'] = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeysForProperties([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getProperties_For_Tenants());
  }, [dispatch]);

  const onChange: TransferProps['onChange'] = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange: TransferProps['onSelectChange'] = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const { t } = useTranslation('');

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('Users'),
      children: (
        <>
          <Transfer
            style={{ width: '100%' }}
            dataSource={data?.users.map((user: any) => ({ key: user.id, title: user.userName }))}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onChange}
            onSelectChange={onSelectChange}
            render={(item) => item.title!}
          />
        </>
      ),
    },
    {
      key: '2',
      label: t('Properties'),
      children: (
        <Transfer
          style={{ width: '100%' }}
          dataSource={ProPertiesForTenants?.map((property: any) => ({
            key: property.id,
            title: `${property.mandantId} / ${property.place}`,
          }))}
          targetKeys={targetKeysForProperties}
          selectedKeys={selectedKeysForProperties}
          onChange={onChangeV1}
          onSelectChange={onSelectChangeV1}
          render={(item) => item.title!}
        />
      ),
    },
  ];

  const handleUpdate = async () => {
    form.validateFields();
    const name = form.getFieldValue('name');

    const dataToUpdate = {
      name,
      users: targetKeys,
      properties: targetKeysForProperties,
    };

    const response: any = await dispatch(update_group({ data: dataToUpdate, id: updatingData?.id }));
    if (response?.payload?.data?.responseDetail?.isSuccess === true) {
      openNotificationWithIcon('success', t('Group Updated successfully'));
      toggle();
      dispatch(getMyGroups());
    } else {
      openNotificationWithIcon('error', t('failed to update group'));
    }
  };

  return (
    <div>
      {contextHolder}
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader tag="h4">{t('Update Group')}</ModalHeader>
        <ModalBody className="scrollModal">
          <Form layout="vertical" form={form}>
            <Form.Item
              required={true}
              label={t('Name')}
              name="name"
              rules={[
                {
                  required: true,
                  message: t('Please enter the group name'),
                },
              ]}
            >
              <Input placeholder={t('Enter Group name')} className="p-2" />
            </Form.Item>
            <Tabs defaultActiveKey="1" items={items} />
          </Form>

          <Col style={{ display: 'flex', justifyItems: 'end', justifyContent: 'end' }}>
            <Tooltip title={t('Update')}>
              <AsyncButton
                style={{ marginTop: 20 }}
                buttonText={t('Update')}
                id="updateGroup"
                isLoading={isGroupUpdating}
                disabled={isGroupUpdating}
                onClick={handleUpdate}
              />
            </Tooltip>
          </Col>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default GroupUpdateModal;
