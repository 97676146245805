

import { createAsyncThunk } from '@reduxjs/toolkit';

import { PreProcessedDataSetsService } from 'src/utils/services/service/mLPreProcessingFlowService';



    export const fetchDeploymentStatus = createAsyncThunk(
      'deployment/fetchStatus',
      async (deploymentId: string, { rejectWithValue }) => {
        try {
          const response = await PreProcessedDataSetsService.getDeploymentStatus(deploymentId);
          return response; 
        } catch (error) {
          return rejectWithValue(error.response ? error.response.data : error.message);
        }
      }
    );
    