import { createSlice } from '@reduxjs/toolkit';
import {
  CreateManageHotelData,
  DeleteManageHotelData,
  GetAllManageHotelData,
  GetSingleManageHotelData,
  UpdateManageHotelData,
} from './thunk';
import { IManageHotel, IPagination } from 'src/utils/types/manageHotelTypes';

interface IManageHotelState {
  data: IManageHotel[];
  pagination: IPagination;
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: IManageHotelState = {
  data: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const manageHotelSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllManageHotelData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetAllManageHotelData.fulfilled, (state, action: any) => {
        if (action.payload) {
          const { data, pagination } = action.payload;
          state.data = data;
          state.pagination = pagination;
        } else {
          state.data = [];
          state.pagination = { currentPage: 1, pageSize: 10, totalPages: 0, totalCount: 0, hasPrevious: false, hasNext: false };
        }
        state.isLoading = false;
      })

      .addCase(GetAllManageHotelData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(GetSingleManageHotelData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GetSingleManageHotelData.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(GetSingleManageHotelData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateManageHotelData.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateManageHotelData.fulfilled, (state, action) => {
        state.data.push(action.payload as IManageHotel);
        state.isCreating = false;
      })
      .addCase(CreateManageHotelData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateManageHotelData.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateManageHotelData.fulfilled, (state, action) => {
        const updatedContact = action.payload as IManageHotel;
        const index = state.data.findIndex((contact) => contact.id === updatedContact.id);
        state.data[index] = updatedContact;
        state.isUpdating = false;
      })
      .addCase(UpdateManageHotelData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isUpdating = false;
      })
      .addCase(DeleteManageHotelData.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteManageHotelData.fulfilled, (state, action) => {
        const deletedContactId = action.payload as string;
        state.data = state.data.filter((contact) => contact.id !== deletedContactId);
        state.isDeleting = false;
      })
      .addCase(DeleteManageHotelData.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isDeleting = false;
      });

  },
});

export default manageHotelSlice.reducer;
