import React from 'react';
import { Table, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';

interface TableViewProps<T> extends TableProps<T> {
      columns: ColumnsType<T>;
      dataSource: T[];
      tableStyles?: React.CSSProperties;
      loading?: boolean;
      defaultPageSize?: number;
      pageSizeOptions?: string[];
      onPageSizeChange?: (pageSize: number) => void;
      showPagination?: boolean;
}

const TableView = <T extends object>({
      columns,
      dataSource,
      tableStyles,
      loading,
      size = 'large',
      defaultPageSize = 50,
      pageSizeOptions = ['25', '50', '100'],
      showPagination = true,
}: TableViewProps<T>) => {
      return (
            <div>
                  <Table
                        bordered
                        loading={loading}
                        style={tableStyles}
                        scroll={{ y: 400 }}
                        sticky={true}
                        columns={columns}
                        dataSource={dataSource}
                        showSorterTooltip={true}
                        size={size}
                        rowHoverable={true}
                        pagination={showPagination
                              ? {
                                    defaultPageSize,
                                    pageSizeOptions,
                                    showSizeChanger: true,
                              }
                              : false
                        }
                  />
            </div>
      );
};

export default TableView;
