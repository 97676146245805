import { createAsyncThunk } from '@reduxjs/toolkit';
import { createKey, deleteKey, editKey, getKeys, getUsers } from 'src/utils/services/service/apiKeysService';

export const getApiKeys = createAsyncThunk('keys/getApiKeys', async () => {
  return await getKeys();
});

export const getKeycloakUsers = createAsyncThunk('keys/getKeycloakUsers', async () => {
  return await getUsers();
});

export const createNewKey = createAsyncThunk<any, any, { rejectValue: string }>(
  'keys/createNewKey',
  async ({ data, tenant }: { data: any; tenant: string }) => {
    const res = await createKey(data, tenant);
    return res;
  },
);

export const changeApiKey = createAsyncThunk<any, any, { rejectValue: any; state: any }>(
  'keys/changeApiKey',
  async (data: any) => {
    return await editKey(data);
  },
);

export const deleteApiKey = createAsyncThunk<any, any, { rejectValue: any; state: any }>(
  'keys/deleteApiKey',
  async (data: any) => {
    return await deleteKey(data);
  },
);
