import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchOffers } from './thunk';
import { IOffer, IPagination } from 'src/utils/types/offerTypes';

interface IOffersState {
  offers: IOffer[];
  pagination: IPagination;
  loading: boolean;
  error: string | null;
}

const initialState: IOffersState = {
  offers: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  loading: false,
  error: null,
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOffers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })

      .addCase(fetchOffers.fulfilled, (state, action: any) => {
        if (action.payload) {
          const { data, pagination } = action.payload;
          state.offers = data;
          state.pagination = pagination;
        } else {
          state.offers = [];
          state.pagination = { currentPage: 1, pageSize: 10, totalPages: 0, totalCount: 0, hasPrevious: false, hasNext: false };
        }
        state.loading = false;
      })

      .addCase(fetchOffers.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

export default offersSlice.reducer;
