// For Server

// https://api.auth.slot.data.projects.excitingdevs.com/data/properties/GetPropertiesForTenant
const VITE_API_KEYS_DEFAULT_URL = 'https://api.slot.keys.data.projects.excitingdevs.com/api/settings';
const VITE_IMPORT_PROVIDER_DEFAULT_URL = 'https://api.slot.import.data.projects.excitingdevs.com';
const VITE_AUTH_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1/admin';
const VITE_USERS_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1';
const VITE_ANOMALY_DEFAULT_URL = 'https://api.slot.anomaly.data.projects.excitingdevs.com';
const VITE_WEATHER_CONFIG_DEFAULT_URL = 'https://api.slot.weatherproviders.data.projects.excitingdevs.com/api/settings';
const VITE_EXTERNAL_EVENT_DEFAULT_URL = 'https://api.slot.data.projects.excitingdevs.com/api/v1';
const VITE_DASHBOARD_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1/dashboard';
const VITE_EXTERNAL_EVENTS_DEFAULT_URL = 'https://api.slot.externalevents.data.projects.excitingdevs.com';
const VITE_SPECIAL_PERIODS_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1';
const VITE_ROOM_CONFIGURATIONS_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1';
const VITE_NOTIFICATION_CONTACTS_DEFAULT_URL = 'https://api.slot.data.projects.excitingdevs.com/api/v1';
const VITE_EXTERNAL_API_KEY_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1';
const VITE_PRICE_LIST_DEFAULT_URL = 'https://api.slot.data.projects.excitingdevs.com/api/v1';
const VITE_MANAGE_HOTEL_DATA_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1';
const VITE_MEAL_PLANS_DATA_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1';
const VITE_TAGS_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1';
const VITE_COMPETITOR_HOTELS_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1';
const VITE_TEXT_MANAGEMENT_DEFAULT_URL = 'https://api.dev.data.projects.excitingdevs.com/api/v1';
const VITE_IMAGE_MANAGEMENT_DEFAULT_URL = 'https://api.slot.data.projects.excitingdevs.com/api/v1';
const VITE_ROOM_INFORMATION_DEFAULT_URL = 'https://api.slot.data.projects.excitingdevs.com/api/v1';
const VITE_WEEKLY_COMPARISON_DEFAULT_URL = 'https://api.slot.data.projects.excitingdevs.com/api/v1';
const VITE_ROLE_MANAGEMENT_DEFAULT_URL = 'https://api.slot.role-management.data.projects.excitingdevs.com';
const VITE_ACCESS_SHIELD_DEFAULT_URL = 'https://api.slot.data.projects.excitingdevs.com/api/v1';

// const VITE_API_KEYS_DEFAULT_URL = "http://localhost:5163"
// const VITE_IMPORT_PROVIDER_DEFAULT_URL = "http://localhost:5032"
// const VITE_AUTH_DEFAULT_URL = "http://localhost:5044"
// const VITE_USERS_DEFAULT_URL = "http://localhost:5207"
// const VITE_ANOMALY_DEFAULT_URL = "http://localhost:5094"
// const VITE_WEATHER_CONFIG_DEFAULT_URL= "https://localhost:7291"
// const VITE_EXTERNAL_EVENT_DEFAULT_URL= "http://localhost:5070"
// const VITE_DASHBOARD_DEFAULT_URL= "http://localhost:5264"

const DEV = 'dev';
const SLOT = 'slot';

const ENVIROMENT = [DEV];

const getBackendServerUrl = (slotUrl: string) => {
  for (const environment of ENVIROMENT) {
    if (window.location.hostname.includes('.' + environment + '.')) {
      return slotUrl.replace(SLOT, environment);
    }
  }
  return slotUrl.replace(SLOT, DEV);
};

const VITE_REACT_APP_API_KEYS_DEFAULT = () => getBackendServerUrl(VITE_API_KEYS_DEFAULT_URL);
const VITE_REACT_APP_IMPORT_PROVIDER_DEFAULT = () => getBackendServerUrl(VITE_IMPORT_PROVIDER_DEFAULT_URL);
const VITE_REACT_APP_DEFAULTAUTH = () => getBackendServerUrl(VITE_AUTH_DEFAULT_URL);
const VITE_REACT_APP_DEFAULTAUTH_USERS = () => getBackendServerUrl(VITE_USERS_DEFAULT_URL);
const VITE_DEFAULT_ANOMALY = () => getBackendServerUrl(VITE_ANOMALY_DEFAULT_URL);
const VITE_WEATHER_CONFIG_DEFAULT = () => getBackendServerUrl(VITE_WEATHER_CONFIG_DEFAULT_URL);
const VITE_EXTERNAL_EVENT = () => getBackendServerUrl(VITE_EXTERNAL_EVENT_DEFAULT_URL);
const VITE_DASHBOARD = () => getBackendServerUrl(VITE_DASHBOARD_DEFAULT_URL);
const VITE_EXTERNAL_EVENTS_DEFAULT = () => getBackendServerUrl(VITE_EXTERNAL_EVENTS_DEFAULT_URL);
const VITE_SPECIAL_PERIODS_DEFAULT_BASE_URL = () => getBackendServerUrl(VITE_SPECIAL_PERIODS_DEFAULT_URL);
const VITE_ROOM_CONFIGURATIONS_BASE_URL = () => getBackendServerUrl(VITE_ROOM_CONFIGURATIONS_DEFAULT_URL);
const VITE_NOTIFICATION_CONTACTS_BASE_URL = () => getBackendServerUrl(VITE_NOTIFICATION_CONTACTS_DEFAULT_URL);
const VITE_EXTERNAL_API_KEY_BASE_URL = () => getBackendServerUrl(VITE_EXTERNAL_API_KEY_DEFAULT_URL);
const VITE_PRICE_LIST_DEFAULT_BASE_URL = () => getBackendServerUrl(VITE_PRICE_LIST_DEFAULT_URL);
const VITE_MANAGE_HOTEL_DATA_DEFAULT_BASE_URL = () => getBackendServerUrl(VITE_MANAGE_HOTEL_DATA_DEFAULT_URL);
const VITE_MEAL_PLANS_DATA_DEFAULT_BASE_URL = () => getBackendServerUrl(VITE_MEAL_PLANS_DATA_DEFAULT_URL);
const VITE_TAGS_DEFAULT_BASE_URL = () => getBackendServerUrl(VITE_TAGS_DEFAULT_URL);
const VITE_COMPETITOR_HOTELS_DEFAULT_BASE_URL = () => getBackendServerUrl(VITE_COMPETITOR_HOTELS_DEFAULT_URL);
const VITE_TEXT_MANAGEMENT_BASE_URL = () => getBackendServerUrl(VITE_TEXT_MANAGEMENT_DEFAULT_URL);
const VITE_IMAGE_MANAGEMENT_DATA_DEFAULT_BASE_URL = () => getBackendServerUrl(VITE_IMAGE_MANAGEMENT_DEFAULT_URL);
const VITE_ROOM_INFORMATION_DEFAULT_BASE_URL = () => getBackendServerUrl(VITE_ROOM_INFORMATION_DEFAULT_URL);
const VITE_COMPARISON_DATA_BASE_URL = () => getBackendServerUrl(VITE_WEEKLY_COMPARISON_DEFAULT_URL);
const VITE_ROLE_MANAGEMENT_BASE_URL = () => getBackendServerUrl(VITE_ROLE_MANAGEMENT_DEFAULT_URL);
const VITE_ACCESS_SHIELD_BASE_URL = () => getBackendServerUrl(VITE_ACCESS_SHIELD_DEFAULT_URL);

const env = {
  VITE_REACT_APP_API_KEYS_DEFAULT,
  VITE_REACT_APP_IMPORT_PROVIDER_DEFAULT,
  VITE_REACT_APP_DEFAULTAUTH,
  VITE_REACT_APP_DEFAULTAUTH_USERS,
  VITE_DEFAULT_ANOMALY,
  VITE_WEATHER_CONFIG_DEFAULT,
  VITE_EXTERNAL_EVENT,
  VITE_DASHBOARD,
  VITE_EXTERNAL_EVENTS_DEFAULT,
  VITE_SPECIAL_PERIODS_DEFAULT_BASE_URL,
  VITE_ROOM_CONFIGURATIONS_BASE_URL,
  VITE_NOTIFICATION_CONTACTS_BASE_URL,
  VITE_EXTERNAL_API_KEY_BASE_URL,
  VITE_PRICE_LIST_DEFAULT_BASE_URL,
  VITE_MANAGE_HOTEL_DATA_DEFAULT_BASE_URL,
  VITE_MEAL_PLANS_DATA_DEFAULT_BASE_URL,
  VITE_TAGS_DEFAULT_BASE_URL,
  VITE_COMPETITOR_HOTELS_DEFAULT_BASE_URL,
  VITE_TEXT_MANAGEMENT_BASE_URL,
  VITE_IMAGE_MANAGEMENT_DATA_DEFAULT_BASE_URL,
  VITE_ROOM_INFORMATION_DEFAULT_BASE_URL,
  VITE_COMPARISON_DATA_BASE_URL,
  VITE_ROLE_MANAGEMENT_BASE_URL,
  VITE_ACCESS_SHIELD_BASE_URL,
};
export default env;
