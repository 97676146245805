import { createAsyncThunk } from '@reduxjs/toolkit';
import { MealPlansDataService } from 'src/utils/services/service/mealPlans';

import { IMealPlans, IPagination } from 'src/utils/types/mealPlansTypes';

export const CreateMealPlansData = createAsyncThunk(
  'data/createMealPlansData',
  async ({ data }: { data: IMealPlans }) => {
    try {
      const response = await MealPlansDataService.createMealPlansData(data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const GetAllMealPlansData = createAsyncThunk<
  { data: IMealPlans[]; pagination: IPagination },
  { page: number; pageSize: number; }
>('data/getAllMealPlansData', async ({ page, pageSize }) => {
  try {
    const response = await MealPlansDataService.getAllMealPlansData(page, pageSize);
    return response;
  } catch (error) {
    return error;
  }
});


export const GetSingleMealPlansData = createAsyncThunk(
  'data/getSingleMealPlansData',
  async ({ mealplansId }: { mealplansId: string }) => {
    try {
      const response = await MealPlansDataService.getSingleMealPlansData(mealplansId);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const UpdateMealPlansData = createAsyncThunk(
  'data/updateMealPlansData',
  async ({ mealplansId, data }: { mealplansId: string; data: IMealPlans }) => {
    try {
      const response = await MealPlansDataService.updateMealPlansData(mealplansId, data);
      return response;
    } catch (error) {
      return error;
    }
  },
);

export const DeleteMealPlansData = createAsyncThunk(
  'data/deleteMealPlansData',
  async ({ mealplansId }: { mealplansId: string }) => {
    try {
      const response = await MealPlansDataService.deleteMealPlansData(mealplansId);
      return response;
    } catch (error) {
      return error;
    }
  },
);
