import React, { useState, useEffect } from 'react';
import { DualAxes } from '@ant-design/plots';

interface DataItem {
  [key: string]: any;
}

interface DualAxesChartProps {
  response: DataItem[];
  xField: string;
  barField: string;
  lineField: string;
  maxDomain: number;
  barLabel?: string;
  lineLabel?: string;
  barColor?: string;
  lineColor?: string;
}

const DualAxesChart: React.FC<DualAxesChartProps> = ({
  response,
  xField,
  barField,
  lineField,
  maxDomain,
  barLabel = 'Bar Data',
  lineLabel = 'Line Data',
  barColor = '#2ECC71',
  lineColor = '#82ca9d',
}: DualAxesChartProps) => {
  const transformData = (response: DataItem[]) => {
    return response.map((item: DataItem) => ({
      ...item,
      [xField]: item[xField],
      [barField]: item[barField],
      [lineField]: item[lineField],
    }));
  };

  const transformedData = transformData(response);
  // Check if targetOccupancy is present in the data
  const hasTargetOccupancy = response.some((item) => 'targetOccupancy' in item);
  const config = {
    xField,
    data: transformedData,

    legend: {
      itemMarker: (name: any) => {
        if (name === barLabel) return { symbol: 'square', style: { fill: barColor } };
        if (name === lineLabel) return { symbol: 'line', style: { stroke: lineColor, lineWidth: 2 } };
        if (name === 'Target Occupancy')
          return { symbol: 'line', style: { stroke: '#8E44AD', lineWidth: 2, lineDash: [2, 2] } };
        return { symbol: 'circle', style: {} };
      },
    },
    children: [
      {
        type: 'interval',
        yField: barField,
        scale: { y: { domainMax: maxDomain, domainMin: 0 } },
        axis: {
          y: {
            position: 'left',
          },
        },
        style: {
          // color: "#2ECC71",
          fill: ({ isCancellation }: { isCancellation: boolean }) => {
            if (isCancellation) {
              return '#E74C3C';
            }
            // return '#2ECC71';
          },
        },
      },
      {
        type: 'line',
        yField: lineField,
        shapeField: 'smooth',
        scale: { color: { relations: [[lineField, '#fdae6b']] }, y: { domainMax: maxDomain, domainMin: 0 } },
        axis: {
          y: {
            position: 'right',
          },
        },
        style: { lineWidth: 2 },
      },
      // Conditionally add the second line configuration
      ...(hasTargetOccupancy
        ? [
            {
              type: 'line',
              yField: 'targetOccupancy',
              shapeField: 'smooth',
              scale: { color: { relations: [[lineField, '#8E44AD']] }, y: { domainMax: maxDomain, domainMin: 0 } },
              style: {
                lineWidth: 2,
                lineDash: [2, 2],
              },
              axis: {
                y: false,
              },
            },
          ]
        : []),
    ],
  };

  return <DualAxes {...config} />;
};

const defaultDualAxesChartValue: DualAxesChartProps = {
  response: [],
  xField: 'date',
  barField: 'price',
  lineField: 'occupancy',
  maxDomain: 100,
};

export default DualAxesChart;
export type { DualAxesChartProps };
export { defaultDualAxesChartValue };
