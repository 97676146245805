/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { NotificationType } from 'src/utils/types/NotificationTypes';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Form, Input, Table, Tooltip } from 'antd';
import AsyncButton from 'src/components/Common/LoaderButton/LoaderButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/slices';
import { CreateRole, GetAllRoles, UpdateRole } from 'src/slices/Permissions/thunk';
import { AppDispatch } from 'src/store';
import { IRoles, IPagination } from 'src/utils/types/roleTypes';
import { GetAllAccessDetails, UpdateAccessDetailsForAUser } from 'src/slices/AccessShield/thunk';
import { FiArrowLeft, FiEdit, FiSkipBack } from 'react-icons/fi';
import { Collapse, Checkbox } from 'antd';
import { LuArrowRight } from 'react-icons/lu';
import { BiLinkExternal } from 'react-icons/bi';

interface IRoleModalProps {
  pagination: IPagination;
  isOpen: boolean;
  toggle: () => void;
  openNotificationWithIcon: (type: NotificationType, message: string, description?: string) => void;
  initialValues?: any | null;
  isEditMode?: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
  setEditingData: (data: any | null) => void;
  selectedRoleName: string;
}

const RoleModal = ({
  isOpen,
  pagination,
  openNotificationWithIcon,
  setEditingData,
  setIsEditMode,
  toggle,
  initialValues,
  isEditMode,
  selectedRoleName,
}: IRoleModalProps) => {
  const { t } = useTranslation('');
  const [form] = useForm();
  const { AllAccessDetails, isAllAccessDetailsLoading } = useSelector((state: RootState) => state.accessDetails);

  useEffect(() => {
    if (!selectedRoleName) return;
    dispatch(GetAllAccessDetails(selectedRoleName));
  }, [selectedRoleName]);

  useEffect(() => {
    if (isEditMode && initialValues !== null) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, isEditMode]);

  console.log(AllAccessDetails);
  const [userAccessRecord, setUserRecords] = useState<null | []>(null);

  const { isRolesLoading, isRoleCreating, isRolesUpdating } = useSelector((state: RootState) => state.permissions);
  const dispatch = useDispatch<AppDispatch>();
  const onModalClose = () => {
    form.resetFields();
    toggle();
    setUserRecords(null);
    if (isEditMode) {
      setIsEditMode(false);
      setEditingData(null);
    }
  };

  const handleCreateOrUpdate = async () => {
    await form.validateFields();

    const values = form.getFieldsValue();
    try {
      if (isEditMode) {
        // ** Update Operation
        values.id = initialValues?.id;
        const response = await dispatch(
          UpdateRole({
            data: values,
            roleId: initialValues?.id,
          }),
        );
        // ** Handled Response
        if ((response.payload as any)['id']) {
          openNotificationWithIcon('success', t('Role updated successfully'));
          onModalClose();
          dispatch(GetAllRoles({ page: pagination.currentPage, pageSize: pagination.pageSize }));
        } else {
          openNotificationWithIcon('error', t(`Error updating role`));
        }
      } else {
        // ** Create Operation
        const response = await dispatch(CreateRole({ data: values }));

        // ** Handled Response
        if ((response.payload as any)['id']) {
          openNotificationWithIcon('success', t('Role added successfully'));
          onModalClose();
          dispatch(GetAllRoles({ page: pagination.currentPage, pageSize: pagination.pageSize }));
        } else {
          openNotificationWithIcon('error', t(`Error adding role`));
        }
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', t(`Something went wrong`));
    }
  };

  console.log(userAccessRecord);
  const columns = [
    {
      title: t('Actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '10%',
      render: (_text: string, record: any) => (
        <div className="tableIconBlock">
          <Tooltip title={t('Update')} placement="top">
            <div
              className="tableIcon"
              onClick={() => {
                setUserRecords(record);
                // setIsModalOpen(!isModalOpen);
                // setIsEditMode(true);
                // setEditingData(record);
                // setSelectedRoleName(record?.name);
              }}
            >
              <BiLinkExternal id="updateSpecialPeriod" size={20} />
            </div>
          </Tooltip>

          {/* <Tooltip title={t('Delete')} placement="top">
            <div className="tableIcon">
              <DeleteButton
                title={t('Are you sure you want to delete?')}
                cancelText={t('Cancel')}
                okText={t('Proceed')}
                okButtonId={`confirmAndDeleteRoomPlan`}
                triggerButtonId="deleteRoomPlan"
                onConfirm={() => {
                  handleDeleteRole(record.id as string);
                }}
                aria-label={t('Delete room plan')}
              />
            </div>
          </Tooltip> */}
        </div>
      ),
    },
    {
      title: t('User Id'),
      dataIndex: 'userId',
      key: 'userId',
      render: (name: string) => <span>{name}</span>,
    },
    {
      title: t('Role Name'),
      dataIndex: 'roleName',
      key: 'roleName',
      render: (description: string) => <span>{description}</span>,
    },
  ];

  return (
    <div>
      <Modal centered isOpen={isOpen} size="xl" toggle={onModalClose} scrollable>
        <ModalHeader toggle={onModalClose} tag="h5">
          {selectedRoleName}
        </ModalHeader>
        <ModalBody>
          {userAccessRecord ? (
            <>
              <Button
                onClick={() => {
                  setUserRecords(null);
                }}
                style={{
                  marginBottom: '20px',
                }}
              >
                <FiArrowLeft />
              </Button>
              <PermissionForm
                selectedRoleName={selectedRoleName}
                data={userAccessRecord}
                openNotificationWithIcon={openNotificationWithIcon}
                setUserRecords={setUserRecords}
              />
            </>
          ) : (
            <Table columns={columns} dataSource={AllAccessDetails?.accessShields} loading={isAllAccessDetailsLoading} />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RoleModal;

const PermissionForm = ({
  data,
  openNotificationWithIcon,
  setUserRecords,
  selectedRoleName,
}: {
  data: any;
  openNotificationWithIcon: any;
  setUserRecords: any;
  selectedRoleName: string;
}) => {
  const [formState, setFormState] = useState(data);
  const { Panel } = Collapse;

  const dispatch: AppDispatch = useDispatch();
  const { isUpdatingAccessDetails } = useSelector((state: RootState) => state.accessDetails);

  // Handle changes for checkboxes
  const handleCheckboxChange = (menuName: string, subMenuName?: string, permissionName?: string) => {
    setFormState((prevState: any) => {
      // Create a deep clone to avoid mutating the previous state directly
      const updatedMenus = prevState?.menus.map((menu: any) => {
        if (menu.menuName === menuName) {
          // Toggle main menu
          if (!subMenuName) {
            return { ...menu, isActive: !menu.isActive };
          }

          // Toggle sub-menu
          const updatedSubMenus = menu.subMenus.map((subMenu: any) => {
            if (subMenu.subMenuName === subMenuName) {
              // Toggle specific permission
              if (permissionName) {
                const updatedPermissions = subMenu.permissions.map((permission: any) => {
                  if (permission.permissionName === permissionName) {
                    return { ...permission, isActive: !permission.isActive };
                  }
                  return permission;
                });
                return { ...subMenu, permissions: updatedPermissions };
              }

              return { ...subMenu, isActive: !subMenu.isActive };
            }
            return subMenu;
          });

          return { ...menu, subMenus: updatedSubMenus };
        }
        return menu;
      });

      return { ...prevState, menus: updatedMenus }; // Return the updated state object
    });
  };

  // Form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Updated Form State:', formState); // Correctly logs the updated form state

    try {
      const response: any = await dispatch(
        UpdateAccessDetailsForAUser({
          accessData: formState,
          userId: formState.userId,
        }),
      );

      if (response?.payload?.userId) {
        openNotificationWithIcon('success', 'Access Details Updated');
        setUserRecords(null);
        dispatch(GetAllAccessDetails(selectedRoleName));
      } else {
        openNotificationWithIcon('error', `Error updating Access Details`);
      }

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Collapse accordion defaultActiveKey={'Dashboards'}>
        {formState?.menus?.map((menu: any) => (
          <Panel header={menu.menuName} key={menu.menuName}>
            <Checkbox checked={menu.isActive} onChange={() => handleCheckboxChange(menu.menuName)}>
              <strong
                style={{
                  fontSize: '18px',
                }}
              >
                {menu.menuName}
              </strong>
            </Checkbox>

            <div style={{ marginLeft: '20px', marginTop: '5px' }}>
              {menu.subMenus.map((subMenu: any) => (
                <div key={subMenu.subMenuName} style={{ marginBottom: '10px' }}>
                  <Checkbox
                    style={{
                      fontSize: '15px',
                    }}
                    checked={subMenu.isActive}
                    onChange={() => handleCheckboxChange(menu.menuName, subMenu.subMenuName)}
                  >
                    {subMenu.subMenuName}
                  </Checkbox>

                  <div style={{ marginLeft: '20px', marginTop: '5px' }}>
                    {subMenu.permissions.map((permission: any) => (
                      <Checkbox
                        key={permission.permissionName}
                        checked={permission.isActive}
                        onChange={() =>
                          handleCheckboxChange(menu.menuName, subMenu.subMenuName, permission.permissionName)
                        }
                      >
                        {permission.permissionName}
                      </Checkbox>
                    ))}
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </Panel>
        ))}
      </Collapse>
      <div
        style={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'end',
          alignContent: 'center',
        }}
      >
        <AsyncButton
          onClick={() => {}}
          type="submit"
          buttonText="Save"
          id="UpdateUserAccessDetails"
          isLoading={isUpdatingAccessDetails}
        />
      </div>
    </form>
  );
};
