import { createSlice } from '@reduxjs/toolkit';
import { IText, IPagination } from 'src/utils/types/textTypes';
import { GetAllTexts, CreateNewTextEntry, DeleteTextEntry, GetSingleText, UpdateTextEntry } from './thunk';

interface ITextsState {
  data: IText[];
  pagination: IPagination;
  isLoading: boolean;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  error: string | null;
}

const initialState: ITextsState = {
  data: [],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  },
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
};

const textsSlice = createSlice({
  name: 'texts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllTexts.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(GetAllTexts.fulfilled, (state, action) => {
        if (action.payload) {
          const { data, pagination } = action.payload;
          state.data = data;
          state.pagination = pagination;
        } else {
          state.data = [];
          state.pagination = {
            currentPage: 1,
            pageSize: 10,
            totalPages: 0,
            totalCount: 0,
            hasPrevious: false,
            hasNext: false,
          };
        }

        state.isLoading = false;
      })
      .addCase(GetAllTexts.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isLoading = false;
      })
      .addCase(CreateNewTextEntry.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(CreateNewTextEntry.fulfilled, (state) => {
        state.isCreating = false;
      })
      .addCase(CreateNewTextEntry.rejected, (state, action) => {
        state.error = action.payload as string;
        state.isCreating = false;
      })
      .addCase(UpdateTextEntry.pending, (state) => {
        state.isUpdating = true;
      })
      .addCase(UpdateTextEntry.fulfilled, (state) => {
        state.isUpdating = false;
      })
      .addCase(UpdateTextEntry.rejected, (state, action) => {
        state.isUpdating = false;
      })
      .addCase(DeleteTextEntry.pending, (state) => {
        state.isDeleting = true;
      })
      .addCase(DeleteTextEntry.fulfilled, (state) => {
        state.isDeleting = false;
      })
      .addCase(DeleteTextEntry.rejected, (state) => {
        state.isDeleting = false;
      });
  },
});

export default textsSlice.reducer;
