import { getDecodedToken } from 'src/utils/jwtDecode';
import env from "../../../env";
import { getTenantId } from 'src/utils/helpers';
import api from 'src/utils/services/axiosConfig';

const apiKeyUrl = env.VITE_REACT_APP_IMPORT_PROVIDER_DEFAULT();

export const getConfigs = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: null,
    };

    const tokenData = getDecodedToken();
    const tenantId = getTenantId(tokenData);

    const res = await api.get(`/providers/import/configurations/tenant/${tenantId}`, { headers, baseURL: apiKeyUrl });
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTenantProviders = async () => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: null, // `Bearer ${accessToken}`,
    };

    const tokenData = getDecodedToken();
    const tenantId = getTenantId(tokenData);

    const res = await api.get(`/providers/import?tenantId=${tenantId}`, { headers, baseURL: apiKeyUrl });
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const createConfig = async (data: any) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    const res = await api.post(`/providers/import/configurations`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL: apiKeyUrl,
    });

    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateConfig = async (config: any) => {
  try {
    const accessToken = localStorage.getItem('accessToken');

    const res = await api.put(
      `/providers/import/configurations/${config.configurationId}`,
      {
        configurationName: config.configName,
        ...config.data,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        baseURL: apiKeyUrl,
      },
    );

    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteConfig = async (data: any) => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await api.delete(`/providers/import/configurations/${data.configurationId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    baseURL: apiKeyUrl,
  });

  return res.data;
};
