/* eslint-disable react-hooks/exhaustive-deps */
import MetaTags from 'react-meta-tags';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { notification, Pagination, Select, Tooltip } from 'antd';
import { IRoomConfiguration, IPagination } from 'src/utils/types/roomConfigurationsTypes';
import { Link } from 'react-router-dom';
import RoomPlanModal from './components/RoomPlanModal';
import { TableProps } from 'antd/lib';
import DeleteButton from 'src/components/Common/DeleteButton/DeleteButton';
import { FiEdit } from 'react-icons/fi';
import SearchBar from 'src/components/Common/SearchBar/SearchBar';
import TableViewPagination from 'src/components/Common/TableView/TableViewPagination';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { DeleteRoomPlan, GetAllRoomPlans } from 'src/slices/RoomPlan/thunk';
import { useTranslation } from 'react-i18next';
import { checkPermission } from 'src/helpers/checkActionPermission';
type NotificationType = 'success' | 'info' | 'warning' | 'error';

const RoomPlans = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingData, setEditingData] = useState<IRoomConfiguration | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation('');
  const [searchField, setSearchField] = useState('Name');
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
  });
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api[type]({
      message: message,
    });
  };

  const dispatch: AppDispatch = useDispatch();
  const { isLoading, roomConfigurations, isDeleting } = useSelector((state: RootState) => state.roomConfiguration);
  // Options for search categories
  const searchOptions = [
    { label: t('Name'), value: 'Name' },
    { label: t('Title'), value: 'Title' },
    { label: t('Description'), value: 'Description' },
    { label: t('Room Type'), value: 'Type' },
  ];

  useEffect(() => {
    dispatch(GetAllRoomPlans({ page: pageNumber, pageSize })).then((action: any) => {
      if (action.payload) {
        setPagination(action.payload.pagination);
      }
    });
  }, [pageNumber, pageSize]);
  const { AccessInFormation } = useSelector((state: RootState) => state.accessDetails);
  // console.log(AccessInFormation);
  const CheckHasDeletePermission = checkPermission('delete', 'Room Plans', AccessInFormation);
  const CheckHasCreatePermission = checkPermission('post', 'Room Plans', AccessInFormation);
  const CheckHasUpdatePermission = checkPermission('put', 'Room Plans', AccessInFormation);
  const columns: TableProps<IRoomConfiguration>['columns'] = [
    {
      title: t('Actions'),
      dataIndex: 'x',
      width: 100,
      render: (_, record) => {
        return (
          <div className="tableIconBlock">
            <Tooltip title={t('Edit')} placement="top">
              {CheckHasUpdatePermission ? (
                <div
                  className="tableIcon"
                  onClick={() => {
                    setIsModalOpen(!isModalOpen);
                    setIsEditMode(true);
                    setEditingData(record);
                  }}
                  role="button"
                  aria-label={t('Edit room plan')}
                >
                  <FiEdit id="updateRoomPlan" size={20} />
                </div>
              ) : (
                ''
              )}
            </Tooltip>
            <Tooltip title={t('Delete')} placement="top">
              <div className="tableIcon">
                {CheckHasDeletePermission ? (
                  <DeleteButton
                    title={t('Are you sure you want to delete?')}
                    cancelText={t('Cancel')}
                    okText={t('Proceed')}
                    okButtonId={`confirmAndDeleteRoomPlan`}
                    triggerButtonId="deleteRoomPlan"
                    onConfirm={() => {
                      deleteRoomPlan(record.id as string);
                    }}
                    aria-label={t('Delete room plan')}
                  />
                ) : (
                  ''
                )}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: t('Name'),
      key: 'name',
      dataIndex: 'name',
      width: 200,
    },
    {
      title: t('Title'),
      key: 'title',
      dataIndex: 'title',
      width: 200,
    },
    {
      title: t('Count'),
      key: 'count',
      dataIndex: 'count',
      width: 200,
    },
    {
      title: t('Description'),
      key: 'description',
      dataIndex: 'description',
      width: 200,
    },
    {
      title: t('Room Type'),
      key: 'type',
      dataIndex: 'type',
      width: 200,
    },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchTerm.trim()) {
      dispatch(
        GetAllRoomPlans({
          page: pageNumber,
          pageSize,
          searchField,
          searchTerm,
        }),
      );
    }
  };

  const deleteRoomPlan = async (roomId: string) => {
    if (!roomId) {
      openNotificationWithIcon('error', t('Did not find the room id'));
      return;
    }
    try {
      const response = await dispatch(
        DeleteRoomPlan({
          roomConfigurationId: roomId,
        }),
      );
      if (response.meta.requestStatus === 'fulfilled') {
        openNotificationWithIcon('success', t('Room plan deleted successfully'));
        dispatch(GetAllRoomPlans({ page: pageNumber, pageSize }));
      } else {
        openNotificationWithIcon('error', t('Error deleting room plan'));
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', t('Something went wrong'));
    }
  };

  return (
    <div>
      {contextHolder}
      <div className="page-content">
        <MetaTags>
          <title>{t('Room Plans')}</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title={t('Settings')} breadcrumbItem={t('Room Plan')} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Tooltip title={t('Search rooms by name, title, description')}>
                        <div className="d-flex align-items-center ">
                          {/* Dropdown to select search field */}
                          <div>
                            <Select
                              defaultValue={searchField}
                              style={{ width: 200, marginRight: 10 }}
                              onChange={setSearchField}
                              options={searchOptions}
                            />
                          </div>
                          <div>
                            <SearchBar
                              id="roomPlanSearchBar"
                              value={searchTerm}
                              onChange={handleSearch}
                              placeholder={t('Search rooms by name, title, description')}
                            />
                          </div>
                        </div>
                      </Tooltip>
                    </Col>

                    <Col md={6}>
                      <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                        <div>
                          <Button style={{ cursor: 'pointer' }} onClick={handleSearchSubmit}>
                            {t('Search')}
                          </Button>
                        </div>

                        <div>
                          {CheckHasCreatePermission && (
                            <Tooltip title={t('Add')}>
                              <Button
                                id="createRoomPlan"
                                className="btn btn-light"
                                onClick={() => {
                                  setIsModalOpen(!isModalOpen);
                                }}
                              >
                                <i className="uil uil-plus me-1"></i> {t('Add')}
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Table View */}
                  <div
                    style={{
                      height: '64vh',
                    }}
                  >
                    <TableViewPagination
                      loading={isLoading || isDeleting}
                      columns={columns}
                      dataSource={roomConfigurations}
                      showPagination={false}
                    />
                    <Pagination
                      current={pageNumber}
                      pageSize={pageSize}
                      total={pagination?.totalCount || 0}
                      onChange={(page, pageSize) => {
                        setPageNumber(page);
                        setPageSize(pageSize);
                        dispatch(GetAllRoomPlans({ page, pageSize }));
                      }}
                      style={{ float: 'right', marginTop: '20px' }}
                      showSizeChanger
                      pageSizeOptions={['10', '20', '30', '50', '100']}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/**  Modal to handle create and update  **/}
          <RoomPlanModal
            pagination={pagination}
            isEditMode={isEditMode}
            initialValues={editingData}
            openNotificationWithIcon={openNotificationWithIcon}
            isOpen={isModalOpen}
            toggle={() => setIsModalOpen(!isModalOpen)}
            setIsEditMode={setIsEditMode}
            setEditingData={setEditingData}
          />
        </Container>
      </div>
    </div>
  );
};

export default RoomPlans;
