import axios from 'axios';
import env from 'src/env';
import { HotelData, IPagination } from 'src/utils/types/HotelData';

const baseURL = env.VITE_COMPETITOR_HOTELS_DEFAULT_BASE_URL();
const notificationBaseUrl = '/pms/competitorhotel/competitor-hotel';
// const notificationBaseUrl = '/pms/competitorhotel';

const createCompetitorHotelEntry = async (data: HotelData): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.post(`${notificationBaseUrl}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error creating competitor hotel entry:', error);
    throw error;
  }
};

const readHotelCompetitors = async (
  pageNumber: number,
  pageSize: number,
): Promise<{ data: HotelData[]; pagination: IPagination } | null | any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const headers = {
      Authorization: accessToken ? `Bearer ${accessToken}` : null,
    };
    const params: {
      pageNumber: number;
      pageSize: number;
    } = { pageNumber, pageSize };

    const response = await axios.get(`${notificationBaseUrl}`, {
      headers,
      baseURL,
      params,
    });

    return response;
  } catch (error) {
    console.error('Error reading hotel competitors:', error);
    throw error;
  }
};

const readSpecificHotelCompetitor = async (Id: any): Promise<HotelData> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.get(`${notificationBaseUrl}/${Id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response.data;
  } catch (error) {
    console.error('Error reading specific hotel competitor:', error);
    throw error;
  }
};

const updateCompetitorHotelEntry = async (data: HotelData, id: string): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.put(`${notificationBaseUrl}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error updating competitor hotel entry:', error);
    throw error;
  }
};

const deleteCompetitorHotelEntry = async (Id: any): Promise<any> => {
  try {
    const accessToken = localStorage.getItem('accessToken');
    const response = await axios.delete(`${notificationBaseUrl}/${Id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      baseURL,
    });

    return response;
  } catch (error) {
    console.error('Error deleting competitor hotel entry:', error);
    throw error;
  }
};

export const HotelCompetitorService = {
  createCompetitorHotelEntry,
  readHotelCompetitors,
  readSpecificHotelCompetitor,
  updateCompetitorHotelEntry,
  deleteCompetitorHotelEntry,
};
